<template>
  <v-col cols="12">
    <div class="title">
      <h5 class="text-left font-weight-medium black--text">
        Top activities by file type ({{dateText}})
      </h5>
    </div>
    <div
      id="typeActivity"
      style="width: 100%;height:400px;"
      class="mb-7"
    ></div>
  </v-col>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
]);

export default {
  props: {
    activityTypeCount: Object,
    dateText: String,
  },

  data() {
    return {
      items: [],
      myChart: null,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "0%",
          right: "2%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [
            "Document",
            "Spreadsheet",
            "Presentation",
            "Text",
            "Image",
            "Video",
            "Audio",
            "Pdf",
          ],
        },
        series: [
          {
            name: "Download",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Upload",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Create",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Share",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Restore",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Trash",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (param) {
                return param.data == 0 ? "" : param.data;
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };
  },

  computed: {
    typeCount: {
      get() {
        return this.activityTypeCount;
      },
      set(value) {
        this.$emit("update:activityTypeCount", value);
      },
    },
  },

  mounted() {
    setTimeout(() => {
      var chartDom = document.getElementById("typeActivity");
      this.myChart = echarts.init(chartDom);

      if (this.typeCount.doc == undefined) {
        const unwatch = this.$watch("typeCount", () => {
          if (this.typeCount.doc == undefined) return;

          this.myChart.setOption(this.setOptions());

          unwatch();
        });
      } else {
        this.myChart.setOption(this.setOptions());
      }

      let chart = this.myChart;

      window.addEventListener(
        "resize",
        function () {
          chart.resize();
        },
        true
      );
    }, "200");
  },

  methods: {
    setOptions() {
      let objects = [
        this.typeCount.doc,
        this.typeCount.xls,
        this.typeCount.ppt,
        this.typeCount.text,
        this.typeCount.image,
        this.typeCount.video,
        this.typeCount.audio,
        this.typeCount.pdf,
      ];

      for (let i = 0; i < this.option.series.length; i++) {
        this.option.series[i].data = [0, 0, 0, 0, 0, 0, 0, 0];
      }

      for (let i = 0; i < objects.length; i++) {
        for (const property in objects[i]) {
          switch (property) {
            case "file_download":
              this.option.series[0].data[i] = objects[i][property];
              break;
            case "file_upload":
              this.option.series[1].data[i] = objects[i][property];
              break;
            case "file_create":
              this.option.series[2].data[i] = objects[i][property];
              break;
            case "file_share":
              this.option.series[3].data[i] = objects[i][property];
              break;
            case "file_restore":
              this.option.series[4].data[i] = objects[i][property];
              break;
            case "file_delete":
              this.option.series[5].data[i] = objects[i][property];
              break;

            default:
              break;
          }
        }
      }

      return this.option;
    },

    async loadData(item) {
      this.typeCount = item.activityTypeCount;
    },

    setChart(item) {
      this.loadData(item).then(() => {
        this.myChart.setOption(this.setOptions());
      });
    },
  },
};
</script>