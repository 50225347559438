<template>
  <v-dialog
    v-model="shareDialog"
    v-if="loaded"
    persistent
    max-width="555"
  >
    <v-card class="ma-0 pa-0">
      <v-col class="ma-0 pa-0 row">
        <v-card-title
          class="text-h5 d-inline-block text-truncate"
          style="max-width: 555px;"
        >
          Share <b>{{ getShareItem.name }}</b>
        </v-card-title>
      </v-col>
      <v-card-text class="pa-0">
        <v-row class="ma-4 pa-0 flex-nowrap">
          <v-autocomplete
            v-if="!linkView"
            v-model="share"
            :disabled="isUpdating"
            :color="getAppearances.btn_color"
            :items="itemsToSelect"
            @change="onChange"
            hide-selected
            chips
            label="Select users"
            item-text="name"
            item-value="id"
            return-object
            no-data-text="No users available"
            multiple
            hide-details
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar left>
                  <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                </v-avatar>
                {{ data.item.name }} {{data.item.lastname}}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-row class="pa-3">
                  <v-list-item-avatar>
                    <img :src="`/api/users/photo/${data.item.photo}`">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name + ' ' + data.item.lastname + ` (${data.item.email})`"></v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
            </template>
          </v-autocomplete>

          <v-btn
            v-if="!linkView"
            @click="switchLinkView(true)"
            :color="getAppearances.btn_color"
            elevation="0"
            small
            fab
            dark
            class="ml-4 mt-4"
          >
            <v-icon>
              mdi-link-variant
            </v-icon>
          </v-btn>
        </v-row>

        <v-scroll-y-transition
          mode="out-in"
          hide-on-leave
        >
          <div v-if="shareView && !linkView">
            <v-col
              cols="12"
              class="ma-0 pa-0"
            >
              <v-list
                class="transparent"
                dense
              >
                <v-list-item>
                  <v-list-item-title>Expiration date</v-list-item-title>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Select"
                        :color="getAppearances.btn_color"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      :color="getAppearances.btn_color"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Allow reshare</v-list-item-title>
                  <v-switch
                    v-model="reshare"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Allow edit</v-list-item-title>
                  <v-switch
                    v-model="allowEdit"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Allow edit metadata</v-list-item-title>
                  <v-switch
                    v-model="allowEditMeta"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
              </v-list>
            </v-col>
          </div>
        </v-scroll-y-transition>
        <v-scroll-y-transition>
          <div v-if="linkView">
            <v-card-text class="ma-0 pa-0">
              <v-list
                class="transparent"
                dense
              >
                <v-list-item>
                  <v-list-item-title>Expiration date</v-list-item-title>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editItem.expiration"
                        label="Select"
                        :color="getAppearances.btn_color"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editItem.expiration"
                      :color="getAppearances.btn_color"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Password protected</v-list-item-title>
                  <v-switch
                    v-model="editItem.passwordProtected"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
                <v-list-item v-if="editItem.passwordProtected == true">
                  <v-list-item-title>Password</v-list-item-title>
                  <v-text-field
                    v-model="editItem.password"
                    :color="getAppearances.btn_color"
                    type="password"
                    prepend-icon="mdi-lock"
                    placeholder="********"
                    label="Password"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Allow download</v-list-item-title>
                  <v-switch
                    v-model="editItem.allowDownload"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
                <v-list-item v-if="getShareItem.isFolder">
                  <v-list-item-title>Allow upload</v-list-item-title>
                  <v-switch
                    v-model="editItem.allowUpload"
                    :color="getAppearances.btn_color"
                    hide-details
                  ></v-switch>
                </v-list-item>
                <v-list-item>
                  <v-textarea
                    v-model="editItem.message"
                    :color="getAppearances.btn_color"
                    class="mt-2"
                    label="Enter message"
                    rows="1"
                  ></v-textarea>
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>
        </v-scroll-y-transition>
        <div v-if="!shareView && !linkView">
          <v-col
            class="pa-0"
            cols="12"
          >
            <v-list>
              <v-subheader>User permissions</v-subheader>
              <v-list-item
                v-if="fileOwner != null"
                class="v-list-item--link"
              >
                <v-badge
                  color="error"
                  offset-x="25"
                  offset-y="22"
                  bordered
                  overlap
                  :value="userStatus(fileOwner)"
                >
                  <template v-slot:badge>
                    <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                  </template>
                  <v-list-item-avatar>
                    <v-img :src="`/api/users/photo/${fileOwner.photo}`">
                    </v-img>
                  </v-list-item-avatar>
                </v-badge>

                <v-list-item-content>
                  <v-list-item-title v-text="fileOwner.name + ' ' + fileOwner.lastname"></v-list-item-title>
                  <v-list-item-subtitle v-text="fileOwner.email"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    :disabled="true"
                    depressed
                  >
                    owner
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                class="v-list-item--link"
                v-for="(item, index) in getDetails.sharedUsers"
                :key="index"
              >
                <v-badge
                  color="error"
                  offset-x="25"
                  offset-y="22"
                  bordered
                  overlap
                  :value="userStatus(item)"
                >
                  <template v-slot:badge>
                    <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                  </template>
                  <v-list-item-avatar>
                    <v-img :src="`/api/users/photo/${item.photo}`">
                    </v-img>
                  </v-list-item-avatar>
                </v-badge>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name + ' ' + item.lastname"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="getShareItem.owner == item.id"
                        v-bind="attrs"
                        v-on="on"
                        depressed
                      >
                        {{ getShareItem.owner == item.id ? 'owner' : 'writer'}}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="openPermissions(item)">
                        <v-list-item-title>permissions</v-list-item-title>
                      </v-list-item>
                      <v-dialog
                        v-model="confirmation"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>unshare</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            Unshare this user?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="confirmation = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="deleteShare(item)"
                            >
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            v-if="getDetails.sharedLinks != undefined && getDetails.sharedLinks.length > 0 && !shareView && !linkView"
            class="py-0"
            cols="12"
          >
            <v-list>
              <v-subheader>Links</v-subheader>
              <v-list-item
                class="v-list-item--link"
                v-for="(item, index) in getDetails.sharedLinks"
                :key="index"
              >
                <v-list-item-avatar :color="getAppearances.btn_color">
                  <v-icon dark>
                    mdi-link-variant
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="'Shared by ' + item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="getDateAndTime(item.shareTime)"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                      >
                        edit
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openLinkPermissions(item)">
                        <v-list-item-title>permissions</v-list-item-title>
                      </v-list-item>
                      <v-dialog
                        v-model="linkUnshareConfirmation"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>unshare</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            Unshare this link?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="linkUnshareConfirmation = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="deleteLink(item)"
                            >
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          :color="getAppearances.btn_color"
          dark
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="linkDialog"
      persistent
      max-width="600"
    >
      <v-card class="pa-1">
        <v-card-title class="headline pa-3">
          Copy this link and share with others!
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5">
            <v-text-field
              v-model="link"
              :color="getAppearances.btn_color"
              outlined
              dense
              label="Your link"
              append-icon="mdi-clipboard-outline"
              readonly
            ></v-text-field>
            <v-btn
              :color="getAppearances.btn_color"
              dark
              @click="copyToClipBoard"
              :class="$vuetify.breakpoint.mobile ? '' : 'ml-3'"
            >
              <v-icon>mdi-link-variant</v-icon>
              Copy link
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="linkDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";
import { dateUtils } from "../../mixins/date";
import axios from "axios";

export default {
  data() {
    return {
      isUpdating: false,
      share: [],
      itemsToSelect: [],
      users: [],
      link: "",
      loaded: false,

      shareView: false,
      linkView: false,
      linkEdit: false,

      dateMenu: false,
      date: "",

      reshare: false,
      allowEdit: false,
      allowEditMeta: false,

      userPermissionDialog: false,
      linkDialog: false,
      linkSettingsDialog: false,

      editUser: {},
      editItem: {},

      confirmation: false,
      linkUnshareConfirmation: false,

      fileOwner: null,
    };
  },

  mixins: [utils, dateUtils],

  watch: {
    async shareDialog(visible) {
      if (visible) {
        await this.$store.dispatch("files/getDetails").then(() => {
          this.loadUsers();
        });
        this.editItem = {};
      }
    },
  },

  computed: {
    ...mapGetters("shareDialog", ["shareDialog"]),

    ...mapGetters("files", ["getShareItem", "getDetails"]),

    ...mapGetters("user", ["getUsers"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    clearLinkView() {
      this.linkView = false;
      this.editItem = {};
      this.linkEdit = false;
    },

    userStatus(item) {
      for (let i = 0; i < this.getUsers.length; i++) {
        if (this.getUsers[i].id == item.id) {
          if (this.getUsers[i].deactivated_at == 0) {
            return false;
          } else {
            return true;
          }
        }
      }
    },

    close() {
      this.$store.commit("shareDialog/openShareDialog", false);
      this.share = [];
      this.shareView = false;
      this.clearLinkView();
    },

    copyToClipBoard() {
      navigator.clipboard.writeText(this.link);
    },

    openPermissions(item) {
      this.$store.commit("shareDialog/openUserPermissionsDialog", {
        status: true,
        user: item,
      });
    },

    openLinkPermissions(item) {
      this.$store.commit("shareDialog/openLinkPermissionsDialog", {
        status: true,
        link: item,
      });
    },

    onChange() {
      if (this.share.length > 0) {
        this.shareView = true;
      }
    },

    openLinkDialog(data) {
      this.link = window.location.origin + "/share/" + data.id;
      this.linkDialog = true;

      if (this.getShareItem.id == this.getDetails.id) {
        if (this.getDetails.sharedLinks.length <= 0) {
          this.getDetails.sharedLinks = [data];
          return;
        }
      }
      this.getDetails.sharedLinks.push(data);
    },

    switchLinkView(val) {
      this.shareView = false;
      this.linkView = val;
    },

    deleteLink(item) {
      this.linkUnshareConfirmation = false;

      this.$store.dispatch("files/deleteLink", item.id).then(() => {
        this.removeElement(this.getDetails.sharedLinks, item);
        this.setAsUnshared();
      });
    },

    deleteShare(item) {
      this.confirmation = false;

      this.$store
        .dispatch("files/deleteShare", {
          file: this.getShareItem.id,
          id: item.id,
        })
        .then(() => {
          if (this.getShareItem.id == this.getDetails.id) {
            for (let i = 0; i < this.getDetails.sharedUsers.length; i++) {
              if (this.getDetails.sharedUsers[i].id == item.id) {
                this.removeElement(
                  this.getDetails.sharedUsers,
                  this.getDetails.sharedUsers[i]
                );
              }
            }

            this.removeElement(this.users, item);
          }

          for (let i = 0; i < this.getUsers.length; i++) {
            if (this.getUsers[i].id == item.id && item.deactivated_at == 0) {
              this.itemsToSelect.push(this.getUsers[i]);
            }
          }

          this.setAsUnshared();
        });
    },

    async loadUsers() {
      let users = [];
      let items = [];

      await this.$store.dispatch("user/getUsers").then((response) => {
        this.users = response.data;
        users = response.data;
      });

      for (let i = 0; i < users.length; i++) {
        if (
          this.getDetails.sharedUsers.filter((e) => e.id === users[i].id)
            .length <= 0 &&
          this.getShareItem.owner != users[i].id &&
          users[i].deactivated_at == 0
        ) {
          items.push(users[i]);
        } else if (this.getShareItem.owner == users[i].id) {
          this.fileOwner = {
            id: users[i].id,
            owner: true,
            name: users[i].name,
            lastname: users[i].lastname,
            email: users[i].email,
            photo: users[i].photo,
          };
        }
      }

      this.itemsToSelect = items;
      this.loaded = true;
    },

    remove(item) {
      const index = this.share.indexOf(item);
      if (index >= 0) this.share.splice(index, 1);
      if (this.share.length <= 0) {
        this.shareView = false;
      }
    },

    save() {
      if (this.linkView == true) {
        if (this.linkEdit == true) {
          this.updateLink();
          return;
        }
        this.publicShare();
        return;
      }
      if (this.shareView) {
        this.shareFile();
        return;
      }

      this.close();
    },

    publicShare() {
      var data = {
        item: this.getShareItem.id,
        expiration: this.editItem.expiration,
        passwordProtected: this.editItem.passwordProtected,
        password: this.editItem.password,
        allowDownload: this.editItem.allowDownload,
        allowUpload:
          this.getShareItem.isFolder == true
            ? this.editItem.allowUpload
            : false,
        message: this.editItem.message,
      };

      this.$store.dispatch("share/publicShare", data).then((response) => {
        this.close();
        this.getShareItem.shared = true;
        this.openLinkDialog(response.data);
        this.clearLinkView();
      });
    },

    shareFile() {
      let data = {
        fileID: this.getShareItem.id,
        users: [],
        expiration: this.date,
        allowReshare: this.reshare,
        allowEdit: this.allowEdit,
        allowEditMeta: this.allowEditMeta,
      };

      for (let i = 0; i < this.share.length; i++) {
        data.users.push(this.share[i].id);
      }

      const options = {
        url: "/api/files/share",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: data,
      };

      axios(options)
        .then((response) => {
          this.getShareItem.shared = true;
          if (this.getShareItem.id == this.getDetails.id) {
            this.getDetails.sharedUsers.push(...response.data.SharedUsers);
          }
          this.close();
        })
        .catch((error) => {
          this.$store.dispatch("alert/showAlert", {
            color: "red",
            text: error.response.data,
          });
        });
    },

    setAsUnshared() {
      if (
        this.getDetails.sharedUsers.length <= 1 &&
        this.getDetails.sharedLinks.length <= 0
      ) {
        this.getShareItem.shared = false;
      }
    },
  },
};
</script>