<template>
  <v-container
    fluid
    class="fill-height px-0"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col
        align="center"
        class="px-0"
      >
        <h1 class="font-weight-light">
          General settings
        </h1>
        <div
          :style="{ maxWidth: '700px' }"
          class="mx-7 text-wrap font-weight-regular text--secondary"
        >
          Personalize and manage our services for your business needs.
        </div>
        <v-card
          width="920"
          class="mb-9"
          elevation="0"
        >
          <v-toolbar
            color="white"
            flat
          >
            <template v-slot:extension>
              <v-tabs
                v-model="model"
                centered
                :slider-color="getAppearances.btn_color"
                show-arrows
              >
                <v-tab class="grey--text text--darken-1">
                  <v-icon left>
                    mdi-palette-outline
                  </v-icon>
                  Appearance
                </v-tab>
                <v-tab class="grey--text text--darken-1">
                  <v-icon left>
                    mdi-email-edit-outline
                  </v-icon>
                  Email server
                </v-tab>
                <v-tab class="grey--text text--darken-1">
                  <v-icon left>
                    mdi-bell-ring-outline
                  </v-icon>
                  Notifications
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items
            v-model="model"
            touchless
          >
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Appearances />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Emailserver />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Notifications />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Emailserver from "../../../components/Settings/Admin/GeneralSettings/EmailServer";
import Appearances from "../../../components/Settings/Admin/GeneralSettings/Appearances";
import Notifications from "../../../components/Settings/Admin/GeneralSettings/Notifications";

export default {
  components: {
    Emailserver,
    Appearances,
    Notifications,
  },
  data: () => ({
    model: "tab-2",
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },
};
</script>