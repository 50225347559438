<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    persistent
    transition
  >
    <v-card>
      <v-card-title
        v-if="getDocument"
        class="headline"
      >Create New {{ getDocument.title }}</v-card-title>
      <v-card-text>
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="getDocument"
            style="align-self: center;"
          >
            <v-form
              ref="form"
              v-model="valid"
              v-on:submit.prevent
              lazy-validation
            >
              <v-text-field
                :rules="[ v => !!v || 'File name is required', fileExists ]"
                v-model="doc_name"
                :color="getAppearances.btn_color"
                autofocus
                label="Enter name"
                @keyup.enter="createDocument"
              ></v-text-field>
            </v-form>
          </div>
        </v-scroll-y-transition>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          v-show="getDocument"
          :loading="loading"
          :disabled="disableCreate"
          :color="getAppearances.btn_color"
          class="white--text"
          @click="createDocument"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";

export default {
  data: () => ({
    doc_name: "",
    doc_create: "",
    item: false,
    disableCreate: false,
    valid: true,
    loading: false,
  }),

  computed: {
    ...mapGetters("files", ["path", "getFiles"]),
    ...mapGetters("fileDialogs", ["dialog", "getDocument"]),
    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [utils],

  methods: {
    showFileForm(item) {
      this.doc_create = Object.assign({}, item);
    },

    closeDialog() {
      this.$store.commit("fileDialogs/openFileDialog", false);
      this.$refs.form.reset();
      this.item = false;
      this.doc_create = "";
      this.doc_name = "";
      this.loading = false;
      this.disableCreate = false;
    },

    addFile(file) {
      this.$store.commit("files/addFile", file);
      this.closeDialog();
    },

    createDocument() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.disableCreate = true;

        var data = {
          doc_name: this.doc_name,
          path: this.path,
          type: this.getDocument.type,
          extension: this.getDocument.extension,
        };

        this.$store
          .dispatch("files/createDocument", data)
          .then((response) => {
            this.addFile(response.data);
          })
          .catch(() => {
            this.closeDialog();
          });
      }
    },

    fileExists() {
      let item = this.findItem(
        "name",
        this.doc_name + "." + this.getDocument.extension,
        this.getFiles
      );
      if (item) {
        if (!item.isFolder) {
          this.disableCreate = true;
          return "A file with that name already exists";
        }
      }

      if (!this.doc_name) {
        this.disableCreate = true;
      } else {
        this.disableCreate = false;
      }

      return true;
    },
  },
};
</script>
