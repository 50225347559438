import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    alert: false,
    text: '',
    color: '',
    buttonColor: 'white'
  },

  getters: {
    getAlert(state) {
        return state.alert;
    },
    getText(state) {
        return state.text;
    },
    getColor(state) {
        return state.color;
    },
    getButtonColor(state) {
        return state.buttonColor;
    }
  },

  mutations: {
    setAlert(state, status) {
        state.alert = status;
    },
    setText(state, text) {
        state.text = text;
    },
    setColor(state, color) {
        state.color = color;
    },
    setButtonColor(state, color) {
        state.buttonColor = color;
    }
  },

  actions: {
      showAlert({commit}, payload) {
        commit('setText', payload.text);
        commit('setColor', payload.color);
        commit('setAlert', true);
      },
  },
  namespaced: true,
})
