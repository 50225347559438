<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      bottom
      offset-y
      min-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$route.name != 'project'"
          class="mr-2 d-none d-sm-block"
          rounded
          :color="getAppearances.btn_color"
          v-bind="attrs"
          v-on="on"
          dark
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          New
        </v-btn>
        <v-btn
          v-if="$route.name != 'project'"
          class="mr-2 d-sm-none"
          fab
          small
          elevation="0"
          :color="getAppearances.btn_color"
          v-bind="attrs"
          v-on="on"
          dark
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in actions()"
          :key="item.title"
          @click="item.action"
        >
          <v-list-item-avatar>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ProjectDialog
      :projectDialog="projectDialog"
      @close="closeProjectDialog"
    />

    <v-dialog
      v-model="taskDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>New Task</v-card-title>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <div class="my-0">
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-select
                  v-model="project"
                  label="Project"
                  :items="projects"
                  item-text="title"
                  return-object
                  prepend-icon="mdi-clipboard-check-multiple-outline"
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-text-field
                  v-model="taskTitle"
                  prepend-icon="mdi-text-box"
                  label="Title"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-autocomplete
                  v-if="loaded == true"
                  v-model="selectedUser"
                  :disabled="isUpdating"
                  :items="users"
                  chips
                  prepend-icon="mdi-account-plus"
                  label="Assign this task"
                  item-text="name"
                  item-value="id"
                  dense
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove()"
                      color="grey lighten-3"
                    >
                      <v-avatar left>
                        <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                      </v-avatar>
                      {{data.item.name + ' ' + data.item.lastname}}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img :src="`/api/users/photo/${data.item.photo}`">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title> {{data.item.name + ' ' + data.item.lastname}}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-textarea
                  v-model="taskDescription"
                  prepend-icon="mdi-text-box-plus"
                  label="Description"
                  rows="1"
                  dense
                ></v-textarea>
              </v-col>
              <v-row class="mt-1">

                <v-menu
                  :close-on-click="false"
                  v-model="datePickerMenu"
                  :close-on-content-click="false"
                  offset-y
                  :key="refreshDate"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-if="dateSet == false">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="ml-5"
                      >
                        <v-icon>mdi-calendar-plus</v-icon>
                      </v-btn>
                    </div>
                    <v-btn
                      v-else
                      align="left"
                      class="ml-6 text-capitalize font-weight-regular endDateFont"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      dark
                    >
                      <v-icon left>
                        mdi-calendar
                      </v-icon>
                      {{ endDate(date) }}
                      <v-icon
                        right
                        dark
                        @click="dateSet = false, refreshDate += 1"
                      >
                        mdi-close-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                  >
                    <v-spacer />
                    <v-btn
                      text
                      @click="datePickerMenu = false"
                      class="mr-1 mb-3"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      dark
                      :color="getAppearances.btn_color"
                      @click="datePickerMenu = false, dateSet = true, refreshDate += 1"
                      class="mb-3 mr-3"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-btn
                  icon
                  class="mx-2"
                >
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-1"
                @click="taskDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="getAppearances.btn_color"
                class="white--text"
                @click="taskDialog = false, saveTask()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ProjectDialog from "../Projects/Dialogs/newProjectDialog.vue";
import { projectUtils } from "../../mixins/project";

export default {
  components: {
    ProjectDialog,
  },

  data: () => ({
    loaded: false,
    selectedUser: "",
    isUpdating: false,
    users: [],
    projects: [],
    project: {},
    datePickerMenu: false,
    dateSet: false,
    date: new Date(Date.now()).toISOString().substr(0, 10),
    refreshDate: 0,
    newDueDate: "",
    projectDialog: false,
    taskDialog: false,
    taskTitle: "",
    taskDescription: "",
    valid: true,
  }),

  mixins: [projectUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getUsers", "getTimezone"]),

    ...mapGetters("projects", [
      "getProjects",
      "getColumns",
      "getProjectFolder",
    ]),
  },

  mounted() {
    this.$store.dispatch("user/getUsers").then(() => {
      this.users = this.getUsers;
      this.loaded = true;
    });
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },

  methods: {
    remove() {
      this.selectedUser = "";
    },

    endDate(date) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);
      var formatedDate = timezoneDate.utc().format("X");
      this.newDueDate = formatedDate;

      return this.dateFormat(formatedDate);
    },

    saveTask() {
      var data = {
        type: "task",
        parentID: "",
        projectID: this.project.id,
        title: this.taskTitle,
        description: this.taskDescription,
        assigned_to: this.selectedUser,
        depends: "",
        startDate: "",
        endDate: this.newDueDate,
      };
      this.$store.dispatch("projects/createTask", data).then((response) => {
        this.$store.dispatch("alert/showAlert", {
          color: "green",
          text: "Task created",
        });

        var project = JSON.parse(this.project.properties);
        project[0].tasks.unshift(response.data);

        for (let i = 0; i < project.length; i++) {
          for (let j = 0; j < project[i].tasks.length; j++) {
            var tasks = { id: project[i].tasks[j].id };
            project[i].tasks[j] = tasks;
          }
        }

        var projectData = {
          id: this.project.id,
          title: this.project.title,
          description: this.project.description,
          properties: JSON.stringify(project),
          startDate: this.project.start_date,
          endDate: this.project.end_date,
        };
        this.$store.dispatch("projects/patchProject", projectData).then(() => {
          this.newDueDate = "";
          this.date = new Date(Date.now()).toISOString().substr(0, 10);
          this.taskTitle = "";
          this.taskDescription = "";
          this.selectedUser = "";
          this.dateSet = false;
          this.refreshDate += 1;
          this.project = {};
        });
      });
    },

    actions() {
      var items = [
        {
          title: "Project",
          icon: "mdi-clipboard-text-outline",
          action: this.newProject,
        },
      ];
      return items;
    },

    newProject() {
      this.projectDialog = true;
    },

    closeProjectDialog() {
      this.projectDialog = false;
    },

    newTask() {
      this.$store.dispatch("projects/getProjects").then(() => {
        this.projects = this.getProjects;
        this.taskDialog = true;
      });
    },
  },
};
</script>
