export const file = {
  methods: {
    convertSize(size) {
      var sizeType = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (size == 0) { return '0 ' + sizeType[0] }

      var i = parseInt(Math.floor(Math.log(size) / Math.log(1000)));

      return (size / Math.pow(1000, i)).toFixed(1) + ' ' + sizeType[i];
    }
  }
}