<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col align="center">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Header
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Customize the application header name.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <v-text-field
            v-model="appearance.header"
            :placeholder="getAppearances.header"
            :rules="headerRules"
            :color="getAppearances.btn_color"
            required
            label="Header name"
            outlined
            dense
            class="mx-n7"
          ></v-text-field>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Logo
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Upload the application logo.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mb-5 mt-n6 mx-n4">
            <v-img
              max-height="88"
              max-width="88"
              :src="`/api/settings/appearances/image/logo?t=${ts}`"
              class="mx-4 mb-2"
            ></v-img>
            <v-btn
              class="mx-4"
              :color="getAppearances.btn_color"
              @click="removeImg('logo')"
              dark
              x-small
            >
              Remove logo
            </v-btn>
          </div>
          <v-file-input
            v-model="logo"
            :rules="imgRules"
            label="Upload Logo"
            :color="getAppearances.btn_color"
            prepend-icon=""
            accept=".svg, .png"
            persistent-hint
            hint="Maximum file size is 1MB. Accept .svg, .png file formats"
            outlined
            dense
            class="mx-n7"
          ></v-file-input>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Favicon
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Upload the application favicon.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mb-5 mt-n6 mx-n4">
            <v-img
              max-height="32"
              max-width="32"
              :src="`/api/settings/appearances/image/favicon?t=${ts}`"
              class="mx-4 mb-2"
            ></v-img>
            <v-btn
              class="mx-4"
              :color="getAppearances.btn_color"
              @click="removeImg('favicon')"
              dark
              x-small
            >
              Remove favicon
            </v-btn>
          </div>
          <v-file-input
            v-model="favicon"
            :rules="imgRules"
            label="Upload favicon"
            :color="getAppearances.btn_color"
            prepend-icon=""
            accept=".png"
            persistent-hint
            hint="Maximum file size is 1MB. Accept .png file format"
            outlined
            dense
            class="mx-n7"
          ></v-file-input>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Background
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Upload the application Login screen background img.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mb-5 mt-n6 mx-n4">
            <v-img
              max-height="80"
              max-width="160"
              :src="`/api/settings/appearances/image/background?t=${ts}`"
              class="mx-4 mb-2"
            ></v-img>
            <v-btn
              class="mx-4"
              :color="getAppearances.btn_color"
              @click="removeImg('background')"
              dark
              x-small
            >
              Remove background img
            </v-btn>
          </div>
          <v-file-input
            v-model="background"
            :rules="imgRules"
            label="Upload Background Img"
            :color="getAppearances.btn_color"
            prepend-icon=""
            accept=".jpg, .png, .svg"
            persistent-hint
            hint="Maximum file size is 1MB. Accept .jpg, .png, .svg file formats"
            outlined
            dense
            class="mx-n7"
          ></v-file-input>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Navigation theme
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Customize the application header and navigation sidebar color.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <v-radio-group
            v-model="selectedHeaderColor"
            mandatory
            class="mt-n1"
            @change="preview()"
          >
            <v-row>
              <div
                v-for="item in colorList"
                :key="item.label"
              >
                <v-col v-if="item.label != 'Blue'">
                  <v-sheet
                    :color="item.color"
                    class="rounded-lg"
                    elevation="1"
                    height="50"
                    width="135"
                  ></v-sheet>
                  <v-radio
                    class="mt-3 ml-3"
                    :label="item.label"
                    :color="item.color"
                    :value="item.color"
                  ></v-radio>
                </v-col>
              </div>
              <v-col>
                <v-sheet
                  :color="customHeaderColor"
                  class="rounded-lg"
                  elevation="1"
                  height="50"
                  width="135"
                >
                </v-sheet>
                <v-radio
                  class="mt-3 ml-3"
                  @click="dialogColor = true"
                  label="Custom"
                  :color="customHeaderColor"
                  value="custom"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Button color
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Customize the application button color.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
        >
          <v-radio-group
            v-model="selectedButtonColor"
            mandatory
            @change="preview()"
            class="mt-n1"
          >
            <v-row>
              <div
                v-for="item in colorList"
                :key="item.label"
              >
                <v-col v-if="item.label != 'Dark Grey'">
                  <v-sheet
                    :color="item.color"
                    class="rounded-lg"
                    elevation="1"
                    height="50"
                    width="135"
                  ></v-sheet>
                  <v-radio
                    class="mt-3 ml-3"
                    :label="item.label"
                    :color="item.color"
                    :value="item.color"
                  ></v-radio>
                </v-col>
              </div>
              <v-col>
                <v-sheet
                  :color="customBtnColor"
                  class="rounded-lg"
                  elevation="1"
                  height="50"
                  width="135"
                >
                </v-sheet>
                <v-radio
                  class="mt-3 ml-3"
                  @click="dialogBtnColor = true"
                  label="Custom"
                  value="custom"
                  :color="customBtnColor"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="10"
          xs="12"
        >
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-5"
          ></v-divider>
          <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="9"
          xs="12"
          align="right"
        >
          <v-btn
            :color="getAppearances.btn_color"
            :disabled="disable()"
            @click="updateAppearance()"
            class="mt-6 white--text"
          >
            Update appearance settings
          </v-btn>
        </v-col>
      </v-form>
    </v-col>
    <v-dialog
      v-model="dialogColor"
      max-width="340"
    >
      <v-card class="text-center">
        <v-col class="text-center">
          <v-color-picker
            class="ma-2 mb-n3"
            hide-mode-switch
            :mode.sync="colorFormat"
            v-model="customHeaderColor"
          ></v-color-picker>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-3"
            text
            @click="saveHeaderColor()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogBtnColor"
      max-width="340"
    >
      <v-card class="text-center">
        <v-col class="text-center">
          <v-color-picker
            class="ma-2 mb-n3"
            hide-mode-switch
            :mode.sync="colorFormat"
            v-model="customBtnColor"
          ></v-color-picker>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-3"
            text
            @click="saveButtonColor()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    colorFormat: "hexa",
    appearance: {},
    oldAppearance: {},
    selectedHeaderColor: "",
    selectedButtonColor: "",
    logo: null,
    valid: true,
    favicon: null,
    background: null,
    dialogColor: false,
    dialogBtnColor: false,
    ts: Date.now(),
    colorList: [
      { color: "#212121", label: "Dark Grey" },
      { color: "#1976D2", label: "Blue" },
      { color: "#7E57C2", label: "Purple" },
      { color: "#4254C0", label: "Indigo" },
      { color: "#0D47A1", label: "Dark Blue" },
      { color: "#269DAC", label: "Cyan" },
      { color: "#0D8B80", label: "Teal" },
      { color: "#37943A", label: "Green" },
    ],
    colorRules: [(v) => !!v || "Color is required"],
    headerRules: [(v) => !!v || "Header name is required"],
    imgRules: [
      (value) =>
        !value || value.size < 1000000 || "File size should be less than 1 MB!",
    ],
    customHeaderColor: "#f0f0f0",
    customBtnColor: "#f0f0f0",
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  mounted() {
    this.$store.dispatch("admin/getAppearances").then((response) => {
      this.appearance = response.data;

      if (
        !this.colorList.includes(
          this.colorList.find((el) => el.color === this.appearance.header_color)
        )
      ) {
        this.selectedHeaderColor = "custom";
      } else {
        this.selectedHeaderColor = this.appearance.header_color;
      }

      if (
        !this.colorList.includes(
          this.colorList.find((el) => el.color === this.appearance.btn_color)
        )
      ) {
        this.selectedButtonColor = "custom";
      } else {
        this.selectedButtonColor = this.appearance.btn_color;
      }

      this.customHeaderColor = this.appearance.header_color;
      this.customBtnColor = this.appearance.btn_color;
      this.oldAppearance = JSON.parse(JSON.stringify(this.appearance));
    });
  },

  methods: {
    disable() {
      if (
        JSON.stringify(this.appearance) ===
          JSON.stringify(this.oldAppearance) &&
        this.logo == null &&
        this.favicon == null &&
        this.background == null
      ) {
        return true;
      }

      return false;
    },

    saveButtonColor() {
      this.dialogBtnColor = false;
      this.appearance.btn_color = this.customBtnColor;
    },

    saveHeaderColor() {
      this.dialogColor = false;
      this.appearance.header_color = this.customHeaderColor;
    },

    preview() {
      this.appearance.header_color =
        this.selectedHeaderColor == "custom"
          ? this.customHeaderColor
          : this.selectedHeaderColor;

      this.appearance.btn_color =
        this.selectedButtonColor == "custom"
          ? this.customBtnColor
          : this.selectedButtonColor;
    },

    removeImg(v) {
      this.$store.dispatch("admin/removeAppearanceImage", v).then(() => {
        this.ts = Date.now();
      });
    },

    updateAppearance() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("admin/updateAppearances", {
            header: this.appearance.header,
            header_color: this.appearance.header_color,
            btn_color: this.appearance.btn_color,
          })
          .then(() => {
            this.oldAppearance = JSON.parse(JSON.stringify(this.appearance));
          });

        let formData = new FormData();
        if (this.logo != null) {
          formData.append("logo", this.logo);
        }
        if (this.favicon != null) {
          formData.append("favicon", this.favicon);
        }
        if (this.background != null) {
          formData.append("background", this.background);
        }

        this.$store
          .dispatch("admin/uploadAppearanceImages", formData)
          .then(() => {
            this.ts = Date.now();
            this.logo = null;
            this.favicon = null;
            this.background = null;
          });
      }
    },
  },
};
</script>