<template>
  <div>
    <v-col>
      <v-list>
        <v-list-item :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''">
          <v-list-item-content>
            <v-col
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            >
              <v-list-item-title>
                Session length using email
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap my-1">
                If the user does not refresh or request a page within the time-out period, the session ends.
              </v-list-item-subtitle>
            </v-col>
            <v-row :class="$vuetify.breakpoint.smAndDown ? 'mx-0 mt-1' : 'mt-3 mx-3'">
              <div
                style="max-width: 137px;"
                class="mr-3"
              >
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-text-field
                    v-model="settings.SessionLength"
                    :color="getAppearances.btn_color"
                    label="Timeout period"
                    required
                    :rules="[timeoutRules.required, timeoutRules.valid]"
                    suffix="days"
                    outlined
                    dense
                  ></v-text-field>
                </v-form>
              </div>
              <div style="max-width: 137px;">
                <v-btn
                  @click="update()"
                  :color="getAppearances.btn_color"
                  dark
                >
                  Update
                </v-btn>
              </div>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider :class="$vuetify.breakpoint.smAndDown ? 'my-3' : 'my-3 mx-1'" />
      <v-list>
        <v-list-item :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''">
          <v-list-item-content>
            <v-col
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            >
              <v-list-item-title>
                Password policy
              </v-list-item-title>
            </v-col>
            <v-col
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'pa-0 mt-1' : 'pb-0'"
            >
              <div style="max-width: 137px;">
                <v-select
                  v-model="settings.PasswordSettings.PasswordMinLength"
                  :color="getAppearances.btn_color"
                  :items="pwLenght"
                  :value="settings.PasswordSettings.PasswordMinLength"
                  label="Minimal lenght"
                  @change="update()"
                  outlined
                  dense
                ></v-select>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
              :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : 'mx-3'"
            >
              <v-list-item class="px-0">
                <v-checkbox
                  v-model="settings.PasswordSettings.UpperLowerCharacters"
                  label="Enforce upper and lower case characters"
                  :color="getAppearances.btn_color"
                  @click="update()"
                  hide-details
                  dense
                ></v-checkbox>
              </v-list-item>
              <v-list-item class="px-0">
                <v-checkbox
                  v-model="settings.PasswordSettings.NumericCharacters"
                  label="Enforce numeric characters"
                  :color="getAppearances.btn_color"
                  @click="update()"
                  hide-details
                  dense
                ></v-checkbox>
              </v-list-item>
              <v-list-item class="px-0">
                <v-checkbox
                  v-model="settings.PasswordSettings.SpecialCharacters"
                  label="Enforce special characters"
                  :color="getAppearances.btn_color"
                  @click="update()"
                  hide-details
                  dense
                ></v-checkbox>
              </v-list-item>
            </v-col>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    sessionLength: 0,
    valid: true,
    pwLenght: [8, 9, 10, 11, 12],
    selectedPwLenght: "",
    timeoutRules: {
      required: (v) => !!v || "Session timeout is required",
      valid: (v) => /^[1-9]\d*$|^$/.test(v) || "Only numbers are allowed",
    },
    settings: {
      PasswordSettings: {
        NumericCharacters: true,
        PasswordMinLength: 8,
        SpecialCharacters: true,
        UpperLowerCharacters: true,
      },
      SessionLength: 30,
    },
  }),

  created() {
    this.$store.dispatch("admin/getSecuritySettings").then((response) => {
      this.settings = response.data;
    });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances", "getAuth"]),
  },

  methods: {
    update() {
      this.$store.dispatch("admin/updateSecuritySettings", {
        passwordSettings: this.settings.PasswordSettings,
        sessionLength: this.settings.SessionLength,
      });
    },
  },
};
</script>
