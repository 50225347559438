<template>
  <v-snackbar
    v-model="uploading"
    :timeout="timeout"
    bottom
    right
  >
    <v-list
      style="padding: 0px;"
      expand
    >
      <v-list-group
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        :ripple="false"
        color="white"
        disabled
      >
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="fileCount > 0">{{ fileCount }} files uploading</v-list-item-title>
              <v-list-item-title v-else>Upload finished</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:appendIcon>
          <v-icon @click="expand">mdi-arrow-down</v-icon>
          <v-icon
            class="ml-3"
            @click="close"
          >mdi-close</v-icon>
        </template>

        <v-card
          class="pa-0 rounded-0"
          color="#fff"
        >
          <v-virtual-scroll
            :bench="5"
            :items="uploadingItems"
            min-height="60"
            max-height="300"
            item-height="64"
          >

            <template v-slot:default="{ item }">
              <v-list-item
                light
                style="background-color: white;"
                :key="item.id"
              >
                <v-list-item-avatar>
                  <v-icon
                    v-text="uploadIcon(item.type)"
                    :color="iconColor(item.type)"
                  ></v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="contentName(item.name)"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-progress-circular
                    v-if="item.status == 'uploading'"
                    :size="30"
                    :width="3"
                    :value="item.value"
                    :color="getAppearances.btn_color"
                  >
                    <div class="text-caption">{{item.value}}</div>
                  </v-progress-circular>
                  <v-btn
                    v-else-if="item.status == 'finish'"
                    loading
                    icon
                  >
                  </v-btn>
                  <v-btn
                    v-else-if="item.status == 'error'"
                    icon
                  >
                    <v-icon color="red">mdi-close-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    icon
                  >
                    <v-icon color="green">mdi-check-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>

          </v-virtual-scroll>
        </v-card>
      </v-list-group>
    </v-list>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    item: {
      action: "mdi-upload",
      active: true,
    },
    snackbar: true,
    active: true,
  }),

  computed: {
    ...mapGetters("upload", [
      "uploadingItems",
      "uploading",
      "fileCount",
      "timeout",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("files", ["fileTypes"]),
  },

  methods: {
    contentName(name) {
      if (name.length > 30) {
        return name.substring(0, 27) + "...";
      }
      return name;
    },

    expand() {
      this.item.active == true
        ? (this.item.active = false)
        : (this.item.active = true);
    },

    uploadIcon(type) {
      if (type == "") {
        return "mdi-file";
      } else if (this.fileTypes[type] == undefined) {
        return "mdi-file";
      }

      return this.fileTypes[type].icon;
    },

    iconColor(type) {
      if (type == "") {
        return "primary";
      } else if (this.fileTypes[type] == undefined) {
        return "primary";
      }

      return this.fileTypes[type].color;
    },

    close() {
      this.$store.commit("upload/setUploading", false);
      this.$store.commit("upload/clearUploadingItems");
    },
  },
};
</script>

<style>
.v-snack__action {
  margin-right: 0px !important;
}
.v-snack__content {
  padding: 0px !important;
}
</style>
