import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    path: '',
    open: [],
    files: [],
    driveID: null,
    treeviewFolders: [],
    selectedFile: {},
    isSelectedFile: false,
    item_to_move: [],
    share_item: {},
    fileTypes: {
      'inode/directory': { icon: 'mdi-folder', color: 'primary', fileType: 'folder', isEditable: false },
      'cloud#drive': { icon: 'mdi-folder', color: 'primary', fileType: 'folder', isEditable: false },
      'application/pdf': { icon: 'mdi-file-pdf-box', color: 'red', fileType: 'pdf', isEditable: false },
      'image/png': { icon: 'mdi-file-image', color: 'orange', fileType: 'image', isEditable: false },
      'image/jpeg': { icon: 'mdi-file-image', color: 'orange', fileType: 'image', isEditable: false },
      'image/x-canon-cr2': { icon: 'mdi-file-image', color: 'orange', fileType: 'raw file', isEditable: false },
      'text/plain': { icon: 'mdi-file-document', color: 'grey', fileType: 'text', isEditable: true },
      'text/x-go': { icon: 'mdi-code-tags', color: 'grey', fileType: 'text', isEditable: true },
      'text/x-csrc': { icon: 'mdi-code-tags', color: 'grey', fileType: 'text', isEditable: true },
      'text/css': { icon: 'mdi-language-css3', color: 'grey', fileType: 'text', isEditable: true },
      'text/html': { icon: 'mdi-language-html5', color: 'red', fileType: 'text', isEditable: true },
      'text/csv': { icon: 'mdi-file-document', color: 'grey', fileType: 'text', isEditable: true },
      'text/calendar': { icon: 'mdi-calendar', color: 'grey', fileType: 'text', isEditable: true },
      'text/javascript': { icon: 'mdi-language-javascript', color: 'grey', fileType: 'text', isEditable: true },
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { icon: 'mdi-file-excel', color: 'green', fileType: 'excel', isEditable: true },
      'application/vnd.ms-excel': { icon: 'mdi-file-excel', color: 'green', fileType: 'excel', isEditable: true },
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { icon: 'mdi-file-word', color: 'primary', fileType: 'word', isEditable: true },
      'application/msword': { icon: 'mdi-file-word', color: 'primary', fileType: 'word', isEditable: true },
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': { icon: 'mdi-file-powerpoint', color: 'orange', fileType: 'powerpoint', isEditable: true },
      'application/vnd.ms-powerpoint': { icon: 'mdi-file-powerpoint', color: 'orange', fileType: 'powerpoint', isEditable: true },
      'application/x-cd-image': { icon: 'mdi-file', color: 'primary', fileType: 'x-cd-image', isEditable: false },
      'application/x-raw-disk-image': { icon: 'mdi-file', color: 'primary', fileType: 'x-cd-image', isEditable: false },
      'application/vnd.debian.binary-package': { icon: 'mdi-zip-box', color: 'green accent-4', fileType: 'debian package', isEditable: false },
      'application/zip': { icon: 'mdi-zip-box', color: 'green accent-4', fileType: 'zip archive', isEditable: false },
      'application/gzip': { icon: 'mdi-zip-box', color: 'green accent-4', fileType: 'gzip archive', isEditable: false },
      'application/x-xz-compressed-tar': { icon: 'mdi-zip-box', color: 'green accent-4', fileType: 'tar archive', isEditable: false },
      'audio/aac': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'audio/mpeg': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'audio/ogg': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'audio/wav': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'audio/webm': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'audio/x-wav': { icon: 'mdi-file-music', color: 'grey', fileType: 'audio', isEditable: false },
      'video/mp4': { icon: 'mdi-file-video', color: 'orange', fileType: 'video', isEditable: false },
      'video/x-msvideo': { icon: 'mdi-file-video', color: 'orange', fileType: 'video', isEditable: false },
      'video/mpeg': { icon: 'mdi-file-video', color: 'orange', fileType: 'video', isEditable: false },
      'video/ogg': { icon: 'mdi-file-video', color: 'orange', fileType: 'video', isEditable: false },
      'video/webm': { icon: 'mdi-file-video', color: 'orange', fileType: 'video', isEditable: false },
      'unknown': { icon: 'mdi-file', color: 'primary', fileType: 'unknown', isEditable: false },
    },
    loaded: false,
    page: 1,
    perPage: 20,
    selectedItems: [],
    activity: [],
    details: []
  },

  getters: {
    fileTypes(state) {
      return state.fileTypes;
    },
    path(state) {
      return state.path;
    },
    open(state) {
      return state.open;
    },
    getFiles(state) {
      return state.files;
    },
    loaded(state) {
      return state.loaded;
    },
    getTreeview(state) {
      return state.treeviewFolders;
    },
    selectedFile(state) {
      return state.selectedFile;
    },
    isSelectedFile(state) {
      return state.isSelectedFile;
    },
    getItemToMove(state) {
      return state.item_to_move;
    },
    getShareItem(state) {
      return state.share_item;
    },
    getPage(state) {
      return state.page;
    },
    getPerPage(state) {
      return state.perPage;
    },
    getSelection(state) {
      return state.selectedItems;
    },
    isEditable: (state) => (type) => {
      if (state.fileTypes[type] != undefined) {
        return state.fileTypes[type].isEditable
      }
      return false
    },
    getActivity(state) {
      return state.activity;
    },
    getDetails(state) {
      return state.details;
    }
  },

  mutations: {
    setFiles: (state, arr) => {
      state.files = arr;
    },
    setTreeview: (state, files) => {
      state.treeviewFolders = files;
    },
    addFile: (state, file) => {
      state.files.push(file);
    },
    setPath: (state, path) => {
      state.path = path;
    },
    setOpen: (state, open) => {
      state.open = open;
    },
    setItemToMove: (state, items) => {
      state.item_to_move = items;
    },
    setShareItem(state, item) {
      state.share_item = item;
    },
    setSelectedFile: (state, item) => {
      if (item.type == 'cloud#drive' || item.type == undefined) {
        state.isSelectedFile = false;
        return;
      }
      state.selectedFile = Object.assign({}, item);
      state.isSelectedFile = true;
    },
    setSelection: (state, value) => {
      state.selectedItems = value;
    },
    setLoaded: (state, status) => {
      state.loaded = status;
    },
    setPage: (state, val) => {
      state.page = val;
    },
    setActivity: (state, val) => {
      state.activity = val;
    },
    setDetails: (state, val) => {
      state.details = val
    },
    deleteFile: (state, removeitem) => {
      let array = state.files;
      const index = array.findIndex(item => item.id == removeitem.id)
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        /* TODO: pass error to snackbar and show error */
        console.log("Can't find item to delete");
      }
    },
  },

  actions: {
    async getActivity(context) {
      const options = {
        url: '/api/activity/file/' + context.state.selectedFile.id,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
      };

      axios(options)
        .then(response => {
          context.commit('setActivity', response.data)
        }).catch(() => { });
    },

    async getDetails(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/details/' + context.state.selectedFile.id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setDetails', response.data)
            resolve(response)
          }).catch(() => { });
      });
    },

    async getFiles(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/load-files/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getDeletedFiles(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/deleted',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getRecentFiles(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/recents',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setPage', 1);
            context.commit('setFiles', response.data);
            context.commit('setLoaded', true);
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getFavouriteFiles(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/get-favourites',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setPage', 1);
            context.commit('setFiles', response.data);
            context.commit('setLoaded', true);
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getDrive(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/get-drive',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        return axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to get drive'
            }, { root: true })
          });
      })
    },

    async getParents(context, path) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/get-parent/' + path,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            resolve(response)
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to get files'
            }, { root: true })
          });
      })
    },

    async downloadFile(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/getfile/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: id,
          }
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getSharedFiles(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/get-shared',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setPage', 1);
            context.commit('setFiles', response.data);
            context.commit('setLoaded', true);
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    uploadFile(context, formData) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/files/upload',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
      })
    },

    createDocument(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/create-document',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            docname: data.doc_name,
            id: data.path,
            type: data.type,
            extension: data.extension
          }
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create document'
            }, { root: true })
          });
      })
    },

    createFolder(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/create-folder',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            id: info.id,
          }
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create folder'
            }, { root: true })
          });
      })
    },

    renameFile(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/rename',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            id: info.id,
          }
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create folder'
            }, { root: true })
          });
      })
    },

    deleteFile(context, items) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/trash',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: items,
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deletePermanently(context, items) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: items,
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    restoreFromTrash(context, items) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/restore',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: items,
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deleteLink(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/link/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            token: id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deleteShare(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/unshare',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            group: info.group,
            file: info.file,
            id: info.id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
