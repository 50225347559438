<template>
  <v-container
    fluid
    class="pa-0 ma-0 fill-height"
  >
    <v-row class="fill-height pa-0">
      <v-col
        @dragenter="dragenter"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop.stop.prevent="onFileSelected"
        fill-height
      >
        <v-row
          v-if="getFiles.length == 0 && loaded"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-center mb-5 child-elements">
            <v-list-item-avatar
              size="200"
              class="child-elements"
            >
              <v-icon
                size="100"
                dark
                color="white"
                class="grey lighten-2"
              >
                mdi-folder-upload-outline
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-medium noselect">Drop your files here</h1>
            <div
              noselect
              class="subtitle-1 grey--text mb-5 noselect"
            >
              Or use button new
            </div>
          </div>
        </v-row>
        <v-list
          class="mt-0 pt-0"
          two-line
        >
          <v-list-item-group
            :value="getSelection"
            multiple
            :color="getAppearances.btn_color"
          >
            <template v-for="(item, index) in filtered_items">
              <v-list-item
                :key="index"
                :id="item.id"
                @click="setActive($event, item, index)"
                @dblclick="onDblClick(item)"
                @contextmenu="show($event, item, index)"
                class="pa-0"
              >
                <template v-slot:default="{ }">
                  <v-badge
                    light
                    bottom
                    color="yellow darken-1"
                    icon="mdi-star"
                    offset-x="30"
                    offset-y="30"
                    :value="item.favourite"
                  >
                    <v-list-item-avatar tile>
                      <v-icon
                        v-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType !== 'image'"
                        size="48"
                        dark
                        :color="fileTypes[item.type].color"
                      >
                        {{ fileTypes[item.type].icon }}
                      </v-icon>
                      <v-img
                        v-else-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType == 'image'"
                        :src="`/api/files/thumbnails/${item.id}`"
                      >
                      </v-img>
                      <v-icon
                        v-else
                        size="48"
                        dark
                        :color="fileTypes['unknown'].color"
                      >
                        {{ fileTypes['unknown'].icon }}
                      </v-icon>
                    </v-list-item-avatar>
                  </v-badge>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>

                    <v-list-item-subtitle class="text--primary">
                      <div style="font-size: 11px;">

                        <v-row class="ma-0 pa-0">
                          <div>
                            LAST MODIFIED: {{ getDateAndTime(item.created) }}
                          </div>
                          <div v-if="!$vuetify.breakpoint.mobile">
                            , FILE SIZE:{{ convertSize(item.size) }}
                          </div>
                        </v-row>

                        <template v-for="metaitem in colorTags(item.metadata)">
                          <v-icon
                            small
                            :color="metaitem.value.color"
                            :key="metaitem.id"
                            v-if="metaitem.value.name !== undefined && metaitem.value.name.length > 0"
                          >
                            mdi-label
                          </v-icon>
                          {{metaitem.value.name}}
                        </template>
                      </div>

                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="mr-5">
                    <v-row>
                      <v-btn
                        @click.stop="shareDialog(item)"
                        icon
                      >
                        <v-icon :color="item.shared == true ? getAppearances.btn_color : 'grey lighten-1'">mdi-share-variant</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click.stop="download(item)"
                      >
                        <v-icon color="grey lighten-1">mdi-download</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </template>

              </v-list-item>
              <v-divider :key="index + item.name"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-pagination
          v-model="pages"
          v-if="filtered.length/getPerPage > 1"
          :length="Math.ceil(filtered.length/getPerPage)"
        ></v-pagination>
      </v-col>

    </v-row>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      min-width="280"
    >
      <v-list>
        <v-list-item-group v-model="menu_item">
          <template v-for="(item) in menuItems">
            <v-list-item
              v-if="item.show"
              :disabled="item.disabled"
              :key="item.id"
              @click="actions(item)"
            >
              <v-list-item-icon>
                <v-icon
                  :color="item.color"
                  v-text="item.icon"
                >
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <DeleteDialog
      :delete-conf="deleteConf"
      @confirmDelete="confirmDelete"
      @cancelDelete="deleteConf = false"
    />
    <RenameDialog
      :rename-dialog="renameDialog"
      :edit-item="editItem"
      @close="renameDialog = false"
    />
    <Movedialog />
    <Sharedialog />
    <DragInfo />
  </v-container>
</template>

<script>
import Movedialog from "../../components/Dialogs/moveDialog";
import Sharedialog from "../../components/Dialogs/shareDialog";
import DragInfo from "../../components/DragInfo";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import RenameDialog from "../../components/Dialogs/renameDialog";
import { mapGetters } from "vuex";
import { actions } from "../../mixins/browserActions";
import { utils } from "../../mixins/utils";
import { upload } from "../../mixins/upload";
import { dateUtils } from "../../mixins/date";
import { file } from "../../mixins/file";

export default {
  components: {
    Movedialog,
    Sharedialog,
    DragInfo,
    DeleteDialog,
    RenameDialog,
  },

  data: () => ({
    selectedItem: false,
    disableRename: false,
    activeItem: false,
  }),

  mixins: [utils, upload, actions, dateUtils, file],

  computed: {
    ...mapGetters("files", [
      "path",
      "fileTypes",
      "getFiles",
      "selectedFile",
      "isSelectedFile",
      "loaded",
      "getPage",
      "getPerPage",
      "getSelection",
    ]),

    ...mapGetters("toolbar", ["search"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("meta", ["getMetadata"]),

    ...mapGetters("user", ["getUser"]),

    ...mapGetters("main", ["details"]),

    pages: {
      get() {
        return this.getPage;
      },
      set(val) {
        this.$store.commit("files/setPage", val);
      },
    },
  },

  methods: {
    dragenter(event) {
      this.$store.commit("dragBar/showDragBar", true);

      event.preventDefault();
    },

    dragleave(event) {
      this.$store.commit("dragBar/showDragBar", false);
      event.preventDefault();
    },

    dragover(event) {
      event.preventDefault();
    },

    colorTags(metadata) {
      var list = [];
      if (metadata !== null) {
        for (let i = 0; i < this.getMetadata.length; i++) {
          for (let j = 0; j < metadata.length; j++) {
            if (
              this.getMetadata[i].id == metadata[j].metadata_id &&
              this.getMetadata[i].type == "color"
            ) {
              list.push(metadata[j]);
            }
          }
        }
      }

      return list;
    },
  },
};
</script>