<template>
  <div>
    <v-dialog
      v-model="dialogDeactivate"
      max-width="400px"
    >
      <v-card>
        <v-col
          cols="12"
          align="center"
        >
          <v-card
            max-width="400px"
            max-height="400px"
            class="mx-2 my-2"
          >
            <v-img
              :src="`/api/users/photo/${editedItem.photo}`"
              dark
            >
            </v-img>
          </v-card>
          <h3 class="my-4 text-center">
            {{ editedItem.name +' '+ editedItem.lastname + ' ' + '(' + editedItem.username + ')' }}
          </h3>
          <p
            v-if="editedItem.deactivated_at == 0"
            class="text-center"
          >
            Are you sure you want to deactivate this User?
          </p>
          <p
            v-else
            class="text-center"
          >
            Are you sure you want to restore this User?
          </p>
        </v-col>
        <v-card-actions class="mx-1">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mr-1 mb-3"
            @click="closeDelete()"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            class="mb-3"
            @click="deactivationConfirm(editedItem.deactivated_at)"
          >
            <div v-if="editedItem.deactivated_at == 0">
              Deactivate
            </div>
            <div v-else>
              Restore
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col
      v-if="getUsers.length == 0"
      class="mt-9"
    >
      <v-row
        class="fill-height mt-9"
        align="center"
        justify="space-around"
      >
        <div class="text-center mt-9">
          <v-list-item-avatar
            size="250"
            class="child-elements"
          >
            <v-icon
              size="150"
              dark
              color="white"
              class="grey lighten-2"
            >
              mdi-account-plus-outline
            </v-icon>
          </v-list-item-avatar>
          <h1 class="grey--text text--darken-1 font-weight-medium noselect">
            No Users
          </h1>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="createUser ()"
          >
            Create new user
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-card
      class="elevation-0"
      v-if="getUsers.length != 0"
    >
      <div class="my-1 mx-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :color="getAppearances.btn_color"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="getUsers"
        :custom-filter="filter"
        :search="search"
        @click:row="filterUsers"
        :item-class="rowStyle"
        class="elevation-0"
        :disable-sort="$vuetify.breakpoint.smAndDown"
      >
        <template v-slot:item.actions="{ item }">
          <td class="non-clickable">
            <div class="text-left">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="26"
                    v-bind="attrs"
                    v-on="on"
                    @click="openActions()"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    link
                    @click="editItem(item)"
                  >
                    <v-icon class="mr-3">mdi-pencil</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.deactivated_at == 0"
                    link
                    @click="deactivateUser(item)"
                  >
                    <v-icon class="mr-3">mdi-archive-arrow-down</v-icon>
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    link
                    @click="deactivateUser(item)"
                  >
                    <v-icon class="mr-3">mdi-archive-arrow-up</v-icon>
                    <v-list-item-title>Restore</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
        <template #item.deactivated_at="{item}">
          {{ getDateAndTime(item.deactivated_at) }}
        </template>
        <template #item.name="{item}">
          <div :style="item.deactivated_at != 0 ? 'text-decoration: line-through' : ''">
            {{ item.name + ' ' + item.lastname }}
          </div>
        </template>
        <template #item.photo="{item}">
          <v-badge
            color="error"
            offset-x="0"
            offset-y="9"
            bordered
            overlap
            :value="item.deactivated_at == 0 ? false : true"
          >
            <template v-slot:badge>
              <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
            </template>
            <v-avatar
              :color="getAppearances.btn_color"
              size="36"
              class="mr-n3"
            >
              <v-img :src="`/api/users/photo/${item.photo}`">
              </v-img>
            </v-avatar>
          </v-badge>
        </template>
        <template v-slot:no-data>
          <div>No data available</div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { dateUtils } from "../../../../mixins/date";
import { navigation } from "../../../../mixins/navigation";

export default {
  data: () => ({
    hideTitle: false,
    isAdmin: [
      { admin: true, name: "Yes" },
      { admin: false, name: "No" },
    ],
    search: "",
    actionShow: false,
    dialogDeactivate: false,
    headers: [
      { value: "photo", width: "48px", sortable: false },
      {
        text: "User",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Username", value: "username", sortable: true },
      { text: "Email", value: "email", sortable: true },
      { text: "Cloud Role", value: "role", sortable: true },
      { text: "Quota", value: "quota", sortable: true },
      { text: "Deactivated", value: "deactivated_at", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      lastname: "",
      username: "",
      email: "",
      role: "",
      photo: "",
    },
  }),

  mixins: [dateUtils, navigation],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUsers"]),
  },

  watch: {
    dialogDeactivate(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.$store.dispatch("user/getUsers");
  },
  methods: {
    ...mapMutations("admin", ["setFilteredUser"]),

    filter(value, search, item) {
      let filter = RegExp(search, "i").test(
        item.name + item.lastname + item.email + item.username
      );
      return filter;
    },

    rowStyle(item) {
      if (item.deactivated_at != 0) {
        return "grey lighten-4";
      }
    },

    editItem(item) {
      this.setFilteredUser(item);
      this.navigateToRoute({ name: "edit-user" });
    },

    deactivateUser(item) {
      this.editedIndex = this.getUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeactivate = true;
    },

    deactivationConfirm(item) {
      this.closeDelete();
      var data = {
        id: this.editedItem.id,
      };
      this.$store.dispatch("admin/deactiavateUser", data).then((response) => {
        if (item == 0) {
          this.$store.dispatch("alert/showAlert", {
            color: "green",
            text: "User deactivated",
          });
        } else {
          this.$store.dispatch("alert/showAlert", {
            color: "green",
            text: "User restore",
          });
        }

        for (let i = 0; i < this.getUsers.length; i++) {
          if (this.getUsers[i].id == response.data.id) {
            this.getUsers[i].deactivated_at = response.data.deactivated_at;
          }
        }
      });
    },

    closeDelete() {
      this.dialogDeactivate = false;
    },

    filterUsers(item) {
      this.setFilteredUser(item);
    },

    openActions() {
      this.actionShow = true;
    },

    createUser() {
      this.navigateToRoute({ name: "create-new-user" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr {
  cursor: pointer;
}
::v-deep tbody tr td.non-clickable {
  cursor: auto;
}

@media all and (max-width: 599.9999px) {
  ::v-deep .v-data-footer {
    justify-content: right;

    .v-data-footer__pagination {
      margin: 0;
    }
  }
}

::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 23px;
}
</style>