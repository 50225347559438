<template>
  <v-main>
    <v-container
      v-if="loaded"
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
        >
          <v-card class="elevation-12 form-style pa-5 pb-3">
            <v-row
              align="center"
              justify="center"
            >
              <v-img
                max-height="96"
                max-width="96"
                :src="`/static/${getAppearances.logo}`"
              ></v-img>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-card-title>Enter password</v-card-title>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="password"
                    :rules="[passwordRules.required, forbidPasswordRules, minimalCharLength,
                    enforceUpperChar, enforceLowerChar, enforceNumericChar, enforceSpecialChar]"
                    :color="getAppearances.btn_color"
                    required
                    label="New password"
                    type="password"
                    class="input-group--focused mr-1"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="confirm"
                    :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                    :color="getAppearances.btn_color"
                    label="Confirm new password"
                    type="password"
                    outlined
                    dense
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                :disabled="loading"
                :dark="!loading"
                @click="set"
                block
                :color="getAppearances.btn_color"
              >Set Password</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      loaded: false,
      loading: false,
      valid: "",
      password: "",
      confirm: "",
      passwordPolicy: {},
      passwordRules: {
        required: (value) => !!value || "Password is required.",
      },
      confirmPasswordRules: [(v) => !!v || "Password is required"],
    };
  },

  created() {
    this.$store
      .dispatch("auth/isValidLink", this.$router.currentRoute.params.token)
      .then(() => {
        this.loaded = true;
      })
      .catch(() => {
        this.$router.push({ name: "login" });
      });

    this.$store.dispatch("auth/getPasswordPolicy").then((response) => {
      this.passwordPolicy = response.data;
    });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    passwordConfirmationRule() {
      return () => this.password === this.confirm || "Password must match";
    },
  },

  methods: {
    forbidPasswordRules(v) {
      var commonPassword = Array(
        "123456",
        "password",
        "12345678",
        "1234",
        "12345",
        "dragon",
        "qwerty",
        "696969",
        "mustang",
        "letmein",
        "baseball",
        "master",
        "michael",
        "football",
        "shadow",
        "monkey",
        "abc123",
        "pass",
        "6969",
        "jordan",
        "harley",
        "ranger",
        "iwantu",
        "jennifer",
        "hunter",
        "2000",
        "test",
        "batman",
        "trustno1",
        "thomas",
        "tigger",
        "robert",
        "access",
        "love",
        "buster",
        "1234567",
        "soccer",
        "hockey",
        "killer",
        "george",
        "sexy",
        "andrew",
        "charlie",
        "superman",
        "asshole",
        "dallas",
        "jessica",
        "panties",
        "pepper",
        "1111",
        "austin",
        "william",
        "daniel",
        "golfer",
        "summer",
        "heather",
        "hammer",
        "yankees",
        "joshua",
        "maggie",
        "biteme",
        "enter",
        "ashley",
        "thunder",
        "cowboy",
        "silver",
        "richard",
        "orange",
        "merlin",
        "michelle",
        "corvette",
        "bigdog",
        "cheese",
        "matthew",
        "121212",
        "patrick",
        "martin",
        "freedom",
        "ginger",
        "blowjob",
        "nicole",
        "sparky",
        "yellow",
        "camaro",
        "secret",
        "dick",
        "falcon",
        "taylor",
        "111111",
        "131313",
        "123123",
        "bitch",
        "hello",
        "scooter",
        "please",
        "",
        "porsche",
        "guitar",
        "chelsea",
        "black",
        "diamond",
        "nascar",
        "jackson",
        "cameron",
        "654321",
        "computer",
        "pussy",
        "amanda",
        "wizard",
        "xxxxxxxx",
        "money",
        "phoenix",
        "mickey",
        "bailey",
        "knight",
        "iceman",
        "tigers",
        "purple",
        "andrea",
        "horny",
        "dakota",
        "aaaaaa",
        "player",
        "sunshine",
        "morgan",
        "starwars",
        "boomer",
        "cowboys",
        "edward",
        "charles",
        "girls",
        "booboo",
        "coffee",
        "xxxxxx",
        "bulldog",
        "ncc1701",
        "rabbit",
        "peanut",
        "john",
        "johnny",
        "gandalf",
        "spanky",
        "winter",
        "brandy",
        "compaq",
        "carlos",
        "tennis",
        "james",
        "mike",
        "brandon",
        "fender",
        "anthony",
        "blowme",
        "ferrari",
        "cookie",
        "chicken",
        "maverick",
        "chicago",
        "joseph",
        "diablo",
        "sexsex",
        "hardcore",
        "666666",
        "willie",
        "welcome",
        "chris",
        "panther",
        "yamaha",
        "justin",
        "banana",
        "driver",
        "marine",
        "angels",
        "fishing",
        "david",
        "maddog",
        "hooters",
        "wilson",
        "butthead",
        "dennis",
        "captain",
        "bigdick",
        "chester",
        "smokey",
        "xavier",
        "steven",
        "viking",
        "snoopy",
        "blue",
        "eagles",
        "winner",
        "samantha",
        "house",
        "miller",
        "flower",
        "jack",
        "firebird",
        "butter",
        "united",
        "turtle",
        "steelers",
        "tiffany",
        "zxcvbn",
        "tomcat",
        "golf",
        "bond007",
        "bear",
        "tiger",
        "doctor",
        "gateway",
        "gators",
        "angel",
        "junior",
        "thx1138",
        "porno",
        "badboy",
        "debbie",
        "spider",
        "melissa",
        "booger",
        "1212",
        "flyers",
        "fish",
        "porn",
        "matrix",
        "teens",
        "scooby",
        "jason",
        "walter",
        "cumshot",
        "boston",
        "braves",
        "yankee",
        "lover",
        "barney",
        "victor",
        "tucker",
        "princess",
        "mercedes",
        "5150",
        "doggie",
        "zzzzzz",
        "gunner",
        "horney",
        "bubba",
        "2112",
        "fred",
        "johnson",
        "xxxxx",
        "tits",
        "member",
        "boobs",
        "donald",
        "bigdaddy",
        "bronco",
        "penis",
        "voyager",
        "rangers",
        "birdie",
        "trouble",
        "white",
        "topgun",
        "bigtits",
        "bitches",
        "green",
        "super",
        "qazwsx",
        "magic",
        "lakers",
        "rachel",
        "slayer",
        "scott",
        "2222",
        "asdf",
        "video",
        "london",
        "7777",
        "marlboro",
        "srinivas",
        "internet",
        "action",
        "carter",
        "jasper",
        "monster",
        "teresa",
        "jeremy",
        "11111111",
        "bill",
        "crystal",
        "peter",
        "pussies",
        "cock",
        "beer",
        "rocket",
        "theman",
        "oliver",
        "prince",
        "beach",
        "amateur",
        "7777777",
        "muffin",
        "redsox",
        "star",
        "testing",
        "shannon",
        "murphy",
        "frank",
        "hannah",
        "dave",
        "eagle1",
        "11111",
        "mother",
        "nathan",
        "raiders",
        "steve",
        "forever",
        "angela",
        "viper",
        "ou812",
        "jake",
        "lovers",
        "suckit",
        "gregory",
        "buddy",
        "whatever",
        "young",
        "nicholas",
        "lucky",
        "helpme",
        "jackie",
        "monica",
        "midnight",
        "college",
        "baby",
        "brian",
        "mark",
        "startrek",
        "sierra",
        "leather",
        "232323",
        "4444",
        "beavis",
        "bigcock",
        "happy",
        "sophie",
        "ladies",
        "naughty",
        "giants",
        "booty",
        "blonde",
        "golden",
        "0",
        "fire",
        "sandra",
        "pookie",
        "packers",
        "einstein",
        "dolphins",
        "0",
        "chevy",
        "winston",
        "warrior",
        "sammy",
        "slut",
        "8675309",
        "zxcvbnm",
        "nipples",
        "power",
        "victoria",
        "asdfgh",
        "vagina",
        "toyota",
        "travis",
        "hotdog",
        "paris",
        "rock",
        "xxxx",
        "extreme",
        "redskins",
        "erotic",
        "dirty",
        "ford",
        "freddy",
        "arsenal",
        "access14",
        "wolf",
        "nipple",
        "iloveyou",
        "alex",
        "florida",
        "eric",
        "legend",
        "movie",
        "success",
        "rosebud",
        "jaguar",
        "great",
        "cool",
        "cooper",
        "1313",
        "scorpio",
        "mountain",
        "madison",
        "987654",
        "brazil",
        "lauren",
        "japan",
        "naked",
        "squirt",
        "stars",
        "apple",
        "alexis",
        "aaaa",
        "bonnie",
        "peaches",
        "jasmine",
        "kevin",
        "matt",
        "qwertyui",
        "danielle",
        "beaver",
        "4321",
        "4128",
        "runner",
        "swimming",
        "dolphin",
        "gordon",
        "casper",
        "stupid",
        "shit",
        "saturn",
        "gemini",
        "apples",
        "august",
        "3333",
        "canada",
        "blazer",
        "cumming",
        "hunting",
        "kitty",
        "rainbow",
        "112233",
        "arthur",
        "cream",
        "calvin",
        "shaved",
        "surfer",
        "samson",
        "kelly",
        "paul",
        "mine",
        "king",
        "racing",
        "5555",
        "eagle",
        "hentai",
        "newyork",
        "little",
        "redwings",
        "smith",
        "sticky",
        "cocacola",
        "animal",
        "broncos",
        "private",
        "skippy",
        "marvin",
        "blondes",
        "enjoy",
        "girl",
        "apollo",
        "parker",
        "qwert",
        "time",
        "sydney",
        "women",
        "voodoo",
        "magnum",
        "juice",
        "abgrtyu",
        "777777",
        "dreams",
        "maxwell",
        "music",
        "rush2112",
        "russia",
        "scorpion",
        "rebecca",
        "tester",
        "mistress",
        "phantom",
        "billy",
        "6666",
        "albert"
      );

      if (
        v.length > 0 &&
        this.passwordPolicy.forbid_common_passwords == "true"
      ) {
        for (let i = 0; i < commonPassword.length; i++) {
          if (commonPassword[i] == v) {
            return `Your password is too weak`;
          }
        }
      } else {
        return true;
      }
      return true;
    },

    enforceUpperChar(v) {
      if (v.length > 0 && this.passwordPolicy.UpperLowerCharacters == true) {
        if (!/[A-Z]/.test(v)) {
          return `Password must contain at least one upper case characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceLowerChar(v) {
      if (v.length > 0 && this.passwordPolicy.UpperLowerCharacters == true) {
        if (!/[a-z]/.test(v)) {
          return `Password must contain at least one lower case characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    minimalCharLength(v) {
      if (v.length > 0) {
        if (v.length <= this.passwordPolicy.PasswordMinLength - 1) {
          return `Minimum "${this.passwordPolicy.PasswordMinLength}" characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceSpecialChar(v) {
      if (v.length > 0 && this.passwordPolicy.SpecialCharacters == true) {
        if (!/[!@#$%^&*)(+=._-]/.test(v)) {
          return `Password must contain at least one special character`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceNumericChar(v) {
      if (v.length > 0 && this.passwordPolicy.NumericCharacters == true) {
        if (!/\d/.test(v)) {
          return `Password must contain at least one numeric character`;
        }
      } else {
        return true;
      }
      return true;
    },

    set() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var data = {
          password: this.password,
          confirmPassword: this.confirm,
          token: this.$router.currentRoute.params.token,
        };
        this.$store
          .dispatch("auth/setPassword", data)
          .then(() => {
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>