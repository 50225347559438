<template>
  <v-container fluid>
    <v-row
      class="px-1 pt-5 fill-height"
      align="start"
    >
      <v-col>
        <v-card-text class="px-0 ma-0">
          <v-list>
            <v-list-item class="px-0 ma-0">
              <v-list-item-icon class="ml-4 mr-3 px-0">
              </v-list-item-icon>

              <v-list-item-content class="mx-1">
                <v-list-item-subtitle v-html="headers.task">
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block mx-1">
                <v-list-item-subtitle v-html="headers.project">
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block mx-1">
                <v-list-item-subtitle v-html="headers.status">
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="ml-1">
                <v-list-item-subtitle v-html="headers.due">
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-card-text>
        <v-row class="mb-2">
          <v-btn
            icon
            @click="show = !show"
          >
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-btn
            color="red darken-2"
            text
            @click="show = !show"
          >
            Late
          </v-btn>
        </v-row>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>

            <v-card-text class="px-0 ma-0">
              <v-card-text v-if="late.length <= 0">Tasks that are missed will appear here</v-card-text>
              <v-list>
                <div
                  v-for="(item, index) in late"
                  :key="item.id"
                >
                  <v-list-item
                    class="overdue px-0 ma-0"
                    color="primary"
                    @click="openProject(item)"
                  >
                    <v-list-item-icon class="ml-4 mr-3 px-0">
                      <v-icon v-if="item.type == 'subtask'">mdi-file-tree</v-icon>
                      <v-icon v-else>mdi-calendar-check
                      </v-icon>

                    </v-list-item-icon>
                    <v-list-item-content class="mx-1">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.status}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="ml-1">
                      <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateString(item.end_date) }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                  <v-divider :key="index + item.title"></v-divider>
                </div>
              </v-list>
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-row class="mb-2">
          <v-btn
            icon
            @click="showToday = !showToday"
          >
            <v-icon>{{ showToday ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-btn
            color="orange lighten-2"
            text
            @click="showToday = !showToday"
          >
            Today
          </v-btn>
        </v-row>

        <v-expand-transition>
          <div v-show="showToday">
            <v-divider></v-divider>

            <v-card-text class="px-0 ma-0">
              <v-card-text v-if="today.length <= 0">Tasks that are due today will appear here</v-card-text>
              <v-list>
                <div
                  v-for="(item, index) in today"
                  :key="item.id"
                >
                  <v-list-item
                    class="today px-0 ma-0"
                    color="primary"
                    @click="openProject(item)"
                  >
                    <v-list-item-icon class="ml-4 mr-3 px-0">
                      <v-icon v-if="item.type == 'subtask'">mdi-file-tree</v-icon>
                      <v-icon v-else>mdi-calendar-check
                      </v-icon>

                    </v-list-item-icon>
                    <v-list-item-content class="mx-1">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.status}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="ml-1">
                      <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateString(item.end_date) }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                  <v-divider :key="index + item.title"></v-divider>
                </div>
              </v-list>
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-row class="mb-2">
          <v-btn
            icon
            @click="showUpcoming = !showUpcoming"
          >
            <v-icon>{{ showUpcoming ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-btn
            color="green"
            text
            @click="showUpcoming = !showUpcoming"
          >
            Upcoming
          </v-btn>
        </v-row>

        <v-expand-transition>
          <div v-show="showUpcoming">
            <v-divider></v-divider>

            <v-card-text class="px-0 ma-0">
              <v-card-text v-if="upcoming.length <= 0">Tasks that are scheduled soon will appear here</v-card-text>
              <v-list>
                <div
                  v-for="(item, index) in upcoming"
                  :key="item.id"
                >
                  <v-list-item
                    class="upcoming px-0 ma-0"
                    color="primary"
                    @click="openProject(item)"
                  >
                    <v-list-item-icon class="ml-4 mr-3 px-0">
                      <v-icon v-if="item.type == 'subtask'">mdi-file-tree</v-icon>
                      <v-icon v-else>mdi-calendar-check
                      </v-icon>

                    </v-list-item-icon>
                    <v-list-item-content class="mx-1">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.status}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="ml-1">
                      <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateString(item.end_date) }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                  <v-divider :key="index + item.title"></v-divider>
                </div>
              </v-list>
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-row class="mb-2">
          <v-btn
            icon
            @click="showNoDate = !showNoDate"
          >
            <v-icon>{{ showNoDate ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="showNoDate = !showNoDate"
          >
            No Date
          </v-btn>
        </v-row>

        <v-expand-transition>
          <div v-show="showNoDate">
            <v-divider></v-divider>

            <v-card-text class="px-0 ma-0">
              <v-card-text v-if="noDate.length <= 0">Tasks that have no date will appear here</v-card-text>
              <v-list>
                <div
                  v-for="(item, index) in noDate"
                  :key="item.id"
                >
                  <v-list-item
                    class="nodate px-0 ma-0"
                    color="primary"
                    @click="openProject(item)"
                  >
                    <v-list-item-icon class="ml-4 mr-3 px-0">
                      <v-icon v-if="item.type == 'subtask'">mdi-file-tree</v-icon>
                      <v-icon v-else>mdi-calendar-check
                      </v-icon>

                    </v-list-item-icon>
                    <v-list-item-content class="mx-1">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="d-none d-sm-block mx-1">
                      <v-list-item-title>{{item.status}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content class="ml-1">
                      <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateString(item.end_date) }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                  <v-divider :key="index + item.title"></v-divider>
                </div>
              </v-list>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";

export default {
  data() {
    return {
      dayStart: new Date().getTime() / 1000,
      show: true,
      showToday: true,
      showUpcoming: true,
      showNoDate: true,
      late: [],
      today: [],
      upcoming: [],
      noDate: [],
      headers: {
        task: "Task name",
        project: "Project",
        due: "Due date",
        status: "Status",
      },
    };
  },

  mixins: [dateUtils],

  created() {
    this.$store.dispatch("projects/getMyTasks").then((response) => {
      this.sortByCategory(response.data);
    });
  },

  computed: {
    ...mapGetters("user", ["getUserById", "getUsers"]),
  },

  methods: {
    openProject(item) {
      let id = item.id;
      if (item.type == "subtask") {
        id = item.parent_id;
      }
      this.$router.push({
        name: "project",
        params: { id: item.project_id, fullTask: id },
      });
    },

    getFullUserName(id) {
      let user = this.getUserById(id);

      return user.name + " " + user.lastname;
    },

    sortByCategory(data) {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      let sday = start.getTime() / 1000;

      const end = new Date();
      end.setHours(23, 59, 59, 999);
      let eday = end.getTime() / 1000;

      for (let i = 0; i < data.length; i++) {
        if (
          data[i].status != "completed" &&
          data[i].status != "closed" &&
          data[i].trash == false
        ) {
          if (data[i].end_date == "") {
            this.noDate.push(data[i]);
          } else if (data[i].end_date < sday) {
            this.late.push(data[i]);
          } else if (data[i].end_date <= eday && data[i].end_date >= sday) {
            this.today.push(data[i]);
          } else if (data[i].end_date > sday) {
            this.upcoming.push(data[i]);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
th .aligner {
  display: flex;
  align-items: center;
}
.nodate {
  border-left: 3px solid #2196f3;
  border-radius: 3px;
}

.overdue {
  border-left: 3px solid #d32f2f;
  border-radius: 3px;
}

.today {
  border-left: 3px solid coral;
  border-radius: 3px;
}

.upcoming {
  border-left: 3px solid #2e7d32;
  border-radius: 3px;
}
</style>