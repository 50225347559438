import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    snackbar: false,
  },

  getters: {
    getDragBar(state) {
      return state.snackbar;
    },
  },

  mutations: {
    showDragBar(state, status) {
      state.snackbar = status;
    },
  },
  namespaced: true,
})
