import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    appearances: {},
    notifications: [],
    filtered: [],
  },

  getters: {
    getAppearances(state) {
      return state.appearances;
    },
    getFilteredUser(state) {
      return state.filtered;
    },
  },

  mutations: {
    setAppearances(state, appearances) {
      state.appearances = appearances;
    },
    setFilteredUser(state, filtered) {
      state.filtered = filtered;
    },
    addUser: (state, user) => {
      state.users.push(user);
    },
  },
  actions: {
    createUser(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/create-user',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: data.name,
            username: data.username,
            lastname: data.lastname,
            email: data.email,
            password: data.password,
            timezone: data.timezone,
            clockDisplay: data.clockDisplay,
            role: data.role,
            quota: data.quota,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.dispatch('alert/showAlert', {
              color: 'green',
              text: response.data.name + ', ' + 'registered in database'
            }, { root: true })
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create new User'
            }, { root: true })
          });
      })
    },

    editUser(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/edit-user',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id,
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            role: data.role,
            quota: data.quota,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.dispatch('alert/showAlert', {
              color: 'green',
              text: response.data.name + ', ' + 'updated in database'
            }, { root: true })
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create new User'
            }, { root: true })
          });
      })
    },

    deactiavateUser(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/deactivate-user/' + data.id,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update User status'
            }, { root: true })
          });
      })
    },

    editUserPhoto(context, formData) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/upload-users-photo',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        };
        axios(options)
          .then(response => {
            resolve(response)
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to upload'
            }, { root: true })
          });
      })
    },

    getPreferences(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/preferences',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to load User Notifications'
            }, { root: true })
          });
      })
    },

    updatePreferences(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/preferences',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            preferences: data
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to save Notification'
            }, { root: true })
          });
      })
    },

    updateNotifications(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/notifications/update',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            notifications: data
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to save Notification'
            }, { root: true })
          });
      })
    },

    async getAppearances(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/appearances',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit('setAppearances', response.data);
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to load Appearances'
            }, { root: true })
          });
      })
    },

    updateAppearances(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/appearances',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            AppearanceSettings: data,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update'
            }, { root: true })
          });
      })
    },

    uploadAppearanceImages(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/appearances/image',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: data
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update'
            }, { root: true })
          });
      })
    },

    getEmailServer(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/email-server',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to load Email Server'
            }, { root: true })
          });
      })
    },

    updateEmailServer(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/email-server',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            ConnectionSecurity: data.ConnectionSecurity,
            EnableSMTPAuth: data.EnableSMTPAuth,
            SMTPServer: data.SMTPServer,
            SMTPPort: data.SMTPPort,
            SMTPUsername: data.SMTPUsername,
            SMTPPassword: data.SMTPPassword,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update Email Server'
            }, { root: true })
          });
      })
    },

    testEmailConnection() {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/settings/email-server/connection',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error)
          });
      })
    },

    removeAppearanceImage(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings//appearances/image',
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            image: data,
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getSecuritySettings(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/security-settings',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to load Authorization/Encryption'
            }, { root: true })
          });
      })
    },

    updateSecuritySettings(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/security-settings',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            passwordSettings: data.passwordSettings,
            sessionLength: data.sessionLength
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update'
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})