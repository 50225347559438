<template>
  <div class="grow">
    <v-list dense>
      <v-subheader>
        HOME
      </v-subheader>
      <v-list-item-group
        v-model="favourite"
        :mandatory="mandatory"
        :color="getAppearances.btn_color"
      >
        <v-list-item
          v-for="(favourite, i) in favourites"
          :key="i"
          @click="navigate(favourite)"
        >
          <v-list-item-icon>
            <v-icon v-text="favourite.icon">

            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="favourite.text">

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list dense>
      <v-subheader>
        PROJECTS
      </v-subheader>
    </v-list>
    <v-treeview
      :open.sync="open"
      :active="active"
      :load-children="fetch"
      :items="getTreeviewFolders"
      :color="getAppearances.btn_color"
      item-text="title"
      activatable
      hoverable
      transition
      dense
      v-on:update:active="openProject"
      return-object
    >
      <template v-slot:prepend="{ item }">
        <v-icon>
          {{ item.id == "0" ? 'mdi-chart-gantt' : 'mdi-folder' }}
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <div
          v-if="item.id == '0'"
          class="text-body-2"
        >
          {{item.title}}
        </div>
        <div
          v-else
          class="text-body-2"
        >
          {{item.title}}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";
import { navigation } from "../../mixins/navigation";
export default {
  data: function () {
    return {
      open: [],
      active: [],

      mandatory: false,
      favourite: false,

      favourites: [
        {
          text: "My Tasks",
          icon: "mdi-checkbox-marked-outline",
          name: "my-tasks",
        },
        { text: "Recent tasks", icon: "mdi-history", name: "project-recents" },
        {
          text: "Favourite projects",
          icon: "mdi-star",
          name: "project-favourites",
        },
        { text: "Deleted tasks", icon: "mdi-delete", name: "project-trash" },
      ],
    };
  },

  mixins: [utils, navigation],

  created() {
    if (this.$route.name == "project-folder") {
      this.$store
        .dispatch("projects/getProjectsInFolder", this.$route.params.id)
        .then((response) => {
          this.$store.commit("projects/setProjectFolder", response.data);
        });
    }
  },

  watch: {
    open() {
      this.$store.commit("projects/setOpenedFolders", this.open);
    },
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getProjects", "getTreeviewFolders"]),
  },

  methods: {
    navigate(item) {
      this.active = [];
      this.mandatory = true;
      this.favourite = item;

      this.navigateToRoute({
        name: item.name,
      });
    },

    async fetch(item) {
      if (item.id == "0") {
        await this.$store
          .dispatch("projects/getProjectsFolders")
          .then((response) => {
            item.children.push(...response.data.Folders);
          });
      }
    },

    openProject(selected) {
      if (selected.length == 0) {
        return;
      }

      this.mandatory = false;
      this.favourite = false;

      if (selected[0] != undefined && selected[0].id != "0") {
        this.navigateToRoute({
          name: "project-folder",
          params: { id: selected[0].id },
        });

        this.$store
          .dispatch("projects/getProjectsInFolder", selected[0].id)
          .then((response) => {
            this.$store.commit("projects/setProjectFolder", response.data);
          });
      } else {
        this.navigateToRoute({ name: "my-projects" });
      }
    },
  },
};
</script>

<style>
.v-treeview-node__root > button::after {
  color: rgba(0, 0, 0, 0);
}
</style>
