<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col
        align="center"
        style="max-width: 700px"
      >
        <h1 class="font-weight-light">
          Security
        </h1>
        <div
          class="text-wrap font-weight-regular text--secondary"
        >
          Manage your account security and sharing settings
        </div>
        <v-row
          align="center"
          justify="center"
        >
          <v-card
            class="my-9"
            elevation="0"
          >
            <v-card flat>
              <v-card-text
                class="px-0"
              >
                <div class="text-left">
                  <AuthorizationEncryption />
                </div>
              </v-card-text>
            </v-card>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AuthorizationEncryption from "../../../components/Settings/Admin/Security/AuthorizationEncryption";

export default {
  components: {
    AuthorizationEncryption,
    // Sharing,
    // Retention,
    // Timeandplace,
  },
  data: () => ({
    model: "tab-2",
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },
};
</script>