<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      class="fill-height d-none d-lg-block"
      justify="center"
    >
      <v-col align="center">
        <h1 class="font-weight-light">
          Personal info
        </h1>
        <div class="text-wrap font-weight-regular text--secondary">
          Basic info, such as your name and photo, that you use on our services
        </div>
        <v-card
          max-width="700"
          class="my-9"
          elevation="0"
        >
          <div class="pl-2">
            <v-list-item class="px-0 text-left">
              <v-list-item-content>
                <v-list-item-title class="text-left pl-4 pt-5">
                  Profile
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap my-1 text-left pl-4 pb-0">
                  Some info may be visible to other people using our services.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list
            class="pb-0 mx-1"
            flat
          >
            <v-list-item-group>
              <template v-for="(item, index) in description()">
                <v-list-item
                  class="py-0 my-n1"
                  @click="openDialog(item)"
                  :key="index"
                >
                  <v-list-item-content class="py-0">
                    <v-col
                      lg="4"
                      md="4"
                      sm="4"
                      xs="12"
                      class="py-0 ml-n3"
                    >
                      <v-list
                        dense
                        class="py-0"
                      >
                        <v-subheader class="py-0 text-uppercase black--text">
                          {{item.name}}
                        </v-subheader>
                      </v-list>
                    </v-col>
                    <v-col
                      lg="8"
                      md="8"
                      sm="8"
                      xs="12"
                      class="py-0 pl-0"
                    >
                      <v-list-item-title
                        :class="item.name == 'Photo' ? 'text-left pl-2 text-wrap' : 'text-left text-wrap'"
                        v-text="item.description"
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.name == 'Timezone'"
                        class="text-left"
                        v-text="time()"
                      >
                        <v-icon class="mr-1">mdi-earth</v-icon>
                      </v-list-item-subtitle>
                    </v-col>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-avatar v-if="item.name == 'Photo'">
                      <v-img
                        :src="`/api/users/photo/`+ getUser.photo"
                        alt="mdi-menu-right"
                      >
                      </v-img>
                    </v-list-item-avatar>
                    <v-btn
                      v-else
                      icon
                    >
                      <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  class="my-2 mb-2 mx-1"
                  :key="item.name"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <!-- Mobile -->
    <v-row
      class="fill-height d-lg-none"
      justify="center"
    >
      <v-col
        align="center"
        class="px-0 py-0"
      >
        <h1 class="headline">
          Personal info
        </h1>
        <div class="text-wrap font-weight-regular text--secondary">
          Basic info, such as your name and photo, that you use on our services
        </div>
        <v-card
          max-width="750"
          elevation="0"
          class="my-9"
        >
          <div class="pl-2 pt-3">
            <v-list-item class="px-0 text-left">
              <v-list-item-content>
                <v-list-item-title class="text-left ml-1">
                  Profile
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap my-1 text-left px-0 ml-1">
                  Some info may be visible to other people using our services.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list
            class="pb-0"
            flat
          >
            <v-list-item-group>
              <template v-for="(item, index) in description()">
                <v-list-item
                  class="py-0 my-n1 px-0"
                  @click="openDialog(item)"
                  :key="index"
                >
                  <v-list-item-content class="py-0">
                    <v-col
                      cols="4"
                      class="py-0"
                    >
                      <v-list
                        dense
                        class="py-0"
                      >
                        <v-subheader class="py-0 text-uppercase black--text">
                          {{item.name}}
                        </v-subheader>
                      </v-list>
                    </v-col>
                    <v-col
                      cols="8"
                      class="py-0 pl-0"
                    >
                      <v-list-item-title
                        :class="item.name == 'Photo' ? 'text-left pl-2 text-wrap' : 'text-left text-wrap'"
                        v-text="item.description"
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.name == 'Timezone'"
                        class="text-left"
                        v-text="time()"
                      >
                        <v-icon class="mr-1">mdi-earth</v-icon>

                      </v-list-item-subtitle>
                    </v-col>
                  </v-list-item-content>
                  <v-list-item-action class="mr-3">
                    <v-list-item-avatar v-if="item.name == 'Photo'">
                      <v-img
                        :src="`/api/users/photo/`+ getUser.photo"
                        alt="mdi-menu-right"
                      >
                      </v-img>
                    </v-list-item-avatar>
                    <v-btn
                      v-else
                      icon
                    >
                      <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  class="my-2 mb-2 mx-3"
                  :key="item.name"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="photoDialog"
      persistent
      max-width="500"
    >
      <v-card class="pa-1">
        <v-form
          ref="formPhoto"
          v-model="valid"
        >
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-file-input
                v-model="photo"
                :rules="photoRules"
                :color="getAppearances.btn_color"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Photo"
                prepend-icon="mdi-camera"
                label="Pick a photo"
                hint="Use square format photo for better experience"
                persistent-hint
              ></v-file-input>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1"
              @click="closePhotoDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              :disabled="disabledPhoto()"
              class="white--text"
              @click="photoUpload()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="nameDialog"
      persistent
      max-width="500"
    >
      <v-card class="pa-1">
        <v-form
          ref="formName"
          v-model="valid"
        >
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="profile.name"
                :rules="nameRules"
                :color="getAppearances.btn_color"
                required
                prepend-icon="mdi-account-outline"
                type="text"
                label="First name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="profile.lastname"
                :rules="lastnameRules"
                :color="getAppearances.btn_color"
                required
                prepend-icon="mdi-account-outline"
                type="text"
                label="Last name"
              ></v-text-field>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1"
              @click="closeNameDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              :disabled="disabledNameLastname()"
              class="white--text"
              @click="updateName()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="emailDialog"
      persistent
      max-width="500"
    >
      <v-card class="pa-1">
        <v-form
          ref="formEmail"
          v-model="valid"
        >
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="profile.email"
                :rules="emailRules"
                :color="getAppearances.btn_color"
                required
                prepend-icon="mdi-at"
                type="text"
                label="Email"
              ></v-text-field>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1"
              @click="closeEmailDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              :disabled="disabledEmail()"
              class="white--text"
              @click="updateEmail()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="passwordDialog"
      persistent
      max-width="500"
    >
      <v-card class="pa-1">
        <v-form
          ref="formPassword"
          v-model="valid"
        >
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                dense
                v-model="passwordOld"
                class="input-group--focused"
                prepend-icon="mdi-lock-outline"
                :color="getAppearances.btn_color"
                label="Old password"
                :append-icon="showOldpw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required]"
                required
                :type="showOldpw ? 'text' : 'password'"
                @click:append="showOldpw = !showOldpw"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="passwordNew"
                dense
                class="input-group--focused"
                prepend-icon="mdi-lock-outline"
                :color="getAppearances.btn_color"
                label="New password"
                :append-icon="showNewpw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required, minimalCharLength,
                enforceUpperChar, enforceLowerChar, enforceNumericChar, enforceSpecialChar]"
                required
                :type="showNewpw ? 'text' : 'password'"
                @click:append="showNewpw = !showNewpw"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="passwordConfirm"
                prepend-icon="mdi-lock-check-outline"
                :color="getAppearances.btn_color"
                dense
                label="Confirm new password"
                :append-icon="showConfirmpw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                required
                :type="showConfirmpw ? 'text' : 'password'"
                @click:append="showConfirmpw = !showConfirmpw"
              ></v-text-field>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1"
              @click="closePasswordDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              :disabled="disabledPassword()"
              class="white--text"
              @click="updatePassword()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="localeDialog"
      persistent
      max-width="500"
    >
      <v-card class="pa-1">
        <v-form
          ref="formLocale"
          v-model="valid"
        >
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex mb-0 pb-0"
            >
              <v-checkbox
                v-model="getUser.timezone.useAutomaticTimezone"
                false-value="false"
                true-value="true"
                hide-details
                label="Use automatic timezone"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-autocomplete
                v-model="timezone"
                :color="getAppearances.btn_color"
                :items="timezoneList()"
                single-line
                :disabled="getUser.timezone.useAutomaticTimezone == 'true'"
                prepend-icon="mdi-earth"
                label="Locale"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <p>Date and Clock Display</p>
              <v-radio-group v-model="selectedClock">
                <v-radio
                  label="12-hour clock (example: month/day/year 4:00 PM)"
                  :color="getAppearances.btn_color"
                  value="12h"
                ></v-radio>
                <v-radio
                  label="24-hour clock (example: day.month.year 16:00)"
                  :color="getAppearances.btn_color"
                  value="24h"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1"
              @click="closeLocaleDialog(), timezone = oldTimezone"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              :disabled="disabledLocale()"
              class="white--text"
              @click="updateLocale()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import timezones from "timezones.json";
import moment from "moment-timezone";
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    passwordPolicy: {},
    useAutomaticTimezone: false,
    selectedClock: "",
    timezone: "",
    oldTimezone: "",
    photoDialog: false,
    nameDialog: false,
    emailDialog: false,
    passwordDialog: false,
    localeDialog: false,
    photo: null,
    profile: {},
    valid: true,
    showOldpw: false,
    showNewpw: false,
    showConfirmpw: false,
    passwordOld: "",
    passwordNew: "",
    passwordConfirm: "",
    localeList: moment.tz.names(),
    photoRules: [
      (value) =>
        !value ||
        value.size < 1000000 ||
        "Photo size should be less than 1 MB!",
    ],
    nameRules: [
      (v) => !!v || "First name is required",
      (v) =>
        (v && v.length <= 20) || "First name must be less than 20 characters",
    ],
    lastnameRules: [
      (v) => !!v || "Last name is required",
      (v) =>
        (v && v.length <= 20) || "Last name must be less than 20 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: {
      required: (value) => !!value || "Password is required.",
    },
    confirmPasswordRules: [(v) => !!v || "Password is required"],
  }),

  computed: {
    ...mapGetters("user", [
      "getUser",
      "getTimezone",
      "getAutomaticTimezone",
      "getClockDisplay",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    passwordConfirmationRule() {
      return () =>
        this.passwordNew === this.passwordConfirm || "Password must match";
    },
  },

  created() {
    this.profile = { ...this.getUser };
  },

  async mounted() {
    this.$store.dispatch("auth/getPasswordPolicy").then((response) => {
      this.passwordPolicy = response.data;
    });

    this.getTimezoneInfo();
  },

  methods: {
    ...mapMutations("user", ["setUser"]),

    getTimezoneInfo() {
      this.useAutomaticTimezone = this.getUser.timezone.useAutomaticTimezone;

      for (let i = 0; i < timezones.length; i++) {
        for (let j = 0; j < timezones[i].utc.length; j++) {
          if (timezones[i].utc[j] == this.getTimezone) {
            this.timezone = timezones[i].text;
            this.oldTimezone = timezones[i].text;
          }
        }
      }
    },

    timezoneList() {
      var list = [];
      for (let i = 0; i < timezones.length; i++) {
        list.push(timezones[i].text);
      }

      return list;
    },

    disabledPassword() {
      if (
        this.passwordNew != "" &&
        this.passwordOld != "" &&
        this.passwordConfirm != ""
      ) {
        return false;
      }
      return true;
    },

    disabledPhoto() {
      if (this.photo != null) {
        return false;
      } else {
        return true;
      }
    },

    disabledLocale() {
      if (
        this.oldTimezone != this.timezone ||
        this.selectedClock != this.getClockDisplay ||
        this.useAutomaticTimezone != this.getUser.timezone.useAutomaticTimezone
      ) {
        return false;
      }

      return true;
    },

    disabledEmail() {
      if (this.profile.email != this.getUser.email) {
        return false;
      } else {
        return true;
      }
    },

    disabledNameLastname() {
      if (
        this.profile.name != this.getUser.name ||
        this.profile.lastname != this.getUser.lastname
      ) {
        return false;
      } else {
        return true;
      }
    },

    enforceUpperChar(v) {
      if (
        v.length > 0 &&
        this.passwordPolicy.enforce_upper_lower_characters == "true"
      ) {
        if (!/[A-Z]/.test(v)) {
          return `Password must contain at least one upper case characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceLowerChar(v) {
      if (
        v.length > 0 &&
        this.passwordPolicy.enforce_upper_lower_characters == "true"
      ) {
        if (!/[a-z]/.test(v)) {
          return `Password must contain at least one lower case characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    minimalCharLength(v) {
      if (v.length > 0) {
        if (v.length <= this.passwordPolicy.password_min_lenght - 1) {
          return `Minimum "${this.passwordPolicy.password_min_lenght}" characters`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceSpecialChar(v) {
      if (
        v.length > 0 &&
        this.passwordPolicy.enforce_special_characters == "true"
      ) {
        if (!/[!@#$%^&*)(+=._-]/.test(v)) {
          return `Password must contain at least one special character`;
        }
      } else {
        return true;
      }
      return true;
    },

    enforceNumericChar(v) {
      if (
        v.length > 0 &&
        this.passwordPolicy.enforce_numeric_characters == "true"
      ) {
        if (!/\d/.test(v)) {
          return `Password must contain at least one numeric character`;
        }
      } else {
        return true;
      }
      return true;
    },

    time() {
      let tz = this.getTimezone;
      var format = "";

      if (this.getClockDisplay == "12h") {
        format = moment().tz(tz).format("MM/DD/YYYY LT"); // USA
      } else {
        format = moment().tz(tz).format("DD.MM.YYYY HH:mm"); // EU
      }

      return format;
    },

    openDialog(item) {
      switch (item.name) {
        case "Photo":
          this.photoDialog = true;
          break;
        case "Name":
          this.nameDialog = true;
          break;
        case "Email":
          this.emailDialog = true;
          break;
        case "Password":
          this.passwordDialog = true;
          break;
        case "Timezone":
          this.selectedClock = this.getClockDisplay;
          this.localeDialog = true;
          break;
        default:
          break;
      }
    },

    description() {
      var items = [
        { name: "Photo", description: "Upload your photo here" },
        {
          name: "Name",
          description: this.getUser.name + " " + this.getUser.lastname,
        },
        { name: "Email", description: this.getUser.email },
        { name: "Password", description: "Change your password" },
        { name: "Timezone", description: this.timezone },
      ];
      return items;
    },

    photoUpload() {
      if (this.$refs.formPhoto.validate() && this.photo != null) {
        let formData = new FormData();
        formData.append("file", this.photo);
        this.$store.dispatch("user/updatePhoto", formData).then((response) => {
          this.getUser.photo = response.data;
        });
        this.closePhotoDialog();
      }
    },

    closePhotoDialog() {
      this.$refs.formPhoto.reset();
      this.photoDialog = false;
    },

    closeNameDialog() {
      this.profile.name = this.getUser.name;
      this.profile.lastname = this.getUser.lastname;
      this.nameDialog = false;
    },

    closeEmailDialog() {
      this.profile.email = this.getUser.email;
      this.emailDialog = false;
    },

    closePasswordDialog() {
      this.passwordDialog = false;
      this.passwordConfirm = "";
      this.passwordNew = "";
      this.passwordOld = "";
      this.$refs.formPassword.resetValidation();
    },

    closeLocaleDialog() {
      this.localeDialog = false;
    },

    updateName() {
      if (
        (this.$refs.formName.validate() &&
          this.profile.name != this.getUser.name) ||
        this.profile.lastname != this.getUser.lastname
      ) {
        var data = {
          id: this.getUser.id,
          name: this.profile.name,
          lastname: this.profile.lastname,
        };
        this.$store.dispatch("user/updateName", data).then((response) => {
          this.closeNameDialog();
          this.profile.name = response.data.Name;
          this.getUser.name = response.data.Name;
          this.profile.lastname = response.data.Lastname;
          this.getUser.lastname = response.data.Lastname;
          this.$store.dispatch("alert/showAlert", {
            color: "green",
            text: "Account updated",
          });
        });
      }
    },

    updateEmail() {
      if (
        this.$refs.formEmail.validate() &&
        this.profile.email != this.getUser.email
      ) {
        var data = {
          id: this.getUser.id,
          email: this.profile.email,
        };
        this.$store.dispatch("user/updateEmail", data).then((response) => {
          this.closeEmailDialog();
          this.setUser.email = response.data.Email;
          this.profile.email = response.data.Email;
          this.getUser.email = response.data.Email;
          this.$store.dispatch("alert/showAlert", {
            color: "green",
            text: "Account updated",
          });
        });
      }
    },

    updateLocale() {
      if (this.$refs.formLocale.validate()) {
        let timezone = "";
        for (let i = 0; i < timezones.length; i++) {
          if (this.timezone == timezones[i].text) {
            timezone = timezones[i].utc[0];
          }
        }

        this.$store
          .dispatch("user/updateTimezone", {
            automaticTimezone: this.getAutomaticTimezone,
            manualTimezone: timezone,
            useAutomaticTimezone: this.getUser.timezone.useAutomaticTimezone,
            clockDisplay: this.selectedClock,
          })
          .then(() => {
            this.useAutomaticTimezone =
              this.getUser.timezone.useAutomaticTimezone;
            if (this.getUser.timezone.useAutomaticTimezone == "true") {
              for (let i = 0; i < timezones.length; i++) {
                for (let j = 0; j < timezones[i].utc.length; j++) {
                  if (timezones[i].utc[j] == this.getAutomaticTimezone) {
                    this.timezone = timezones[i].text;
                    this.oldTimezone = timezones[i].text;
                  }
                }
              }
            } else {
              this.oldTimezone = this.timezone;
            }
          });

        this.$store.commit("user/setClock", this.selectedClock);
        this.closeLocaleDialog();
      }
    },

    updatePassword() {
      if (this.$refs.formPassword.validate()) {
        var data = {
          id: this.getUser.id,
          password: this.passwordNew,
          oldPassword: this.passwordOld,
          email: this.getUser.email,
        };
        this.$store.dispatch("user/updatePassword", data).then(() => {
          this.$store.dispatch("alert/showAlert", {
            color: "green",
            text: "Account updated",
          });
        });
        this.closePasswordDialog();
      }
    },
  },
};
</script>