import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    dialog: false,
    folderdialog: false,
    moveDialog: false,
    document: {}
  },

  getters: {
    dialog(state) {
      return state.dialog;
    },
    getDocument(state) {
      return state.document;
    },
    folderdialog(state) {
      return state.folderdialog;
    },
    moveDialog(state) {
      return state.moveDialog;
    },
  },

  mutations: {
    setDocument(state, document) {
      state.document = document;
    },
    openFileDialog(state, status) {
      state.dialog = status;
    },
    openFolderDialog(state, status) {
      state.folderdialog = status;
    },
    openMoveDialog(state, status) {
      state.moveDialog = status;
    },
  },

  actions: {
    openFileDialog(context, data) {
      context.commit('setDocument', data.document);
      context.commit('openFileDialog', data.open);
    }
  },
  namespaced: true,
})
