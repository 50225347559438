<template>
  <v-dialog
    v-model="dialogUploads"
    persistent
    max-width="700"
  >
    <v-card>
      <v-container>
        <v-col class="px-1 py-1">
          <v-card elevation="0">
            <v-card-title
              class="white--text py-2"
              style="background: #3ba272"
            >
              <span class="headline">
                Uploads today
              </span>
            </v-card-title>
            <v-card-title>
              <v-text-field
                v-model="searchUploads"
                append-icon="mdi-magnify"
                color="#3ba272"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headersUpload"
              :items="uploads"
              :custom-filter="filter"
              :search="searchUploads"
              dense
            >
              <template #item.Parameters.name="{item}">
                <div v-if="item.Parameters.name.length >= 27">
                  {{ item.Parameters.name.slice(0,24) + '...' }}
                </div>
                <div v-else>
                  {{ item.Parameters.name }}
                </div>
              </template>
              <template #item.Parameters.size="{item}">
                <div
                  v-if="convertSize(item.Parameters.size) == 'Deleted'"
                  class="red--text"
                >
                  {{ convertSize(item.Parameters.size) }}
                </div>
                <div v-else>
                  {{ convertSize(item.Parameters.size) }}
                </div>
              </template>
              <template #item.Created="{item}">
                {{ getDateAndTime(item.Created) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-card-actions class="px-1 py-1">
          <v-spacer></v-spacer>
          <v-btn
            color="#3ba272"
            dark
            @click="$emit('update:dialogUploads', false)"
            class="ml-1"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../../../mixins/date";

export default {
  name: "DialogUploads",
  props: {
    uploads: Array,
    convertSize: Function,
    dialogUploads: Boolean,
  },

  data: () => ({
    searchUploads: "",
    headersUpload: [
      {
        text: "File name",
        align: "start",
        filterable: false,
        value: "Parameters.name",
      },
      { text: "Size", value: "Parameters.size" },
      { text: "User", value: "UserName" },
      { text: "Time", value: "Created" },
    ],
  }),

  mixins: [dateUtils],

  computed: {
    ...mapGetters("user", ["getUser"]),
  },

  methods: {
    filter(value, search, item) {
      let filter = RegExp(search, "i").test(
        item.Parameters.name + item.UserName
      );

      return filter;
    },
  },
};
</script>