<template>
  <v-container
    fluid
    class="fill-height px-0"
  >
    <v-row
      class="fill-height px-0"
      justify="center"
    >
      <v-col align="center">
        <v-card
          max-width="690"
          elevation="0"
        >
          <v-col>
            <div class="text-left mt-n3 mb-3">
              <v-icon class="mb-2 mr-2 ml-3">
                mdi-pencil-outline
              </v-icon>
              <span class="headline">Account Details</span>
            </div>
            <v-form
              ref="form"
              v-model="valid"
            >
              <div class="my-3">
                <div class="d-none d-sm-block ">
                  <v-col
                    cols="12"
                    class="d-flex justify-center my-n2"
                  >
                    <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      :color="getAppearances.btn_color"
                      required
                      type="text"
                      label="First name"
                      prepend-icon="mdi-account"
                      outlined
                      dense
                      class="mr-1"
                    ></v-text-field>
                    <v-text-field
                      v-model="lastname"
                      :rules="lastnameRules"
                      :color="getAppearances.btn_color"
                      required
                      type="text"
                      label="Last name"
                      prepend-icon="mdi-account"
                      outlined
                      dense
                      class="ml-2"
                    ></v-text-field>
                  </v-col>
                </div>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2 d-sm-none"
                >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="First name"
                    prepend-icon="mdi-account"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2 d-sm-none"
                >
                  <v-text-field
                    v-model="lastname"
                    :rules="lastnameRules"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="Last name"
                    prepend-icon="mdi-account"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-text-field
                    v-model="username"
                    :rules="[checkDuplicateUsername, usernameRules.required, usernameRules.length]"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="Username"
                    prepend-icon="mdi-account-plus"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-text-field
                    v-model="email"
                    :rules="[checkDuplicateEmail, emailRules.required, emailRules.valid]"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="Email"
                    outlined
                    prepend-icon="mdi-at"
                    dense
                  ></v-text-field>
                </v-col>
                <div class="text-left mb-6">
                  <v-icon class="mb-2 mr-2 ml-3">
                    mdi-pencil-outline
                  </v-icon>
                  <span class="headline">Account Settings</span>
                </div>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-select
                    v-model="quota"
                    :rules="quotaRules"
                    :value='customQuota()'
                    :color="getAppearances.btn_color"
                    required
                    :items="quotaSelect"
                    :menu-props="{ maxHeight: '400' }"
                    label="Quota"
                    outlined
                    prepend-icon="mdi-cloud-upload"
                    dense
                  ></v-select>
                </v-col>
                <v-col
                  v-if="showCustomQuota == true"
                  cols="12"
                  class="d-flex justify-center mt-n3"
                >
                  <v-text-field
                    v-model="quotaCustom"
                    :rules="[customQuotaRules.required, customQuotaRules.valid]"
                    :color="getAppearances.btn_color"
                    required
                    label="Amount"
                    prepend-icon="mdi-cloud-upload"
                    outlined
                    dense
                    value="22"
                    suffix="GB"
                  ></v-text-field>
                </v-col>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mr-1"
                  @click="backToUsersAndGroups ()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :color="getAppearances.btn_color"
                  dark
                  @click="createUser ()"
                  class="mr-1"
                >
                  Create User
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { navigation } from "../../../../mixins/navigation";

export default {
  data: () => ({
    quotaCustom: "",
    showCustomQuota: false,
    name: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
    role: "",
    timezone: "",
    clockDisplay: "",
    quota: "",
    showPw: false,
    showPw2: false,
    valid: true,
    quotaSelect: [
      "2 GB",
      "5 GB",
      "10 GB",
      "20 GB",
      "100 GB",
      "Unlimited",
      "Custom",
    ],
    roles: ["system_user", "system_admin"],

    confirmPasswordRules: [(v) => !!v || "Password is required"],

    nameRules: [
      (v) => !!v || "First name is required",
      (v) =>
        (v && v.length <= 40) || "First name must be less than 40 characters",
    ],

    lastnameRules: [
      (v) => !!v || "Last name is required",
      (v) =>
        (v && v.length <= 40) || "First name must be less than 40 characters",
    ],

    roleRules: [(v) => !!v || "Role is required"],

    quotaRules: [(v) => !!v || "Quota is required"],

    passwordRules: {
      required: (value) => !!value || "Password is required.",
    },

    usernameRules: {
      required: (v) => !!v || "Username is required",
      length: (v) =>
        (v && v.length <= 40) || "Username must be less than 40 characters",
    },

    emailRules: {
      required: (v) => !!v || "E-mail is required",
      valid: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    },

    customQuotaRules: {
      required: (v) => !!v || "Quota is required",
      valid: (v) => /^[1-9]\d*$|^$/.test(v) || "Only numbers are allowed",
    },
  }),

  mixins: [navigation],

  computed: {
    ...mapGetters("admin", ["getAppearances", "getAuth", "getTimezone"]),

    ...mapGetters("user", ["getUsers"]),

    passwordConfirmationRule() {
      return () =>
        this.password === this.passwordConfirm || "Password must match";
    },
  },

  mounted() {
    this.$store.dispatch("user/getUsers");
  },

  methods: {
    customQuota() {
      if (this.quota == "Custom") {
        this.showCustomQuota = true;
      } else if (this.quota != "Custom") {
        this.showCustomQuota = false;
      }
    },

    backToUsersAndGroups() {
      this.navigateToRoute({ name: "users-and-groups" });
    },

    checkDuplicateUsername(v) {
      if (v.length > 0) {
        for (let i = 0; i < this.getUsers.length; i++) {
          if (this.getUsers[i].username == v) {
            return `Username "${v}" already exist`;
          }
        }
      } else {
        return true;
      }
      return true;
    },

    checkDuplicateEmail(v) {
      if (v.length > 0) {
        for (let i = 0; i < this.getUsers.length; i++) {
          if (this.getUsers[i].email == v) {
            return `Email "${v}" already exist`;
          }
        }
      } else {
        return true;
      }
      return true;
    },

    createUser() {
      if (this.$refs.form.validate()) {
        if (this.quota == "Custom") {
          this.quota = this.quotaCustom + " " + "GB";
        }

        var data = {
          name: this.name,
          username: this.username,
          lastname: this.lastname,
          email: this.email,
          timezone: this.timezone,
          clockDisplay: this.clockDisplay,
          quota: this.quota,
        };
        this.$store.dispatch("admin/createUser", data).then(() => {
          this.$store.dispatch("user/getUsers");
        });
        this.navigateToRoute({ name: "users-and-groups" });
      }
    },
  },
};
</script>