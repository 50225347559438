<template>
  <v-container fluid>
    <v-row
      class="px-4 pt-5 fill-height"
      align="start"
      justify="center"
    >
      <v-row
        v-if="tasks.length <= 0"
        class="fill-height mt-16 pt-16"
        align="center"
        justify="center"
      >
        <div class="text-center mb-5 child-elements">
          <v-list-item-avatar
            size="200"
            class="child-elements"
          >
            <v-icon
              size="100"
              dark
              color="white"
              class="grey lighten-2"
            >
              mdi-calendar-check
            </v-icon>
          </v-list-item-avatar>
          <h1 class="grey--text text--darken-1 font-weight-medium noselect">Recent tasks will appear here</h1>
        </div>
      </v-row>

      <v-card-text
        v-else
        class="px-0 ma-0"
      >
        <v-list>
          <v-list-item class="px-0 ma-0">
            <v-list-item-icon class="ml-4 mr-3 px-0">
            </v-list-item-icon>
            <v-list-item-content class="mx-1">
              <v-list-item-subtitle>
                {{headers.task}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="d-none d-sm-block mx-1">
              <v-list-item-subtitle>
                {{headers.project}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="d-none d-sm-block mx-1">
              <v-list-item-subtitle>
                {{headers.status}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="mx-1">
              <v-list-item-subtitle>
                {{headers.due}}
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>

          <div
            v-for="(item, index) in tasks"
            :key="item.id"
          >
            <div v-if="item.trash == false">
              <v-list-item
                color="primary"
                @click="openProject(item)"
                class="px-0 ma-0"
              >
                <v-list-item-icon class="ml-4 mr-3 px-0">
                  <v-icon v-if="item.type == 'subtask'">mdi-file-tree</v-icon>
                  <v-icon v-else>mdi-calendar-check
                  </v-icon>

                </v-list-item-icon>
                <v-list-item-content class="mx-1">
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content class="d-none d-sm-block mx-1">
                  <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="d-none d-sm-block mx-1">
                  <v-list-item-title>{{item.status}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="mx-1">
                  <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateString(item.end_date) }}</v-list-item-title>
                </v-list-item-content>

              </v-list-item>
              <v-divider :key="index + item.title"></v-divider>
            </div>
          </div>
        </v-list>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";

export default {
  data() {
    return {
      tasks: [],
      headers: {
        task: "Task name",
        project: "Project",
        due: "Due date",
        status: "Status",
      },
    };
  },

  mixins: [dateUtils],

  created() {
    this.$store.dispatch("projects/getRecentTasks").then((response) => {
      this.tasks = response.data;
    });
  },

  computed: {
    ...mapGetters("user", ["getUserById"]),
  },

  methods: {
    openProject(item) {
      let id = item.id;
      if (item.type == "subtask") {
        id = item.parent_id;
      }

      this.$router.push({
        name: "project",
        params: { id: item.project_id, fullTask: id },
      });
    },

    getFullUserName(id) {
      let user = this.getUserById(id);

      return user.name + " " + user.lastname;
    },
  },
};
</script>
