<template>
  <v-container
    fluid
    class="py-0 px-0"
  >
    <v-row
      class="fill-height"
      no-gutters
    >
      <template>
        <v-scroll-x-transition
          mode="out-in"
          hide-on-leave
        >
          <router-view></router-view>
        </v-scroll-x-transition>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    // ProjectToolbar,
  },
};
</script>