<template>
  <v-col cols="12" class="ma-0 pa-0">
    <div class="title">
      <h5 class="text-left font-weight-medium black--text">
        Largest files ({{dateText}})
      </h5>
    </div>
    <div
      v-show="fileList.length > 0"
      id="largestFiles"
      style="width: 100%;height:400px;"
      class="mb-7"
    ></div>
    <div
      v-if="fileList.length <= 0"
      class="barChart"
    >
      There are no files to show
    </div>
  </v-col>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, LegendComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { file } from "../../../mixins/file";

echarts.use([GridComponent, LegendComponent, BarChart, CanvasRenderer]);

export default {
  props: {
    largestFiles: Array,
    dateText: String,
  },

  data() {
    return {
      items: [],
      myChart: null,
      option: {
        grid: {
          left: "2%",
          right: "10%",
          bottom: "2%",
          containLabel: true,
        },
        tooltip: {},
        xAxis: {
          type: "value",
          name: "MB",
          nameLocation: "end",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        series: [
          {
            realtimeSort: true,
            type: "bar",
            data: [],
            label: {
              show: true,
              position: 'insideLeft',
              formatter: '{b}',
              align: 'left',
            },
            itemStyle: {
              color: '#64B5F6'
            }
          },
        ],
        legend: {
          show: true,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      },
    };
  },

  computed: {
    fileList: {
      get() {
        return this.largestFiles;
      },
      set(value) {
        this.$emit("update:largestFiles", value);
      },
    },
  },

  mixins: [file],

  mounted() {
    setTimeout(() => {
      var chartDom = document.getElementById("largestFiles");
      this.myChart = echarts.init(chartDom);

      if (this.fileList.length <= 0) {
        const unwatch = this.$watch("fileList", () => {
          if (this.fileList.length <= 0) return;

          this.myChart.setOption(this.setOptions());
          chart.resize();

          unwatch();
        });
      } else {
        this.myChart.setOption(this.setOptions());
      }

      let chart = this.myChart;

      window.addEventListener(
        "resize",
        function () {
          chart.resize();
        },
        true
      );
    }, "200");
  },

  methods: {
    setOptions() {
      let self = this;

      this.fileList.sort(function (a, b) {
        return a.Value - b.Value;
      });

      var names = [];
      var sizes = [];

      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name.length >= 33) {
          this.fileList[i].name = this.fileList[i].name.slice(0, 30) + "...";
        }

        names.push(this.fileList[i].name);
        sizes.push(this.fileList[i].size / (1024 * 1024));
      }

      this.option.yAxis.data = names;
      this.option.series[0].data = sizes;

      this.option.tooltip = {
        trigger: "axis",
        formatter: function (params) {
          let str = "<span>" + params[0].name + "</span><br/>";
          var size = 0;

          for (let i = 0; i < params.length; i++) {
            size = params[i].value * (1024 * 1024);
          }

          return str + self.convertSize(size);
        },
        axisPointer: {
          type: "shadow",
        },
      };

      return this.option;
    },

    async loadData(item) {
      this.fileList = item.largestFiles;
    },

    setChart(item) {
      this.loadData(item).then(() => {
        this.myChart.setOption(this.setOptions());
      });
    },
  },
};
</script>

<style scoped>
.barChart {
  height: 330px;
  width: 100%;
}
</style>