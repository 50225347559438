<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-col class="ma-0 pt-0">
      <v-row class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="createUser()"
          :color="getAppearances.btn_color"
          dark
        >
          Create user
        </v-btn>
      </v-row>
      <Users />
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Users from "../../../components/Settings/Admin/UsersAndGroups/UserView";
import { navigation } from "../../../mixins/navigation";

export default {
  components: {
    Users,
  },

  data: () => ({}),

  mixins: [navigation],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    createUser() {
      this.navigateToRoute({ name: "create-new-user" });
    },
  },
};
</script>