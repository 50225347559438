<template>
  <div>
    <v-col cols="12" class="pa-1">
      <v-card
        v-click-outside="onClickOutside"
        style="border-radius:9px;"
        class="pt-1"
        width="294"
      >
        <v-card-title class="pb-0 mt-n2">
          <v-text-field
            v-model="taskTitle"
            label="Name"
            @keydown.enter="newTask"
            class="mb-n2"
          ></v-text-field>
        </v-card-title>
        <v-card-actions class="pt-0">
          <v-menu
            :close-on-click="false"
            v-model="assigneeMenu"
            :close-on-content-click="false"
            offset-y
            :key="refreshAssignee"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="assignSet == false"
                @click="assigneeMenu = true, validateAssignee = true"
                icon
                v-bind="attrs"
                v-on="on"
                small
                class="my-1 ml-1 mr-1"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
              <v-avatar
                @click="assigneeMenu = true, validateAssignee = true"
                v-else
                :color="getAppearances.btn_color"
                v-bind="attrs"
                v-on="on"
                size="24"
                class="ml-1 mr-2"
              >
                <v-img :src="`/api/users/photo/${assignedUser(getAssigned)}`">
                </v-img>
              </v-avatar>
            </template>
            <MemberSelect
              @close="assigneeMenu = false, dateClickOutside()"
              @save="saveTask(), dateClickOutside()"
            />
          </v-menu>
          <v-menu
            :close-on-click="false"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            offset-y
            :key="refreshDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-if="dateSet == false">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="validateDate = true"
                >
                  <v-icon>mdi-calendar-plus</v-icon>
                </v-btn>
              </div>
              <div
                v-else
                align="left"
                class="ml-2 text-capitalize font-weight-regular endDateFont"
                v-bind="attrs"
                v-on="on"
                @click="validateDate = true"
              >
                <div class="py-2">
                  {{ endDate(date) }}
                </div>
              </div>
            </template>
            <v-date-picker
              v-model="date"
              no-title
            >
              <v-spacer />
              <v-btn
                text
                @click="datePickerMenu = false, assigneeClickOutside()"
                class="mr-1 mb-3"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                :color="getAppearances.btn_color"
                @click="saveDate(), assigneeClickOutside()"
                class="mb-3 mr-3"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment";
import MemberSelect from "../Menu/MemberSelect";
import { projectUtils } from "../../../mixins/project";
import { mapGetters } from "vuex";

export default {
  components: {
    MemberSelect,
  },
  name: "NewTask",
  props: {
    closeCreate: Function,
    addTaskIndex: Number,
    taskPosition: String,
  },

  data: () => ({
    validateAssignee: false,
    validateDate: false,
    taskTitle: "",
    datePickerMenu: false,
    dateSet: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    refreshDate: 0,
    dueDate: "",
    assigneeMenu: false,
    refreshAssignee: 9999,
    assignSet: false,
  }),

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", [
      "getAssigned",
      "getProject",
      "getColumns",
      "getProjectMembers",
    ]),

    ...mapGetters("user", ["getTimezone"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    saveDate() {
      this.datePickerMenu = false;
      this.dateSet = true;
      this.refreshDate += 1;
    },

    saveTask() {
      this.assigneeMenu = false;
      this.assignSet = true;
      this.refreshAssignee += 1;
    },

    dateClickOutside() {
      setTimeout(() => {
        this.validateAssignee = false;
      }, 1000);
    },

    assigneeClickOutside() {
      setTimeout(() => {
        this.validateDate = false;
      }, 1000);
    },

    onClickOutside() {
      if (
        this.taskTitle != "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        this.newTask();
      } else if (
        this.taskTitle == "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        this.closeCreate();
      }
    },

    endDate(date) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);
      var formatedDate = timezoneDate.utc().format("X");
      this.dueDate = formatedDate;

      return this.dateFormat(formatedDate);
    },

    newTask() {
      if (this.taskTitle != "") {
        var task = {
          title: this.taskTitle,
          position: this.taskPosition,
          date: this.dueDate,
          index: this.addTaskIndex,
          type: "task",
        };
        this.createTask(task);
        this.taskTitle = "";
        this.dueDate = "";
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      this.closeCreate();
    },
  },
};
</script>

<style scoped>
.endDateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>