<template>
  <v-item-group>
    <v-row class="pa-3">
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>OPEN</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("open")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>CLOSED</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("closed")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>IN PROGRESS</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("in progress")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>ON HOLD</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("on hold")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>OVERDUE</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("overdue")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>COMPLETED</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{getCount("completed")}}</h2>
            <div class="subtitle">
              Current task count
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
import { file } from "../../../mixins/file";

export default {
  props: {
    taskCount: Object,
  },

  data() {
    return {};
  },

  mixins: [file],

  computed: {},

  methods: {
    getCount(status) {
      if (this.taskCount[status] == undefined) {
        return 0;
      }

      return this.taskCount[status];
    },
  },
};
</script>

<style scoped>
.h1Size {
  font-size: 270%;
  font-weight: 200;
}

.disable-events {
  pointer-events: none;
}
</style>