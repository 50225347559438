import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    license: {}
  },

  getters: {
    getLicense(state) {
      return state.license;
    }
  },

  mutations: {
    setLicense(state, license) {
      state.license = license;
    }
  },

  actions: {
    async getLicense(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/license',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setLicense', response.data);
            resolve(response)
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    addLicense(context, license) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/license/add',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: {
            license: license
          },
        };

        axios(options)
          .then(response => {
            resolve(response)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    removeLicense(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/license/remove',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: {},
        };

        axios(options)
          .then(response => {
            resolve(response)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
