<template>
  <v-container
    fluid
    class="pa-0 ma-0 fill-height"
  >
    <v-row
      class="fill-height"
      no-gutters
    >
      <v-col fill-height>
        <v-row
          v-if="getFiles.length == 0 && loaded"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-center mb-5 child-elements">
            <v-list-item-avatar
              size="200"
              class="child-elements"
            >
              <v-icon
                size="100"
                dark
                color="white"
                class="grey lighten-2"
              >
                mdi-share-variant
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-medium noselect">Shared files</h1>
            <div
              noselect
              class="subtitle-1 grey--text mb-5 noselect"
            >Shared files with you will appear here</div>
          </div>
        </v-row>
        <v-list
          class="mt-0 pt-0"
          two-line
        >
          <v-list-item-group
            v-model="selectedItem"
            :color="getAppearances.btn_color"
          >
            <template v-for="(item, index) in filtered_items">
              <v-list-item
                @click="setActive($event, item, index)"
                @dblclick="onDblClick(item)"
                @contextmenu="show($event, item, index)"
                :key="index"
              >
                <template v-slot:default="{ }">
                  <v-list-item-avatar tile>
                    <v-icon
                      v-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType !== 'image'"
                      size="48"
                      dark
                      :color="fileTypes[item.type].color"
                    >
                      {{ fileTypes[item.type].icon }}
                    </v-icon>
                    <v-img
                      v-else-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType == 'image'"
                      :src="`/api/files/thumbnails/${item.id}`"
                    >
                    </v-img>
                    <v-icon
                      v-else
                      size="48"
                      dark
                      :color="fileTypes['unknown'].color"
                    >
                      {{ fileTypes['unknown'].icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>

                    <v-list-item-subtitle class="text--primary">
                      {{ getDateAndTime(item.created) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <v-btn icon>
                        <v-icon
                          :color="item.shared == true ? getAppearances.btn_color : 'grey lighten-1'"
                          @click.stop="shareDialog(item)"
                        >
                          mdi-share-variant
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click.stop="download(item)"
                        class="mr-2"
                      >
                        <v-icon color="grey lighten-1">mdi-download</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider :key="index + item.name"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-pagination
          v-model="pages"
          v-if="filtered.length/getPerPage > 1"
          :length="Math.ceil(filtered.length/getPerPage)"
        ></v-pagination>
      </v-col>
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        min-width="280"
      >
        <v-list>
          <v-list-item-group v-model="menu_item">
            <template v-for="(item) in menuItems">
              <v-list-item
                v-if="item.show"
                :disabled="item.disabled"
                :key="item.id"
                @click="actions(item)"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="item.color"
                    v-text="item.icon"
                  >
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <DeleteDialog
        :delete-conf="deleteConf"
        @confirmDelete="confirmDelete"
        @cancelDelete="deleteConf = false"
      />
      <Sharedialog />
    </v-row>
  </v-container>
</template>

<script>
import Sharedialog from "../../components/Dialogs/shareDialog";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import { actions } from "../../mixins/browserActions";
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";
export default {
  components: {
    Sharedialog,
    DeleteDialog,
  },
  data: () => ({
    selectedItem: false,
  }),

  mixins: [actions, dateUtils],

  created() {
    this.loadShareItems();
  },

  computed: {
    ...mapGetters("files", [
      "path",
      "fileTypes",
      "getFiles",
      "getTreeview",
      "selectedFile",
      "isSelectedFile",
      "loaded",
      "getPage",
      "getPerPage",
    ]),

    ...mapGetters("user", ["getUser"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("toolbar", ["search"]),

    ...mapGetters("main", ["details"]),

    pages: {
      get() {
        return this.getPage;
      },
      set(val) {
        this.$store.commit("files/setPage", val);
      },
    },
  },

  methods: {
    loadShareItems() {
      this.$store.dispatch("files/getSharedFiles").then(() => {
        let files = this.getFiles;
        for (let i = 0; i < files.length; i++) {
          if (this.$router.currentRoute.params.id == files[i].id) {
            let selected =
              i - (Math.ceil(i / this.getPerPage) - 1) * this.getPerPage;
            let page = Math.ceil(i / this.getPerPage);

            if (i == this.getPerPage) {
              page += 1;
              selected = 0;
            } else if (selected == this.getPerPage) {
              selected = 0;
            }

            if (page == 0) {
              page = 1;
            }

            this.$store.commit("files/setPage", page);
            this.selectedItem = selected;

            break;
          }
        }
      });
    },
  },
};
</script>