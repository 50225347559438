<template>
  <v-container
    fluid
    class="pa-0 ma-0 fill-height"
  >
    <v-row
      class="fill-height"
      align="center"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center mb-5 mx-2 child-elements"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <v-list-item-avatar
          size="200"
          class="child-elements"
        >
          <v-icon
            size="100"
            dark
            color="white"
            class="grey lighten-2"
          >
            mdi-calendar
          </v-icon>
        </v-list-item-avatar>
          <h1 class="grey--text text--darken-1 font-weight-medium noselect">Get everyone on the same page</h1>
          <div noselect class="subtitle-1 grey--text mb-5 noselect">Easily schedule meetings and events from virtually anywhere, even if you don't have a computer with you. All you need is a device with internet connection. Calendars may be shared with write access or read-only. This way, all members of a team know when each member is busy.</div>
          <div noselect class="overline noselect">Coming soon</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script> 
  export default {
    data: function() {
      return {

      }
    },
  }
</script>