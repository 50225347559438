<template>
  <v-dialog
    v-model="dialogShare"
    persistent
    max-width="760"
  >
    <v-card>
      <v-container>
        <v-col class="px-1 py-1">
          <v-card elevation="0">
            <v-card-title
              class="white--text py-2"
              style="background: #fac858"
            >
              <span class="headline">
                Shared today
              </span>
            </v-card-title>
            <v-card-title>
              <v-text-field
                v-model="searchShare"
                append-icon="mdi-magnify"
                color="#fac858"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headersShare"
              :items="shared"
              :custom-filter="filter"
              :search="searchShare"
              dense
            >
              <template #item.Parameters.name="{item}">
                <div v-if="item.Parameters.name.length >= 22">
                  {{ item.Parameters.name.slice(0,19) + '...' }}
                </div>
                <div v-else>
                  {{ item.Parameters.name }}
                </div>
              </template>
              <template #item.Parameters.size="{item}">
                <div
                  v-if="convertSize(item.Parameters.size) == 'Deleted'"
                  class="red--text"
                >
                  {{ convertSize(item.Parameters.size) }}
                </div>
                <div v-else>
                  {{ convertSize(item.Parameters.size) }}
                </div>
              </template>
              <template #item.share="{item}">
                <div v-if="item.Type == 'link_share'">
                  <v-icon left>
                    mdi-web
                  </v-icon>
                </div>
                <div v-else>
                  {{ sharedWith(item) }}
                </div>
              </template>
              <template #item.Created="{item}">
                {{ getDateAndTime(item.Created) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-card-actions class="px-1 py-1">
          <v-spacer></v-spacer>
          <v-btn
            color="#fac858"
            dark
            @click="$emit('update:dialogShare', false)"
            class="ml-1"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../../../mixins/date";

export default {
  name: "DialogShare",
  props: {
    shared: Array,
    convertSize: Function,
    dialogShare: Boolean,
  },

  data: () => ({
    searchShare: "",
    headersShare: [
      {
        text: "File name",
        align: "start",
        filterable: false,
        value: "Parameters.name",
      },
      { text: "Size", value: "Parameters.size" },
      { text: "User", value: "UserName" },
      { text: "Shared with", value: "share", sortable: false },
      { text: "Time", value: "Created" },
    ],
  }),

  mixins: [dateUtils],

  computed: {
    ...mapGetters("user", ["getUser"]),
  },

  methods: {
    sharedWith(item) {
      var users = "";
      var text = "";

      if (item.Parameters.users != undefined) {
        users = item.Parameters.users;
      }

      if (users.length == 1) {
        text = users.length + " user";
      } else {
        text = users.length + " users";
      }

      return text;
    },

    filter(value, search, item) {
      let filter = RegExp(search, "i").test(
        item.Parameters.name + item.UserName
      );

      return filter;
    },
  },
};
</script>