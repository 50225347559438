<template>
  <div>
    <v-menu
      :close-on-click="closeOnClick"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="getEditing == 'board'"
          class="mr-2 my-1"
          text
          icon
          v-bind="attrs"
          v-on="on"
          @click="closeCreate()"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="mr-2"
          text
          icon
          v-bind="attrs"
          x-small
          v-on="on"
          @click="closeCreate()"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list v-if="dragStart == false">
        <div v-for="(item, index) in sectionMenu"
          :key="index"
        >
          <v-list-item
            @click="sectionActions(item, colIndex)"
            dense
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider 
            class="my-3 mx-4" 
            v-if="item.divider == true"
          ></v-divider>
        </div>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="clearSection"
      persistent
      max-width="400"
    >
      <v-card>
        <v-container>
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <h4 v-if="taskCount > 0">This section has {{ taskCount }} tasks to move before delete.</h4>
              <h4 v-else>One Section must be saved</h4>
            </v-col>
          </div>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="clearSection = false"
          >
            Close
          </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: {
      closeCreate: Function,
      dragStart: Boolean,
      colIndex: Number,
    },
    data () {
      return {
        closeOnClick: true,
        clearSection: false,
        taskCount: 0,

        sectionMenu: [
          { title: 'Rename section', icon: 'mdi-pencil' },
          { title: 'Delete section', icon: 'mdi-delete' }
        ]
      }
    },

    computed: {
      ...mapGetters('projects', [
        'getColumns',
        'getSectionName',
        'getSectionIndex',
        'getEditing'
      ]),

      ...mapGetters('admin', [
        'getAppearances',
      ]),
    },

    methods: {
      ...mapMutations('projects', [
        'setSectionName',
        'setSectionIndex',
        'setDisableDrag',
        'setEditSectionName'
      ]),

      sectionActions(item, index) {
        var tasks = 0;
        var sections = 0;
        for (let i = 0; i < this.getColumns.length; i++) {
          sections += 1
          if (this.getColumns[i].index == index) {
            if (item.title == "Rename section") {
              this.setSectionName(this.getColumns[i].name);
            } else {
              this.setSectionName('');
            }
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].trash == false) {
                tasks += 1
              }
            }
            this.taskCount = tasks
          }
        }
        switch (item.title) {
          case "Rename section":
              this.setSectionIndex(index);
              this.setDisableDrag(true);
              this.setEditSectionName(true);
            break;
          case "Add section":
              // īzņemšu pagaidam, jāskatās cits risinājums
            break;
          case "Delete section":
            if (this.taskCount == 0 && sections > 1 ) {
              this.deleteSection(index);
            } else {
              this.clearSection = true;
            }
            break;
          default:
            break;
        }
      },

      deleteSection(i) {
        let index = this.getColumns.map(item => item.index).indexOf(i);
        this.getColumns.splice(index, 1);
        this.$emit('patch');
      },
    }
  }
</script>