<template>
  <v-container
    v-if="loaded == true"
    class="py-0 px-0"
    fluid
  >
    <v-row
      v-if="webSocket.readyState == 1"
      class="fill-height"
      no-gutters
      fluid
    >
      <template>
        <v-col>
          <ProjectToolbar />
          <v-tabs
            show-arrows
            v-model="model"
            :slider-color="getAppearances.btn_color"
            color="grey darken-1"
            height="30"
            class="d-none d-sm-block"
          >
            <v-tab
              class="mt-n1 text-capitalize"
              href="#tab-1"
              @click="closeSideBars()"
            >
              OVERVIEW
            </v-tab>
            <v-tab
              class="mt-n1 text-capitalize"
              @click="setEditing('list')"
              href="#tab-2"
            >
              LIST
            </v-tab>
            <v-tab
              class="mt-n1 text-capitalize"
              @click="setEditing('board')"
              href="#tab-3"
            >
              BOARD
            </v-tab>
            <v-row class="mt-1 mr-1">
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    text
                    color="grey darken-2"
                    class="mr-3 d-none d-sm-block"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>
                      mdi-check-circle-outline
                    </v-icon>
                    {{ getSelectedStatusFilter }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in taskStatusFilter"
                    :key="index"
                    link
                    @click="changeStatusFilter(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <TaskFilter />
              <v-btn
                :disabled="getLicense.isLicensed && !getLicense.project_fields"
                x-small
                text
                color="grey darken-2"
                class="mr-3 d-none d-sm-block"
                @click="openCustomFields"
              >
                <v-icon left>
                  mdi-view-grid-plus-outline
                </v-icon>
                Customize
              </v-btn>
            </v-row>
          </v-tabs>
          <div class="d-sm-none">
            <v-row class="my-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey darken-3"
                    dark
                    small
                    text
                    v-bind="attrs"
                    class="ml-4"
                    v-on="on"
                  >
                    {{ selectedTab }}
                    <v-icon
                      right
                      dark
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="tabSwitch(item.action)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer />
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    text
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-check-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in taskStatusFilter"
                    :key="index"
                    link
                    @click="changeStatusFilter(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <TaskFilter />
              <v-btn
                x-small
                text
                color="grey darken-2"
                class="mr-5"
                @click="openCustomFields"
              >
                <v-icon>
                  mdi-view-grid-plus-outline
                </v-icon>
              </v-btn>
            </v-row>
          </div>
          <v-divider />
          <v-row class="pt-3 px-3">
            <v-col
              :cols="getTaskNavigation == true && getMobileView == false ||
              getCustomFields == true && getMobileView == false ? '8' : '12'"
              class="pa-0"
            >
              <v-tabs-items
                v-model="model"
                touchless
              >
                <v-tab-item
                  value="tab-1"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    class="gradient pa-3"
                    flat
                    rounded="0"
                  >
                    <ProjectOverview />
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  value="tab-2"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    class="gradient pa-3 mb-3"
                    flat
                    rounded="0"
                  >
                    <ListView @websocket="sendMessage()" />
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  value="tab-3"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    class="gradient pa-3 mb-3"
                    flat
                  >
                    <BoardView @websocket="sendMessage()" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col
              v-if="getTaskNavigation == true && getMobileView == false"
              cols="4"
              class="pa-0"
            >
              <FullTaskInfo />
            </v-col>
            <v-col
              v-if="getCustomFields == true && getMobileView == false"
              cols="4"
              class="pa-0"
            >
              <CustomFields />
            </v-col>
            <v-dialog
              v-if="getMobileView == true"
              v-model="getTaskNavigation"
              persistent
            >
              <v-card>
                <FullTaskInfo />
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="getMobileView == true"
              v-model="getCustomFields"
              persistent
            >
              <v-card>
                <CustomFields />
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <div
      v-else
      class="ma-3"
    >
      <h3>Failed to connect to project server</h3>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ProjectToolbar from "../../components/Projects/ProjectToolbar.vue";
import BoardView from "../../components/Projects/BoardView";
import ListView from "../../components/Projects/ListView";
import { projectUtils } from "../../mixins/project";
import FullTaskInfo from "../../components/Projects/FullTaskInfo";
import CustomFields from "../../components/Projects/CustomFields.vue";
import TaskFilter from "../../components/Projects/Menu/TaskFilter.vue";
import ProjectOverview from "../../components/Projects/ProjectOverview.vue";

export default {
  components: {
    ProjectToolbar,
    BoardView,
    ListView,
    FullTaskInfo,
    CustomFields,
    TaskFilter,
    ProjectOverview,
  },

  data: () => ({
    reloadTasks: 0,
    loaded: false,
    model: "tab-2",
    subtasks: [],
    selectedTab: "List view",
    disableItemDrop: false,
    items: [
      { title: "Overview", action: "tab-1" },
      { title: "List view", action: "tab-2" },
      { title: "Board view", action: "tab-3" },
    ],
    taskStatusFilter: [
      { title: "Incomplete tasks" },
      { title: "Completed tasks" },
      { title: "All tasks" },
    ],
  }),

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  watch: {
    "$store.state.projects.isAssigned": function () {
      if (this.getIsAssigned == true) {
        this.sendMessage();
        this.setIsAssigned(false);
      }
    },
    "$store.state.projects.date": function () {
      if (this.getDate == true) {
        this.sendMessage();
        this.setDate(false);
      }
    },
    "$store.state.projects.taskDeleted": function () {
      if (this.getTaskDeleted == true) {
        this.onEnd();
        this.sendMessage();
        this.setTaskDeleted(false);
      }
    },
    "$store.state.projects.taskEdited": function () {
      if (this.getTaskEdited == true) {
        this.sendMessage();
        this.setTaskEdited(false);
      }
    },
    "$store.state.projects.taskCreated": function () {
      if (this.getTaskCreated == true) {
        this.onEnd();
        this.sendMessage();
        this.setTaskCreated(false);
      }
    },
    "$store.state.projects.fieldUpdate": function () {
      if (this.getFieldUpdate == true) {
        this.onEnd();
        this.sendMessage();
        this.setFieldUpdate(false);
      }
    },
    "$store.state.projects.fileActions": function () {
      if (this.getFileActions == true) {
        this.onEnd();
        this.sendMessage();
        this.setFileActions(false);
      }
    },
    "$store.state.projects.memberDataUpdate": function () {
      if (this.getMemberDataUpdate == true) {
        this.shareMessage();
        this.$store.commit("projects/setMemberDataUpdate", false);
      }
    },
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUser"]),
    ...mapGetters("license", ["getLicense"]),
    ...mapGetters("projects", [
      "getTask",
      "getTasks",
      "getColumns",
      "getHeaders",
      "getProject",
      "getProjectMembers",
      "getRefreshDate",
      "getDate",
      "getTaskDeleted",
      "getTaskEdited",
      "getTaskCreated",
      "getIsAssigned",
      "getSocketPath",
      "getSocket",
      "getTaskNavigation",
      "getCustomFields",
      "getSelectedMeta",
      "getMetaList",
      "getFieldUpdate",
      "getRefreshCustomDate",
      "getRestoreKey",
      "getSubTaskReloadKey",
      "getFileActions",
      "getMobileView",
      "getMemberData",
      "getMemberDataUpdate",
      "getActiveUsers",
      "getSelectedStatusFilter",
    ]),

    dateKey: {
      get() {
        return this.getRefreshDate;
      },
      set(value) {
        this.$store.commit("projects/setRefreshDate", value);
      },
    },

    subTaskReloadKey: {
      get() {
        return this.getSubTaskReloadKey;
      },
      set(value) {
        this.$store.commit("projects/setSubTaskReloadKey", value);
      },
    },

    taskRestoreKey: {
      get() {
        return this.getRestoreKey;
      },
      set(value) {
        this.$store.commit("projects/setRestoreKey", value);
      },
    },

    customDateKey: {
      get() {
        return this.getRefreshCustomDate;
      },
      set(value) {
        this.$store.commit("projects/setRefreshCustomDate", value);
      },
    },

    webSocket: {
      get() {
        return this.getSocket;
      },
      set(value) {
        this.$store.commit("projects/setSocket", value);
      },
    },
  },

  mounted() {
    var protocol = "wss://";
    if (window.location.hostname == "localhost") {
      protocol = "ws://";
    }
    let address =
      protocol +
      window.location.host +
      "/api/projects/ws/" +
      this.$route.params.id;
    this.webSocket = new WebSocket(address);
    this.webSocket.onmessage = (msg) => {
      var fromSocket = JSON.parse(msg.data);
      if (fromSocket[0].action == "restore") {
        this.restoreTask(fromSocket[0]);
      } else if (
        fromSocket[0].action == "removeUser" ||
        fromSocket[0].action == "addUser"
      ) {
        this.memberListChange(fromSocket[0]);
      } else if (fromSocket[0].action == "userStatusChange") {
        // future updates
      } else if (fromSocket[0].action == "delete") {
        this.deleteTaskFromList(fromSocket[0]);
      } else {
        if (fromSocket[2].projectID == this.$route.params.id) {
          this.setColumns(fromSocket[0]);
          this.setHeaders(fromSocket[1]);
          this.dateKey += 1;
          this.setTasks(fromSocket[4]);
          if (fromSocket[3].id == this.getTask.id) {
            this.setTask(fromSocket[3]);
          }
          this.setMetaList(fromSocket[5]);
          this.customDateKey += 1;
        }
      }
    };
    this.webSocket.onopen = function () {};
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  created() {
    this.$store.commit("projects/setFilterData", []);
    this.$store
      .dispatch("projects/getProject", this.$route.params.id)
      .then((response) => {
        this.setEditing("list");
        this.setProject(response.data);
        var properties = JSON.parse(this.getProject.properties);
        this.setColumns(properties[0]);
        this.setHeaders(properties[1]);
        this.customFields();
        this.$store
          .dispatch("projects/getTasks", this.getProject.id)
          .then(() => {
            if (this.$route.params.fullTask) {
              for (let i = 0; i < this.getTasks.length; i++) {
                if (this.$route.params.fullTask == this.getTasks[i].id) {
                  this.fullTaskInfo(this.getTasks[i]);
                }
              }
            }
            this.$store
              .dispatch("projects/getProjectMembers", this.getProject.id)
              .then(() => {
                for (let i = 0; i < this.getProjectMembers.length; i++) {
                  if (this.getProjectMembers[i].deactivated_at == 0) {
                    this.getProjectMembers[i].disabled = false;
                  } else {
                    this.getProjectMembers[i].disabled = true;
                  }
                }
                this.$store
                  .dispatch("projects/getSelectedMeta", this.getProject.id)
                  .then(() => {
                    // getColumns (Main Project array with sections info, tasks info and task order)
                    for (let i = 0; i < this.getColumns.length; i++) {
                      for (
                        let j = 0;
                        j < this.getColumns[i].tasks.length;
                        j++
                      ) {
                        for (let k = 0; k < this.getTasks.length; k++) {
                          if (
                            this.getColumns[i].tasks[j].id ==
                              this.getTasks[k].id &&
                            this.getTasks[k].trash == false
                          ) {
                            this.getColumns[i].tasks[j] = this.getTasks[k];
                            for (let k = 0; k < this.getMetaList.length; k++) {
                              this.getColumns[i].tasks[j][
                                this.getMetaList[k].id.toString()
                              ] = "";
                            }
                            for (
                              let l = 0;
                              l < this.getSelectedMeta.length;
                              l++
                            ) {
                              if (
                                this.getColumns[i].tasks[j].id ==
                                this.getSelectedMeta[l].item_id
                              ) {
                                var key =
                                  this.getSelectedMeta[
                                    l
                                  ].metadata_id.toString();
                                this.getColumns[i].tasks[j][key] = JSON.parse(
                                  this.getSelectedMeta[l].value
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                    this.loaded = true;
                  });
              });
          });
      });
  },

  methods: {
    ...mapMutations("projects", [
      "setColumns",
      "setHeaders",
      "setProject",
      "setIsAssigned",
      "setDate",
      "setTaskDeleted",
      "setTaskEdited",
      "setTaskCreated",
      "setEditing",
      "setSocket",
      "setTask",
      "setTasks",
      "setFieldUpdate",
      "setMetaList",
      "setFileActions",
      "setMobileView",
    ]),

    deleteTaskFromList(item) {
      for (let i = 0; i < this.getColumns.length; i++) {
        for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
          if (this.getColumns[i].tasks[j].id == item.task.id) {
            this.getColumns[i].tasks.splice(
              this.getColumns[i].tasks.indexOf(this.getColumns[i].tasks[j]),
              1
            );
            this.onEnd();
          }
        }
      }
    },

    tabSwitch(item) {
      if (item == "tab-1") {
        this.selectedTab = "Overview";
      } else if (item == "tab-2") {
        this.selectedTab = "List view";
        this.setEditing("list");
      } else if (item == "tab-3") {
        this.selectedTab = "Borad view";
        this.setEditing("board");
      }
      this.model = item;
    },

    changeStatusFilter(item) {
      this.$store.commit("projects/setSelectedStatusFilter", item.title);
    },

    onResize() {
      this.disableItemDrop = window.innerWidth < 959;
      if (this.disableItemDrop == true) {
        this.setMobileView(true);
      } else {
        this.setMobileView(false);
      }
    },

    memberListChange(item) {
      if (item.action == "removeUser") {
        let i = this.getProjectMembers.map((t) => t.id).indexOf(item.user.id);
        this.getProjectMembers.splice(i, 1);

        let j = this.getActiveUsers.map((t) => t.id).indexOf(item.user.id);
        this.getActiveUsers.splice(j, 1);

        let n = this.getProject.members
          .map((t) => t.user_id)
          .indexOf(item.user.id);
        this.getProject.members.splice(n, 1);

        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].assigned_to == item.user.id) {
              this.getColumns[i].tasks[j].assigned_to = "";
            }
          }
        }
        for (let i = 0; i < this.getTasks.length; i++) {
          if (this.getTasks[i].assigned_to == item.user.id) {
            this.getTasks[i].assigned_to = "";
          }
        }
        if (this.getTask.assigned_to == item.user.id) {
          this.getTask.assigned_to = "";
        } else if (this.getTask.subtasks != undefined) {
          for (let i = 0; i < this.getTask.subtasks.length; i++) {
            if (this.getTask.subtasks[i].assigned_to == item.user.id) {
              this.getTask.subtasks[i].assigned_to = "";
            }
          }
        }
      } else if (item.action == "addUser") {
        this.getProject.members.push(...item.members);
        for (let i = 0; i < item.members.length; i++) {
          for (let j = 0; j < this.getUsers.length; j++) {
            if (this.getUsers[j].id == item.members[i].user_id) {
              this.getUsers[j].project_role = item.members[i].role;
              this.getProjectMembers.push(this.getUsers[j]);
            }
          }
        }
      }

      this.$store.commit("projects/setMemberData", {});
    },

    customFields() {
      var fields = [];
      this.$store
        .dispatch("projects/getMetaList", this.getProject.id)
        .then(() => {
          for (let i = 0; i < this.getMetaList.length; i++) {
            var header = {
              value: this.getMetaList[i].id,
              name: this.getMetaList[i].title,
              show: false,
            };
            fields.push(header);
          }
          const difference = fields.filter(
            ({ value: id1 }) =>
              !this.getHeaders.some(({ value: id2 }) => id2 === id1)
          );
          for (let j = 0; j < difference.length; j++) {
            this.getHeaders.push(difference[j]);
          }
        });
    },

    closeSideBars() {
      this.$store.commit("projects/setTaskNavigation", false);
      this.$store.commit("projects/setCustomFields", false);
    },

    restoreTask(item) {
      var inList = false;
      if (item.task.type == "task") {
        for (let k = 0; k < this.getMetaList.length; k++) {
          item.task[this.getMetaList[k].id.toString()] = "";
        }
        for (let l = 0; l < this.getSelectedMeta.length; l++) {
          if (item.task.id == this.getSelectedMeta[l].item_id) {
            var key = this.getSelectedMeta[l].metadata_id.toString();
            item.task[key] = JSON.parse(this.getSelectedMeta[l].value);
          }
        }
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == item.task.id) {
              this.getColumns[i].tasks[j] = item.task;
              this.getColumns[i].tasks[j].trash = false;
              inList = true;
            }
          }
        }

        if (inList == false) {
          (item.task.trash = false),
            this.getColumns[0].tasks.unshift(item.task);
          this.onEnd();
        }

        this.taskRestoreKey += 1;
      } else if (item.task.type == "subtask") {
        for (let i = 0; i < this.getTask.subtasks.length; i++) {
          if (this.getTask.subtasks[i].id == item.task.id) {
            this.getTask.subtasks[i].trash = false;
          }
        }
        for (let i = 0; i < this.getTasks.length; i++) {
          if (this.getTasks[i].id == item.task.id) {
            this.getTasks[i].trash = false;
          }
        }
        this.subTaskReloadKey += 1;
      }
    },

    openCustomFields() {
      var val = this.getCustomFields;
      this.$store.commit("projects/setTaskNavigation", false);
      this.$store.commit("projects/setCustomFields", !val);
    },

    shareMessage() {
      let msg = {
        properties: JSON.stringify(this.getMemberData),
      };
      this.webSocket.send(JSON.stringify(msg));
    },

    sendMessage() {
      var project = [
        this.getColumns,
        this.getHeaders,
        { projectID: this.getProject.id },
        this.getTask,
        this.getTasks,
        this.getMetaList,
      ];
      let msg = {
        properties: JSON.stringify(project),
      };
      this.webSocket.send(JSON.stringify(msg));
    },
  },
};
</script>