export function findItem(tosearch, attr, array) {
  return array.reduce((a, item) => {
    if (a) return a;
    switch (tosearch) {
      case 'id':
        if (attr == item.id) return item;
        break;
      case 'path':
        if (attr == item.path) return item;
        break;
      case 'array':
        if (attr == item.id) return array;
        break;
      case 'name':
        if (attr == item.name) return item;
        break;
      case 'title':
        if (attr == item.title) return item;
        break;
      default:
        break;
    }
    if (item.children) return findItem(tosearch, attr, item.children);
  }, null)
}

export function removeElement(array, elem) {
  var index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function convertSize(size) {
  var sizeType = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (size == 0) { return '0 ' + sizeType[0] }

  var i = parseInt(Math.floor(Math.log(size) / Math.log(1000)));

  return (size / Math.pow(1000, i)).toFixed(1) + ' ' + sizeType[i];
}

export function getCsrfFromCookie() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("CSCSRF=")) {
      return cookie.replace("CSCSRF=", "");
    }
  }

  return ""
}