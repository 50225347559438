import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    notification: false,
    messages: [],
  },

  getters: {
    getNotification(state) {
      return state.notification;
    },
  },

  mutations: {
    showNotification(state, visible) {
      state.notification = visible;
    }
  },

  actions: {
    async getNotifications(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/notifications/user-notifications',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async readNotification(context, payload) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/notifications/notifications',
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            read: payload.read,
            id: payload.id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async deleteNotification(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/notifications/notifications/' + id,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    }
  },
  namespaced: true,
})
