<template>
  <v-snackbar
    v-model="showAlert"
    :timeout="7000"
    :color="getColor"
    bottom
    right
  >
    <div class="px-4 py-4">
      <v-row align="center">
        <v-icon class="px-2">mdi-alert-circle</v-icon>
        {{ getText }}
      </v-row>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="getButtonColor"
        text
        v-bind="attrs"
        @click="dismiss"
        class="mr-2"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapGetters("alert", [
      "getAlert",
      "getText",
      "getColor",
      "getButtonColor",
    ]),

    showAlert: {
      get() {
        return this.getAlert;
      },
      set(value) {
        this.setAlert(value);
      },
    },
  },

  methods: {
    ...mapMutations("alert", ["setAlert", "setText", "setColor"]),

    dismiss() {
      this.setAlert(false);
    },
  },
};
</script>
