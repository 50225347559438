
export const sort = {
  methods: {
    sortByName(items, order) {
      switch (order) {
        case "asc":
          items.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case "desc":
          items.sort((a, b) => a.name.localeCompare(b.name));
          break;

        default:
          break;
      }
    }
  }
}
