<template>
  <div>
    <v-dialog
      v-model="assignDialog"
      max-width="400"
      persistent
    >
      <v-card>
        <div class="d-flex justify-center">
          <v-card-title
            class="text-h5 px-4 pt-4 pb-0 d-inline-block text-truncate"
            style="max-width: 400px;"
          >
            {{ selectedTask.title }}
          </v-card-title>
        </div>
        <div class="d-flex justify-center my-1">
          <div
            class="px-4 d-inline-block text-truncate"
            style="max-width: 400px; font-size: 14px;"
          >
            Assignee
          </div>
        </div>
        <v-card-text class="px-4">

          <v-col
            cols="12"
            class="pa-0"
          >
            <v-autocomplete
              v-model="user"
              :items="getProjectMembers"
              item-disabled="disabled"
              filled
              chips
              color="blue-grey lighten-2"
              label="Assign this task"
              item-text="name"
              item-value="id"
              class="mt-3"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                  color="white"
                  class="mt-2"
                >
                  <v-avatar left>
                    <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                  </v-avatar>
                  {{data.item.name + ' ' + data.item.lastname}}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-badge
                    color="error"
                    offset-x="25"
                    offset-y="22"
                    bordered
                    overlap
                    :value="data.item.deactivated_at == 0 ? false : true"
                  >
                    <template v-slot:badge>
                      <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                    </template>
                    <v-list-item-avatar>
                      <img :src="`/api/users/photo/${data.item.photo}`">
                    </v-list-item-avatar>
                  </v-badge>
                  <v-list-item-content>
                    <v-list-item-title> {{data.item.name + ' ' + data.item.lastname}}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="selectedTask.assigned_to != ''"
            small
            color="grey lighten-3"
            class="mb-2"
            elevation="0"
            @click="removeMember(selectedTask.id)"
          >
            Unassign
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mr-1"
            text
            @click="$emit('update:assignDialog', false)"
          >
            Close
          </v-btn>
          <v-btn
            class="mb-2"
            :color="getAppearances.btn_color"
            dark
            @click="save()"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { mapMutations, mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    assignDialog: Boolean,
    selectedTask: Object,
    selectedUser: String,
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("projects", ["getProject", "getProjectMembers", "getTask"]),

    user: {
      get() {
        return this.selectedUser;
      },
      set(value) {
        this.$emit("update:selectedUser", value);
      },
    },
  },

  mixins: [projectUtils],

  methods: {
    ...mapMutations("projects", ["setAssigned"]),

    save() {
      if (this.user != null && this.user != "") {
        this.setAssigned(this.user);
        if (this.selectedTask.id == this.getTask.id) {
          this.getTask.assigned_to = this.user;
        }
        this.assign();
      }
    },

    assign() {
      this.assignUser(this.selectedTask.id);
      this.$emit("update:assignDialog", false);
      this.user = "";
    },

    removeMember(item) {
      this.removeMemberFromTask(item);
      this.user = "";
      this.$emit("update:assignDialog", false);
    },
  },
};
</script>