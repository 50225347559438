<template>
    <v-snackbar
      v-model="getDragBar"
      :timeout="timeout"
      bottom
      class="text-center"
    >
      <div class="text-center px-4 py-4">
        {{ text }}
      </div>
    </v-snackbar>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        data: () => ({
            text: 'Drop files here to start uploading to folder!',
            timeout: -1,
        }),

        computed: {
            ...mapGetters('dragBar', [
                'getDragBar'
            ]),
        }
    }
</script>