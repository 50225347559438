<template>
  <v-dialog
      v-model="linkPermissionDialog"
      persistent
      width="500"
    >
      <v-card class="pa-0">
        <v-card-title class="text-h5 pa-4">
          Link permissions
        </v-card-title>

        <v-card-text class="pa-0">
          <v-list
            class="transparent"
            dense
          >
            <v-list-item>
              <v-list-item-title>Expiration date</v-list-item-title>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editPermissions.expiration"
                    label="Select"
                    :color="getAppearances.btn_color"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editPermissions.expiration"
                  :color="getAppearances.btn_color"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Password protected</v-list-item-title>
              <v-switch
                v-model="editPermissions.passwordProtected"
                :color="getAppearances.btn_color"
                hide-details
              ></v-switch>
            </v-list-item>
            <v-list-item
              v-if="editPermissions.passwordProtected == true"
            >
              <v-list-item-title>Password</v-list-item-title>
              <v-text-field
                v-model="editPermissions.password"
                :color="getAppearances.btn_color"
                type="password"
                prepend-icon="mdi-lock"
                placeholder="********"
                label="Password"
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Allow download</v-list-item-title>
              <v-switch
                v-model="editPermissions.allowDownload"
                :color="getAppearances.btn_color"
                hide-details
              ></v-switch>
            </v-list-item>
            <v-list-item
              v-if="getShareItem.isFolder"
            >
              <v-list-item-title>Allow upload</v-list-item-title>
              <v-switch
                v-model="editPermissions.allowUpload"
                :color="getAppearances.btn_color"
                hide-details
              ></v-switch>
            </v-list-item>
            <v-list-item
            >
              <v-textarea
                v-model="editPermissions.message"
                :color="getAppearances.btn_color"
                class="mt-2"
                label="Enter message"
                rows="1"
              ></v-textarea>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            text
            @click="updateLinkSettings(editPermissions)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dateMenu: false,
      date: '',
    }
  },

  computed: {
    ...mapGetters('shareDialog', [
      'linkPermissionDialog',
      'editPermissions'
    ]),

    ...mapGetters('files', [
      'getShareItem',
    ]),

    ...mapGetters('admin', [
      'getAppearances',
    ]),
  },

  methods: {
    close() {
      this.$store.commit('shareDialog/openLinkPermissionsDialog', {status: false});
    },

    updateLinkSettings(item) {
      this.close();
      
      this.$store.dispatch('share/updateLink', {
        token: item.id,
        expiration: item.expiration,
        passwordProtected: item.passwordProtected,
        password: item.password,
        allowDownload: item.allowDownload,
        allowUpload: item.allowUpload,
        message: item.message
      })
    },
  }
}
</script>