<template>
  <v-col cols="12">
    <div class="title">
      <h5 class="text-left font-weight-medium black--text mt-n3">
        Added files ({{dateText}})
      </h5>
    </div>
    <div
      id="fileCount"
      style="width: 100%;height:400px;"
    ></div>
  </v-col>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

export default {
  props: {
    typeCount: Object,
    dateText: String,
  },

  data() {
    return {
      myChart: null,
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          top: "0%",
          bottom: "0%",
          left: "center",
        },
        series: [
          {
            name: "File type",
            top: "22%",
            bottom: "0%",
            type: "pie",
            radius: ["10%", "90%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: "Document" },
              { value: 0, name: "Spreadsheet" },
              { value: 0, name: "Presentation" },
              { value: 0, name: "Audio" },
              { value: 0, name: "Video" },
              { value: 0, name: "Image" },
              { value: 0, name: "Text" },
              { value: 0, name: "Pdf" },
              { value: 0, name: "Other" },
            ],
          },
        ],
      },
    };
  },

  mounted() {
    setTimeout(() => {
      var chartDom = document.getElementById("fileCount");
      this.myChart = echarts.init(chartDom);

      if (this.typeCount.doc == undefined) {
        const unwatch = this.$watch("typeCount", () => {
          if (this.typeCount.doc == undefined) return;

          this.myChart.setOption(this.setOptions());

          unwatch();
        });
      } else {
        this.myChart.setOption(this.setOptions());
      }

      let chart = this.myChart;

      window.addEventListener(
        "resize",
        function () {
          chart.resize();
        },
        true
      );
    }, "200");
  },

  methods: {
    setOptions() {
      this.option.series[0].data[0].value = this.typeCount.doc;
      this.option.series[0].data[1].value = this.typeCount.xls;
      this.option.series[0].data[2].value = this.typeCount.ppt;
      this.option.series[0].data[3].value = this.typeCount.audio;
      this.option.series[0].data[4].value = this.typeCount.video;
      this.option.series[0].data[5].value = this.typeCount.image;
      this.option.series[0].data[6].value = this.typeCount.text;
      this.option.series[0].data[7].value = this.typeCount.pdf;
      this.option.series[0].data[8].value = this.typeCount.other;

      return this.option;
    },

    async loadData(item) {
      this.option.series[0].data[0].value = item.typeCount.doc;
      this.option.series[0].data[1].value = item.typeCount.xls;
      this.option.series[0].data[2].value = item.typeCount.ppt;
      this.option.series[0].data[3].value = item.typeCount.audio;
      this.option.series[0].data[4].value = item.typeCount.video;
      this.option.series[0].data[5].value = item.typeCount.image;
      this.option.series[0].data[6].value = item.typeCount.text;
      this.option.series[0].data[7].value = item.typeCount.pdf;
      this.option.series[0].data[8].value = item.typeCount.other;
    },

    setChart(item) {
      this.loadData(item).then(() => {
        this.myChart.setOption(this.setOptions());
      });
    },
  },
};
</script>

<style scoped>
.barChart {
  height: 330px;
  width: 100%;
}
</style>
