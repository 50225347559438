<template>
  <v-dialog
    v-model="folderdialog"
    max-width="400"
    persistent
    transition
  >
    <v-card>
      <v-card-title class="headline pa-4 ma-0">Create New Folder</v-card-title>

      <v-card-text class="pa-4">
        <v-scroll-y-transition
          mode="out-in"
          hide-on-leave
        >
          <v-form
            ref="form"
            v-model="valid"
            v-on:submit.prevent
            lazy-validation
          >
            <v-text-field
              :rules="[ v => !!v || 'Folder name is required', folderExists ]"
              v-model="folder_name"
              label="Enter folder name"
              :color="getAppearances.btn_color"
              autofocus
              @keyup.enter="createFolder"
            ></v-text-field>
          </v-form>
        </v-scroll-y-transition>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="closeFolderDialog"
        >
          Cancel
        </v-btn>

        <v-btn
          :disabled="disableCreate"
          :loading="loading"
          class="white--text"
          :color="getAppearances.btn_color"
          @click="createFolder"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";
import { sort } from "../../mixins/sort";

export default {
  data: () => ({
    folder_name: "",
    disableCreate: false,
    valid: true,
    loading: false,
  }),

  computed: {
    ...mapGetters("fileDialogs", ["folderdialog"]),

    ...mapGetters("files", ["path", "getTreeview", "getFiles"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [utils, sort],

  methods: {
    closeFolderDialog() {
      this.$store.commit("fileDialogs/openFolderDialog", false);
      this.$refs.form.reset();
      this.folder_name = "";
      this.disableCreate = false;
      this.loading = false;
    },

    addFolder(item) {
      this.$store.commit("files/addFile", item);

      const parentItem = this.findItem("id", this.path, this.getTreeview);
      if (parentItem) {
        let items = [...parentItem.children, item];

        this.sortByName(items, "desc");

        parentItem.children = items;
      }

      this.closeFolderDialog();
    },

    createFolder() {
      if (this.$refs.form.validate()) {
        this.disableCreate = true;
        this.loading = true;
        var data = {
          name: this.folder_name,
          id: this.path,
        };

        this.$store
          .dispatch("files/createFolder", data)
          .then((response) => {
            response.data.children = [];
            this.addFolder(response.data);
          })
          .catch(() => {
            this.closeFolderDialog();
          });
      }
    },

    folderExists() {
      let item = this.findItem("name", this.folder_name, this.getFiles);
      if (item) {
        if (item.isFolder) {
          this.disableCreate = true;
          return "A folder with that name already exists";
        }
      }

      if (!this.folder_name) {
        this.disableCreate = true;
      } else {
        this.disableCreate = false;
      }
      return true;
    },
  },
};
</script>
