import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    metadata: []
  },

  getters: {

  },

  mutations: {

  },

  actions: {
    async getWorkflows(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/workflows',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
  
        axios(options)
        .then(response => {
          resolve(response);
        }).catch(error => {
          context.dispatch('alert/showAlert', {
            color: 'red',
            text: error.response.data
          }, { root: true })
        });
      })
    },

    async delete(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/workflows/delete',
          method: 'POST',
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          data: {
            id: id,
          },
        };
  
        axios(options)
        .then(() => {
          resolve()
        }).catch(() => {
          context.dispatch('alert/showAlert', {
            color: 'red',
            text: 'Failed to delete workflow'
          }, { root: true })
        });
      })
    },

    async workflow(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/workflows/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
  
        axios(options)
        .then(response => {
          resolve(response);
        }).catch(error => {
          context.dispatch('alert/showAlert', {
            color: 'red',
            text: error.response.data
          }, { root: true })
        });
      })
    }
  },

  namespaced: true,
})
