<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col>
        <v-col align="center">
          <div class="text-h4 font-weight-light">
            Projects and Operations
          </div>
          <div class="mx-7 text-wrap font-weight-regular text--secondary">
            Manage and organize all your Projects, Tasks and Operations
          </div>
        </v-col>

        <v-row justify="center">
          <v-col
            lg="6"
            md="8"
            sm="12"
          >
            <v-list
              subheader
              two-line
            >
              <v-subheader>Folders</v-subheader>

              <v-divider></v-divider>

              <v-list-item @click="openFolderDialog()">
                <v-list-item-avatar tile>
                  <v-icon
                    dark
                    color="grey"
                    size="48"
                  >
                    mdi-shape-square-rounded-plus
                  </v-icon>
                </v-list-item-avatar>

                <v-dialog
                  v-model="dialogCreateFolder"
                  persistent
                  max-width="450"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-title>New Folder</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-card>
                    <v-card-title class="headline px-4 pt-4">Create New Project Folder</v-card-title>

                    <v-card-text class="px-4">
                      <v-scroll-y-transition
                        mode="out-in"
                        hide-on-leave
                      >
                        <v-form
                          ref="formFolder"
                          v-model="valid"
                          v-on:submit.prevent
                          lazy-validation
                        >
                          <v-text-field
                            :rules="[ v => !!v || 'Folder name is required', folderExists ]"
                            v-model="folder_name"
                            label="Enter folder name"
                            :color="getAppearances.btn_color"
                            autofocus
                            @keyup.enter="newFolder"
                          ></v-text-field>
                        </v-form>
                      </v-scroll-y-transition>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :color="getAppearances.btn_color"
                        text
                        @click="closeNewFolder"
                        class="mb-2"
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        :disabled="disableCreate"
                        class="white--text mb-2"
                        :color="getAppearances.btn_color"
                        @click="newFolder"
                      >
                        Create
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item
                v-for="(folder, i) in folders"
                :key="folder.title"
                @click="openProjectFolder(folder)"
              >
                <v-list-item-avatar tile>
                  <v-icon
                    dark
                    :color="folder.properties.color"
                    size="48"
                  >
                    mdi-folder
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="folder.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="grey lighten-1">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-dialog
                        v-model="renameDialog"
                        persistent
                        max-width="450"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                            @click="openRenameDialog(folder.title, i)"
                          >
                            <v-list-item-title>Rename</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5 pa-4">
                            {{ folder_name }}
                          </v-card-title>
                          <v-card-text class="px-4">
                            <v-scroll-y-transition
                              mode="out-in"
                              hide-on-leave
                            >
                              <v-form
                                ref="formRename"
                                v-model="valid"
                                v-on:submit.prevent
                                lazy-validation
                              >
                                <v-text-field
                                  :rules="[ v => !!v || 'Folder name is required', folderExists ]"
                                  v-model="folder_name"
                                  label="Enter folder name"
                                  :color="getAppearances.btn_color"
                                  autofocus
                                  @keyup.enter="updateFolderName(folder, i)"
                                ></v-text-field>
                              </v-form>
                            </v-scroll-y-transition>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              @click="closeRename(i)"
                              class="mb-2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              class="white--text mb-2"
                              :disabled="disableCreate"
                              :color="getAppearances.btn_color"
                              @click="updateFolderName(folder,i)"
                            >
                              Rename
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-menu
                        open-on-hover
                        top
                        offset-x
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>Change color</v-list-item-title>
                          </v-list-item>

                        </template>

                        <v-color-picker
                          class="pa-2"
                          hide-canvas
                          hide-sliders
                          hide-inputs
                          :swatches="swatches"
                          value="#000"
                          show-swatches
                          @update:color="updateFolderColor($event, folder)"
                        ></v-color-picker>
                      </v-menu>
                      <v-dialog
                        v-model="deleteDialog"
                        persistent
                        max-width="450"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5 pa-4">
                            {{ folder.title }}
                          </v-card-title>
                          <v-card-text class="px-4">
                            Are you sure you want to delete? All projects will be deleted with this folder
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              @click="deleteDialog = false"
                              class="mb-2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="red"
                              dark
                              @click="deleteFolder(folder)"
                              class="mb-2"
                            >
                              Delete
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list
              subheader
              two-line
            >
              <v-subheader>Projects</v-subheader>
              <v-divider></v-divider>
              <v-list-item @click="newProjectDialog">
                <v-list-item-avatar tile>
                  <v-icon
                    dark
                    color="grey"
                    size="48"
                  >
                    mdi-shape-square-rounded-plus
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>New Project</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <ProjectDialog
                :projectDialog="projectDialog"
                :edit="edit"
                :project="projectToEdit"
                @close="closeProjectDialog"
                @update="updateProject"
              />

              <v-divider></v-divider>

              <v-list-item
                v-for="project in getProjects"
                :key="project.id"
                @click="openProject(project)"
              >
                <v-list-item-avatar tile>
                  <v-icon size="48">
                    mdi-clipboard-text-outline
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{project.title}}</v-list-item-title>

                  <v-list-item-subtitle>{{project.description}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu
                    close-on-content-click
                    close-on-click
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="grey lighten-1">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="editProject(project)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openDeleteConfirmation(project)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <!-- for some reason delete project function passes wrong project so v-dialog is without v-slot activator -->
            <v-dialog
              v-model="deleteProjectDialog"
              persistent
              max-width="450"
            >
              <v-card>
                <v-card-title class="text-h5 pa-4">
                  {{ projectToDelete.title }}
                </v-card-title>
                <v-card-text class="px-4">
                  Are you sure you want to delete?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="deleteProjectDialog = false"
                    class="mb-2"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="red"
                    dark
                    @click="deleteProject(projectToDelete)"
                    class="mb-2"
                  >
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";
import { sort } from "../../mixins/sort";
import ProjectDialog from "../../components/Projects/Dialogs/newProjectDialog.vue";

export default {
  components: {
    ProjectDialog,
  },

  data: () => ({
    folders: [],
    projects: [],
    folder_name: "",
    projectToEdit: {},
    projectToDelete: {},
    edit: false,
    dialogCreateFolder: false,
    projectDialog: false,
    renameDialog: false,
    deleteDialog: false,
    deleteProjectDialog: false,
    disableCreate: false,
    valid: true,
    swatches: [
      ["#B71C1C", "#E91E63", "#9C27B0"],
      ["#673AB7", "#3F51B5", "#2196F3"],
      ["#03A9F4", "#00BCD4", "#009688"],
      ["#4CAF50", "#FFC107", "#FF9800"],
      ["#FF5722", "#795548", "#607D8B"],
    ],
  }),

  mixins: [utils, sort],

  mounted() {
    this.$store.dispatch("projects/getProjectsFolders").then((response) => {
      this.folders = response.data.Folders;
      this.$store.commit("projects/setProjects", response.data.Projects);
    });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("projects", ["getTreeviewFolders", "getProjects"]),
  },

  methods: {
    openProjectFolder(item) {
      this.$router.push({ name: "project-folder", params: { id: item.id } });

      this.$store
        .dispatch("projects/getProjectsInFolder", item.id)
        .then((response) => {
          this.$store.commit("projects/setProjectFolder", response.data);
        });
    },

    openFolderDialog() {
      if (this.$refs.formFolder != undefined) {
        this.$refs.formFolder.resetValidation();
      }
      this.dialogCreateFolder = true;
    },

    openRenameDialog(item, index) {
      this.folder_name = item;

      if (
        this.$refs.formRename != undefined &&
        this.$refs.formRename[index] != undefined
      ) {
        this.$refs.formRename[index].resetValidation();
      }
    },

    openDeleteConfirmation(project) {
      this.projectToDelete = project;
      this.deleteProjectDialog = true;
    },

    newProjectDialog() {
      this.projectDialog = true;
    },

    closeProjectDialog() {
      this.projectDialog = false;
      this.projectToEdit = {};
      this.edit = false;
    },

    newFolder() {
      if (this.$refs.formFolder.validate()) {
        let props = {
          color: "#1976D2",
        };

        this.$store
          .dispatch("projects/createProjectFolder", {
            title: this.folder_name,
            properties: props,
          })
          .then((response) => {
            this.folders.push(response.data);

            this.$store.commit("projects/insertFolder", {
              id: this.getTreeviewFolders[0].id,
              folder: response.data,
            });
            // this.getTreeviewFolders[0].children.push(response.data);
          });

        this.dialogCreateFolder = false;
        this.$refs.formFolder.reset();
      }
    },

    folderExists() {
      let item = this.findItem("title", this.folder_name, this.folders);
      if (item) {
        this.disableCreate = true;
        return "A folder with that name already exists";
      }

      if (!this.folder_name) {
        this.disableCreate = true;
      } else {
        this.disableCreate = false;
      }
      return true;
    },

    updateFolderColor(color, folder) {
      if (color.hex != "#000000") {
        folder.properties.color = color.hex;

        this.$store.dispatch("projects/patchProjectFolder", folder);
      }
    },

    updateFolderName(folder, index) {
      if (this.$refs.formRename[0].validate()) {
        this.renameDialog = false;
        folder.title = this.folder_name;
        this.$store.dispatch("projects/patchProjectFolder", folder).then(() => {
          let tree_item = this.findItem(
            "id",
            folder.id,
            this.getTreeviewFolders
          );

          if (tree_item != undefined) {
            tree_item.title = folder.title;
          }
        });

        this.closeRename(index);
      }
    },

    deleteFolder(folder) {
      this.$store
        .dispatch("projects/deleteProjectFolder", folder.id)
        .then(() => {
          this.folders.splice(this.folders.indexOf(folder), 1);
          let array = this.findItem(
            "array",
            folder.id,
            this.getTreeviewFolders
          );

          for (let i = 0; i < array.length; i++) {
            if (array[i].id == folder.id) {
              array.splice(i, 1);
            }
          }
        });

      this.deleteDialog = false;
    },

    deleteProject(project) {
      this.$store.dispatch("projects/deleteProject", project.id).then(() => {
        this.getProjects.splice(this.getProjects.indexOf(project), 1);
      });

      this.deleteProjectDialog = false;
      this.projectToDelete = {};
    },

    editProject(item) {
      this.projectToEdit = item;
      this.edit = true;
      this.projectDialog = true;
    },

    updateProject(data) {
      this.projectToEdit.title = data.title;
      this.projectToEdit.description = data.description;
      this.projectToEdit.end_date = data.endDate;
      this.projectToEdit.start_date = data.startDate;
    },

    openProject(project) {
      this.$router.push({ name: "project", params: { id: project.id } });
    },

    closeRename(index) {
      this.renameDialog = false;
      if (
        this.$refs.formRename != undefined &&
        this.$refs.formRename[index] != undefined
      ) {
        this.$refs.formRename[index].reset();
      }
    },

    closeNewFolder() {
      this.dialogCreateFolder = false;
      this.$refs.formFolder.reset();
    },
  },
};
</script>