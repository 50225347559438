<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
        size="32"
      ></v-avatar>

      <v-img
        max-height="55"
        max-width="55"
        :src="`/static/${getAppearances.logo}`"
      ></v-img>
      <v-spacer></v-spacer>
      <v-scroll-y-transition>
        <div v-if="getOpened">
          <v-btn
            depressed
            :color="getAppearances.btn_color"
            dark
            @click="closeOffice"
          >
            Close
          </v-btn>
        </div>
      </v-scroll-y-transition>
      <!-- <v-btn
        v-if="file.AllowDownload && file.Children != null && !getOpened"
        small
        align="end"
        depressed
        :color="getAppearances.btn_color"
        dark
      >
        Download All(butaforija)
      </v-btn> -->
      <v-btn
        v-if="file.AllowUpload && !getOpened"
        small
        align="end"
        depressed
        :color="getAppearances.btn_color"
        @click="$refs.file.click()"
        dark
      >
        Upload
      </v-btn>
      <!-- <v-list-item
        @click="$refs.file.click()"
      >
        <v-list-item-avatar>
          <v-icon
          >
            mdi-upload
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>File upload</v-list-item-title>
      </v-list-item> -->
      <input
        type="file"
        ref="file"
        style="display: none"
        @change="onFileSelected"
        multiple
      >
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-row
        v-if="getOpened"
        class="fill-height"
        no-gutters
      >
        <Office />
      </v-row>
      <v-container
        v-if="!getOpened"
        @dragenter="dragenter"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop.stop.prevent="onFileSelected"
        fluid
        class="fill-height"
      >
        <v-row
          v-if="file.Children != null && file.Children.length == 0"
          align="center"
          justify="center"
          class="fill-height"
        >
          <div class="text-center mb-5 child-elements">
            <v-list-item-avatar
              size="200"
              class="child-elements"
            >
              <v-icon
                size="100"
                dark
                color="white"
                class="grey lighten-2"
              >
                mdi-folder-upload-outline
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-medium noselect">Folder is empty</h1>
            <!-- <div noselect class="subtitle-1 grey--text mb-5 noselect">Or use button new</div> -->
          </div>
        </v-row>
        <v-row
          v-if="file.Children != null && file.Children.length > 0"
          align="start"
          justify="center"
          class="fill-height"
        >
          <v-col class="pa-0">
            <v-list
              class="mt-0 py-0"
              two-line
            >
              <v-list-item-group
                v-model="selectedItem"
                :color="getAppearances.btn_color"
              >
                <template v-for="(item, index) in file.Children">
                  <v-list-item
                    :key="index"
                    @dblclick="dblclick(item)"
                  >
                    <template v-slot:default="{ }">
                      <v-list-item-avatar size="40">
                        <v-icon
                          v-if="fileTypes[item.Type] !== undefined && fileTypes[item.Type].fileType !== 'image'"
                          large
                          class="grey lighten-4"
                          dark
                          :color="fileTypes[item.Type].color"
                        >
                          {{ fileTypes[item.Type].icon }}
                        </v-icon>
                        <v-img
                          v-else-if="fileTypes[item.Type] !== undefined && fileTypes[item.Type].fileType == 'image'"
                          :src="`/api/public/thumbnails/${item.ID}`"
                        >
                        </v-img>
                        <v-icon
                          v-else
                          large
                          class="grey lighten-4"
                          dark
                          :color="fileTypes['unknown'].color"
                        >
                          {{ fileTypes['unknown'].icon }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.Name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row>
                          <v-btn
                            v-if="file.AllowDownload"
                            icon
                            class="mr-2"
                            @click="download(item)"
                          >
                            <v-icon color="grey lighten-1">mdi-download</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider :key="item.Name + index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-row
          v-if="file.Type != undefined && file.Children == null"
          align="center"
          justify="center"
          class="fill-height"
        >

          <v-card
            :loading="loading"
            class="mx-auto my-12 pa-4"
            max-width="374"
            min-width="374"
          >
            <template slot="progress">
              <v-progress-linear
                :color="getAppearances.btn_color"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-icon
              size="128"
              :color="fileTypes[file.Type].color"
            >
              {{ fileTypes[file.Type].icon }}
            </v-icon>

            <v-card-title>{{ file.Name }}</v-card-title>

            <v-card-text>
              <div class="mb-4 subtitle-1">
                Shared by {{file.User.Name + ' ' + file.User.LastName}}
              </div>

              <div>{{ file.Message }}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Actions</v-card-title>

            <v-card-actions>
              <v-btn
                v-if="showOpen()"
                :color="getAppearances.btn_color"
                text
                @click="reserve"
              >
                Open
              </v-btn>
              <v-btn
                v-if="file.AllowDownload"
                :color="getAppearances.btn_color"
                text
                @click="download(file)"
              >
                Download
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
    <UploadBar />
    <DragInfo />
    <Alert />
  </v-app>
</template>

<script>
import axios from "axios";
import UploadBar from "../components/Upload";
import DragInfo from "../components/DragInfo";
import Alert from "../components/Alert";
import Office from "../components/Office";

import { mapGetters } from "vuex";
export default {
  components: {
    UploadBar,
    DragInfo,
    Alert,
    Office,
  },

  data: () => ({
    loading: false,
    selection: 1,
    file: {},
    selectedItem: "",
    files_to_upload: [],
    count: 0,
    uploaded_chunk_count: 0,
    queue: 0,
    maxUploadCount: 4,
    currentUploadCount: 0,
    abort: false,
  }),

  created() {
    this.getFile(this.$route.params.path);
    this.$store.dispatch("office/getAddress");
  },

  async beforeRouteUpdate(to, from, next) {
    this.getFile(to.params.path);
    next();
  },

  computed: {
    ...mapGetters("files", ["fileTypes", "isEditable"]),
    ...mapGetters("office", ["getOpened", "getConfig"]),
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    reserve() {
      // this.loading = true;

      this.$store.dispatch("office/openPublicFile", this.file.ID).then(() => {
        let config = this.getConfig;
        this.$store.commit("office/setOpened", true);

        setTimeout(
          () => this.$store.dispatch("office/openOffice", config),
          2000
        );
      });
    },

    showOpen() {
      return this.isEditable(this.file.Type);
    },

    closeOffice() {
      this.$store.commit("office/destroyEditor");
    },

    download(item) {
      var file_path = "/api/public/download/" + item.ID;
      var a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 2);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.loading = false;
    },

    dragenter(event) {
      if (this.file.AllowUpload) {
        this.$store.commit("dragBar/showDragBar", true);
      }
      event.preventDefault();
    },

    dragleave(event) {
      this.$store.commit("dragBar/showDragBar", false);
      event.preventDefault();
    },

    dragover(event) {
      // this.$store.commit('dragBar/showDragBar', false);
      event.preventDefault();
    },

    dblclick(item) {
      if (this.fileTypes[item.Type].isEditable) {
        this.$store.dispatch("office/openPublicFile", item.ID);
        return;
      }

      switch (item.Type) {
        case "inode/directory":
          this.$router.push({
            name: "external-share",
            params: { path: item.ID },
          });
          break;
        default:
          this.download(item);
      }
    },

    getFile(path) {
      const options = {
        url: "/api/public/get/" + path,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };

      axios(options)
        .then((response) => {
          this.file = response.data;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push({ name: "login" });
            return;
          }
          this.$router.push({
            name: "password-protect",
            params: { id: this.$route.params.path },
          });
        });
    },

    createChunks(file, selected) {
      let size = 20000000;
      let chunks = 0;

      chunks = Math.ceil(selected.size / size);
      for (let i = 0; i < chunks; i++) {
        file.chunks.push(
          selected.slice(i * size, Math.min(i * size + size, selected.size))
        );
      }
    },

    async upload(file, chunk, index) {
      let formData = new FormData();

      formData.append("id", file.id);
      formData.append("file", chunk);
      formData.append("name", file.name);
      formData.append("path", this.file.ID);
      formData.append("last", index + 1 === file.chunks.length ? true : false);
      formData.append("full_size", file.size);
      formData.append("type", file.type);
      formData.append("part", "-part-" + this.pad(index));

      const options = {
        url: "/api/public/upload/" + this.file.ID,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      await axios(options)
        .then(() => {
          file.uploaded += chunk.size;
          file.value = Math.round((file.uploaded / file.size) * 100);
          this.uploaded_chunk_count -= 1;
          this.currentUploadCount -= 1;

          if (this.uploaded_chunk_count == 0) {
            file.status = "finish";
            const options = {
              url: "/api/public/upload/" + this.file.ID + "?action=finish",
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: formData,
            };

            axios(options)
              .then((response) => {
                file.status = "completed";
                this.file.Children.push(response.data);
              })
              .catch((error) => {
                file.status = "error";
                console.log(error);
                this.abort = true;
              });
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          this.abort = true;
        });
    },

    async uploadFile(file) {
      this.uploaded_chunk_count = file.chunks.length;
      let formData = new FormData();
      formData.append("path", this.file.ID);
      formData.append("name", file.name);
      formData.append("type", file.type);

      //send upload request
      const options = {
        url: "/api/public/upload/" + this.file.ID + "?action=start",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      await axios(options)
        .then((response) => {
          //maximum chunks per parallel upload
          file.id = response.data;
        })
        .catch((error) => {
          file.status = "error";
          console.log(error);
        });

      //Create tasks to execute concurrently
      for (let i = 0; i < file.chunks.length; i++) {
        if (this.abort == true) {
          //abort
          break;
        }
        await this.upload(file, file.chunks[i], i);
      }
    },

    async onFileSelected(event) {
      /* disable dragBar info */
      this.$store.commit("dragBar/showDragBar", false);
      /* Create file object with chunks for each selected file in event */
      let files = [];
      this.abort = false;

      let eventFiles = event.target.files;
      if (event.dataTransfer) {
        eventFiles = event.dataTransfer.files;
      }

      for (let i = 0; i < eventFiles.length; i++) {
        let file = {
          name: eventFiles[i].name,
          size: eventFiles[i].size,
          path: this.path,
          uploaded: 0,
          timeleft: 0,
          type: eventFiles[i].type,
          value: 0,
          chunks: [],
          status: "uploading",
        };

        this.createChunks(file, eventFiles[i]);

        files.push(file);
      }

      this.$store.commit("upload/setUploadingItems", files);
      if (this.files_to_upload.length > 0) {
        this.files_to_upload.push(...files);
        return;
      }

      this.files_to_upload.push(...files);

      /* Set up indicator for downloads */
      this.$store.commit("upload/setFileCount", this.files_to_upload.length);
      this.$store.commit("upload/setUploading", true);

      for (const [index, item] of this.files_to_upload.entries()) {
        if (this.abort == true) {
          //Improve this and show dialog for cancel and pause upload;
          this.files_to_upload = [];
          break;
        }
        await this.uploadFile(item);
        this.$store.commit(
          "upload/setFileCount",
          this.files_to_upload.length - (index + 1)
        );
        /* create file and push to path(selected or chosen path) */
        //here is the code
      }

      this.files_to_upload = [];
      this.$store.commit("upload/setFileCount", this.files_to_upload.length);
      //this.clearUploadingItems();
    },

    pad(d) {
      return d < 10 ? "0" + d.toString() : d.toString();
    },

    //           this.file.Children.push(response.data);
  },
};
</script>