<template>
  <v-dialog
    v-model="permissionDialog"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="text-h5">
        Permissions
      </v-card-title>

      <v-card-text>
        <v-list
          class="transparent"
          dense
        >
          <v-list-item class="pl-0 ml-0">
            <v-list-item-title>Expiration date</v-list-item-title>
            <v-menu
              :disabled="getUser.id != getShareItem.owner"
              v-model="dateMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editPermissions.expiration"
                  :disabled="getUser.id != getShareItem.owner"
                  label="Select"
                  :color="getAppearances.btn_color"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editPermissions.expiration"
                :color="getAppearances.btn_color"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item class="pl-0 ml-0">
            <v-list-item-title>Allow reshare</v-list-item-title>
            <v-switch
              v-model="editPermissions.allowReshare"
              :disabled="getUser.id != getShareItem.owner"
              :color="getAppearances.btn_color"
              hide-details
            ></v-switch>
          </v-list-item>
          <v-list-item class="pl-0 ml-0">
            <v-list-item-title>Allow edit</v-list-item-title>
            <v-switch
              v-model="editPermissions.allowEdit"
              :disabled="getUser.id != getShareItem.owner"
              :color="getAppearances.btn_color"
              hide-details
            ></v-switch>
          </v-list-item>
          <v-list-item class="pl-0 ml-0">
            <v-list-item-title>Allow edit metadata</v-list-item-title>
            <v-switch
              v-model="editPermissions.allowEditMeta"
              :disabled="getUser.id != getShareItem.owner"
              :color="getAppearances.btn_color"
              hide-details
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          :color="getAppearances.btn_color"
          text
          :disabled="getUser.id != getShareItem.owner"
          @click="updatePermissions(editPermissions)"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dateMenu: false,
      date: "",
    };
  },

  computed: {
    ...mapGetters("shareDialog", ["permissionDialog", "editPermissions"]),

    ...mapGetters("files", ["getShareItem"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getUser"]),
  },

  methods: {
    close() {
      this.$store.commit("shareDialog/openUserPermissionsDialog", {
        status: false,
      });
    },

    updatePermissions(item) {
      this.close();

      if (this.getUser.id != this.getShareItem.owner) {
        return;
      }

      if (item.owner) {
        this.$store.dispatch("share/updatePermissions", {
          group: true,
          id: item.id,
          fileID: this.getShareItem.id,
          allowEdit: item.allowEdit,
          allowEditMeta: item.allowEditMeta,
          allowReshare: item.allowReshare,
          expiration: item.expiration,
        });

        return;
      }

      this.$store.dispatch("share/updatePermissions", {
        group: false,
        id: item.id,
        fileID: this.getShareItem.id,
        allowEdit: item.allowEdit,
        allowEditMeta: item.allowEditMeta,
        allowReshare: item.allowReshare,
        expiration: item.expiration,
      });
    },
  },
};
</script>