<template>
  <v-container fluid>
    <v-row
      class="px-4 pt-5 fill-height"
      align="start"
      justify="center"
    >
      <v-row
        v-if="tasks.length <= 0"
        class="fill-height mt-16 pt-16"
        align="center"
        justify="center"
      >
        <div class="text-center mb-5 child-elements">
          <v-list-item-avatar
            size="200"
            class="child-elements"
          >
            <v-icon
              size="100"
              dark
              color="white"
              class="grey lighten-2"
            >
              mdi-delete
            </v-icon>
          </v-list-item-avatar>
          <h1 class="grey--text text--darken-1 font-weight-medium noselect">Deleted tasks will appear here</h1>
        </div>
      </v-row>

      <v-card-text
        v-else
        class="px-0 ma-0"
      >
        <v-list>
          <v-list-item class="px-0 ma-0">
            <v-list-item-icon class="ml-4 mr-3 px-0">
            </v-list-item-icon>
            <v-list-item-content class="mx-1">
              <v-list-item-subtitle>
                {{headers.task}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="d-none d-sm-block mx-1">
              <v-list-item-subtitle>
                {{headers.project}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="d-none d-sm-block mx-1">
              <v-list-item-subtitle>
                {{headers.status}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="mx-1">
              <v-list-item-subtitle>
                {{headers.due}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="mr-3">
            </v-list-item-action>
          </v-list-item>

          <div
            v-for="(item, index) in tasks"
            :key="item.id"
          >
            <v-list-item
              class="overdue px-0 ma-0"
              color="primary"
            >
              <v-list-item-icon class="ml-4 mr-3 px-0">
                <v-icon v-if="item.type == 'subtask'">mdi-subdirectory-arrow-right</v-icon>
                <v-icon v-else>mdi-calendar-check
                </v-icon>

              </v-list-item-icon>
              <v-list-item-content class="mx-1">
                <v-list-item-title>{{item.title}}</v-list-item-title>
                <v-list-item-subtitle>Owner: {{getFullUserName(item.created_by)}}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block mx-1">
                <v-list-item-title>{{item.project_details.title}}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block mx-1">
                <v-list-item-title>{{item.status}}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="mx-1">
                <v-list-item-title>{{ item.end_date == "" ? "no date" : getDateAndTime(item.end_date) }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      x-small
                      class="mr-3"
                    >
                      <v-icon>
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="restoreTask(item)">
                      <v-list-item-title>Restore</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteConfirm(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index + item.title"></v-divider>
          </div>
        </v-list>
      </v-card-text>
    </v-row>
    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="400"
    >
      <v-card>
        <v-container>
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <h4>When you delete the task, the subtasks associated with it are also deleted</h4>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogDelete = false"
            >
              Close
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              dark
              @click="deleteTask(selectedTask)"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";
import { projectUtils } from "../../mixins/project";

export default {
  data() {
    return {
      tasks: [],
      headers: {
        task: "Task name",
        project: "Project",
        due: "Due date",
        status: "Status",
      },
      dialogDelete: false,
      selectedTask: {},
    };
  },

  mixins: [dateUtils, projectUtils],

  created() {
    this.$store.dispatch("projects/getDeletedTasks").then((response) => {
      this.tasks = response.data;
    });
  },

  computed: {
    ...mapGetters("projects", [
      "getSocket",
      "getProject",
      "getColumns",
      "getHeaders",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getUserById"]),

    webSocket: {
      get() {
        return this.getSocket;
      },
      set(value) {
        this.$store.commit("projects/setSocket", value);
      },
    },
  },

  methods: {
    deleteConfirm(item) {
      if (item.type == "task") {
        this.selectedTask = item;
        this.dialogDelete = true;
      } else {
        this.deleteTask(item);
      }
    },

    restoreTask(item) {
      this.$store
        .dispatch("projects/restoreTask", {
          projectID: item.project_id,
          taskID: item.id,
        })
        .then(() => {
          this.tasks.splice(this.tasks.indexOf(item), 1);
          this.openSocket(item, "restore");
        });
    },

    openSocket(item, action) {
      var protocol = "wss://";
      if (window.location.hostname == "localhost") {
        protocol = "ws://";
      }
      let address =
        protocol + window.location.host + "/api/projects/ws/" + item.project_id;
      this.webSocket = new WebSocket(address);
      var that = this;
      this.webSocket.onopen = function () {
        that.sendMessage(item, action);
      };
    },

    sendMessage(item, action) {
      var project = [
        { action: action, projectID: item.project_id, task: item },
      ];
      let msg = {
        properties: JSON.stringify(project),
      };
      this.webSocket.send(JSON.stringify(msg));
      this.webSocket.close();
    },

    getFullUserName(id) {
      let user = this.getUserById(id);

      return user.name + " " + user.lastname;
    },

    deleteTask(item) {
      this.$store
        .dispatch("projects/deleteTask", {
          projectID: item.project_id,
          taskID: item.id,
        })
        .then(() => {
          this.tasks.splice(this.tasks.indexOf(item), 1);
          this.openSocket(item, "delete");
        });
      this.dialogDelete = false;
      this.selectedTask = {};
    },
  },
};
</script>
