import { mapGetters } from 'vuex';
import axios from "axios";

export const upload = {

  data: () => ({
    files_to_upload: [],
    count: 0,
    uploaded_chunk_count: 0,
    queue: 0,
    maxUploadCount: 4,
    currentUploadCount: 0,
    abort: false,
  }),

  computed: {
    ...mapGetters('files', [
      'path',
    ]),

    ...mapGetters('upload', [
      'uploading'
    ])
  },

  methods: {
    createChunks(file, selected) {
      let size = 20000000;
      let chunks = 0;

      chunks = Math.ceil(selected.size / size);
      for (let i = 0; i < chunks; i++) {
        file.chunks.push(selected.slice(i * size, Math.min(i * size + size, selected.size)));
      }
    },

    async upload(file, chunk, index) {
      let formData = new FormData;

      formData.append('id', file.id);
      formData.append('file', chunk);
      formData.append('name', file.name);
      formData.append('path', file.path);
      formData.append('last', (index + 1) === file.chunks.length ? true : false);
      formData.append('full_size', file.size);
      formData.append('type', file.type);
      formData.append('part', '-part-' + this.pad(index));

      await this.$store
        .dispatch('files/uploadFile', formData)
        .then(() => {
          file.uploaded += chunk.size;
          file.value = Math.round(file.uploaded / file.size * 100);
          this.uploaded_chunk_count -= 1;
          this.currentUploadCount -= 1;

          if (this.uploaded_chunk_count == 0) {
            file.status = "finish"
            const options = {
              url: '/api/files/upload?action=finish',
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: formData,
            };

            axios(options)
              .then(response => {
                file.status = "completed"
                this.$store.commit('files/addFile', response.data);
              }).catch((error) => {
                file.status = "error";
                console.log(error.response.data);
                this.abort = true;
              });
          }
        }).catch(() => {
          file.status = "error"
          this.abort = true;
        });
    },

    async uploadFile(file) {
      this.uploaded_chunk_count = file.chunks.length;
      let formData = new FormData;
      formData.append('path', file.path);
      formData.append('name', file.name);
      formData.append('type', file.type);

      //send upload request
      const options = {
        url: '/api/files/upload?action=start',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      };

      await axios(options)
        .then(response => {
          //maximum chunks per parallel upload
          file.id = response.data;
        }).catch(() => {
          file.status = "error"
          this.$store.commit('upload/setUploading', false);
        });

      //Create tasks to execute concurrently
      for (let i = 0; i < file.chunks.length; i++) {
        if (this.abort == true) {
          //abort
          break;
        }
        await this.upload(file, file.chunks[i], i);
      }
    },

    async onFileSelected(event) {
      /* disable dragBar info */
      this.$store.commit('dragBar/showDragBar', false);
      /* Create file object with chunks for each selected file in event */
      let files = [];
      this.abort = false;

      let eventFiles = event.target.files;
      if (event.dataTransfer) {
        eventFiles = event.dataTransfer.files
      }

      for (let i = 0; i < eventFiles.length; i++) {
        let file = {
          name: eventFiles[i].name,
          size: eventFiles[i].size,
          path: this.path,
          uploaded: 0,
          timeleft: 0,
          type: eventFiles[i].type,
          value: 0,
          chunks: [],
          status: "uploading"
        }

        this.createChunks(file, eventFiles[i]);

        files.push(file);
      }

      this.$store.commit('upload/setUploadingItems', files);
      if (this.files_to_upload.length > 0 && this.uploading != false) {
        this.files_to_upload.push(...files);
        return;
      }

      this.files_to_upload.push(...files);

      /* Set up indicator for downloads */
      this.$store.commit('upload/setFileCount', this.files_to_upload.length);
      this.$store.commit('upload/setUploading', true);

      for (const [index, item] of this.files_to_upload.entries()) {
        if (this.abort == true) {
          //Improve this and show dialog for cancel and pause upload;
          this.files_to_upload = [];
          break;
        }
        await this.uploadFile(item);
        this.$store.commit('upload/setFileCount', this.files_to_upload.length - (index + 1));
        /* create file and push to path(selected or chosen path) */
        //here is the code
      }

      this.files_to_upload = [];
      this.$store.commit('upload/setFileCount', this.files_to_upload.length);
      //this.clearUploadingItems();
    },

    pad(d) {
      return (d < 10) ? '0' + d.toString() : d.toString();
    },
  }
}
