<template>
  <v-container
    class="pa-0"
    fluid
    fill-height
  >
    <div id="placeholder"></div>
    <Sharedialog />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Sharedialog from "../components/Dialogs/shareDialog";

export default {
  components: {
    Sharedialog,
  },

  data: () => ({
    docEditor: null,
  }),

  mounted() {
    this.$store.dispatch("office/getAddress").then(() => {
      if (this.$route.name != "external-share") {
        this.$store
          .dispatch("office/openFile", this.$route.params.id)
          .then(() => {
            setTimeout(() => {
              this.openOffice();
            }, 500);
          });
      }
    });
  },

  computed: {
    ...mapGetters("office", ["getDocEditor", "getConfig"]),
  },

  methods: {
    shutdownOffice() {
      this.$store.dispatch("office/closeOffice");
    },

    openOffice() {
      let config = this.getConfig;
      this.$store.dispatch("office/openOffice", config);
    },
  },
};
</script>