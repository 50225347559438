import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    show: false,
    src: {},
    files: [],
    selectedFileType: ''
  },

  getters: {
    getState(state) {
      return state.show;
    },

    getFiles(state) {
      return state.files;
    },

    getSrc(state) {
      return state.src;
    },

    getSelectedFileType(state) {
      return state.selectedFileType;
    }
  },

  mutations: {
    openViewer(state, data) {
      state.show = true;
      state.src = data.src;
    },

    closeViewer(state) {
      state.show = false;
      state.src = {};
    },

    setSelectedFileType(state, selectedFileType) {
      state.selectedFileType = selectedFileType;
    },

    setFiles(state, files) {
      state.files = files;
    },

  },

  actions: {

  },

  namespaced: true,
})
