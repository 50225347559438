import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
  },

  getters: {

  },

  mutations: {

  },

  actions: {
    getAnalytics(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/statistics?app='
            + data.app + "&time=" + data.time + "&id=" + data.id + "&start="
            + data.periodTimestamp.start + "&end=" + data.periodTimestamp.end,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
