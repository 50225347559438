<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
        >
          <v-card class="elevation-12 form-style">
            <v-row
              align="center"
              justify="center"
            >
              <v-img
                max-height="96"
                max-width="96"
                :src="`/static/${getAppearances.logo}`"
              ></v-img>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-card-title>Enter password</v-card-title>
            </v-row>
            <v-row
              align="center"
              justify="center"  
            >
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  v-on:submit.prevent
                >
                  <v-text-field
                    v-model="password"
                    :color="getAppearances.btn_color"
                    dense
                    outlined
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    @keyup.enter="enter"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block @click="enter" dark :color="getAppearances.btn_color">Enter</v-btn>
            </v-card-actions>
          </v-card> 
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

  export default {
    data: function() {
      return {
          valid: true,
          password: '' 
      }
    },

    computed: {
      ...mapGetters('admin', [
        'getAppearances',
      ]),
    },

    mounted () {
      this.$store.dispatch('admin/getAppearances')
    },

    methods: {
      enter() {
        this.$store
          .dispatch('share/authenticateLink', {
              id: this.$route.params.path,
              password: this.password
            })
          .then(() => {
            this.$router.push({ name: 'external-share', params: { path: this.$route.params.path }});
          });
      }
    }
  }
</script>

<style scoped>
  .form-style {
    padding-top: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }
</style>