import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    search: '',
    breadcrumbs: [],
    breadcrumbsMenu: [],
    sortBy: {}
  },

  getters: {
    search(state) {
      return state.search;
    },
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    },
    getBreadcrumbsMenu(state) {
      return state.breadcrumbsMenu;
    },
    getSortBy(state) {
      return state.sortBy;
    }
  },

  mutations: {
    setSearch(state, search) {
      state.search = search;
    },
    setBreadcrumbs(state, items) {
      state.breadcrumbs = [];
      state.breadcrumbsMenu = [];

      if (items.length >= 4) {
        for (let i = 0; i < items.length; i++) {
          if (i >= 1 && i <= items.length - 3) { //-2 vai -1 ?
            state.breadcrumbsMenu.push(items[i]);
          } else {
            state.breadcrumbs.push(items[i]);
          }
        }

        return;
      }

      state.breadcrumbs = items;

    },
    addItem(state, item) {
      state.breadcrumbs.push(item);
    },
    setSortBy(state, item) {
      state.sortBy = item;
    }
  },

  actions: {
    search(context, name) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/search',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: name
          }
        };
        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(() => { });
      })
    },
  },

  namespaced: true,
})
