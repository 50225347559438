import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    metadata: []
  },

  getters: {
    getHost(state) {
      return state.host;
    },

    getMetadata(state) {
      return state.metadata;
    }
  },

  mutations: {
    setMetadata(state, metadata) {
      state.metadata = metadata;
    }
  },

  actions: {
    async create(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            type: info.type,
            fields: info.fields,
            app: info.app,
            projectID: info.projectID
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async createFileMetadata(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            type: info.type,
            fields: info.fields,
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async edit(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/' + info.id + '/edit',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            type: info.type,
            fields: info.fields,
            difference: info.difference
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async editFileMetadata(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/' + info.id + '/edit',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            name: info.name,
            type: info.type,
            fields: info.fields,
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async addMeta(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/add',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: info.id,
            metaid: info.metaid,
            value: info.value,
            app: info.app,
            projectID: info.projectID
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async addFileMetadataEntry(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/entry/add',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: info.id,
            metaid: info.metaid,
            value: info.value,
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async deleteFileMeta(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/file/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            fileID: data.fileID,
            id: data.id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async deleteFileMetadataEntry(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/entry/remove',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            fileID: data.fileID,
            id: data.id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },


    async delete(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async deleteFileMetadata(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: id
          }
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async updateMeta(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/update/' + info.id,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            value: info.value,
            app: info.app,
            projectID: info.projectID
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },


    async updateFileMetadataEntry(context, info) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/entry/' + info.id + '/update',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            value: info.value,
          }
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getMeta(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/metadata/get',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setMetadata', response.data);
            resolve(response)
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getFileMetadata(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/metadata/get',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit('setMetadata', response.data);
            resolve(response)
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
