<template>
  <v-toolbar
    class="my-1"
    elevation="0"
    dense
  >
    <ToolbarMenu
      :folderdialog="folderdialog"
      :dialog="dialog"
    />
    <v-divider vertical></v-divider>

    <v-breadcrumbs
      class="pl-2"
      light
      :items="getBreadcrumbs"
    >
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :link="true"
          @click="onClick(item)"
          ripple
        >
          <v-btn
            small
            text
          >
            {{ item.name }}
          </v-btn>
        </v-breadcrumbs-item>

        <v-breadcrumbs-item
          v-if="getBreadcrumbsMenu.length > 0 && getBreadcrumbs.indexOf(item) == 0"
          :link="true"
          ripple
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                text
                v-bind="attrs"
                v-on="on"
              >
                ...
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in getBreadcrumbsMenu"
                :key="index"
                @click="onClick(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-breadcrumbs-item>

      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

    <v-menu
      offset-y
      :close-on-content-click=false
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          height="36"
          width="36"
        >
          <v-icon>mdi-sort</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>Sort By</v-subheader>
        <v-list-item
          v-for="(item, index) in sortOptions"
          :key="index"
          @click="setSort(item)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      icon
      @click="toggleDetails"
      height="36"
      width="36"
      class="mr-n2"
    >
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <FolderDialog />
    <DocumentDialog />
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import FolderDialog from "./Dialogs/createFolderDialog";
import DocumentDialog from "./Dialogs/createDocumentDialog";
import ToolbarMenu from "./ToolbarMenu";
import { utils } from "../mixins/utils";

export default {
  name: "ToolbarComponent",

  components: {
    FolderDialog,
    DocumentDialog,
    ToolbarMenu,
  },

  data: () => ({
    upload_progress: 0,
    folderdialog: false,
    search_text: "",
    dialog: false,
    items: [],
    sortOptions: [
      {
        type: "date",
        order: "none",
        text: "Created",
        icon: "mdi-sort-numeric-variant",
        defaultIcon: "mdi-sort-numeric-variant",
      },
      {
        type: "modified",
        order: "none",
        text: "Modified",
        icon: "mdi-sort-numeric-variant",
        defaultIcon: "mdi-sort-numeric-variant",
      },
      {
        type: "size",
        order: "none",
        text: "Filesize",
        icon: "mdi-sort-numeric-variant",
        defaultIcon: "mdi-sort-numeric-variant",
      },
      {
        type: "name",
        order: "none",
        text: "Filename",
        icon: "mdi-sort-alphabetical-variant",
        defaultIcon: "mdi-sort-alphabetical-variant",
      },
      {
        type: "type",
        order: "none",
        text: "Filetype",
        icon: "mdi-sort-alphabetical-variant",
        defaultIcon: "mdi-sort-alphabetical-variant",
      },
    ],
  }),

  mixins: [utils],

  computed: {
    ...mapGetters("toolbar", [
      "search",
      "getBreadcrumbs",
      "getBreadcrumbsMenu",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("main", ["details"]),

    ...mapGetters("files", ["getFiles"]),
  },

  methods: {
    openDialog(item) {
      this.doc_create = Object.assign({}, item);
    },

    searchInput() {
      this.$store.dispatch("toolbar/search", this.search_text);
    },

    findItem(path, array) {
      return array.reduce((a, item) => {
        if (a) return a;
        if (path == item.path) return item;
        if (item.children) return this.findItem(path, item.children);
      }, null);
    },

    toggleDetails() {
      if (this.details == true) {
        this.$store.commit("main/openDetails", false);
        return;
      }
      this.$store.commit("main/openDetails", true);
    },

    onClick(item) {
      var items = [...this.getBreadcrumbs];
      this.$store.commit(
        "toolbar/setBreadcrumbs",
        items.splice(0, items.indexOf(item) + 1)
      );
      if (items.length == 0) {
        return;
      }
      this.$router.push({ name: "files", params: { path: item.id } });
    },

    setSort(item) {
      for (let i = 0; i < this.sortOptions.length; i++) {
        if (item.type != this.sortOptions[i].type) {
          this.sortOptions[i].icon = this.sortOptions[i].defaultIcon;
          this.sortOptions[i].order = "none";
        }
      }

      switch (item.order) {
        case "none":
          item.order = "desc";
          item.icon = item.icon.replace("variant", "descending");
          break;
        case "desc":
          item.order = "asc";
          item.icon = item.icon.replace("descending", "ascending");
          break;
        case "asc":
          item.order = "none";
          item.icon = item.icon.replace("ascending", "variant");
          break;

        default:
          break;
      }

      this.$store.commit("toolbar/setSortBy", item);
    },
  },
};
</script>

<style>
.v-breadcrumbs__divider {
  padding: 0px !important;
}
</style>
