import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router/index'
import vuetify from './plugins/vuetify'
import axios from "axios"
import './scss/main.scss'

Vue.config.devtools = false;

store.dispatch("license/getLicense");
store.dispatch("admin/getAppearances");

store.dispatch("user/checkSession").then(() => {
  store.commit("user/setIsAuthenticated", true);
}).catch(() => { }).finally(() => {

  axios.interceptors.request.use(function (config) {
    config.headers.common['X-CSRF-TOKEN'] = store.getters["auth/getCSRF"];

    return config;
  });

  axios.interceptors.response.use(function (response) {
    // store.commit("auth/setCSRF", response.headers["x-csrf-token"]);
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      store.dispatch('user/clearUserData');
      router.push({ name: 'login' });
    }
    return Promise.reject(error);

  });

  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
