<template>
  <v-dialog
    v-if="loaded"
    v-model="getState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    class="fill-height"
    persistent
    @keydown.esc="close"
  >
    <v-toolbar
      elevation="0"
      flat
      dark
      color="transparent"
      :style="'position:fixed; width: 100%; z-index: 6; background-image: linear-gradient(to right, '+getAppearances.header_color+', transparent)'"
      dense
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-list-item-icon>
        <v-icon
          v-if="fileTypes[getSrc.type] !== undefined"
          dark
          :color="fileTypes[getSrc.type].color"
          class="mt-n1 mx-1"
        >
          {{ fileTypes[getSrc.type].icon }}
        </v-icon>
      </v-list-item-icon>
      <v-toolbar-title>{{ getSrc.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items>
        <v-btn
          icon
          dark
        >
          <v-icon>mdi-comment-plus-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          dark
        >
          <v-icon>mdi-printer-pos</v-icon>
        </v-btn>
        <v-btn
          icon
          dark
        >
          <v-icon>mdi-tray-arrow-down</v-icon>
        </v-btn>
        <v-btn
          icon
          dark
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items> -->
    </v-toolbar>
    <v-card class="backgroundColor d-flex align-center justify-center">
      <v-col
        align="center"
        justify="center"
        cols="12"
      >
        <div
          v-if="getSelectedFileType == 'pdf'"
          class="mt-9"
          style="z-index: -1"
        >

          <div
            v-for="i in numPages"
            :key="i"
            @mouseover="currentPage = i"
            @touchstart="currentPage = i"
            class="mt-9"
          >
            <div :style="'width:'+viewSize+'%'">
              <pdf
                class="mt-3"
                :key="i"
                :src="src"
                :page="i"
                style="display: inline-block; width: 100%"
                @num-pages="pageCount = $event"
              ></pdf>
            </div>
          </div>
        </div>
        <div
          v-else-if="getSelectedFileType == 'image'"
          :style="'width:'+viewSize+'%'"
          class="mt-12"
          :key="keyImg"
        >
          <v-img
            contain
            :src="'/api/files/download/' + getSrc.id"
            max-width="100%"
            min-width="40%"
          ></v-img>
        </div>
        <div
          v-else-if="getSelectedFileType == 'video'"
          :style="'width:'+viewSize+'%'"
          class="mt-12"
          :key="keyVideo"
        >
          <video
            class="video"
            controls
          >
            <source
              :src="'/api/files/download/' + getSrc.id"
              type="video/mp4"
            >
          </video>
        </div>
        <div
          v-else-if="getSelectedFileType == 'audio'"
          :style="'width:'+viewSize+'%'"
          class="mt-12"
          :key="keyAudio"
        >
          <audio controls>
            <source
              :src="'/api/files/download/' + getSrc.id"
              type="audio/mpeg"
            >
          </audio>
        </div>
        <div
          v-if="!hideBoth"
          class="sideButtons"
        >
          <v-btn
            icon
            dark
            class="mx-3"
            :style="!hideLeft ? '' : 'visibility:hidden'"
            @click="loadFile('previous')"
          >
            <v-icon class="white--text">mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            class="mx-3"
            :style="!hideRight ? '' : 'visibility:hidden'"
            @click="loadFile('next')"
          >
            <v-icon class="white--text">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-card>
    <div
      v-if="getSelectedFileType != 'audio'"
      class="bottomFixed mb-3"
    >
      <v-card class="d-inline-flex backgroundColor">
        <div
          v-if="getSelectedFileType == 'pdf'"
          class="white--text ml-3"
          style="margin-top: 6px;"
        >
          Page {{ currentPage }} / {{ pageCount }}
        </div>
        <v-divider
          v-if="getSelectedFileType == 'pdf'"
          class="mx-3"
          dark
          vertical
        />
        <div class="white--text mr-3">
          <v-btn
            icon
            dark
            @click="changeViewSize('minus')"
            :class="getSelectedFileType == 'pdf' ? '' : 'ml-3'"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
            v-if="showMinus == false && viewSize != 100"
            icon
            dark
            @click="viewSize = 100, showMinus = true"
          >
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            dark
            @click="viewSize = 40, showMinus = false"
          >
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            @click="changeViewSize('plus')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
import { mapGetters } from "vuex";
import { actions } from "../mixins/browserActions";

export default {
  components: {
    pdf,
  },

  data: function () {
    return {
      currentPage: 0,
      pageCount: 0,
      src: undefined,
      numPages: undefined,
      viewSize: 40,
      showMinus: false,
      hideRight: false,
      hideLeft: false,
      hideBoth: false,
      loaded: false,
      viewerFiles: [],
      currentFileIndex: 0,
      maxFileIndex: 0,
      keyVideo: 0,
      keyAudio: 110,
      keyImg: 222,
    };
  },

  mixins: [actions],

  mounted() {
    this.routeCheck();
    if (this.getSelectedFileType == "pdf") {
      this.loadPdfReader();
    }
    document.body.style = "overflow: hidden;";
  },

  computed: {
    ...mapGetters("fileViewer", ["getState", "getSrc", "getSelectedFileType"]),
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("files", ["fileTypes", "getFiles"]),
  },

  methods: {
    fileFilter() {
      var arr = [];
      for (let i = 0; i < this.getFiles.length; i++) {
        if (
          this.getFiles[i].isFolder == false &&
          this.fileTypes[this.getFiles[i].type] !== undefined
        ) {
          if (
            this.fileTypes[this.getFiles[i].type].fileType == "image" ||
            this.fileTypes[this.getFiles[i].type].fileType == "video" ||
            this.fileTypes[this.getFiles[i].type].fileType == "pdf" ||
            this.fileTypes[this.getFiles[i].type].fileType == "audio"
          ) {
            arr.push(this.getFiles[i]);
          }
        }
      }

      this.viewerFiles = arr;

      return arr;
    },

    btnHideCheck() {
      if (this.maxFileIndex == 0) {
        this.hideLeft = true;
        this.hideRight = true;
      } else {
        if (this.currentFileIndex == 0) {
          this.hideLeft = true;
          this.hideRight = false;
        } else if (this.currentFileIndex == this.maxFileIndex) {
          this.hideRight = true;
          this.hideLeft = false;
        } else {
          this.hideLeft = false;
          this.hideRight = false;
        }
      }
    },

    routeCheck() {
      if (this.$route.name == "project") {
        this.hideBoth = true;
      } else {
        this.fileFilter();
      }

      for (let i = 0; i < this.viewerFiles.length; i++) {
        if (this.viewerFiles[i].id == this.getSrc.id) {
          this.currentFileIndex = i;
        }
      }
      this.maxFileIndex = this.viewerFiles.length - 1;
      this.btnHideCheck();

      this.loaded = true;
    },

    loadPdfReader() {
      this.src = pdf.createLoadingTask("/api/files/download/" + this.getSrc.id);
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },

    close() {
      document.body.style = "";

      this.$store.commit("fileViewer/closeViewer");

      const html = document.querySelector("html");
      html.style.overflowY = "auto";
    },
    changeViewSize(action) {
      if (this.viewSize != 100 && action == "plus") {
        this.viewSize += 20;
      } else if (this.viewSize != 40 && action == "minus") {
        this.viewSize -= 20;
      }
    },

    loadFile(item) {
      var file = {};
      var index = null;
      if (item == "next") {
        if (this.currentFileIndex < this.maxFileIndex) {
          index = this.currentFileIndex + 1;
          file = this.viewerFiles[index];
          this.openFileViewer(file, this.fileTypes[file.type].fileType);
          if (this.fileTypes[file.type].fileType == "pdf") {
            this.loadPdfReader();
          }
        }
      } else {
        if (this.currentFileIndex > 0) {
          index = this.currentFileIndex - 1;
          file = this.viewerFiles[index];
          this.openFileViewer(file, this.fileTypes[file.type].fileType);
          if (this.fileTypes[file.type].fileType == "pdf") {
            this.loadPdfReader();
          }
        }
      }
      this.currentFileIndex = index;
      this.btnHideCheck();
      if (this.fileTypes[file.type].fileType == "audio") {
        this.keyAudio += 1;
      } else if (this.fileTypes[file.type].fileType == "video") {
        this.keyVideo += 1;
      } else if (this.fileTypes[file.type].fileType == "image") {
        this.keyImg += 1;
      }
    },
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: rgba(0, 0, 0, 0.712);
}
.bottomFixed {
  text-align: center;
  position: fixed;
  bottom: 0;
}
.sideButtons {
  position: sticky;
  bottom: 50%;
  padding-top: 10px;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.video {
  /* object-fit: contain; */
  width: 100%;
  height: auto;
}
</style>