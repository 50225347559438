<template>
  <div>
    <v-row v-if="!getLicense.isLicensed || !getLicense.admin_metrics">
      <v-col
        align="center"
        justify-center
      >
        <LicenseInfo />
      </v-col>
    </v-row>
    <div v-else>
      <v-tabs
        v-model="tab"
        fixed-tabs
        :color="getAppearances.btn_color"
        align-with-title
        class="ml-n7"
        active-class="active"
      >

        <v-tab
          @click="tabFiles"
          href="#1"
        >
          Files
        </v-tab>

        <v-tab
          @click="tabProjects"
          href="#2"
        >
          Projects
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-if="tab == 1"
          value="1"
        >
          <v-card
            class="mt-1"
            elevation="0"
          >
            <ChartFilter
              :users="users"
              @fetch="fetchFileData"
              :selectedPeriod.sync="selectedPeriod"
              :periodTimestamp.sync="periodTimestamp"
              :dateText.sync="dateText"
            />

            <Counts
              :downloads="counts.downloads"
              :uploads="counts.uploads"
              :shares="counts.shares"
              :storageSize="storageSize"
              :dateText="dateText"
              :periodTimestamp.sync="periodTimestamp"
              :userID="userID"
            />
            <Activity
              ref="fileChart"
              :counts="counts"
              :selectedPeriod.sync="selectedPeriod"
              :periodTimestamp.sync="periodTimestamp"
            />
            <v-row
              justify="center"
              class="px-3"
            >
              <v-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <RecentActivities
                  :activities="recentActivities"
                  :userID="userID"
                  :users="users"
                />
              </v-col>
              <v-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <FileCount
                  ref="fileCount"
                  :typeCount="typeCount"
                  :dateText="dateText"
                />
              </v-col>
            </v-row>

            <v-row
              justify="center"
              class="px-3"
            >
              <v-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <LargestFiles
                  ref="largest"
                  :largestFiles.sync="largestFiles"
                  :dateText="dateText"
                />
              </v-col>

              <v-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <FileTypeActivity
                  ref="fileActivity"
                  :activityTypeCount="activityTypeCount"
                  :dateText="dateText"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="tab == 2"
          value="2"
        >
          <v-card
            elevation="0"
            class="mt-1"
          >
            <ChartFilter
              :users="users"
              @fetch="fetchProjectData"
              :selectedPeriod.sync="selectedPeriod"
              :periodTimestamp.sync="periodTimestamp"
              :dateText.sync="dateText"
            />
            <ProjectCounts :taskCount="taskCount" />
            <ProjectActivity
              ref="projectChart"
              :counts="projectCounts"
              :selectedPeriod.sync="selectedPeriod"
              :periodTimestamp.sync="periodTimestamp"
            />
            <ProjectFilterView />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Counts from "../../../components/Charts/Files/Counts.vue";
import Activity from "../../../components/Charts/Files/Activity.vue";
import LargestFiles from "../../../components/Charts/Files/LargestFiles.vue";
import RecentActivities from "../../../components/Charts/Files/RecentActivities.vue";
import FileTypeActivity from "../../../components/Charts/Files/FileTypeActivity.vue";
import FileCount from "../../../components/Charts/Files/FileCount.vue";
import ProjectCounts from "../../../components/Charts/Projects/Counts.vue";
import ProjectActivity from "../../../components/Charts/Projects/Activity.vue";
import ProjectFilterView from "../../../components/Settings/Admin/Dashboard/ProjectFilterView.vue";
import ChartFilter from "../../../components/Charts/ChartFilter.vue";
import LicenseInfo from "../../../components/License/LicenseInfo.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Counts,
    Activity,
    FileCount,
    LargestFiles,
    FileTypeActivity,
    RecentActivities,
    ProjectCounts,
    ProjectActivity,
    ProjectFilterView,
    LicenseInfo,
    ChartFilter,
  },

  data() {
    return {
      tab: "",
      users: [],
      activities: [],
      recentActivities: [],
      largestFiles: [],
      counts: {},
      typeCount: {},
      activityTypeCount: {},
      storageSize: 0,
      taskCount: {},
      projectCounts: {},
      periodTimestamp: {},
      selectedPeriod: "today",
      dateText: "today",
      userID: "",
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUsers", "getTimezone"]),
    ...mapGetters("license", ["getLicense"]),

    dateFormatted() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },

    dateFormattedMonth() {
      return this.dateMonth ? moment(this.dateMonth).format("MMMM, YYYY") : "";
    },
  },

  created() {
    if (this.getLicense.isLicensed && this.getLicense.admin_metrics) {
      var date = moment().format("YYYY-MM-DD" + " 00:00");
      var start_timestamp = moment
        .tz(date, this.getTimezone)
        .startOf("day")
        .format("X");
      var end_timestamp = moment
        .tz(date, this.getTimezone)
        .endOf("day")
        .format("X");

      var data = { start: start_timestamp, end: end_timestamp };
      this.periodTimestamp = data;

      this.$store
        .dispatch("analytics/getAnalytics", {
          app: "files",
          time: this.selectedPeriod,
          id: "all",
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.activities = response.data.activities;
          this.recentActivities = response.data.recentActivity;
          this.largestFiles = response.data.largestFiles;
          this.counts = {
            downloads: response.data.downloads,
            uploads: response.data.uploads,
            shares: response.data.shares,
            created: response.data.created,
            deleted: response.data.deleted,
          };
          this.typeCount = response.data.typeCount;
          this.activityTypeCount = response.data.activityTypeCount;
          this.storageSize = response.data.storageSize;
        });

      this.$store.dispatch("user/getUsers").then((response) => {
        this.users.push(...response.data);
      });
    }
  },

  methods: {
    fetchFileData(id) {
      this.userID = id;
      this.$store
        .dispatch("analytics/getAnalytics", {
          app: "files",
          time: this.selectedPeriod,
          id: id,
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.activities = response.data.activities;
          this.recentActivities = response.data.recentActivity;
          this.largestFiles = response.data.largestFiles;
          this.counts = {
            downloads: response.data.downloads,
            uploads: response.data.uploads,
            shares: response.data.shares,
            created: response.data.created,
            deleted: response.data.deleted,
          };
          this.typeCount = response.data.typeCount;
          this.activityTypeCount = response.data.activityTypeCount;
          this.storageSize = response.data.storageSize;

          this.$refs.fileChart.setChart(response.data);
          this.$refs.largest.setChart(response.data);
          this.$refs.fileCount.setChart(response.data);
          this.$refs.fileActivity.setChart(response.data);
        });
    },

    fetchProjectData(id) {
      this.userID = id;
      this.$store
        .dispatch("analytics/getAnalytics", {
          app: "projects",
          time: this.selectedPeriod,
          id: id,
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.taskCount = response.data.taskCount;
          this.projectCounts = {
            completed: response.data.completed,
            created: response.data.created,
            closed: response.data.closed,
          };

          this.$refs.projectChart.setChart(response.data);
        });
    },

    tabFiles() {
      if (this.getLicense.isLicensed && this.getLicense.admin_metrics) {
        if (this.userID == "") {
          this.userID = "all";
        }
        this.$store
          .dispatch("analytics/getAnalytics", {
            app: "files",
            time: this.selectedPeriod,
            id: this.userID,
            periodTimestamp: this.periodTimestamp,
          })
          .then((response) => {
            this.activities = response.data.activities; // šito vairs neizmanto nekur??
            this.recentActivities = response.data.recentActivity;
            this.largestFiles = response.data.largestFiles;
            this.counts = {
              downloads: response.data.downloads,
              uploads: response.data.uploads,
              shares: response.data.shares,
              created: response.data.created,
              deleted: response.data.deleted,
            };
            this.typeCount = response.data.typeCount;
            this.activityTypeCount = response.data.activityTypeCount;
            this.storageSize = response.data.storageSize;
          });
      }
    },

    tabProjects() {
      if (this.getLicense.isLicensed && this.getLicense.admin_metrics) {
        if (this.userID == "") {
          this.userID = "all";
        }
        this.$store
          .dispatch("analytics/getAnalytics", {
            app: "projects",
            time: this.selectedPeriod,
            id: this.userID,
            periodTimestamp: this.periodTimestamp,
          })
          .then((response) => {
            this.taskCount = response.data.taskCount;
            this.projectCounts = {
              completed: response.data.completed,
              created: response.data.created,
              closed: response.data.closed,
            };
          });
      }
    },
  },
};
</script>

<style scoped>
.active {
  background-color: rgb(240, 240, 240) !important;
}
</style>