<template>
  <v-col
    class="mb-5 mt-n2"
    cols="12"
  >
    <div
      id="activity"
      style="width: 100%;height:370px;"
    ></div>
  </v-col>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer,
  UniversalTransition,
  BarChart,
]);

export default {
  props: {
    counts: Object,
    selectedPeriod: String,
    periodTimestamp: Object
  },

  data() {
    return {
      selected: "",
      countObjects: {
        completed: {},
        created: {},
        closed: {},
      },
      items: ["today", "exact date", "month", "year"],
      myChart: null,
      option: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Completed", "Created", "Closed"],
        },
        grid: {
          left: "0.1%",
          right: "1.2%",
          bottom: "3%",
          top: "13.5%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Completed",
            type: "bar",
            smooth: true,
            data: new Array(24).fill(0),
          },
          {
            name: "Created",
            type: "bar",
            smooth: true,
            data: new Array(24).fill(0),
          },
          {
            name: "Closed",
            type: "bar",
            smooth: true,
            data: new Array(24).fill(0),
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("user", ["getClockDisplay"]),

    period: {
      get() {
        return this.selectedPeriod;
      },
      set(value) {
        this.$emit("update:selectedPeriod", value);
      },
    },
  },

  mounted() {
    var chartDom = document.getElementById("activity");
    this.myChart = echarts.init(chartDom);

    this.myChart.setOption(this.setOptions());

    let chart = this.myChart;

    window.addEventListener(
      "resize",
      function () {
        chart.resize();
      },
      true
    );
  },

  watch: {
    counts() {
      this.countObjects = JSON.parse(JSON.stringify(this.counts));

      this.myChart.setOption(this.setOptions());
    },
  },

  methods: {
    setOptions() {
      if (this.period == this.items[0] || this.period == this.items[1]) {
        if (this.getClockDisplay == "12h") {
          this.option.xAxis.data = [
            "12 AM",
            "1 AM",
            "2 AM",
            "3 AM",
            "4 AM",
            "5 AM",
            "6 AM",
            "7 AM",
            "8 AM",
            "9 AM",
            "10 AM",
            "11 AM",
            "12 PM",
            "1 PM",
            "2 PM",
            "3 PM",
            "4 PM",
            "5 PM",
            "6 PM",
            "7 PM",
            "8 PM",
            "9 PM",
            "10 PM",
            "11 PM",
          ];
        } else if (this.getClockDisplay == "24h") {
          this.option.xAxis.data = [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ];
        }

        for (let i = 0; i < this.option.series.length; i++) {
          this.option.series[i].data = new Array(24).fill(0);
        }
      } else if (this.period == this.items[2]) {
        var dateRFC = moment.unix(this.periodTimestamp.start).format("YYYY-MM-DD");
        var days = moment(dateRFC).daysInMonth();

        let xAsis = [];
        for (let i = 0; i < days; i++) {
          xAsis.push(i + 1);
        }

        this.option.xAxis.data = xAsis;

        for (let i = 0; i < this.option.series.length; i++) {
          this.option.series[i].data = new Array(days).fill(0);
        }
      } else {
        this.option.xAxis.data = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];

        for (let i = 0; i < this.option.series.length; i++) {
          this.option.series[i].data = new Array(12).fill(0);
        }
      }

      let objects = [
        this.countObjects.completed,
        this.countObjects.created,
        this.countObjects.closed,
      ];

      for (let i = 0; i < objects.length; i++) {
        for (const [key, value] of Object.entries(objects[i])) {
          let k = key;
          if (this.period != this.items[0]) {
            k -= 1;
          }
          this.option.series[i].data[k] += value;
        }
      }

      return this.option;
    },

    async loadData(item) {
      this.countObjects.closed = item.shares;
      this.countObjects.created = item.created;
      this.countObjects.completed = item.deleted;
    },

    setChart(item) {
      this.loadData(item).then(() => {
        this.myChart.setOption(this.setOptions());
      });
    },
  },
};
</script>