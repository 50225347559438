import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    details: false,
    uploading: false,
    uploading_items: [],
    file_count: 0,
    timeout: -1,
  },

  getters: {
    details(state) {
      return state.details;
    },
    uploading(state) {
      return state.uploading
    },
    uploadingItems(state) {
      return state.uploading_items;
    },
    fileCount(state) {
      return state.file_count;
    },
    timeout(state) {
      return state.timeout;
    }
  },

  mutations: {
    openDetails(state, status) {
      state.details = status;
    },
    setUploading(state, status) {
      state.uploading = status;
    },
    setUploadingItems(state, items) {
      state.uploading_items.push(...items);
    },
    clearUploadingItems(state) {
      state.uploading_items = [];
    },
    setFileCount(state, count) {
      state.file_count = count;
    },
  },
  namespaced: true,
})
