<template>
  <div class="fill-width border">
    <v-row class="pa-3">
      <v-btn
        color="green"
        dark
        small
        class="ma-3"
        @click="markCompleted(getTask)"
        :key="getStatusKey"
      >
        <div v-if="getTask.status == 'completed'">
          Completed
        </div>
        <div v-else>
          Mark Complete
        </div>
        <v-icon
          v-if="getTask.status == 'completed'"
          right
          dark
          small
        >
          mdi-restore
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="getMobileView == false"
        icon
        small
        class="my-3 mr-3"
        @click="setTaskNavigation(false)"
      >
        <v-icon>mdi-arrow-collapse-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        small
        class="my-3 mr-3"
        @click="setTaskNavigation(false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <v-card
      tile
      flat
    >
      <div :key="getFullTaskKey">
        <v-card-title
          v-if="changeTitle == false"
          @dblclick="openEditTitle()"
          class="pb-1"
        >
          {{ getTask.title }}
        </v-card-title>
        <v-card-title
          v-else
          class="pb-1"
          v-click-outside="onClickOutside"
        >
          <v-text-field
            v-model="newTaskTitle"
            @keydown.enter="onClickOutside"
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-list class="transparent pa-0">
          <v-list-item
            v-for="item in fields"
            :key="item.id"
            dense
            class="py-0 my-n1"
          >
            <v-col
              cols="3"
              class="pa-0"
            >
              <v-list-item-subtitle>
                {{ item.title }}
              </v-list-item-subtitle>
            </v-col>
            <v-col
              v-if="item.title == 'Assignee'"
              class="pa-0"
            >
              <div class="mx-n3 mt-n2">
                <AssigneeMenu
                  :assigneeMenu.sync="assigneeMenu"
                  :item="getTask"
                  :taskID.sync="taskID"
                  :closeCreate.sync="closeCreate"
                  :position="'fulltask'"
                />
              </div>
            </v-col>
            <DateMenu
              v-else-if="item.title == 'Due date'"
              :dateTaskMenu.sync="endDateTaskMenu"
              class="mx-n3"
              :item="getTask"
              :getTaskID.sync="getTaskID"
              :taskID.sync="subTaskID"
              @close="subTaskID = ''"
              :closeCreate.sync="closeCreate"
              :dateType="getTask.end_date"
              :update="'end'"
            />
            <DateMenu
              v-else-if="item.title == 'Start date'"
              :dateTaskMenu.sync="startDateTaskMenu"
              class="mx-n3"
              :item="getTask"
              :getTaskID.sync="getTaskID"
              :taskID.sync="subTaskID"
              @close="subTaskID = ''"
              :closeCreate.sync="closeCreate"
              :dateType="getTask.start_date"
              :update="'start'"
            />
            <div v-else-if="item.title=='Status'">
              <v-select
                @change="editTask(getTask)"
                v-model="getTask.status"
                :items="taskStatusList"
                item-text="name"
                item-value="value"
                append-icon=""
                class="ml-n3"
                hide-details
                dense
                style="font-size: 13px;"
                flat
                solo
              >
                <template #selection="{ item }">
                  <v-btn
                    x-small
                    :color="item.color"
                    class="mb-n1"
                    dark
                  >
                    {{item.name}}
                  </v-btn>
                </template>
              </v-select>
            </div>
            <v-col
              v-else-if=" item.title=='Project'"
              class="pa-0"
            >
              <v-list-item-title>{{ getProject.title }}</v-list-item-title>
            </v-col>
            <div
              v-else-if="item.title == 'Updated'"
              class="text-capitalize font-weight-regular dateFont"
            >
              {{ dateFormat(getTask.updated) }}
            </div>

            <div
              v-else-if="item.title == 'Created'"
              class="text-capitalize font-weight-regular dateFont"
            >
              {{ dateFormat(getTask.created) }}
            </div>

            <v-col
              v-else-if="item.title == 'Created by'"
              class="pa-0"
            >
              <v-avatar
                :color="getAppearances.btn_color"
                size="24"
              >
                <v-img :src="`/api/users/photo/${assignedUser(getTask.created_by)}`">
                </v-img>
              </v-avatar>
            </v-col>

          </v-list-item>

          <v-col
            rows="2"
            cols="12"
            class="py-0"
          >
            <v-textarea
              v-model="getTask.description"
              auto-grow
              filled
              color="primary"
              hide-details
              @change="updateDescriptio()"
              rows="1"
            ></v-textarea>
          </v-col>
          <CustomFields v-if="showCustomHeaders() == true" />
        </v-list>
      </div>
      <div :key="getSubTaskReloadKey">
        <v-list
          class="ml-2 mt-1"
          dense
        >
          <v-subheader>Subtasks</v-subheader>
        </v-list>
        <div
          v-for="item in getTask.subtasks"
          :key="item.id"
        >
          <v-row
            v-if="item.trash == false"
            @mouseover="showDelete = true, subID = item.id"
            @mouseleave="showDelete = false, subID = ''"
          >
            <v-col cols="12">
              <v-card-text
                class="py-1 d-flex"
                :key="deleteKey"
                :style="item.status == 'completed' ? 'opacity: 0.5' : ''"
              >
                <v-row>
                  <v-col
                    v-if="subTaskID == item.id && editSubTaskTitle == true"
                    :cols="getMobileView == true ? 5 : 7"
                    v-click-outside="closeSubEdit"
                  >
                    <v-text-field
                      class="py-0 mt-n1"
                      v-model="newSubTaskTitle"
                      @change="changeSubTaskTitle(item)"
                      style="font-size: 13px"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-else
                    :cols="getMobileView == true ? 5 : 7"
                    @dblclick="editTitle(item)"
                  >
                    <v-row class="pa-0 ma-0">
                      <div style="font-size: 13px">
                        <v-btn
                          icon
                          x-small
                          :class="item.status == 'completed' ? 'mr-1' : 'status mr-1'"
                          :color="item.status == 'completed' ? '#4CAF50' : ''"
                          @click="updateSubtaskStatus(item)"
                          :key="getStatusKey"
                        >
                          <v-icon size="18">{{item.status == 'completed' ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}</v-icon>
                        </v-btn>
                        {{ item.title }}
                      </div>
                    </v-row>
                  </v-col>
                  <v-col
                    :cols="getMobileView == true ? 7 : 5"
                    class="d-flex justify-end"
                  >
                    <v-menu
                      :close-on-click="false"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      :key="getRefreshDate"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-if="item.end_date != ''"
                          align="left"
                          class="mx-2 text-capitalize font-weight-regular endDateFont"
                          @click="subTaskID = item.id"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ dateFormat(item.end_date) }}
                        </div>
                        <div v-else>
                          <v-btn
                            @click="subTaskID = item.id"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            x-small
                            class="mx-2"
                          >
                            <v-icon>mdi-calendar-plus</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <v-date-picker
                        v-if="item.id == subTaskID"
                        v-model="date"
                        no-title
                      >
                        <v-spacer />
                        <v-btn
                          text
                          @click="endDateMenu = false, subTaskID = ''"
                          class="mr-1 mb-3"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :color="getAppearances.btn_color"
                          dark
                          @click="updateDate(item, 'end')"
                          class="mb-3 mr-3"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-menu
                      :close-on-click="false"
                      v-model="assigneeSubMenu"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-badge
                          color="error"
                          offset-x="7"
                          offset-y="7"
                          bordered
                          overlap
                          :value="userStatus(item)"
                        >
                          <template v-slot:badge>
                            <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                          </template>
                          <div class="mt-0 ml-1">
                            <v-avatar
                              @click="assignOpen(item)"
                              v-bind="attrs"
                              v-on="on"
                              :tile="item.assigned_to == '' ? true : false"
                              size="19"
                            >
                              <v-icon
                                v-if="item.assigned_to == ''"
                                size="18"
                              >
                                mdi-account-plus
                              </v-icon>
                              <v-img
                                v-else
                                size="17"
                                :src="`/api/users/photo/${assignedUser(item.assigned_to)}`"
                              >
                              </v-img>
                            </v-avatar>
                          </div>
                        </v-badge>
                      </template>
                      <MemberSelect
                        v-if="item.id == subTaskID"
                        @close="assigneeSubMenu = false, subTaskID = '', selectedUser = ''"
                        @save="assign('subtask'), selectedUser = ''"
                        :user="selectedUser"
                        :item="item"
                      />
                    </v-menu>
                    <v-btn
                      icon
                      x-small
                      @click="deleteSubTask(item.id)"
                      class="ml-3"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider
            v-if="item.trash == false"
            :key="item.id"
            class="mx-4"
          />
        </div>
        <div
          v-if="createSubtask == true"
          class="mx-4"
        >
          <NewSubtask
            :getTask.sync="getTask"
            :createSubtask.sync="createSubtask"
          />
        </div>
        <v-btn
          v-if="createSubtask != true"
          small
          text
          class="mx-4 mt-1"
          @click="createSubtask = true"
        >
          + Add Subtask
        </v-btn>
      </div>
      <TaskFiles />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import NewSubtask from "../../components/Projects/FullTask/NewSubTask";
import { projectUtils } from "../../mixins/project";
import AssigneeMenu from "./Menu/AssigneeMenu.vue";
import DateMenu from "../../components/Projects//Menu/DateMenu.vue";
import MemberSelect from "./Menu/MemberSelect";
import TaskFiles from "../Projects/FullTask/TaskFiles.vue";
import CustomFields from "../Projects/FullTask/CustomFields.vue";

export default {
  components: {
    NewSubtask,
    AssigneeMenu,
    DateMenu,
    MemberSelect,
    TaskFiles,
    CustomFields,
  },
  data() {
    return {
      selectedUser: "",
      endDateTaskMenu: false,
      startDateTaskMenu: false,
      fields: [
        { title: "Assignee" },
        { title: "Project" },
        { title: "Start date" },
        { title: "Due date" },
        { title: "Status" },
        { title: "Updated" },
        { title: "Created" },
        { title: "Created by" },
        { title: "Description" },
      ],
      showDelete: false,
      subID: "",
      createSubtask: false,
      deleteKey: 0,
      subTaskID: "",
      newSubTaskTitle: "",
      editSubTaskTitle: false,
      assigneeSubMenu: false,
      assigneeMenu: false,
      endDateMenu: false,
      taskID: "",
      date: new Date(Date.now()).toISOString().substr(0, 10),
      fullTaskEndDate: true,
      changeTitle: false,
      newTaskTitle: "",
      editedTask: {},
    };
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", [
      "getTask",
      "getTasks",
      "getProject",
      "getStatusKey",
      "getColumns",
      "getAssigned",
      "getRefreshDate",
      "getFullTaskKey",
      "getRestoreKey",
      "getSubTaskReloadKey",
      "getMobileView",
      "getProjectMembers",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getTimezone"]),
  },

  methods: {
    ...mapMutations("projects", [
      "setStatusKey",
      "setTaskNavigation",
      "setFullTaskKey",
    ]),

    userStatus(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          if (this.getProjectMembers[i].deactivated_at == 0) {
            return false;
          } else {
            return true;
          }
        } else if (item.assigned_to == "") {
          return false;
        }
      }
    },

    updateDate(item, period) {
      this.newDate(item, period, this.date);

      var timezoneDate = moment.tz(this.date + " 00:00", this.getTimezone);
      var formatedDate = timezoneDate.utc().format("X");

      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].id == this.subTaskID) {
          this.getTasks[i].end_date = formatedDate;
        }
      }
      for (let j = 0; j < this.getTask.subtasks.length; j++) {
        if (this.getTask.subtasks[j].id == this.subTaskID) {
          this.getTask.subtasks[j].end_date = formatedDate;
        }
      }
      this.date = new Date(Date.now()).toISOString().substr(0, 10);
      this.subTaskID = "";
      this.endDateMenu = false;
    },

    openEditTitle() {
      this.newTaskTitle = this.getTask.title;
      this.changeTitle = true;
      this.editedTask = JSON.parse(JSON.stringify(this.getTask));
    },

    getTaskID(item) {
      if (this.endDateTaskMenu == true || this.startDateTaskMenu == true) {
        this.endDateTaskMenu = false;
        this.startDateTaskMenu = false;
      } else {
        this.subTaskID = item.id;
        this.endDateTaskMenu = true;
        this.startDateTaskMenu = true;
      }
      this.closeCreate();
    },

    closeCreate() {
      this.endDateTaskMenu = false;
      this.startDateTaskMenu = false;
      this.assigneeMenu = false;
      this.assigneeSubMenu = false;
      this.taskID = "";
    },

    changeSubTaskTitle(item) {
      for (let i = 0; i < this.getTask.subtasks.length; i++) {
        if (this.getTask.subtasks[i].id == item.id) {
          this.getTask.subtasks[i].title = this.newSubTaskTitle;
        }
      }
      for (let j = 0; j < this.getTasks.length; j++) {
        if (this.getTasks[j].id == item.id) {
          this.getTasks[j].title = this.newSubTaskTitle;
        }
      }
      item.title = this.newSubTaskTitle;
      this.editTask(item);
      this.editSubTaskTitle = false;
    },

    closeSubEdit() {
      this.editSubTaskTitle = false;
    },

    assign(update) {
      var id = "";
      if (update == "task") {
        id = this.taskID;
      } else if (update == "subtask") {
        id = this.subTaskID;
      }
      this.assignUser(id);
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].id == id) {
          this.getTasks[i].assigned_to = this.getAssigned;
        }
      }
      for (let j = 0; j < this.getTask.subtasks.length; j++) {
        if (this.getTask.subtasks[j].id == id) {
          this.getTask.subtasks[j].assigned_to = this.getAssigned;
        }
      }
      this.subTaskID = "";
      this.taskID = "";
      this.assigneeSubMenu = false;
      var key = this.getFullTaskKey;
      this.setFullTaskKey((key += 1));
    },

    showCustomHeaders() {
      var activeCount = 0;
      for (let i = 0; i < this.getHeaders.length; i++) {
        if (
          this.except(this.getHeaders[i].value) == true &&
          this.getHeaders[i].show == true
        ) {
          activeCount += 1;
        }
      }
      if (activeCount >= 1) {
        return true;
      } else {
        return false;
      }
    },

    onClickOutside() {
      if (this.newTaskTitle != "") {
        this.editedTask.title = this.newTaskTitle;
        this.editTask(this.editedTask);
      }
      if (this.getTask.id == this.editedTask.id) {
        this.getTask.title = this.editedTask.title;
      }
      this.changeTitle = false;
      this.newTaskTitle = "";
    },

    editTitle(item) {
      this.subTaskID = item.id;
      this.newSubTaskTitle = item.title;
      this.editSubTaskTitle = true;
    },

    assignOpen(item) {
      this.subTaskID = item.id;
      this.selectedUser = item.assigned_to;
    },

    deleteSubTask(taskID) {
      this.deleteTask(taskID);
      for (let i = 0; i < this.getTask.subtasks.length; i++) {
        if (this.getTask.subtasks[i].id == taskID) {
          this.getTask.subtasks[i].trash = true;
        }
      }
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].id == taskID) {
          this.getTasks[i].trash = true;
        }
      }
      this.deleteKey += 1;
    },

    updateDescriptio() {
      this.editTask(this.getTask);
    },

    updateSubtaskStatus(item) {
      if (item.status == "completed") {
        item.status = "open";
      } else {
        item.status = "completed";
      }

      this.editTask(item);
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #f3f3f3;
  border-radius: 0px;
  border-bottom: none;
  height: 100%;
  flex-wrap: nowrap;
}

.subFont {
  font-size: 15px;
}

.fill-width {
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 85.2vh;
}

.hide {
  visibility: hidden !important;
}

.endDateFont {
  font-size: 13px;
  cursor: pointer;
}

.dateFont {
  font-size: 13px;
}

.status:hover {
  color: #4caf50;
}
</style>
