import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    
  },

  getters: {
    
  },

  mutations: {
    
  },

  actions: {
    publicShare(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/link/share',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            item: data.item,
            expiration: data.expiration,
            passwordProtected: data.passwordProtected,
            password: data.password,
            allowDownload: data.allowDownload,
            allowUpload: data.allowUpload,
            message: data.message,
          }
        };
  
        axios(options)
        .then(response => {
          resolve(response);
        }).catch((error) => {
          context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
        });
      })
    },

    updateLink(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/link/update',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            token: data.token,
            expiration: data.expiration,
            passwordProtected: data.passwordProtected,
            password: data.password,
            allowDownload: data.allowDownload,
            allowUpload: data.allowUpload,
            message: data.message,
          }
        };

        axios(options)
        .then(() => {
          resolve();
        }).catch((error) => {
          context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data 
            }, { root: true })
        });
      })
    },

    updatePermissions(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/files/share/update',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            group: data.group,
            id: data.id,
            fileID: data.fileID,
            allowEdit: data.allowEdit,
            allowEditMeta: data.allowEditMeta,
            allowReshare: data.allowReshare,
            expiration: data.expiration
          }
        };

        axios(options)
        .then(() => {
          resolve();
        }).catch((error) => {
          context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data 
            }, { root: true })
        });
      })
    },

    authenticateLink(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/auth/password',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id,
            password: data.password
          }
        };

        axios(options)
        .then(() => {
          resolve();
        }).catch((error) => {
          context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data 
            }, { root: true })
        });
      })
    },
  },

  namespaced: true,
})
