<template>
  <v-container
    fluid
    class="pa-0 fill-height"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({

    }),
  }
</script>