<template>
  <v-container
    class="pt-0"
    fluid
  >
    <v-list dense>
      <v-subheader class="ml-n1">File attachments</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in getTask.files"
          :key="i"
          @dblclick="onDblClick(item)"
          class="px-0"
        >
          <v-list-item-icon>
            <v-icon
              v-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType !== 'image'"
              dark
              :color="fileTypes[item.type].color"
              class="mt-1 ml-2"
            >
              {{ fileTypes[item.type].icon }}
            </v-icon>
            <v-img
              v-else-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType == 'image'"
              max-height="24"
              max-width="24"
              :src="`/api/files/thumbnails/${item.id}`"
              class="mt-1 ml-2"
            >
            </v-img>
            <v-icon
              v-else
              size="24"
              dark
              :color="fileTypes['unknown'].color"
              class="mt-1 ml-2"
            >
              {{ fileTypes['unknown'].icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-dialog
              v-model="deleteDialog"
              persistent
              max-width="320"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Remove file from task?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="deleteDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="red darken-2"
                    dark
                    @click="removeFile(item)"
                  >
                    Remove
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-fade-transition mode="out-in">
      <v-row>
        <v-btn
          small
          text
          class="mx-4 my-3"
          @click="dialog = true"
        >
          + Attach file
        </v-btn>
      </v-row>
    </v-fade-transition>
    <FileSelector
      :dialog="dialog"
      @close="closeFileSelector"
      @accept="attachFile"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FileSelector from "../../Dialogs/fileSelector.vue";
import { actions } from "../../../mixins/browserActions";

export default {
  components: {
    FileSelector,
  },

  data: () => ({
    dialog: false,
    deleteDialog: false,
  }),

  mixins: [actions],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getTask", "getProject"]),

    ...mapGetters("files", ["fileTypes"]),
  },

  methods: {
    closeFileSelector() {
      this.dialog = false;
    },

    attachFile(data) {
      this.$store
        .dispatch("projects/addFile", {
          projectID: this.getProject.id,
          id: this.getTask.id,
          fileID: data.id,
        })
        .then(() => {
          if (this.getTask.files.length <= 0) {
            this.getTask.files = [];
          }
          this.getTask.files.push(data);
          this.$store.commit("projects/setFileActions", true);
        });

      this.dialog = false;
    },

    removeFile(item) {
      this.$store
        .dispatch("projects/removeFile", {
          projectID: this.getProject.id,
          id: this.getTask.id,
          fileID: item.id,
        })
        .then(() => {
          this.getTask.files.splice(this.getTask.files.indexOf(item), 1);
          this.$store.commit("projects/setFileActions", true);
        });
      
      this.deleteDialog = false;
    },
  },
};
</script>

<style scoped>
.customFont {
  font-weight: normal;
  color: rgb(88, 88, 88);
  font-size: 14px;
}
</style>