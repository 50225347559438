<template>
  <v-container fluid>
    <v-row>
      <v-toolbar
        color="elevation-0"
        dense
        class="my-1"
      >
        <ProjectMenu />
        <div class="d-flex mt-0 ml-2 projectFont">{{ getProjectFolder.title }}</div>
        <v-spacer />
      </v-toolbar>
    </v-row>

    <v-row
      class="px-1 pt-5 fill-height"
      :align="getProjectFolder.projects.length <= 0 ? 'center' : 'start'"
    >
      <v-col>
        <v-row
          v-if="getProjectFolder.projects.length <= 0"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-center mb-5 child-elements">
            <v-list-item-avatar
              size="200"
              class="child-elements"
            >
              <v-icon
                size="100"
                dark
                color="white"
                class="grey lighten-2"
              >
                mdi-folder-upload-outline
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-medium noselect">This project folder is empty</h1>
            <div
              noselect
              class="subtitle-1 grey--text mb-5 noselect"
            >Use button New to create your first project</div>
          </div>
        </v-row>
        <v-data-table
          v-if="getProjectFolder.projects.length > 0 && !isMobile"
          :headers="headers"
          :items="getProjectFolder.projects"
          class="elevation-0"
          @click:row="openProject"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.completed="{ item }">
            <!-- v-model -->
            <v-progress-linear
              :value="(item.status.completed/item.status.count)*100"
              height="15"
              rounded
            >
              <strong>{{item.status.completed}} of {{item.status.count}}</strong>
            </v-progress-linear>
          </template>
          <template v-slot:item.end_date="{ item }">
            {{ getDate(item.end_date) }}
          </template>
          <template v-slot:item.start_date="{ item }">
            {{ getDate(item.start_date) }}
          </template>
          <template v-slot:item.created="{ item }">
            {{ getDate(item.created) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click.stop="editProject(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click.stop="openDeleteProject(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
        <div v-if="isMobile && getProjectFolder.projects.length > 0">
          <v-list>
            <v-subheader>Project name</v-subheader>
            <v-list-item-group
              :color="getAppearances.btn_color"
              link
            >
              <div
                v-for="(item, i) in getProjectFolder.projects"
                :key="i"
              >
                <v-list-item @click="openProject(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row class="ma-0 pa-0">
                      <v-icon
                        small
                        class="mr-2"
                        @click.stop="editProject(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click.stop="openDeleteProject(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </div>
            </v-list-item-group>
          </v-list>
        </div>
        <v-dialog
          v-model="deleteDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{projectToDelete.title}}
            </v-card-title>
            <v-card-text>Are you sure you want to delete this project?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="deleteDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="red"
                text
                @click="deleteProject(projectToDelete)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ProjectDialog
          :projectDialog="projectDialog"
          :edit="edit"
          :project="projectToEdit"
          @close="closeProjectDialog"
          @update="updateProject"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectMenu from "../../components/Projects/ProjectMenu.vue";
import ProjectDialog from "../../components/Projects/Dialogs/newProjectDialog.vue";
import { dateUtils } from "../../mixins/date";

export default {
  components: {
    ProjectMenu,
    ProjectDialog,
  },

  data: () => ({
    projectDialog: false,
    edit: false,
    projectToEdit: {},
    deleteDialog: false,
    projectToDelete: {},
    headers: [
      {
        text: "Project name",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Start Date", value: "start_date" },
      { text: "End date", value: "end_date" },
      { text: "Completed", value: "completed" },
      { text: "Created", value: "created" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isMobile: false,
    valid: true,
  }),

  mixins: [dateUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getProjectFolder"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {},

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    getDate(tstamp) {
      return this.getDateString(tstamp);
    },

    openProject(item) {
      this.$router.push({ name: "project", params: { id: item.id } });
    },

    editProject(item) {
      this.projectToEdit = item;
      this.edit = true;
      this.projectDialog = true;
    },

    updateProject(data) {
      this.projectToEdit.title = data.title;
      this.projectToEdit.description = data.description;
      this.projectToEdit.end_date = data.endDate;
      this.projectToEdit.start_date = data.startDate;
    },

    closeProjectDialog() {
      this.projectDialog = false;
      this.projectToEdit = {};
      this.edit = false;
    },

    saveProject(item) {
      this.$store
        .dispatch("projects/patchProject", {
          id: this.editItem.id,
          title: this.editItem.title,
          description: this.editItem.description,
          properties: this.editItem.properties,
          startDate: this.editItem.start_date,
          endDate: this.editItem.end_date,
        })
        .then(() => {
          Object.assign(item, this.editItem);
        });

      this.editDialog = false;
    },

    openDeleteProject(item) {
      this.projectToDelete = item;
      this.deleteDialog = true;
    },

    deleteProject(item) {
      this.$store.dispatch("projects/deleteProject", item.id).then(() => {
        this.getProjectFolder.projects.splice(
          this.getProjectFolder.projects.indexOf(item),
          1
        );
      });

      this.deleteDialog = false;
      this.projectToDelete = {};
    },
  },
};
</script>
