import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    
  },

  getters: {
    
  },

  mutations: {
    
  },

  actions: {
    getGroups(context) {
      return new Promise((resolve) => {
        const options = {
            url: '/api/users/get-groups',
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
          };
  
          axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
