<template>
  <div v-click-outside="onClickOutside">
    <v-row class="pb-3 pt-3 px-3">
      <v-text-field
        dense
        v-model="taskTitle"
        @keydown.enter="onClickOutside"
        placeholder="Title"
        hide-details
      ></v-text-field>
      <v-menu
        :close-on-click="false"
        v-model="assigneeMenu"
        :close-on-content-click="false"
        offset-y
        :key="refreshAssignee"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="assignSet == false"
            @click="assigneeMenu = true, validateAssignee = true"
            icon
            v-bind="attrs"
            v-on="on"
            small
            class="mt-1 mx-1"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-avatar
            @click="assigneeMenu = true, validateAssignee = true"
            v-else
            color="primary"
            v-bind="attrs"
            v-on="on"
            size="20"
            class="mt-2 mx-1"
          >
            <v-img :src="`/api/users/photo/${assignedUser(assigned.assigned_to)}`">
            </v-img>
          </v-avatar>
        </template>
        <MemberSelect
          @close="assigneeMenu = false, assigneeClickOutside()"
          @save="saveMemberSelected()"
        />
      </v-menu>
      <v-menu
        :close-on-click="false"
        v-model="datePickerMenu"
        :close-on-content-click="false"
        offset-y
        :key="refreshDate"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-if="dateSet == false">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="validateDate = true"
              class="mt-1 ml-1"
            >
              <v-icon>mdi-calendar-plus</v-icon>
            </v-btn>
          </div>
          <div
            v-else
            align="left"
            class="mt-2 ml-1 text-capitalize font-weight-regular endDateFont"
            v-bind="attrs"
            v-on="on"
            @click="validateDate = true"
          >
            {{ endDate(date) }}
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          @click="validateDate = true"
        >
          <v-spacer />
          <v-btn
            text
            @click="dateClickOutside()"
            class="mr-1 mb-3"
          >
            Cancel
          </v-btn>
          <v-btn
            dark
            :color="getAppearances.btn_color"
            @click="dateSet = true, refreshDate += 1, dateClickOutside()"
            class="mb-3 mr-3"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import MemberSelect from "../Menu/MemberSelect";
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  components: {
    MemberSelect,
  },
  name: "NewSubtask",
  props: {
    createSubtask: Boolean,
  },

  data: () => ({
    taskTitle: "",
    datePickerMenu: false,
    dateSet: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    refreshDate: 0,
    newDueDate: "",
    assigneeMenu: false,
    refreshAssignee: 9999,
    assignSet: false,
    assigned: {},
    validateAssignee: false,
    validateDate: false,
  }),

  computed: {
    ...mapGetters("projects", ["getAssigned", "getTask"]),

    ...mapGetters("user", ["getTimezone"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [projectUtils],

  methods: {
    endDate(date) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);
      var formatedDate = timezoneDate.utc().format("X");
      this.newDueDate = formatedDate;

      return this.dateFormat(formatedDate);
    },

    saveMemberSelected() {
      this.assigneeMenu = false;
      this.assigned.assigned_to = this.getAssigned;
      this.assignSet = true;
      this.refreshAssignee += 1;
      this.assigneeClickOutside();
    },

    assigneeClickOutside() {
      setTimeout(() => {
        this.validateAssignee = false;
      }, 1000);
    },

    dateClickOutside() {
      this.datePickerMenu = false;
      setTimeout(() => {
        this.validateDate = false;
      }, 1000);
    },

    onClickOutside() {
      if (
        this.taskTitle != "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        var task = {
          title: this.taskTitle,
          date: this.newDueDate,
          type: "subtask",
          parentID: this.getTask.id,
        };
        this.createTask(task);
        this.newDueDate = "";
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.$emit("update:createSubtask", false);
      } else if (
        this.taskTitle == "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        this.$emit("update:createSubtask", false);
      }
    },
  },
};
</script>

<style scoped>
.taskBorder {
  border: 1px solid rgb(245, 245, 245);
}

.taskBorder:hover {
  border: 1px solid #e2e2e2;
}

.endDateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>