<template>
  <div
    style="width: 136px; min-width: 136px;"
  >
    <div
      align="left"
      class="mb-2 mt-3 text-capitalize font-weight-regular endDateFont"
    >
      <CustomFieldItems
        :selectedTask="item"
        :header="header"
        :placement="'list'"
      />
    </div>
  </div>
</template>

<script>
import CustomFieldItems from '../../Projects/CustomFields/CustomFieldItems.vue'
  export default {
    components: {
      CustomFieldItems
    },
    props: {
      item: Object,
      header: Object,
    }
  }
</script>

<style scoped>
.endDateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>