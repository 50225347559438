<template>
  <v-menu
    v-model="notificationsMenu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-badge
          color="red"
          :content="unreadMessages"
          overlap
          offset-y=17
          :value="unreadMessages > 0 ? true : false"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card
      :height="messages.length < 1 ? 300 : ''"
      :width="messages.length < 1 ? 500 : ''"
    >
      <div
        class="pa-9 fill-height"
        align="center"
        justify="center"
        v-if="messages.length < 1"
      >
        <v-row
          align="center"
          justify="center"
          class="fill-height"
        >
          <div
            align="center"
            justify="center"
          >
            <v-icon size="48">
              mdi-bell
            </v-icon>
            <div class="text-h5 font-weight-regular grey--text pt-2">No notifications</div>
          </div>
        </v-row>
      </div>
      <div v-else>
        <v-card-text>
          <div>Notifications</div>
        </v-card-text>
        <v-list
          two-line
          class="py-0"
        >
          <v-virtual-scroll
            :bench=10
            :items="messages"
            min-height="80"
            item-height="80"
            :min-width="$vuetify.breakpoint.smAndDown ? 300 : 500"
            max-height="300"
          >
            <template v-slot:default="{ item, index }">
              <NotificationItem
                :message="item"
                :user="getUserById(item.sender)"
                :key="item.id"
                @read="readNotification"
                @delete="deleteNotification"
              />
              <v-divider :key="'A'+ index">
                ></v-divider>
            </template>
          </v-virtual-scroll>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="getAppearances.btn_color"
            text
            @click="markAllAsRead"
          >
            mark all as read
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../mixins/date";
import NotificationItem from "./Notifications/NotificationItem.vue";

export default {
  components: {
    NotificationItem,
  },

  props: { messages: Array },

  data: function () {
    return {
      notificationsMenu: false,
    };
  },

  mixins: [dateUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUserById"]),

    unreadMessages() {
      let unread = 0;
      for (let i = 0; i < this.messages.length; i++) {
        if (!this.messages[i].is_read) {
          unread += 1;
        }
      }

      return unread;
    },
  },

  methods: {
    readNotification(message) {
      this.$store
        .dispatch("notifications/readNotification", {
          read: true,
          id: [message.id],
        })
        .then(() => {
          message.is_read = true;
        });
    },

    markAllAsRead() {
      this.$store
        .dispatch("notifications/readNotification", {
          read: true,
          id: this.messages.map((e) => e.id),
        })
        .then(() => {
          for (let i = 0; i < this.messages.length; i++) {
            if (!this.messages[i].is_read) {
              this.messages[i].is_read = true;
            }
          }
        });
    },

    deleteNotification(message) {
      this.$store
        .dispatch("notifications/deleteNotification", message.id)
        .then(() => {
          this.messages.splice(this.messages.indexOf(message), 1);
        });
    },
  },
};
</script>
