<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="my-3" depressed rounded text v-bind="attrs" v-on="on">
          About
        </v-btn>
      </template>
      <v-card class="pb-5">
        <v-card-title class="text-h5">
          About Cospace <v-spacer></v-spacer>
          <v-btn text fab x-small @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="py-5"></v-divider>
        <v-card-text>
          <v-row justify="center">
            <v-img
              lazy-src="/static/logo.png"
              max-height="128"
              max-width="128"
              src="/static/logo.png"
            ></v-img>
          </v-row>
          <v-row justify="center">
            <div>Version: {{ getLicense.build_number }}</div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters("license", ["getLicense"]),
  },
};
</script>
