import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export const projectUtils = {

  data: () => ({
    taskStatusList: [
      { color: "green", name: "Completed", value: "completed" },
      { color: "red", name: "Closed", value: "closed" },
      { color: "red", name: "Overdue", value: "overdue" },
      { color: "green", name: "Open", value: "open" },
      { color: "green", name: "In Progress", value: "in progress" },
      { color: "orange", name: "On hold", value: "on hold" },
    ],
  }),

  computed: {
    ...mapGetters('projects', [
      'getColumns',
      'getProject',
      'getHeaders',
      'getAssigned',
      'getRefreshDate',
      'getTasks',
      'getTask',
      'getProjectMembers',
      'getStatusKey',
      'getFullTaskKey',
      'getMetaList',
      'getSelectedMeta',
      'getFilterData',
      'getActiveUsers',
      'getItemsToSelect',
      'getSelectedStatusFilter'
    ]),

    ...mapGetters('user', [
      'getTimezone',
      'getUsers',
      'getUser'
    ]),

  },

  methods: {
    ...mapMutations('projects', [
      'setIsAssigned',
      'setDate',
      'setTaskDeleted',
      'setTaskEdited',
      'setRefreshDate',
      'setAssigned',
      'setTaskCreated',
      'setStatusKey',
      'setTask',
      'setTaskNavigation',
      'setFullTaskKey',
      'setCustomFields',
      'setFieldUpdate'
    ]),

    onEnd(update) {
      var project = JSON.parse(JSON.stringify(this.getColumns));
      for (let i = 0; i < project.length; i++) {
        for (let j = 0; j < project[i].tasks.length; j++) {
          var tasks = { id: project[i].tasks[j].id }
          project[i].tasks[j] = tasks
        }
      }

      var title = ''
      if (update == "title") {
        title = this.getProject.title
      } else {
        title = undefined
      }

      var properties = [project, this.getHeaders]
      var data = {
        id: this.getProject.id,
        title: title,
        description: undefined,
        properties: JSON.stringify(properties),
        startDate: undefined,
        endDate: undefined
      }
      this.$store
        .dispatch('projects/patchProject', data)
    },

    assignUser(taskID) {
      var data = {
        projectID: this.getProject.id,
        id: taskID,
        userID: this.getAssigned,
      }

      this.$store
        .dispatch('projects/assignTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == taskID) {
                this.getColumns[i].tasks[j].assigned_to = this.getAssigned;
              }
            }
          }
          this.setAssigned('');
          this.setIsAssigned(true);
        });
    },

    removeMemberFromTask(taskID) {
      var data = {
        projectID: this.getProject.id,
        id: taskID,
      }

      this.$store
        .dispatch('projects/removeMemberFromTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == taskID) {
                this.getColumns[i].tasks[j].assigned_to = '';
              }
            }
          }
          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == taskID) {
              this.getTasks[i].assigned_to = '';
            }
          }
          if (this.getTask.subtasks != undefined) {
            for (let i = 0; i < this.getTask.subtasks.length; i++) {
              if (this.getTask.subtasks[i].id == taskID) {
                this.getTask.subtasks[i].assigned_to = '';
              }
            }
          }
          this.setAssigned('');
          this.setIsAssigned(true);
        });
    },

    async newDate(item, period, date, clear) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);

      if (period == 'start') {
        if (clear) {
          item.start_date = ''
        } else {
          item.start_date = timezoneDate.utc().format('X')
        }
      } else {
        if (clear) {
          item.end_date = ''
        } else {
          item.end_date = timezoneDate.utc().format('X')
        }
      }

      var data = {
        id: item.id,
        type: item.type,
        parent_id: item.parent_id,
        projectID: this.getProject.id,
        title: item.title,
        description: item.description,
        startDate: item.start_date,
        endDate: item.end_date,
      }

      this.$store
        .dispatch('projects/patchTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == item.id) {
                this.getColumns[i].tasks[j] = item;
              }
            }
          }
          this.setDate(true)
        });
    },

    deleteTask(taskID) {
      var data = {
        taskID: taskID,
        projectID: this.getProject.id
      }
      this.$store
        .dispatch('projects/trashTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (taskID == this.getColumns[i].tasks[j].id) {
                this.getColumns[i].tasks[j].trash = true;
              }
            }
          }
          this.setTaskDeleted(true);
        });
    },

    editTask(item) {
      var data = {
        id: item.id,
        type: item.type,
        parent_id: item.parent_id,
        projectID: this.getProject.id,
        title: item.title,
        description: item.description,
        startDate: item.start_date,
        endDate: item.end_date,
        status: item.status
      }
      this.$store
        .dispatch('projects/patchTask', data)
        .then(() => {
          if (item.type == 'task') {
            for (let i = 0; i < this.getColumns.length; i++) {
              for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
                if (this.getColumns[i].tasks[j].id == item.id) {
                  this.getColumns[i].tasks[j].title = item.title
                  this.getColumns[i].tasks[j].status = item.status;
                  var key = this.getStatusKey;
                  this.setStatusKey(key += 1);
                  this.getColumns[i].tasks[j].description = item.description;
                }
              }
            }
          }
          this.setTaskEdited(true);
        });
    },

    getSubTasks(item) {
      item.subtasks = []
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].parent_id == item.id) {
          item.subtasks.push(this.getTasks[i])
        }
      }
      return item.subtasks
    },

    fullTaskInfo(item) {
      this.getSubTasks(item)
      this.setTask(item);
      this.setCustomFields(false);
      this.setTaskNavigation(true);
      var key = this.getFullTaskKey
      this.setFullTaskKey(key += 1)
    },

    createTask(task) {
      var data = {
        type: task.type,
        parent_id: task.parentID,
        projectID: this.getProject.id,
        title: task.title,
        description: '',
        assigned_to: this.getAssigned,
        depends: '',
        startDate: '',
        endDate: task.date,
      }
      this.$store
        .dispatch('projects/createTask', data)
        .then((response) => {
          var newTask = response.data
          newTask.created_by = this.getUser.id
          for (let i = 0; i < this.getMetaList.length; i++) {
            newTask[this.getMetaList[i].id.toString()] = ""
          }
          if (task.type == 'task') {
            for (let i = 0; i < this.getColumns.length; i++) {
              if (this.getColumns[i].index == task.index) {
                if (task.position == 'bottom') {
                  this.getColumns[i].tasks.push(newTask)
                } else if (task.position == 'top') {
                  this.getColumns[i].tasks.unshift(newTask)
                }
              }
            }
            this.getTasks.push(newTask);
          } else if (task.type == 'subtask') {
            if (this.getTask.subtasks == undefined) {
              this.getTask.subtasks = []
            }
            this.getTask.subtasks.push(newTask);
            this.getTasks.push(newTask);
          }
          this.setTaskCreated(true);
          this.setAssigned('');
        });
    },

    dateFormat(item) {
      // var newFormat = moment(item * 1000).tz(this.getTimezone).format('D MMM, YYYY, h:mm a')
      var newFormat = moment(item * 1000).tz(this.getTimezone).format('D MMM, YYYY')
      return newFormat
    },

    assignedUser(item) {
      var photo = ''
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item) {
          photo = this.getProjectMembers[i].photo
        }
      }
      return photo
    },

    customFields(id) {
      var field = {}
      for (let i = 0; i < this.getMetaList.length; i++) {
        if (this.getMetaList[i].id == id) {
          field = this.getMetaList[i]
        }
      }
      return field
    },

    priorityColor(item) {
      if (item == "Low") {
        return "green darken-2";
      } else if (item == "Medium") {
        return "orange accent-2";
      } else {
        return "red";
      }
    },

    customLables(name, value) {
      var color = "";
      if (this.customFields(value).type == "Labels") {
        for (let i = 0; i < this.customFields(value).fields.length; i++) {
          if (this.customFields(value).fields[i].name == name) {
            color = this.customFields(value).fields[i].color;
          }
        }
      }
      return color;
    },

    except(item) {
      var custom = true;
      var list = [
        'start_date',
        'end_date',
        'assigned_to',
        'updated',
        'created',
        'title',
        'status'
      ];
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          custom = false
        }
      }
      if (custom) {
        return true
      } else {
        return false
      }
    },

    saveFields(task, value) {
      if (this.editing != undefined && this.editing != '') {
        var createNew = true
        for (let i = 0; i < this.getSelectedMeta.length; i++) {
          if (this.getSelectedMeta[i].item_id == this.editing && this.getSelectedMeta[i].metadata_id == value) {
            this.updateCustomField(task, value, this.getSelectedMeta[i].id)
            createNew = false
          }
        }
        if (createNew) {
          this.addCustomField(task, value)
        }
      }
    },

    showFileIcon(item) {
      if (item.trash == false && item.files.length > 0) {
        return true;
      }
      return false;
    },

    addCustomField(task, value) {
      this.$store.dispatch('meta/addMeta', {
        id: task.id,
        metaid: value,
        value: '"' + task[value] + '"',
        app: 'Projects',
        projectID: this.getProject.id,
      }).then((response) => {
        if (response.data.id != undefined) {
          this.getSelectedMeta.push(response.data)
          this.setFieldUpdate(true)
        }
      })
    },

    filter(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    updateCustomField(task, value, id) {
      this.$store.dispatch('meta/updateMeta', {
        id: id,
        value: task[value],
        projectID: this.getProject.id,
        app: 'Projects'
      }).then((response) => {
        if (response.data == 'success') {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (task.id == this.getColumns[i].tasks[j].id) {
                this.getColumns[i].tasks[j][value] = task[value]
              }
            }
          }
          // For fullView
          if (task.id == this.getTask.id) {
            this.getTask[value] = task[value]
          }
          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == task.id) {
              this.getTasks[i][value] = task[value]
            }
          }
          this.setFieldUpdate(true);
        }
      })
    },

    statusFilter(item) {
      if (this.getSelectedStatusFilter == 'All tasks') {
        return true
      } else if (this.getSelectedStatusFilter == 'Incomplete tasks') {
        if (item.status != 'completed' && item.status != 'closed') {
          return true
        }
      } else if (this.getSelectedStatusFilter == 'Completed tasks') {
        if (item.status == 'completed') {
          return true
        }
      }
    },

    taskFilter(item) {
      var conditions = [];
      var dateList = ['created', 'end_date', 'start_date', 'updated']
      if (this.getFilterData.filterData == undefined) {
        return true
      } else {
        var data = this.getFilterData.filterData;
        var show = {};
        for (let i = 0; i < data.length; i++) {
          var inList = false
          for (let j = 0; j < dateList.length; j++) {
            if (data[i].filterType == dateList[j]) {
              inList = true
            }
          }
          if (item.trash == false) {
            if (data[i].actionType == 'Is' && item[data[i].filterType] != '') {
              if (this.customFields(data[i].filterType).type == 'Date' || inList == true) {
                if (data[i].datePeriod.action == 'stage') {
                  if (item[data[i].filterType] >= data[i].datePeriod.start
                    && item[data[i].filterType] <= data[i].datePeriod.end) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                } else if (data[i].datePeriod.action == 'greater') {
                  if (item[data[i].filterType] < data[i].datePeriod.date) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                } else if (data[i].datePeriod.action == 'less') {
                  if (item[data[i].filterType] > data[i].datePeriod.date) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                }
              } else if (this.customFields(data[i].filterType).type == 'Labels') {
                item[data[i].filterType].forEach((value) => {
                  if (value == data[i].selectedItem) {
                    show[data[i].selectedItem] = 'show';
                  }
                });
                if (show[data[i].selectedItem] == 'show') {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              } else {
                if (item[data[i].filterType] == data[i].selectedItem) {
                  conditions.push(true)
                } else {
                  conditions.push(false)
                }
              }
            } else if (data[i].actionType == 'Is not') {
              if (this.customFields(data[i].filterType).type == 'Date' || inList == true) {
                if (data[i].datePeriod.action == 'stage') {
                  if (item[data[i].filterType] >= data[i].datePeriod.start
                    && item[data[i].filterType] <= data[i].datePeriod.end) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                } else if (data[i].datePeriod.action == 'greater') {
                  if (item[data[i].filterType] < data[i].datePeriod.date) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                } else if (data[i].datePeriod.action == 'less') {
                  if (item[data[i].filterType] > data[i].datePeriod.date) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                }
              } else if (this.customFields(data[i].filterType).type == 'Labels') {
                item[data[i].filterType].forEach((value) => {
                  if (value == data[i].selectedItem) {
                    show[data[i].selectedItem] = 'show';
                  }
                });
                if (show[data[i].selectedItem] == 'show') {
                  conditions.push(false);
                } else {
                  conditions.push(true);
                }
              } else {
                data.forEach((value) => {
                  if (value.selectedItem == item[data[i].filterType] && value.filterType == data[i].filterType) {
                    show.assignee = 'show';
                  }
                });
                if (show.assignee != 'show') {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              }
            } else if (data[i].actionType == 'Is set') {
              if (item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Is not set') {
              if (item[data[i].filterType] == '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Equals') {
              if (parseInt(item[data[i].filterType]) == parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Not equal to') {
              if (parseInt(item[data[i].filterType]) != parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Greater than') {
              if (parseInt(item[data[i].filterType]) > parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Less than') {
              if (parseInt(data[i].selectedItem) > parseInt(item[data[i].filterType]) && item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Greater than or equal to') {
              if (parseInt(item[data[i].filterType]) >= parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Less than or equal to') {
              if (parseInt(item[data[i].filterType]) <= parseInt(data[i].selectedItem) && item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Contains') {
              if (item[data[i].filterType].toLowerCase().includes(data[i].selectedItem.toLowerCase())) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Does not contain') {
              if (item[data[i].filterType].toLowerCase().includes(data[i].selectedItem.toLowerCase())) {
                conditions.push(false);
              } else {
                conditions.push(true);
              }
            } else {
              conditions.push(false);
            }
          } else {
            conditions.push(false);
          }
        }

        if (this.getFilterData.operator == 'AND') {
          let checkValues = arr => arr.every(v => v === true);
          if (checkValues(conditions)) {
            return true
          }
        }

        if (this.getFilterData.operator == 'OR') {
          let checkValues = arr => arr.some(v => v === true);
          if (checkValues(conditions)) {
            return true
          }
        }
      }
    },

    haveSubTask(item) {
      var subtasks = [];
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].parent_id == item.id && this.getTasks[i].trash == false) {
          subtasks.push(this.getTasks[i])
        }
      }
      return subtasks.length
    },

    markCompleted(item) {
      if (item.status == "completed") {
        item.status = "open";
      } else {
        item.status = "completed";
      }

      this.editTask(item);
    },

    removeUser(item) {
      this.$store
        .dispatch("projects/removeMember", {
          project_id: this.getProject.id,
          member: item.id,
        })
        .then(() => {
          var member = [{ action: "removeUser", user: item }];
          this.$store.commit("projects/setMemberData", member);
          this.$store.commit("projects/setMemberDataUpdate", true);
        });
    },

    addMemberToProject() {
      this.$store.commit("projects/setActiveUsers", []);
      this.$store.commit("projects/setItemsToSelect", []);

      let members = this.getProject.members;
      for (let i = members.length - 1; i >= 0; i--) {
        for (let j = 0; j < this.getUsers.length; j++) {
          if (this.getUsers[j].id == members[i].user_id) {
            this.getUsers[j].project_role = members[i].role;
            this.getActiveUsers.push(this.getUsers[j]);
          }
        }
      }
      for (let i = 0; i < this.getUsers.length; i++) {
        if (!this.containsActiveUser(this.getUsers[i], this.getActiveUsers) && this.getUsers[i].deactivated_at == 0) {
          this.getItemsToSelect.push(this.getUsers[i]);
        }
      }

      this.addMember = true;
    },

    containsActiveUser(item, list) {
      for (let i = 0; i < list.length; i++) {
        if (item.id == list[i].id) {
          return true;
        }
      }

      return false;
    },

    userStatus(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          if (this.getProjectMembers[i].deactivated_at == 0) {
            return false;
          } else {
            return true;
          }
        } else if (item.assigned_to == "") {
          return false;
        }
      }
    },
  }
}
