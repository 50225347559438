<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    offset-y
    min-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="$route.name != 'files'"
        class="mr-2"
        rounded
        :color="getAppearances.btn_color"
        :dark="$route.name == 'files'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="$refs.file.click()">
        <v-list-item-avatar>
          <v-icon>
            mdi-upload
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>File upload</v-list-item-title>
      </v-list-item>
      <input
        type="file"
        ref="file"
        style="display: none"
        @change="onFileSelected"
        multiple
      >
      <v-list-item
        v-for="item in documents"
        :key="item.extension"
        @click="toggleDialog(item)"
      >
        <v-list-item-avatar>
          <v-icon :color="item.color">
            {{ item.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="toggleFolderDialog">
        <v-list-item-avatar>
          <v-icon color="primary">
            mdi-folder
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Folder</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { upload } from "../mixins/upload";

export default {
  data: () => ({
    documents: [
      {
        icon: "mdi-file-word",
        title: "Document",
        color: "primary",
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        extension: "docx",
      },
      {
        icon: "mdi-file-excel",
        title: "Spreadsheet",
        color: "green",
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        extension: "xlsx",
      },
      {
        icon: "mdi-file-powerpoint",
        title: "Presentation",
        color: "orange",
        type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        extension: "pptx",
      },
      {
        icon: "mdi-file-document",
        title: "Text file",
        type: "text/plain",
        extension: "txt",
      },
    ],
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [upload],

  methods: {
    ...mapMutations("fileDialogs", ["openFolderDialog", "openDialog"]),

    toggleFolderDialog() {
      this.$store.commit("fileDialogs/openFolderDialog", true);
    },

    toggleDialog(item) {
      this.$store.dispatch("fileDialogs/openFileDialog", {
        document: item,
        open: true,
      });
    },
  },
};
</script>
