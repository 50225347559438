<template>
  <div>
    <div v-if="customFields(header.value).type == 'Labels'">
      <Labels
        :selectedTask="selectedTask"
        :header="header"
        :placement="placement"
      />
    </div>
    <div v-else-if="customFields(header.value).type == 'Dropdown'">
      <v-select
        @input="onFieldInput(selectedTask,header.value)"
        v-model="selectedTask[header.value]"
        :items="customFields(header.value).fields"
        hide-details
        @click.stop="editing = selectedTask.id"
        :menu-props="{closeOnClick: true, closeOnContentClick: true}"
        append-icon=""
        dense
        class="mt-n3 mb-n2"
        style="font-size: 13px;"
        flat
        solo
      >
        <template #prepend-inner>
          <v-btn
            v-if="selectedTask[header.value] == '' || selectedTask[header.value] == undefined"
            icon
            x-small
            class="ml-n1"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <template v-slot:append-item>
          <v-icon
            class="ml-3 mt-2"
            @click="clearDropdown(selectedTask, header.value)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-select>
    </div>
    <div v-else-if="customFields(header.value).type == 'Text'">
      <v-text-field
        v-click-outside="clickOutsideText"
        @change="onFieldInput(selectedTask,header.value)"
        v-if="selectedTask[header.value] != '' && selectedTask[header.value] != undefined || editText == true"
        v-model="selectedTask[header.value]"
        hide-details
        dense
        class="px-0 mx-0 mt-n3 mb-n2"
        style="font-size: 13px;"
        :menu-props="{value: editText}"
        @click.stop="editText = true, editing = selectedTask.id"
        flat
        solo
      >
      </v-text-field>
      <div v-else>
        <v-btn
          icon
          x-small
          class="mx-2 mt-n1"
          @click.stop="editText = true, editing = selectedTask.id"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else-if="customFields(header.value).type == 'Currency'">
      <v-text-field
        v-click-outside="clickOutsideCurrency"
        @change="onFieldInput(selectedTask,header.value)"
        v-if="selectedTask[header.value] != '' && selectedTask[header.value] != undefined || editCurrency == true"
        v-model="selectedTask[header.value]"
        hide-details
        dense
        @keypress="filter"
        class="px-0 mx-0 mt-n3 mb-n2"
        style="font-size: 13px;"
        :menu-props="{value: editCurrency}"
        @click.stop="editCurrency = true, editing = selectedTask.id"
        flat
        solo
      >
        <template #prepend>
          <v-icon
            size="14"
            class="mb-n6 mr-n3"
            right
          >
            mdi-currency-eur
          </v-icon>
        </template>
      </v-text-field>
      <div v-else>
        <v-btn
          icon
          x-small
          class="mx-2 mt-n1"
          @click.stop="editCurrency = true, editing = selectedTask.id"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else-if="customFields(header.value).type == 'Checkbox'">
      <v-checkbox
        @click.stop="saveCheckbox(selectedTask, header.value)"
        v-model="selectedTask[header.value]"
        style="padding-top: 5px;"
        false-value="false"
        true-value="true"
        hide-details
        dense
        class="mx-2 mt-n2"
        :ripple="false"
      ></v-checkbox>
    </div>
    <div v-else-if="customFields(header.value).type == 'Date' && placement == 'fullTask'">
      <v-menu
        :close-on-click="false"
        v-model="showDateMenu"
        :close-on-content-click="false"
        offset-y
        :key="getRefreshCustomDate"
        :content-class="selectedTask.id == editing ? '' : 'elevation-0'"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="selectedTask[header.value] != '' && selectedTask[header.value] != undefined"
            align="left"
            class="mx-3 text-capitalize font-weight-regular"
            style="font-size: 13px;"
            @click.stop="editing = selectedTask.id, showDateMenu = true"
            v-bind="attrs"
            v-on="on"
          >
            {{ dateFormat(selectedTask[header.value]) }}
          </div>
          <div v-else>
            <v-btn
              @click.stop="editing = selectedTask.id, showDateMenu = true"
              v-bind="attrs"
              v-on="on"
              icon
              small
              class="mx-2 mt-n1"
            >
              <v-icon>mdi-calendar-plus</v-icon>
            </v-btn>
          </div>
        </template>
        <v-date-picker
          v-if="selectedTask.id == editing"
          v-model="date"
          no-title
        >
          <v-spacer />
          <v-btn
            text
            @click="close()"
            class="mr-1 mb-3"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            @click="saveFieldDate(selectedTask, header.value)"
            dark
            class="mb-3 mr-3"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <div v-else-if="customFields(header.value).type == 'Priority'">
      <v-select
        @input="onFieldInput(selectedTask,header.value), editPriority = false"
        v-model="selectedTask[header.value]"
        :items="customFields(header.value).fields"
        hide-details
        @click.stop="editPriority = true, editing = selectedTask.id"
        :menu-props="{closeOnClick: true, closeOnContentClick: true}"
        append-icon=""
        dense
        class="mt-n3 mb-n2"
        style="font-size: 13px;"
        flat
        solo
      >
        <template #prepend-inner>
          <v-btn
            v-if="selectedTask[header.value] == '' || selectedTask[header.value] == undefined"
            icon
            x-small
            class="ml-n1"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <template #selection="{ item }">
          <v-btn
            x-small
            :color="priorityColor(item)"
            class="mb-n1"
            dark
          >
            {{item}}
          </v-btn>
        </template>
        <template v-slot:append-item>
          <v-icon
            class="ml-3 mt-2"
            @click="clearDropdown(selectedTask, header.value)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-select>
    </div>
    <div v-if="customFields(header.value).type == 'Number'">
      <v-text-field
        v-click-outside="clickOutsideNumber"
        @change="onFieldInput(selectedTask,header.value)"
        v-if="selectedTask[header.value] != '' && selectedTask[header.value] != undefined || editNumber == true"
        v-model.number="selectedTask[header.value]"
        type="number"
        @keypress="filter"
        hide-details
        style="font-size: 13px;"
        dense
        class="px-0 mx-0 mt-n3 mb-n2"
        :menu-props="{value: editNumber}"
        @click.stop="editNumber = true, editing = selectedTask.id"
        flat
        solo
      >
      </v-text-field>
      <div v-else>
        <v-btn
          icon
          x-small
          class="mx-2 mt-n1"
          @click.stop="editNumber = true, editing = selectedTask.id"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";
import Labels from "../../Projects/CustomFields/Labels.vue";

export default {
  components: {
    Labels,
  },
  props: {
    header: Object,
    selectedTask: Object,
    placement: String,
  },
  data: () => ({
    editing: "",
    editText: false,
    editCurrency: false,
    showDateMenu: false,
    editPriority: false,
    editNumber: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),

  computed: {
    ...mapGetters("projects", ["getRefreshCustomDate"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [projectUtils],

  methods: {
    clickOutsideCurrency() {
      this.editCurrency = false;
    },

    clickOutsideText() {
      this.editText = false;
    },

    clickOutsideNumber() {
      this.editNumber = false;
    },

    clearDropdown(task, value) {
      this.editing = task.id;
      task[value] = "";
      this.onFieldInput(task, value);
    },

    onFieldInput(task, value) {
      if (this.editing == this.selectedTask.id) {
        if (task[value] != "" && task[value] != undefined) {
          this.saveFields(task, value);
        } else {
          if (
            this.customFields(value).type != "Labels" ||
            this.customFields(value).type != "Dropdown"
          ) {
            task[value] = "";
            this.saveFields(task, value);
          }
          this.close();
        }
      }
      this.editing = "";
    },

    saveCheckbox(task, value) {
      this.editing = task.id;
      this.saveFields(task, value);
    },

    saveFieldDate(task, value) {
      var item = moment(this.date).format("X");
      task[value] = item;
      this.close();
      this.saveFields(task, value);
    },

    close() {
      this.editText = false;
      this.editCurrency = false;
      this.showDateMenu = false;
      this.editPriority = false;
      this.editNumber = false;
    },
  },
};
</script>