<template>
  <div>
    <v-dialog
      v-model="newFieldDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ edit == true ? 'Edit field' : 'New field'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="px-0">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :color="getAppearances.btn_color"
                label="Field name"
                required
              ></v-text-field>
              <v-select
                v-if="edit == false"
                v-model="select"
                @change="typeChange()"
                :rules="typeRules"
                :items="items"
                :color="getAppearances.btn_color"
                label="Type"
                required
              ></v-select>
              <div v-if="select == 'Dropdown'">
                <v-text-field
                  v-model="value"
                  label="Enter value"
                  :color="getAppearances.btn_color"
                  :rules="[valueRules, valueSaveRules]"
                  required
                ></v-text-field>
                <v-btn
                  v-if="saveValue"
                  :color="getAppearances.btn_color"
                  class="white--text mt-2 mb-5"
                  :disabled="valueInList(value)"
                  @click="addValue()"
                >
                  Save value
                </v-btn>
                <v-scroll-y-transition
                  mode="out-in"
                  hide-on-leave
                >
                  <v-list
                    v-if="fields.length > 0"
                    dense
                    subheader
                  >
                    <v-subheader class="px-0 my-n2">
                      Values
                    </v-subheader>
                    <div
                      v-for="item in fields"
                      :key="item"
                    >
                      <v-list-item class="mx-1 px-0 my-n2">
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            icon
                            @click="removeValue(item)"
                          >
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="item"></v-divider>
                    </div>
                  </v-list>
                </v-scroll-y-transition>
              </div>
              <div v-if="select == 'Labels'">
                <v-text-field
                  v-model="value"
                  label="Enter value"
                  :color="getAppearances.btn_color"
                  :rules="[valueRules, valueSaveRules]"
                  required
                ></v-text-field>
                <v-btn
                  v-if="saveValue"
                  :color="getAppearances.btn_color"
                  class="white--text mt-2 mb-5"
                  :disabled="valueInList(value)"
                  @click="addLabelsValue()"
                >
                  Save value
                </v-btn>
                <v-scroll-y-transition
                  mode="out-in"
                  hide-on-leave
                >
                  <v-list
                    v-if="fields.length > 0"
                    dense
                    subheader
                  >
                    <v-subheader class="px-0 my-n2">
                      Values
                    </v-subheader>
                    <div
                      v-for="item in fields"
                      :key="item.id"
                    >
                      <v-list-item class="mx-1 px-0 my-n2">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action style="flex-flow: row;">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                :color="item.color"
                              >
                                <v-icon>mdi-palette</v-icon>
                              </v-btn>
                            </template>
                            <v-color-picker
                              hide-canvas
                              hide-sliders
                              hide-inputs
                              :swatches="swatches"
                              value="#000"
                              show-swatches
                              v-model="selectedColor"
                              @update:color="updateColor(item)"
                            ></v-color-picker>
                          </v-menu>
                          <v-btn
                            icon
                            @click="removeValue(item)"
                          >
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="item.id"></v-divider>
                    </div>
                  </v-list>
                </v-scroll-y-transition>
              </div>
              <v-switch
                v-model="accessibleGlobal"
                v-if="edit == false"
                label="Accessible in all projects"
                :color="getAppearances.btn_color"
                hide-details
                class="mt-0 pt-3"
              ></v-switch>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
            class="mb-3 mr-1"
          >
            Close
          </v-btn>
          <v-btn
            v-if="edit == false"
            :color="getAppearances.btn_color"
            dark
            @click="save"
            class="mb-3 mx-1"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            :color="getAppearances.btn_color"
            :disabled="checkMetaChanges()"
            @click="save"
            class="white--text mb-3 mx-1"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="fieldDeleteDialog"
      persistent
      max-width="300"
    >
      <v-card>
        <v-container>
          <div class="my-5">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <h4>After saving, the value will be removed from all tasks.</h4>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="fieldDeleteDialog = false"
              text
            >
              Close
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              dark
              @click="deleteValueConfirm()"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    newFieldDialog: Boolean,
    editTaskMeta: Object,
    taskEdit: Boolean,
  },
  data() {
    return {
      selectedValue: "",
      fieldDeleteDialog: false,
      edit: false,
      valid: true,
      name: "",
      select: null,
      value: "",
      saveValue: false,
      valueSaved: false,
      fieldsChanged: "",
      fields: [],
      newMeta: {},
      editItem: {},
      selectedColor: "",
      accessibleGlobal: false,
      swatches: [
        ["#B71C1C", "#E91E63", "#9C27B0"],
        ["#673AB7", "#3F51B5", "#2196F3"],
        ["#03A9F4", "#00BCD4", "#009688"],
        ["#4CAF50", "#FFC107", "#FF9800"],
        ["#FF5722", "#795548", "#607D8B"],
      ],
      items: [
        "Text",
        "Dropdown",
        "Labels",
        "Currency",
        "Checkbox",
        "Date",
        "Priority",
        "Number",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      typeRules: [(v) => !!v || "Metadata type is required"],
    };
  },

  watch: {
    taskEdit: {
      handler(val) {
        if (val == true) {
          this.editMetadata();
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", [
      "getHeaders",
      "getMetaList",
      "getProject",
      "getColumns",
    ]),
  },

  methods: {
    ...mapMutations("projects", ["setFieldUpdate"]),

    typeChange() {
      if (this.edit == true) {
        if (this.select == "Dropdown") {
          if (this.newMeta.type == "Dropdown") {
            this.fields = JSON.parse(JSON.stringify(this.newMeta.fields));
            this.fieldsChanged = false;
          } else {
            this.fields = [];
          }
        } else {
          this.fields = [];
        }
      } else {
        this.fields = [];
      }
    },

    valueInList(item) {
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].name == item || this.fields[i] == item) {
          return true;
        }
      }
      return false;
    },

    checkMetaChanges() {
      var meta = this.newMeta;
      if (this.select == "Dropdown") {
        if (
          this.name != meta.title ||
          this.select != meta.type ||
          this.fieldsChanged == true
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.select == "Labels") {
        if (
          this.name != meta.title ||
          this.select != meta.type ||
          this.fieldsChanged == true
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.name != meta.title || this.select != meta.type) {
          return false;
        } else {
          return true;
        }
      }
    },

    save() {
      this.valueSaved = true;
      if (this.$refs.form.validate()) {
        if (this.select == "Priority") {
          this.fields = ["High", "Medium", "Low"];
        }
        if (this.edit) {
          var difference = [];
          if (this.select == "Labels") {
            difference = this.editTaskMeta.fields.filter(
              (obj1) => !this.fields.find((obj2) => obj1.id === obj2.id)
            );
          } else {
            var fields = this.editTaskMeta.fields.filter(
              (obj1) => !this.fields.find((obj2) => obj1 === obj2)
            );
            for (let i = 0; i < fields.length; i++) {
              difference.push({ name: fields[i] });
            }
          }

          var metaID = this.editItem.id;
          var type = this.select;

          this.$store
            .dispatch("meta/edit", {
              id: this.editItem.id,
              name: this.name,
              type: this.select,
              fields: this.fields,
              difference: difference,
            })
            .then(() => {
              for (let i = 0; i < this.getColumns.length; i++) {
                for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
                  for (let k = 0; k < difference.length; k++) {
                    if (type == "Labels") {
                      for (
                        let l = 0;
                        l < this.getColumns[i].tasks[j][metaID].length;
                        l++
                      ) {
                        if (
                          this.getColumns[i].tasks[j][metaID][l] ==
                          difference[k].name
                        ) {
                          this.getColumns[i].tasks[j][metaID].splice(
                            this.getColumns[i].tasks[j][metaID].indexOf(
                              this.getColumns[i].tasks[j][metaID][l]
                            ),
                            1
                          );
                        }
                      }
                    } else {
                      if (
                        this.getColumns[i].tasks[j][metaID] ==
                        difference[k].name
                      ) {
                        this.getColumns[i].tasks[j][metaID] = "";
                      }
                    }
                  }
                }
              }
              this.$store.commit("projects/setTaskEdited", true);
            });

          for (let i = 0; i < this.getHeaders.length; i++) {
            if (this.getHeaders[i].value == this.editItem.id) {
              this.getHeaders[i].name = this.name;
            }
          }
          this.editItem.title = this.name;
          this.editItem.type = this.select;
          this.editItem.fields = this.fields;
          this.close();
          this.setFieldUpdate(true);
          return;
        }

        var projectID = "";
        if (this.accessibleGlobal == false) {
          projectID = this.getProject.id;
        } else {
          projectID = "Global";
        }

        this.$store
          .dispatch("meta/create", {
            name: this.name,
            type: this.select,
            fields: this.fields,
            app: "Projects",
            projectID: projectID,
          })
          .then((response) => {
            this.getMetaList.push(response.data);
            var header = {
              value: response.data.id,
              name: response.data.title,
              show: false,
            };
            this.getHeaders.push(header);
            for (let i = 0; i < this.getColumns.length; i++) {
              for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
                this.getColumns[i].tasks[j][response.data.id] = "";
              }
            }
            this.setFieldUpdate(true);
          });
        this.close();
      }
    },

    close() {
      this.$emit("update:newFieldDialog", false);
      this.$emit("update:taskEdit", false);
      this.name = "";
      this.value = "";
      this.fields = [];
      this.select = "";
      this.edit = false;
      this.editItem = {};
      this.valueSaved = false;
      this.$refs.form.resetValidation();
      this.fieldsChanged = false;
      this.accessibleGlobal = false;
    },

    valueSaveRules() {
      if (this.saveValue == true && this.valueSaved == true) {
        return `Value must be saved`;
      } else {
        return true;
      }
    },

    valueRules(v) {
      if (v.length > 0) {
        this.saveValue = true;
      } else {
        this.saveValue = false;
      }

      if (v.length < 1) {
        if (this.fields.length == 0) {
          return `Value is required`;
        }
      } else {
        return true;
      }
      return true;
    },

    removeValue(value) {
      this.selectedValue = value;
      this.fieldDeleteDialog = true;
    },

    deleteValueConfirm() {
      this.fields.splice(this.fields.indexOf(this.selectedValue), 1);
      this.fieldsCompare();
      this.fieldDeleteDialog = false;
      this.selectedValue = "";
    },

    editMetadata() {
      this.editItem = this.editTaskMeta;
      this.newMeta = JSON.parse(JSON.stringify(this.editTaskMeta));
      this.select = this.editTaskMeta.type;
      this.name = this.editTaskMeta.title;
      this.fields = JSON.parse(JSON.stringify(this.editTaskMeta.fields));
      this.createDialog = true;
      this.edit = true;
    },

    addValue() {
      this.valueSaved = false;
      if (this.select == "Dropdown" && this.value != "") {
        this.fields.push(this.value);
        this.value = "";
        this.fieldsCompare();
      }
    },

    updateColor(item) {
      var old = "";
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id == item.id) {
          old = this.fields[i].color;
          this.fields[i].color = this.selectedColor;
        }
      }
      if (old != this.selectedColor) {
        this.fieldsChanged = true;
      }
      this.selectedColor = "";
    },

    addLabelsValue() {
      var id = Math.random().toString(36).substr(2, 9);
      this.valueSaved = false;
      if (this.select == "Labels" && this.value != "") {
        this.fields.push({ id: id, name: this.value, color: "red" });
        this.value = "";
        this.fieldsCompare();
      }
      this.selectedColor = "";
    },

    compareArrays(array1, array2) {
      if (array1.length !== array2.length) {
        return true;
      }

      for (let i = 0; i < array1.length; i++) {
        let foundInArray1 = false;
        let foundInArray2 = false;
        for (let j = 0; j < array2.length; j++) {
          if (array1[i].name === array2[j].name) {
            foundInArray1 = true;
          }
          if (array2[j].name === array1[i].name) {
            foundInArray2 = true;
          }
        }
        if (!foundInArray1 || !foundInArray2) {
          return true;
        }
      }
      return false;
    },

    fieldsCompare() {
      if (this.edit == true) {
        if (this.select == "Dropdown") {
          if (
            this.fields.sort().join(",") ===
            this.newMeta.fields.sort().join(",")
          ) {
            this.fieldsChanged = false;
          } else {
            this.fieldsChanged = true;
          }
        } else {
          this.fieldsChanged = this.compareArrays(
            this.newMeta.fields,
            this.fields
          );
        }
      }
    },
  },
};
</script>