<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="center" align="center">
      <v-col align="center" justify-center>
        <div v-if="metadata.length > 0 && !loading">
          <h1 class="font-weight-light">Metadata</h1>
          <div
            class="text-wrap font-weight-regular text--secondary"
            :style="{ maxWidth: '700px' }"
          >
            Metadata can be attached to any files or folders by the users. You
            can define here the kind of metadata you want to display, and
            whether each meta is readable/writeable by standard users as opposed
            to admin users.
          </div>
        </div>
        <v-card class="my-9" max-width="700" elevation="0">
          <div v-if="loading == true">
            <v-progress-circular
              :size="50"
              :color="getAppearances.btn_color"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            v-if="metadata.length <= 0 && loading == false"
            class="text-center"
          >
            <v-list-item-avatar size="250" class="child-elements">
              <v-icon size="150" dark color="white" class="grey lighten-2">
                mdi-tag
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-light noselect">
              No metadata defined
            </h1>
            <div noselect class="subtitle-2 grey--text mb-5 noselect">
              Metadata can be attached to any files or folders by the users. You
              can define here the kind of metadata you want to display, and
              whether each meta is readable/writeable by standard users as
              opposed to admin users.
            </div>
            <v-btn
              :color="getAppearances.btn_color"
              dark
              @click="createDialog = true"
            >
              New
            </v-btn>
          </div>
          <div v-if="metadata.length > 0 && !loading">
            <v-list class="pt-5 mx-n1">
              <v-row class="mb-1 mx-1">
                <v-col cols="6" class="text-left px-0">
                  <v-list-item class="px-0 text-left">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-wrap text-left">
                        Your defined metadata
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6" class="text-right px-0 mt-2">
                  <v-spacer></v-spacer>
                  <v-btn
                    :color="getAppearances.btn_color"
                    dark
                    @click="createDialog = true"
                  >
                    New
                  </v-btn>
                </v-col>
              </v-row>
              <v-list-item-group
                v-model="selectedItem"
                :color="getAppearances.btn_color"
              >
                <template v-for="(item, index) in metadata">
                  <v-list-item dense :key="item.id" @click="editMeta(item)">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left"
                        v-text="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :color="getAppearances.btn_color"
                        dark
                        icon
                        @click.stop="openDeleteDialog(item)"
                        class="non-clickable"
                      >
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider class="my-1" :key="index + '-index'"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="createDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            edit == true ? "Edit metadata" : "New metadata"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="px-0">
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :color="getAppearances.btn_color"
                label="Name for metadata"
                required
              ></v-text-field>
              <v-select
                v-model="select"
                @change="typeChange()"
                :rules="typeRules"
                :items="items"
                :color="getAppearances.btn_color"
                label="Type"
                required
              ></v-select>
              <div v-if="select == 'selection'">
                <v-text-field
                  v-model="value"
                  label="Enter value"
                  :color="getAppearances.btn_color"
                  :rules="[valueRules, valueSaveRules]"
                  required
                ></v-text-field>
                <v-btn
                  v-if="saveValue"
                  :color="getAppearances.btn_color"
                  class="mt-2 mb-5"
                  dark
                  @click="addValue()"
                >
                  Save value
                </v-btn>
                <v-scroll-y-transition mode="out-in" hide-on-leave>
                  <v-list v-if="fields.length > 0" dense subheader>
                    <v-subheader class="px-0 my-n2"> Values </v-subheader>
                    <div v-for="item in fields" :key="item">
                      <v-list-item class="mx-1 px-0 my-n2">
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn icon @click="removeValue(item)">
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="item"></v-divider>
                    </div>
                  </v-list>
                </v-scroll-y-transition>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close" class="mb-3 mr-1"> Close </v-btn>
          <v-btn
            v-if="edit == false"
            :color="getAppearances.btn_color"
            dark
            @click="save"
            class="mb-3 mx-1"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            :color="getAppearances.btn_color"
            :disabled="checkMetaChanges()"
            @click="save"
            class="white--text mb-3 mx-1"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmation" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete metadata? </v-card-title>
        <v-card-text>All files will lose this metadata entry</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteConfirmation = false" class="mb-2 mr-1">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="remove" class="mb-2"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      fieldsChanged: "",
      saveValue: false,
      valueSaved: false,
      newMeta: {},
      valid: true,
      name: "",
      select: null,
      value: "",
      fields: [],
      createDialog: false,
      loading: false,
      selectedItem: "",
      itemToDelete: {},
      items: ["text", "selection", "date", "color"],
      colors: [
        { color: "#673AB7", name: "Personal" },
        { color: "#4CAF50", name: "Todo" },
        { color: "#B71C1C", name: "Work" },
        { color: "#FF5722", name: "Important" },
      ],
      metadata: [],
      deleteConfirmation: false,
      edit: false,
      editItem: {},
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      typeRules: [(v) => !!v || "Metadata type is required"],
    };
  },

  created() {
    this.loading = true;

    this.$store.dispatch("meta/getFileMetadata").then(() => {
      this.metadata = this.getMetadata;
      this.loading = false;
    });
  },

  computed: {
    ...mapGetters("meta", ["getMetadata"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    typeChange() {
      if (this.edit == true) {
        if (this.select == "color") {
          this.fields = this.colors;
        } else if (this.select == "selection") {
          if (this.newMeta.type == "selection") {
            this.fields = JSON.parse(JSON.stringify(this.newMeta.fields));
            this.fieldsChanged = false;
          } else {
            this.fields = [];
          }
        } else {
          this.fields = [];
        }
      }
    },

    checkMetaChanges() {
      var meta = this.newMeta;
      if (this.select == "selection") {
        if (
          this.name != meta.title ||
          this.select != meta.type ||
          this.fieldsChanged == true
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.name != meta.title || this.select != meta.type) {
          return false;
        } else {
          return true;
        }
      }
    },

    valueSaveRules() {
      if (this.saveValue == true && this.valueSaved == true) {
        return `Value must be saved`;
      } else {
        return true;
      }
    },

    valueRules(v) {
      if (v.length > 0) {
        this.saveValue = true;
      } else {
        this.saveValue = false;
      }

      if (v.length < 1) {
        if (this.fields.length == 0) {
          return `Value is required`;
        }
      } else {
        return true;
      }
      return true;
    },

    save() {
      this.valueSaved = true;
      if (this.$refs.form.validate()) {
        if (this.select == "color") {
          this.fields = this.colors;
        }

        if (this.edit) {
          this.$store.dispatch("meta/editFileMetadata", {
            id: this.editItem.id,
            name: this.name,
            type: this.select,
            fields: this.fields,
          });

          this.editItem.title = this.name;
          this.editItem.type = this.select;
          this.editItem.fields = this.fields;

          this.close();
          return;
        }

        this.$store
          .dispatch("meta/createFileMetadata", {
            name: this.name,
            type: this.select,
            fields: this.fields,
          })
          .then((response) => {
            this.metadata.push(response.data);
          });

        this.close();
      }
    },

    editMeta(item) {
      this.editItem = item;
      this.newMeta = JSON.parse(JSON.stringify(item));
      this.select = item.type;
      this.name = item.title;
      this.fields = JSON.parse(JSON.stringify(item.fields));
      this.createDialog = true;
      this.edit = true;
    },

    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteConfirmation = true;
    },

    remove() {
      this.$store
        .dispatch("meta/deleteFileMetadata", this.itemToDelete.id)
        .then(() => {
          this.metadata.splice(this.metadata.indexOf(this.itemToDelete), 1);
        });
      this.deleteConfirmation = false;
    },

    addValue() {
      this.valueSaved = false;
      if (this.select == "selection" && this.value != "") {
        this.fields.push(this.value);
        this.value = "";
        this.fieldsCompare();
      }
    },

    removeValue(value) {
      this.fields.splice(this.fields.indexOf(value), 1);
      this.fieldsCompare();
    },

    fieldsCompare() {
      if (this.edit == true) {
        if (
          this.fields.sort().join(",") === this.newMeta.fields.sort().join(",")
        ) {
          this.fieldsChanged = false;
        } else {
          this.fieldsChanged = true;
        }
      }
    },

    close() {
      this.createDialog = false;
      this.name = "";
      this.value = "";
      this.fields = [];
      this.select = "";
      this.edit = false;
      this.editItem = {};
      this.valueSaved = false;
      this.$refs.form.resetValidation();
      this.fieldsChanged = false;
    },
  },
};
</script>
