<template>
  <v-container fluid>
    <div class="d-flex justify-center align-center">
      <v-col
        lg="6"
        md="8"
        sm="10"
        xs="12"
      >
        <h3 class="my-3">How we'll collaborate</h3>
        <v-textarea
          v-model="getProject.description"
          @change="updateDescription()"
          name="input-7-1"
          auto-grow
          class="placeholderColor"
          :rows="textAreaRows()"
          placeholder="Welcome your team and set the tone for how you'll work together. Add meeting details, communication channels, and other important information."
        ></v-textarea>
        <v-row class="mx-n1 my-3">
          <v-col
            class="pa-0"
            :cols="getMobileView == true ? 12 : 4"
          >
            <v-card
              @mouseover="addHover = true"
              @mouseleave="addHover = false"
              @click="addMemberToProject()"
              class="ma-1"
              color="transparent"
              :elevation="addHover == true ? 1 : 0"
              style="cursor: pointer;"
              height="62"
            >
              <v-list-item class="px-0 mx-3">
                <v-list-item-avatar>
                  <v-icon class="mt-1">mdi-account-plus</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="mt-1">Add member</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-menu
            v-for="item in getProjectMembers"
            :key="item.title"
            v-model="item.menu"
            offset-y
            nudge-top="3"
            nudge-width="-12"
            nudge-right="6"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-col
                v-bind="attrs"
                v-on="on"
                class="pa-0"
                :cols="getMobileView == true ? 12 : 4"
              >
                <v-card
                  @mouseover="selectedUserID = item.id"
                  @mouseleave="selectedUserID = ''"
                  class="ma-1"
                  color="transparent"
                  :elevation="selectedUserID == item.id || item.menu == true ? 1 : 0"
                  style="cursor: pointer;"
                  @click="selectedUser = item.id, shown = true"
                  height="62"
                >
                  <v-list-item
                    :key="item.id"
                    class="px-0 mx-3"
                  >
                    <v-list-item-avatar>
                      <v-img :src="`/api/users/photo/${item.photo}`"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name + ' ' + item.lastname"></v-list-item-title>
                      <v-list-item-subtitle v-html="userRole(item)"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-icon v-if="selectedUserID == item.id">mdi-chevron-down</v-icon>
                  </v-list-item>
                </v-card>
              </v-col>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click="removeUser(item)"
                >
                  <div style="color: rgb(235, 42, 42);">Remove from Project</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </div>
    <AddMember
      :addMember.sync="addMember"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AddMember from "../../components/Projects/Dialogs/AddMember.vue";
import { projectUtils } from "../../mixins/project";

export default {
  components: {
    AddMember,
  },
  data: () => ({
    addHover: false,
    selectedUserID: "",
    selectedUser: "",
    addMember: false,
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUsers"]),
    ...mapGetters("projects", [
      "getProject",
      "getProjectMembers",
      "getMobileView",
      "getActiveUsers",
      "getItemsToSelect"
    ]),

  },

  mixins: [projectUtils],

  methods: {
    updateDescription() {
      var projectData = {
        id: this.getProject.id,
        title: undefined,
        description: this.getProject.description,
        properties: undefined,
        startDate: undefined,
        endDate: undefined,
      };
      this.$store.dispatch("projects/patchProject", projectData);
    },

    textAreaRows() {
      if (this.getProject.description == '' && this.getMobileView == false) {
        return 3
      } else if (this.getMobileView == true && this.getProject.description == '') {
        return 5
      } else {
        return 1
      }
    },

    userRole(item) {
      var role = "";
      for (let i = 0; i < this.getProject.members.length; i++) {
        if (this.getProject.members[i].user_id == item.id) {
          role = this.getProject.members[i].role;
        }
      }
      return role;
    },
  },
};
</script>

<style scoped>
.placeholderColor ::placeholder {
  color: #3f3f3f !important;
}
</style>>
