<template>
  <div>
    <v-menu
      :close-on-click="false"
      v-model="showEndDateMenu"
      :close-on-content-click="false"
      offset-y
      :key="getRefreshDate"
      :content-class="item.id == taskID ? '' : 'elevation-0'"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="dateType != ''"
          align="left"
          class="mx-3 my-2 text-capitalize font-weight-regular dateFont"
          @click="getTaskID(item)"
          v-bind="attrs"
          v-on="on"
        >
          {{ dateFormat(dateType) }}
        </div>
        <div v-else>
          <v-btn
            @click="getTaskID(item)"
            v-bind="attrs"
            v-on="on"
            icon
            small
            class="mx-2 mt-1"
          >
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <v-date-picker
        v-if="item.id == taskID"
        v-model="date"
        no-title
      >
        <v-spacer />
        <v-btn
          text
          @click="showEndDateMenu = false"
          class="mr-1 mb-3"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="getAppearances.btn_color"
          dark
          @click="updateDate(item, update)"
          class="mb-3 mr-3"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    item: Object,
    getTaskID: Function,
    taskID: String,
    closeCreate: Function,
    dateType: String,
    update: String,
    dateTaskMenu: Boolean,
  },
  data() {
    return {
      date: new Date(Date.now()).toISOString().substr(0, 10),
      close: false,
    };
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getRefreshDate", "getTask"]),

    showEndDateMenu: {
      get() {
        return this.dateTaskMenu;
      },
      set(value) {
        this.$emit("update:dateTaskMenu", value);
      },
    },
  },

  methods: {
    ...mapMutations("projects", ["setTask"]),

    updateDate(item, period) {
      if (item.id == this.getTask.id) {
        this.setTask(item);
      }
      this.newDate(item, period, this.date);
      this.closeCreate();
      (this.date = new Date(Date.now()).toISOString().substr(0, 10)),
        this.$emit("close");
      this.showDateMenu = false;
    },
  },
};
</script>

<style scoped>
.dateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>