<template>
  <div>
    <v-dialog
      v-model="dateDialog"
      max-width="400"
      persistent
    >
      <v-card>
        <div class="d-flex justify-center">
          <v-card-title
            class="text-h5 px-4 pt-4 pb-0 d-inline-block text-truncate"
            style="max-width: 400px;"
          >
            {{ selectedTask.title }}
          </v-card-title>
        </div>
        <div class="d-flex justify-center my-1">
          <div
            v-if="selectedTaskDate == 'start'"
            class="px-4 d-inline-block text-truncate"
            style="max-width: 400px; font-size: 14px;"
          >
            Start Date
          </div>
          <div
            v-else
            class="px-4 d-inline-block text-truncate"
            style="max-width: 400px; font-size: 14px;"
          >
            End Date
          </div>
        </div>
        <v-card-text class="px-4">
          <v-date-picker
            v-model="date"
            no-title
            full-width
          >
          </v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="dateIsSelecterd() != ''"
            text
            @click="updateDate('clear')"
            class="mb-2"
          >
            Clear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$emit('update:dateDialog', false)"
            class="mb-2"
          >
            Close
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="updateDate()"
            class="mb-2"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    dateDialog: Boolean,
    selectedTaskDate: String,
    selectedTask: Object,
  },
  data() {
    return {
      date: new Date(Date.now()).toISOString().substr(0, 10),
      close: false,
    };
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    updateDate(action) {
      if (action == "clear") {
        this.newDate(
          this.selectedTask,
          this.selectedTaskDate,
          this.date,
          true
        ).then(() => {
          this.$emit("update:dateDialog", false);
        });
      } else {
        this.newDate(this.selectedTask, this.selectedTaskDate, this.date).then(
          () => {
            this.$emit("update:dateDialog", false);
          }
        );
      }
      this.date = new Date(Date.now()).toISOString().substr(0, 10);
    },

    dateIsSelecterd() {
      if (this.selectedTaskDate == "start") {
        return this.selectedTask.start_date;
      } else {
        return this.selectedTask.end_date;
      }
    },
  },
};
</script>
  
<style scoped>
.dateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>