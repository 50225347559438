<template>
  <v-container
    fluid
    class="pa-0 ma-0 fill-height"
  >
    <v-row class="fill-height pa-0">
      <v-col fill-height>
        <v-row
          v-if="getFiles.length == 0 && loaded"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-center mb-5 child-elements">
            <v-list-item-avatar
              size="200"
              class="child-elements"
            >
              <v-icon
                size="100"
                dark
                color="white"
                class="grey lighten-2"
              >
                mdi-delete
              </v-icon>
            </v-list-item-avatar>
            <h1 class="grey--text text--darken-1 font-weight-medium noselect">Deleted files</h1>
            <div
              noselect
              class="subtitle-1 grey--text mb-5 noselect"
            >You have no files in trash</div>
          </div>
        </v-row>
        <v-list
          class="mt-0 pt-0"
          two-line
        >
          <v-list-item-group
            :value="getSelection"
            multiple
            :color="getAppearances.btn_color"
          >
            <template v-for="(item, index) in filtered_items">
              <v-list-item
                :key="index"
                @click="setActive($event, item, index)"
                @dblclick="showRestoreDialog(item)"
                @contextmenu="show($event, item, index)"
                class="pa-0"
              >
                <template v-slot:default="{ }">
                  <v-badge
                    light
                    bottom
                    color="yellow darken-1"
                    icon="mdi-star"
                    offset-x="30"
                    offset-y="30"
                    :value="item.favourite"
                  >
                    <v-list-item-avatar tile>
                      <v-icon
                        v-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType !== 'image'"
                        size="48"
                        dark
                        :color="fileTypes[item.type].color"
                      >
                        {{ fileTypes[item.type].icon }}
                      </v-icon>
                      <v-img
                        v-else-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType == 'image'"
                        :src="`/api/files/thumbnails/${item.id}`"
                      >
                      </v-img>
                      <v-icon
                        v-else
                        size="48"
                        dark
                        :color="fileTypes['unknown'].color"
                      >
                        {{ fileTypes['unknown'].icon }}
                      </v-icon>
                    </v-list-item-avatar>
                  </v-badge>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="mr-3">
                    <v-row align="center">
                      <v-btn icon>
                        <v-icon @click.stop="restoreFile(item)">
                          mdi-file-restore
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click.stop="deletePermanently(item)"
                        @dblclick.stop=""
                        class="mr-2"
                      >
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider :key="index + item.name"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-pagination
          v-model="pages"
          v-if="filtered.length/getPerPage > 1"
          :length="Math.ceil(filtered.length/getPerPage)"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      min-width="280"
    >
      <v-list>
        <v-list-item-group v-model="menu_item">
          <template v-for="(item) in menuItems">
            <v-list-item
              v-if="item.show"
              :disabled="item.disabled"
              :key="item.id"
              @click="actions(item)"
            >
              <v-list-item-icon>
                <v-icon
                  :color="item.color"
                  v-text="item.icon"
                >
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          This file is in the trash!
        </v-card-title>
        <v-card-text>To view the file, you need to restore!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            @click="restoreFromDialog"
            dark
          >
            Restore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { actions } from "../../mixins/browserActions";
import { utils } from "../../mixins/utils";
export default {
  data: () => ({
    curDate: new Date(),
    dialog: false,
    restoreItem: {},
  }),

  mixins: [actions, utils],

  created() {
    this.loadDeleted();
  },

  computed: {
    ...mapGetters("files", [
      "path",
      "fileTypes",
      "getFiles",
      "loaded",
      "getPage",
      "getPerPage",
      "getSelection",
    ]),

    ...mapGetters("user", ["getUser"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("toolbar", ["search"]),

    ...mapGetters("main", ["details"]),

    pages: {
      get() {
        return this.getPage;
      },
      set(val) {
        this.$store.commit("files/setPage", val);
      },
    },
  },

  methods: {
    sortDesc(arr) {
      arr.sort(function (a, b) {
        return b.created - a.created;
      });
    },

    loadDeleted() {
      this.$store.dispatch("files/getDeletedFiles").then((response) => {
        this.$store.commit("files/setPage", 1);
        this.$store.commit("files/setFiles", response.data);
        this.$store.commit("files/setLoaded", true);
      });
    },

    deletePermanently(item) {
      this.editItem = item;
      var id =
        this.multiple == false ? [item.id] : this.activeItems.map((e) => e.id);

      this.$store.dispatch("files/deletePermanently", id).then(() => {
        this.deleteItems();
      });
    },

    restoreFile(item) {
      this.editItem = item;
      var id =
        this.multiple == false ? [item.id] : this.activeItems.map((e) => e.id);

      this.$store.dispatch("files/restoreFromTrash", id).then(() => {
        this.deleteItems();
      });
    },

    showRestoreDialog(item) {
      this.restoreItem = item;
      this.dialog = true;
    },

    closeRestoreDialog() {
      this.dialog = false;
      this.restoreItem = {};
    },

    restoreFromDialog() {
      this.restoreFile(this.restoreItem);
      this.dialog = false;
    },
  },
};
</script>