<template>
  <div class="fill-width">
    <v-row class="pa-3">
      <v-card-title>
        Customize
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="getMobileView == false"
        icon
        small
        class="my-3 mr-3"
        @click="setCustomFields(false)"
      >
        <v-icon>mdi-arrow-collapse-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        small
        class="my-3 mr-3"
        @click="setCustomFields(false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card flat>
      <v-card-text>
        <div class="mx-3">Choose which fields are visible in List and Board view</div>
        <v-container
          class="px-0"
          fluid
        >
          <v-col
            v-for="header in getHeaders"
            :key="header.value"
            class="pa-0"
          >
            <v-card
              v-if="header.value != 'title'"
              outlined
              class="my-1"
            >
              <v-row
                class="ma-1"
                align="center"
                @mouseenter="showDelete = header.value"
                @mouseleave="showDelete = ''"
              >
                <div class="mx-3 my-1">
                  {{header.name}}
                </div>
                <div v-if="customFields(header.value).project_id == 'Global' 
                  && showDelete == header.value">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        x-small
                        class="mr-2 ml-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-earth</v-icon>
                      </v-btn>
                    </template>
                    <span>Accessible in all projects</span>
                  </v-tooltip>
                </div>
                <div v-if="showDelete == header.value && except(header.value) == true">
                  <v-btn
                    icon
                    x-small
                    class="mr-2 ml-1"
                    @click="editMeta(header)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    x-small
                    @click="openDeleteDialog(header.value)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-spacer />
                <v-switch
                  v-model="header.show"
                  :color="getAppearances.btn_color"
                  hide-details
                  class="mt-n1"
                  @change="updateHeadersShown(header)"
                ></v-switch>
              </v-row>
            </v-card>
          </v-col>
          <v-btn
            class="mt-6"
            :color="getAppearances.btn_color"
            dark
            @click="newFieldDialog = true"
          >
            New Custom Field
          </v-btn>
        </v-container>
      </v-card-text>
    </v-card>
    <CreateNewField
      :editTaskMeta="editTaskMeta"
      :newFieldDialog.sync="newFieldDialog"
      :taskEdit.sync="taskEdit"
    />
    <DeleteCustomField
      :deleteConfirmation.sync="deleteConfirmation"
      :deleteFieldID.sync="deleteFieldID"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { projectUtils } from "../../mixins/project";
import CreateNewField from "../../components/Projects/Dialogs/CreateNewField.vue";
import DeleteCustomField from "../../components/Projects/Dialogs/DeleteCustomField.vue";

export default {
  components: {
    CreateNewField,
    DeleteCustomField,
  },
  data() {
    return {
      newFieldDialog: false,
      showDelete: "",
      deleteConfirmation: false,
      deleteFieldID: "",
      editTaskMeta: {},
      taskEdit: false,
    };
  },

  computed: {
    ...mapGetters("projects", ["getHeaders", "getMobileView"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  mixins: [projectUtils],

  methods: {
    ...mapMutations("projects", ["setCustomFields", "setFieldUpdate"]),

    editMeta(item) {
      this.newFieldDialog = true;
      this.editTaskMeta = this.customFields(item.value);
      this.taskEdit = true;
    },

    openDeleteDialog(item) {
      this.deleteConfirmation = true;
      this.deleteFieldID = item;
    },

    updateHeadersShown() {
      this.setFieldUpdate(true);
    },
  },
};
</script>

<style scoped>
.fill-width {
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 85.2vh;
  border: 1px solid #f3f3f3;
  border-radius: 0px;
  border-bottom: none;
}
</style>