<template>
  <div>
    <v-dialog
      v-model="customDateDialog"
      max-width="400"
      persistent
    >
      <v-card>
        <div class="d-flex justify-center">
          <v-card-title
            v-if="customDateValues.item != undefined"
            class="text-h5 px-4 pt-4 pb-0 d-inline-block text-truncate"
            style="max-width: 400px;"
          >
            {{ customDateValues.item.title }}
          </v-card-title>
        </div>
        <div class="d-flex justify-center my-1">
          <div
            v-if="customDateValues.header != undefined"
            class="px-4 d-inline-block text-truncate"
            style="max-width: 400px; font-size: 14px;"
          >
            {{ customDateValues.header.name }}
          </div>
        </div>
        <v-card-text class="px-4">
          <v-date-picker
            v-model="date"
            no-title
            full-width
          >
          </v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="dateIsSelecterd() != ''"
            text
            @click="saveFieldDate(customDateValues.item, customDateValues.header.value, true)"
            class="mb-2"
          >
            Clear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$emit('update:customDateDialog', false)"
            class="mb-2"
          >
            Close
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="saveFieldDate(customDateValues.item, customDateValues.header.value)"
            class="mb-2"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    customDateValues: Object,
    customDateDialog: Boolean,
  },
  data() {
    return {
      date: new Date(Date.now()).toISOString().substr(0, 10),
      close: false,
      editing: "",
    };
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    saveFieldDate(task, value, clear) {
      if (!clear) {
        var item = moment(this.date).format("X");
        task[value] = item;
      } else {
        task[value] = "";
      }
      this.editing = task.id;
      this.$emit("update:customDateDialog", false);
      this.saveFields(task, value);
    },

    updateDate(action) {
      if (action == "clear") {
        this.newDate(
          this.selectedTask,
          this.selectedTaskDate,
          this.date,
          true
        ).then(() => {
          this.$emit("update:customDateDialog", false);
        });
      } else {
        this.newDate(this.selectedTask, this.selectedTaskDate, this.date).then(
          () => {
            this.$emit("update:customDateDialog", false);
          }
        );
      }
      this.date = new Date(Date.now()).toISOString().substr(0, 10);
    },

    dateIsSelecterd() {
      if (this.customDateValues.header != undefined) {
        return this.customDateValues.item[this.customDateValues.header.value];
      } else {
        return "";
      }
    },
  },
};
</script>
    
<style scoped>
.dateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>