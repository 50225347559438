<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col
        align="center"
        lg="9"
        md="9"
        sm="10"
        xs="12"
      >
        <div class="text-left mx-n7 my-n3">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-col cols="12">
                  <v-list-item-title>
                    File Notifications
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap my-1">
                    Choose for which File activities you want to get notification.
                  </v-list-item-subtitle>
                </v-col>
                <v-col
                  cols="12"
                  class="d-none d-sm-block mr-n9"
                >
                  <v-row class="mr-0 my-n9">
                    <v-col
                      lg="8"
                      md="8"
                      sm="8"
                      xs="8"
                    >
                    </v-col>
                    <v-col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                    >
                      <div class="mt-6"> Email </div>
                    </v-col>
                    <v-col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                    >
                      <div class="mt-6 ml-n7"> In-App </div>
                    </v-col>
                  </v-row>
                  <span
                    v-for="(item, i) in fileNotifications()"
                    :key="i"
                  >
                    <v-row class="mr-0 my-n9">
                      <v-col
                        lg="8"
                        md="8"
                        sm="8"
                        xs="8"
                      >
                        <div class="mt-6"> {{ item.text }} </div>
                      </v-col>
                      <v-col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                      >
                        <v-checkbox
                          v-model="item.email"
                          :color="getAppearances.btn_color"
                          false-value="false"
                          true-value="true"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        class="ml-n7"
                      >
                        <v-checkbox
                          v-model="item.app"
                          :color="getAppearances.btn_color"
                          false-value="false"
                          true-value="true"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="d-sm-none"
                >
                  <v-row class="mr-0 my-n9">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2">
                      <div class="mt-6"> Email </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="mt-6 ml-n3"> In-App </div>
                    </v-col>
                  </v-row>
                  <span
                    v-for="(item, i) in fileNotifications()"
                    :key="i"
                  >
                    <v-row class="mr-0 my-n9">
                      <v-col cols="8">
                        <div class="mt-6"> {{ item.text }} </div>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-model="item.email"
                          :color="getAppearances.btn_color"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="2"
                        class="ml-n3"
                      >
                        <v-checkbox
                          v-model="item.app"
                          :color="getAppearances.btn_color"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </span>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider class="my-3 d-none d-sm-block mx-3"></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-col cols="12">
                  <v-list-item-title>
                    Project Notifications
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap my-1">
                    Choose for which Project activities you want to get notification.
                  </v-list-item-subtitle>
                </v-col>
                <v-col
                  cols="12"
                  class="d-none d-sm-block mr-n9"
                >
                  <v-row class="mr-0 my-n9">
                    <v-col
                      lg="8"
                      md="8"
                      sm="8"
                      xs="8"
                    >
                    </v-col>
                    <v-col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                    >
                      <div class="mt-6"> Email </div>
                    </v-col>
                    <v-col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                    >
                      <div class="mt-6 ml-n7"> In-App </div>
                    </v-col>
                  </v-row>
                  <span
                    v-for="(item, i) in projectNotifications()"
                    :key="i"
                  >
                    <v-row class="mr-0 my-n9">
                      <v-col
                        lg="8"
                        md="8"
                        sm="8"
                        xs="8"
                      >
                        <div class="mt-6"> {{ item.text }} </div>
                      </v-col>
                      <v-col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                      >
                        <v-checkbox
                          v-model="item.email"
                          :color="getAppearances.btn_color"
                          false-value="false"
                          true-value="true"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        class="ml-n7"
                      >
                        <v-checkbox
                          v-model="item.app"
                          :color="getAppearances.btn_color"
                          false-value="false"
                          true-value="true"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="d-sm-none"
                >
                  <v-row class="mr-0 my-n9">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="2">
                      <div class="mt-6"> Email </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="mt-6 ml-n3"> In-App </div>
                    </v-col>
                  </v-row>
                  <span
                    v-for="(item, i) in fileNotifications()"
                    :key="i"
                  >
                    <v-row class="mr-0 my-n9">
                      <v-col cols="8">
                        <div class="mt-6"> {{ item.text }} </div>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-model="item.email"
                          :color="getAppearances.btn_color"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="2"
                        class="ml-n3"
                      >
                        <v-checkbox
                          v-model="item.app"
                          :color="getAppearances.btn_color"
                          @click="updateValue(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </span>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider class="my-3 d-none d-sm-block mx-3"></v-divider>
          <v-col
            lg="6"
            md="6"
            sm="6"
            xs="8"
          >
            <v-select
              v-model="sendMailSelected"
              @change="updateEmailInterval"
              :color="getAppearances.btn_color"
              :items="sendmail"
              label="Send emails notifications"
              class="mb-n4 mx-4 mt-4"
              outlined
              dense
            ></v-select>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    sendmail: ["immediately", "never"],
    sendMailSelected: "",
    selectedItem: [],

    notifications: [],
  }),

  async mounted() {
    this.$store.dispatch("admin/getPreferences").then((response) => {
      this.notifications = response.data;
      this.sendMailSelected = this.status("send_email_notifications");
    });
  },

  computed: {
    ...mapGetters("admin", ["getNotifications", "getAppearances"]),

    ...mapGetters("user", ["getUser"]),
  },

  methods: {
    ...mapMutations("admin", ["setNotifications"]),

    updateValue(item) {
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].name == item.email_key) {
          this.notifications[i].value = item.email;
        } else if (this.notifications[i].name == item.app_key) {
          this.notifications[i].value = item.app;
        }
      }

      this.updateNotifications();
    },

    updateEmailInterval() {
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].name == "send_email_notifications") {
          this.notifications[i].value = this.sendMailSelected;
        }
      }

      this.updateNotifications();
    },

    updateNotifications() {
      this.$store.dispatch("admin/updatePreferences", this.notifications);
    },

    fileNotifications() {
      var items = [
        {
          text: "A new file or folder has been created",
          email: this.status("notify_email_file_or_folder_created"),
          app: this.status("notify_app_file_or_folder_created"),
          email_key: "notify_email_file_or_folder_created",
          app_key: "notify_app_file_or_folder_created",
        },
        {
          text: "A file or folder has been changed or renamed",
          email: this.status("notify_email_file_or_folder_renamed"),
          app: this.status("notify_app_file_or_folder_renamed"),
          email_key: "notify_email_file_or_folder_renamed",
          app_key: "notify_app_file_or_folder_renamed",
        },
        {
          text: "A file or folder has been deleted",
          email: this.status("notify_email_file_or_folder_deleted"),
          app: this.status("notify_app_file_or_folder_deleted"),
          email_key: "notify_email_file_or_folder_deleted",
          app_key: "notify_app_file_or_folder_deleted",
        },
        {
          text: "A file or folder has been restored",
          email: this.status("notify_email_file_or_folder_restored"),
          app: this.status("notify_app_file_or_folder_restored"),
          email_key: "notify_email_file_or_folder_restored",
          app_key: "notify_app_file_or_folder_restored",
        },
        {
          text: "A file or folder has been shared",
          email: this.status("notify_email_file_or_folder_shared"),
          app: this.status("notify_app_file_or_folder_shared"),
          email_key: "notify_email_file_or_folder_shared",
          app_key: "notify_app_file_or_folder_shared",
        },
        {
          text: "A file or folder shared by mail or by public link was downloaded",
          email: this.status(
            "notify_email_file_or_folder_downloaded_by_mail_or_publick_link"
          ),
          app: this.status(
            "notify_app_file_or_folder_downloaded_by_mail_or_publick_link"
          ),
          email_key:
            "notify_email_file_or_folder_downloaded_by_mail_or_publick_link",
          app_key:
            "notify_app_file_or_folder_downloaded_by_mail_or_publick_link",
        },
        {
          text: "A file or folder shared by mail or by public link was opened",
          email: this.status(
            "notify_email_file_or_folder_shared_by_mail_or_publick_link_opened"
          ),
          app: this.status(
            "notify_app_file_or_folder_shared_by_mail_or_publick_link_opened"
          ),
          email_key:
            "notify_email_file_or_folder_shared_by_mail_or_publick_link_opened",
          app_key:
            "notify_app_file_or_folder_shared_by_mail_or_publick_link_opened",
        },
      ];

      return items;
    },

    projectNotifications() {
      var items = [
        {
          text: "You have been invited to project",
          email: this.status("notify_email_project_invited"),
          app: this.status("notify_app_project_invited"),
          email_key: "notify_email_project_invited",
          app_key: "notify_app_project_invited",
        },
        {
          text: "A project has been deleted",
          email: this.status("notify_email_project_deleted"),
          app: this.status("notify_app_project_deleted"),
          email_key: "notify_email_project_deleted",
          app_key: "notify_app_project_deleted",
        },
        {
          text: "A project has been renamed",
          email: this.status("notify_email_project_renamed"),
          app: this.status("notify_app_project_renamed"),
          email_key: "notify_email_project_renamed",
          app_key: "notify_app_project_renamed",
        },
        {
          text: "A project task has been deleted",
          email: this.status("notify_email_task_deleted"),
          app: this.status("notify_app_task_deleted"),
          email_key: "notify_email_task_deleted",
          app_key: "notify_app_task_deleted",
        },
        {
          text: "A project task has been created",
          email: this.status("notify_email_task_created"),
          app: this.status("notify_app_task_created"),
          email_key: "notify_email_task_created",
          app_key: "notify_app_task_created",
        },
        {
          text: "A project task has been assigned to you",
          email: this.status("notify_email_task_assigned"),
          app: this.status("notify_app_task_assigned"),
          email_key: "notify_email_task_assigned",
          app_key: "notify_app_task_assigned",
        },
        {
          text: "Assigned task status changed",
          email: this.status("notify_email_assigned_task_status_changed"),
          app: this.status("notify_app_assigned_task_status_changed"),
          email_key: "notify_email_assigned_task_status_changed",
          app_key: "notify_app_assigned_task_status_changed",
        },
        {
          text: "Owned task status changed",
          email: this.status("notify_email_owned_task_status_changed"),
          app: this.status("notify_app_owned_task_status_changed"),
          email_key: "notify_email_owned_task_status_changed",
          app_key: "notify_app_owned_task_status_changed",
        },
      ];

      return items;
    },

    status(name) {
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].name == name) {
          return this.notifications[i].value;
        }
      }
    },
  },
};
</script>
