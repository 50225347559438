<template>
  <v-menu
    v-if="loaded"
    :close-on-click="false"
    v-model="filterMenu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="getLicense.isLicensed && !getLicense.project_filters"
        v-if="position == 'reporting'"
        v-bind="attrs"
        v-on="on"
        small
        outlined
        color="grey darken-2"
        class="mr-3 d-none d-sm-block"
      >
        <v-icon left>
          mdi-filter-variant
        </v-icon>
        <v-badge
          color="green"
          dot
          offset-x="-2"
          offset-y="3"
          :value="filterActive == true ? true : false"
        >
          Filter
        </v-badge>
      </v-btn>
      <v-btn
        :disabled="getLicense.isLicensed && !getLicense.project_filters"
        v-else
        v-bind="attrs"
        v-on="on"
        x-small
        text
        color="grey darken-2"
        class="mr-3 d-none d-sm-block"
      >
        <v-icon left>
          mdi-filter-variant
        </v-icon>
        <v-badge
          color="green"
          dot
          offset-x="-2"
          :value="filterActive == true ? true : false"
        >
          Filter
        </v-badge>
      </v-btn>
      <!-- <v-btn
        v-bind="attrs"
        v-on="on"
        x-small
        text
        color="grey darken-2"
        class="mr-1 d-sm-none"
      >
        <v-icon>
          mdi-filter-variant
        </v-icon>
        <v-badge
          color="green"
          dot
          offset-x="-2"
          :value="filterActive == true ? true : false"
        >
        </v-badge>
      </v-btn> -->
    </template>
    <v-card width="770">
      <v-card-title class="pb-0 px-5">Filters</v-card-title>
      <div
        v-for="(item, index) in activeFilter"
        :key="index"
      >
        <v-row :class="index == 0 ? 'row px-3 pb-3 pt-6' : 'row pa-3'">
          <v-col
            cols="2"
            class="pa-0"
          >
            <div
              v-if="index == 0"
              style="font-size: 13px;"
              class="mt-3 ml-5"
            >
              Where
            </div>
            <v-select
              v-if="index == 1"
              v-model="operatopType"
              :items="['AND','OR']"
              style="font-size: 13px;"
              hide-details="auto"
              class="ml-5 mr-3"
              outlined
              dense
            ></v-select>
            <v-select
              v-if="index > 1"
              v-model="operatopType"
              :items="['AND','OR']"
              style="font-size: 13px;"
              hide-details="auto"
              disabled
              class="ml-5 mr-3"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            class="pa-0"
            :cols="selectedDateFormat(item.selectedItem) ? '2' : '3'"
          >
            <v-select
              v-model="item.filterType"
              :items="filterList"
              @change="typeChange(item)"
              item-text="name"
              item-value="value"
              label="Select filter"
              style="font-size: 13px;"
              hide-details="auto"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            class="pa-0"
            :cols="selectedDateFormat(item.selectedItem) ? '2' : '2'"
          >
            <v-select
              v-model="item.actionType"
              :items="actionTypeList"
              style="font-size: 13px;"
              hide-details="auto"
              class="ml-3"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            class="pa-0"
            :cols="selectedDateFormat(item.selectedItem) ? '2' : '5'"
          >
            <v-icon
              v-if="item.actionType == 'Is set' || item.actionType == 'Is not set'"
              @click="deleteFilterRow(index)"
              class="mt-2 ml-2"
            >
              mdi-delete
            </v-icon>
            <v-select
              v-if="customFields(item.filterType).type == 'Date' || inDateList(item.filterType)"
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-model="item.selectedItem"
              :items="dateSortList"
              style="font-size: 13px;"
              :class="selectedDateFormat(item.selectedItem) ? 'ml-3' : 'ml-3 mr-5'"
              :append-outer-icon="selectedDateFormat(item.selectedItem) ? '' : 'mdi-delete'"
              @click:append-outer="deleteFilterRow(index)"
              hide-details="auto"
              outlined
              dense
            ></v-select>
            <v-select
              v-if="customFields(item.filterType).type == 'Priority'"
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-model="item.selectedItem"
              :items="['Low', 'Medium', 'High']"
              style="font-size: 13px;"
              :class="selectedDateFormat(item.selectedItem) ? 'ml-3' : 'ml-3 mr-5'"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              hide-details="auto"
              outlined
              dense
            ></v-select>
            <v-select
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-if="customFields(item.filterType).type == 'Labels'"
              v-model="item.selectedItem"
              :items="customFields(item.filterType).fields"
              item-text="name"
              style="font-size: 13px;"
              :class="selectedDateFormat(item.selectedItem) ? 'ml-3' : 'ml-3 mr-5'"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              hide-details="auto"
              outlined
              dense
            ></v-select>
            <v-text-field
              v-show="item.actionType == 'Contains' || item.actionType == 'Does not contain'"
              v-if="customFields(item.filterType).type == 'Text' || item.filterType == 'title'"
              v-model="item.selectedItem"
              hide-details="auto"
              style="font-size: 13px;"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              outlined
              dense
              class="ml-3 mr-5"
            ></v-text-field>
            <v-text-field
              v-show="showSelect(item.actionType)"
              v-if="customFields(item.filterType).type == 'Currency' || customFields(item.filterType).type == 'Number'"
              v-model="item.selectedItem"
              hide-details="auto"
              style="font-size: 13px;"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              @keypress="filter"
              outlined
              dense
              class="ml-3 mr-5"
            ></v-text-field>
            <v-select
              v-if="item.filterType == 'status'"
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-model="item.selectedItem"
              item-text="name"
              item-value="value"
              :items="taskStatusList"
              style="font-size: 13px;"
              class="ml-3 mr-5"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              hide-details="auto"
              outlined
              dense
            ></v-select>
            <v-select
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-if="customFields(item.filterType).type == 'Dropdown'"
              v-model="item.selectedItem"
              :items="customFields(item.filterType).fields"
              style="font-size: 13px;"
              :class="selectedDateFormat(item.selectedItem) ? 'ml-3' : 'ml-3 mr-5'"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              hide-details="auto"
              outlined
              dense
            ></v-select>
            <v-autocomplete
              v-if="item.filterType == 'assigned_to'"
              v-show="item.actionType == 'Is' || item.actionType == 'Is not'"
              v-model="item.selectedItem"
              :items="position == 'reporting' ? members : getProjectMembers"
              hide-details="auto"
              append-outer-icon="mdi-delete"
              @click:append-outer="deleteFilterRow(index)"
              single-line
              outlined
              dense
              chips
              item-text="name"
              item-value="id"
              :class="selectedDateFormat(item.selectedItem) ? 'ml-3' : 'ml-3 mr-5'"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                  color="white"
                  style="font-size: 13px;"
                  class="ma-0"
                >
                  <v-avatar left>
                    <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                  </v-avatar>
                  {{data.item.name + ' ' + data.item.lastname}}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="`/api/users/photo/${data.item.photo}`">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title> {{data.item.name + ' ' + data.item.lastname}}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="selectedDateFormat(item.selectedItem)"
            cols="4"
            class="pa-0"
          >
            <v-menu
              v-if="selectedDateFormat(item.selectedItem) && item.selectedItem != 'Last' && item.selectedItem != 'Next'"
              v-model="item.menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
              nudge-left='40'
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.selectedDateValue"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  style="font-size: 13px;"
                  outlined
                  class="ml-3 mr-5"
                  append-outer-icon="mdi-delete"
                  @click:append-outer="deleteFilterRow(index)"
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="item.selectedItem == 'Date range'"
                v-model="dates"
                no-title
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mr-1 mb-3"
                  @click="item.menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :color="getAppearances.btn_color"
                  class="mb-3 mr-3"
                  @click="showFormatedDate(item), item.menu = false"
                  dark
                >
                  OK
                </v-btn>
              </v-date-picker>
              <v-date-picker
                v-else
                v-model="date"
                no-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mr-1 mb-3"
                  @click="item.menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :color="getAppearances.btn_color"
                  class="mb-3 mr-3"
                  @click="showFormatedDate(item), item.menu = false"
                  dark
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-row v-else>
              <v-col
                cols="4"
                class="px-0"
              >
                <v-text-field
                  v-model="item.datePeriodeCount"
                  hide-details="auto"
                  style="font-size: 13px;"
                  outlined
                  dense
                  class="ml-6 mr-3"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                class="px-0"
              >
                <v-select
                  v-model="item.datePeriodeType"
                  :items="['Days', 'Months', 'Years']"
                  style="font-size: 13px;"
                  append-outer-icon="mdi-delete"
                  @click:append-outer="deleteFilterRow(index)"
                  hide-details="auto"
                  outlined
                  dense
                  class="mr-8"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row
        class="row my-1"
        @mouseover="showClear = true"
        @mouseleave="showClear = false"
      >
        <v-btn
          class="ml-8 mt-3 mb-1"
          text
          x-small
          @click="addFilter()"
        >
          + Add filter
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="showClear == true"
          class="mr-8 mt-3 mb-1"
          text
          x-small
          @click="setFilterData([]), filterActive = false"
        >
          Clear filter
          <v-icon right>
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>
      <v-card-actions>
        <!-- <v-btn
          class="mb-3 ml-3 text-capitalize"
          outlined
          :color="getAppearances.btn_color"
        >
          <v-icon
            small
            class="mt-n1"
          >
            mdi-filter-variant-plus
          </v-icon>
          Templates
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          class="mb-3 mr-1"
          text
          @click="filterMenu = false"
        >
          Close
        </v-btn>
        <v-btn
          class="mr-3 mb-3"
          :color="getAppearances.btn_color"
          dark
          @click="saveFilter()"
        >
          Filter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";
import moment from "moment";

export default {
  props: {
    position: String,
    globalHeaders: Array,
    members: Array,
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [],
      loaded: false,
      filterMenu: false,
      showOperator: false,
      filterActive: false,
      operatopType: "AND",
      showClear: false,
      filterList: [],
      actionTypeList: ["Is", "Is not", "Is set", "Is not set"],
      activeFilter: [
        {
          filterType: "",
          actionType: "",
          selectedItem: "",
        },
      ],
      numberFilterTypes: [
        "Equals",
        "Not equal to",
        "Greater than",
        "Less than",
        "Greater than or equal to",
        "Less than or equal to",
        "Is set",
        "Is not set",
      ],
      dateSortList: [
        "Today",
        "Yesterday",
        "Tomorrow",
        "Next 7 days",
        "Last 7 days",
        "Last week",
        "This week",
        "Next week",
        "Last month",
        "This month",
        "Next month",
        "Today & Earlier",
        "Last quarter",
        "Next quarter",
        "Overdue",
        "Later than Today",
        "Last",
        "Next",
        "Next year",
        "This year",
        "Last year",
        "Exact date",
        "Before date",
        "After date",
        "Date range",
      ],
    };
  },

  mixins: [projectUtils],

  watch: {
    "$store.state.projects.headers": function () {
      this.createTypeList();
    },
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("license", ["getLicense"]),
    ...mapGetters("projects", ["getProjectMembers", "getHeaders"]),
    ...mapGetters("user", ["getTimezone"]),

    formatDate() {
      var timezoneDate = moment.tz(this.date + " 00:00", this.getTimezone);
      var newFormat = timezoneDate.utc().format("X");

      return this.dateFormat(newFormat);
    },
  },

  mounted() {
    this.createTypeList();
  },

  methods: {
    ...mapMutations("projects", ["setFilterData", "setTestKey"]),

    showFormatedDate(item) {
      if (item.selectedItem == "Date range") {
        var dates = [];
        var tstampStart = moment(this.dates[0]).format("X");
        var tstampEnd = moment(this.dates[1]).format("X");
        dates.push(tstampStart);
        dates.push(tstampEnd);
        dates.sort((a, b) => a - b);
        var newFormatStart = moment(dates[0] * 1000).format("D MMM, YYYY");
        var newFormatEnd = moment(dates[1] * 1000).format("D MMM, YYYY");
        item.selectedDateValue = [newFormatStart, newFormatEnd].join(" - ");
      } else {
        item.selectedDateValue = this.formatDate;
      }
    },

    showSelect(item) {
      for (let i = 0; i < this.numberFilterTypes.length; i++) {
        if (
          this.numberFilterTypes[i] == item &&
          item != "Is set" &&
          item != "Is not set"
        ) {
          return true;
        }
      }
    },

    typeChange(item) {
      item.selectedItem = "";
      if (this.customFields(item.filterType).type == "Checkbox") {
        this.actionTypeList = ["Is set", "Is not set"];
      } else if (
        this.customFields(item.filterType).type == "Currency" ||
        this.customFields(item.filterType).type == "Number"
      ) {
        this.actionTypeList = this.numberFilterTypes;
      } else if (
        this.customFields(item.filterType).type == "Text" ||
        item.filterType == "title"
      ) {
        this.actionTypeList = [
          "Contains",
          "Does not contain",
          "Is set",
          "Is not set",
        ];
      } else if (item.filterType == "status") {
        this.actionTypeList = ["Is", "Is not"];
      } else {
        this.actionTypeList = ["Is", "Is not", "Is set", "Is not set"];
      }
    },

    selectedDateFormat(item) {
      var list = [
        "Date range",
        "After date",
        "Before date",
        "Exact date",
        "Next",
        "Last",
      ];
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          return true;
        }
      }
    },

    dateFilter(item) {
      if (
        this.customFields(item.filterType).type == "Date" ||
        this.inDateList(item.filterType)
      ) {
        var notFormatedToday = moment().format("YYYY-MM-DD" + " 00:00");
        let todayStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("day")
          .format("X");
        let todayEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("day")
          .format("X");
        let yesterdayStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("day")
          .add(-1, "days")
          .format("X");
        let yesterdayEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("day")
          .add(-1, "days")
          .format("X");
        let tomorrowStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("day")
          .add(1, "days")
          .format("X");
        let tomorrowEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("day")
          .add(1, "days")
          .format("X");
        let next7daysEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("day")
          .add(7, "days")
          .format("X");
        let last7daysStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("day")
          .add(-7, "days")
          .format("X");
        let lastWeekStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("X");
        let lastWeekEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("X");
        let thisWeekStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("isoWeek")
          .format("X");
        let thisWeekEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("isoWeek")
          .format("X");
        let nextWeekStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "weeks")
          .startOf("isoWeek")
          .format("X");
        let nextWeekEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "weeks")
          .endOf("isoWeek")
          .format("X");
        let lastMonthStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "months")
          .startOf("month")
          .format("X");
        let lastMonthEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "months")
          .endOf("month")
          .format("X");
        let thisMonthStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("month")
          .format("X");
        let thisMonthEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("month")
          .format("X");
        let nextMonthStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "months")
          .startOf("month")
          .format("X");
        let nextMonthEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "months")
          .endOf("month")
          .format("X");
        let lastQuarterStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "months")
          .startOf("quarter")
          .format("X");
        let lastQuarterEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "months")
          .endOf("quarter")
          .format("X");
        let nextQuarterStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "months")
          .startOf("quarter")
          .format("X");
        let nextQuarterEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "months")
          .endOf("quarter")
          .format("X");
        let lastYearStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "years")
          .startOf("year")
          .format("X");
        let lastYearEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .subtract(1, "years")
          .endOf("year")
          .format("X");
        let thisYearStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .startOf("year")
          .format("X");
        let thisYearEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .endOf("year")
          .format("X");
        let nextYearStart = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "years")
          .startOf("year")
          .format("X");
        let nextYearEnd = moment
          .tz(notFormatedToday, this.getTimezone)
          .add(1, "years")
          .endOf("year")
          .format("X");
        var tstampStart = "";
        var tstampEnd = "";
        if (item.selectedItem == "Date range") {
          var dates = item.selectedDateValue.split(" - ");
          var datesStart = moment(dates[0]).format("YYYY-MM-DD" + " 00:00");
          var datesEnd = moment(dates[1]).format("YYYY-MM-DD" + " 00:00");
          tstampStart = moment
            .tz(datesStart, this.getTimezone)
            .startOf("day")
            .format("X");
          tstampEnd = moment
            .tz(datesEnd, this.getTimezone)
            .endOf("day")
            .format("X");
        } else if (item.selectedItem == "Next") {
          if (item.datePeriodeType == "Days") {
            tstampEnd = moment
              .tz(notFormatedToday, this.getTimezone)
              .endOf("day")
              .add(item.datePeriodeCount, "days")
              .format("X");
          } else if (item.datePeriodeType == "Months") {
            tstampEnd = moment
              .tz(notFormatedToday, this.getTimezone)
              .add(item.datePeriodeCount, "months")
              .startOf("month")
              .format("X");
          } else if (item.datePeriodeType == "Years") {
            tstampEnd = moment
              .tz(notFormatedToday, this.getTimezone)
              .add(item.datePeriodeCount, "years")
              .endOf("year")
              .format("X");
          }
        } else if (item.selectedItem == "Last") {
          if (item.datePeriodeType == "Days") {
            tstampStart = moment
              .tz(notFormatedToday, this.getTimezone)
              .startOf("day")
              .add(-item.datePeriodeCount, "days")
              .format("X");
          } else if (item.datePeriodeType == "Months") {
            tstampStart = moment
              .tz(notFormatedToday, this.getTimezone)
              .subtract(item.datePeriodeCount, "months")
              .startOf("month")
              .format("X");
          } else if (item.datePeriodeType == "Years") {
            tstampStart = moment
              .tz(notFormatedToday, this.getTimezone)
              .subtract(item.datePeriodeCount, "years")
              .endOf("year")
              .format("X");
          }
        } else {
          var notFormated = moment(item.selectedDateValue).format(
            "YYYY-MM-DD" + " 00:00"
          );
          var formated = moment.tz(notFormated, this.getTimezone); // original timezone

          tstampStart = moment(formated)
            .tz(this.getTimezone)
            .startOf("day")
            .format("X");
          tstampEnd = moment(formated)
            .tz(this.getTimezone)
            .endOf("day")
            .format("X");
        }
        switch (item.selectedItem) {
          case "Today":
            item.datePeriod = {
              action: "stage",
              start: todayStart,
              end: todayEnd,
            };
            break;
          case "Yesterday":
            item.datePeriod = {
              action: "stage",
              start: yesterdayStart,
              end: yesterdayEnd,
            };
            break;
          case "Tomorrow":
            item.datePeriod = {
              action: "stage",
              start: tomorrowStart,
              end: tomorrowEnd,
            };
            break;
          case "Next 7 days":
            item.datePeriod = {
              action: "stage",
              start: todayStart,
              end: next7daysEnd,
            };
            break;
          case "Last 7 days":
            item.datePeriod = {
              action: "stage",
              start: last7daysStart,
              end: todayEnd,
            };
            break;
          case "Last week":
            item.datePeriod = {
              action: "stage",
              start: lastWeekStart,
              end: lastWeekEnd,
            };
            break;
          case "This week":
            item.datePeriod = {
              action: "stage",
              start: thisWeekStart,
              end: thisWeekEnd,
            };
            break;
          case "Next week":
            item.datePeriod = {
              action: "stage",
              start: nextWeekStart,
              end: nextWeekEnd,
            };
            break;
          case "Last month":
            item.datePeriod = {
              action: "stage",
              start: lastMonthStart,
              end: lastMonthEnd,
            };
            break;
          case "This month":
            item.datePeriod = {
              action: "stage",
              start: thisMonthStart,
              end: thisMonthEnd,
            };
            break;
          case "Next month":
            item.datePeriod = {
              action: "stage",
              start: nextMonthStart,
              end: nextMonthEnd,
            };
            break;
          case "Today & Earlier":
            item.datePeriod = { action: "greater", date: todayEnd };
            break;
          case "Last quarter":
            item.datePeriod = {
              action: "stage",
              start: lastQuarterStart,
              end: lastQuarterEnd,
            };
            break;
          case "Next quarter":
            item.datePeriod = {
              action: "stage",
              start: nextQuarterStart,
              end: nextQuarterEnd,
            };
            break;
          case "Overdue":
            item.datePeriod = { action: "greater", date: todayStart };
            break;
          case "Later than Today":
            item.datePeriod = { action: "less", date: todayEnd };
            break;
          case "Last":
            item.datePeriod = {
              action: "stage",
              start: tstampEnd,
              end: todayEnd,
            };
            break;
          case "Next":
            item.datePeriod = {
              action: "stage",
              start: todayStart,
              end: tstampEnd,
            };
            break;
          case "Last year":
            item.datePeriod = {
              action: "stage",
              start: lastYearStart,
              end: lastYearEnd,
            };
            break;
          case "This year":
            item.datePeriod = {
              action: "stage",
              start: thisYearStart,
              end: thisYearEnd,
            };
            break;
          case "Next year":
            item.datePeriod = {
              action: "stage",
              start: nextYearStart,
              end: nextYearEnd,
            };
            break;
          case "Exact date":
            item.datePeriod = {
              action: "stage",
              start: tstampStart,
              end: tstampEnd,
            };
            break;
          case "Before date":
            item.datePeriod = { action: "greater", date: tstampStart };
            break;
          case "After date":
            item.datePeriod = { action: "less", date: tstampEnd };
            break;
          case "Date range":
            item.datePeriod = {
              action: "stage",
              start: tstampStart,
              end: tstampEnd,
            };
            break;
          default:
            break;
        }
      }
    },

    inDateList(item) {
      var dateList = ["created", "end_date", "start_date", "updated"];
      for (let i = 0; i < dateList.length; i++) {
        if (item == dateList[i]) {
          return true;
        }
      }
    },

    createTypeList() {
      var list = [];
      if (this.position == "reporting") {
        for (let i = 0; i < this.globalHeaders.length; i++) {
          if (this.globalHeaders[i].name != "Project") {
            list.push(this.globalHeaders[i]);
          }
        }
      } else {
        for (let i = 0; i < this.getHeaders.length; i++) {
          if (
            this.getHeaders[i].name != "" &&
            this.getHeaders[i].show == true
          ) {
            list.push(this.getHeaders[i]);
          } else if (this.getHeaders[i].name == "") {
            this.getHeaders[i].name = "Title";
            list.push(this.getHeaders[i]);
          }
        }
      }

      this.filterList = list;
      this.loaded = true;
    },

    addFilter() {
      var data = {
        filterType: "",
        actionType: "",
        selectedItem: "",
      };
      this.activeFilter.push(data);
    },

    deleteFilterRow(i) {
      this.activeFilter.splice(i, 1);
      var usedFilters = [];
      for (let i = 0; i < this.activeFilter.length; i++) {
        if (
          this.activeFilter[i].filterType != "" &&
          this.activeFilter[i].actionType != ""
        ) {
          usedFilters.push(this.activeFilter[i]);
        }
      }
      var filter = JSON.parse(JSON.stringify(usedFilters));
      var data = {
        filterData: filter,
        operator: this.operatopType,
      };
      this.setFilterData(data);
      if (filter.length == 0) {
        this.filterActive = false;
      }
    },

    saveFilter() {
      var usedFilters = [];
      for (let i = 0; i < this.activeFilter.length; i++) {
        if (
          this.activeFilter[i].filterType != "" &&
          this.activeFilter[i].actionType != ""
        ) {
          this.dateFilter(this.activeFilter[i]);
          usedFilters.push(this.activeFilter[i]);
        }
      }
      var filter = JSON.parse(JSON.stringify(usedFilters));
      var data = {
        filterData: filter,
        operator: this.operatopType,
      };
      this.setFilterData(data);
      this.filterMenu = false;
      this.filterActive = true;
      if (filter.length == 0) {
        this.filterActive = false;
      }
    },
  },
};
</script>