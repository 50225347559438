<template>
  <div class="grow">
    <v-list dense>
      <v-subheader>
        FAVOURITES
      </v-subheader>
      <v-list-item-group
        v-model="favourite"
        :mandatory="mandatory"
        :color="getAppearances.btn_color"
      >
        <v-list-item
          v-for="(favourite, i) in favourites"
          :key="i"
          @click="upperActions(favourite)"
        >
          <v-list-item-icon>
            <v-icon v-text="favourite.icon">

            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="favourite.text">

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list dense>
      <v-subheader>
        DRIVE
      </v-subheader>
    </v-list>
    <v-treeview
      :open.sync="openedNodes"
      :active="active"
      :items="getTreeview"
      :load-children="fetchFiles"
      v-on:update:active="activeChanged"
      :color="getAppearances.btn_color"
      item-key="id"
      return-object
      activatable
      hoverable
      transition
      dense
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="item.children && item.type == 'inode/directory'">mdi-folder</v-icon>
        <v-icon v-else>mdi-harddisk</v-icon>
      </template>
      <template v-slot:label="{ item }">
        <div
          v-if="item.type == 'cloud#drive'"
          class="text-body-2"
        >
          {{item.name}}
        </div>
        <div
          v-else
          class="text-body-2"
        >
          {{item.name}}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";
import { sort } from "../../mixins/sort";
import { navigation } from "../../mixins/navigation";
export default {
  data: function () {
    return {
      // open: [],
      active: [],
      mandatory: false,
      favourite: false,
      favourites: [
        {
          text: "Shared with me",
          icon: "mdi-account-multiple-outline",
          name: "shared",
        },
        { text: "Recent", icon: "mdi-history", name: "recent" },
        { text: "Favourites", icon: "mdi-star", name: "favourites" },
        { text: "Trash", icon: "mdi-delete", name: "trash" },
      ],
    };
  },

  mixins: [utils, sort, navigation],

  computed: {
    ...mapGetters("files", [
      "getTreeview",
      "getPerPage",
      "getSelection",
      "open",
      "path",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("office", ["getOpened"]),

    openedNodes: {
      set(v) {
        this.$store.commit("files/setOpen", v);
      },
      get() {
        return this.open;
      },
    },
  },

  methods: {
    upperActions(favourite) {
      if (this.$route.name == favourite.name) {
        return;
      }

      if (this.$route.name != favourite.name) {
        this.$store.commit("files/setLoaded", false);
        this.$store.commit("files/setFiles", []);
      }

      this.$store.commit("files/setSelectedFile", {});
      this.$store.commit("files/setSelection", []);

      var item = {};
      this.active = [];
      this.mandatory = true;

      switch (favourite.name) {
        case "shared":
          item = { id: "share", name: "Shared" };
          this.navigateToRoute({ name: "shared" });
          break;
        case "recent":
          item = { id: "recents", name: "Recents" };
          this.navigateToRoute({ name: "recent" });
          break;
        case "favourites":
          item = { id: "favourites", name: "Favourites" };
          this.navigateToRoute({ name: "favourites" });
          break;
        case "trash":
          item = { id: "trash", name: "Trash" };
          this.navigateToRoute({ name: "trash" });
          break;
        default:
          break;
      }

      this.$store.commit("toolbar/setBreadcrumbs", [item]);
    },

    async activeChanged(active) {
      this.getTreeview.forEach((element) => {
        if (active[0] != undefined && element.id == active[0].id) {
          this.$store.commit("toolbar/setBreadcrumbs", [element]);
          return;
        }
      });

      this.$store.commit("files/setSelection", []);
      if (active[0] != undefined && active[0].id) {
        this.mandatory = false;
        this.favourite = false;
        this.$store.commit("files/setPath", active[0].id);
        this.$router.push({ name: "files", params: { path: this.path } });
        var item = this.findItem("id", active[0].id, this.getTreeview);
        this.$store.commit("files/setSelectedFile", item);
        if (item.type !== "cloud#drive") {
          this.$store.dispatch("files/getDetails");
          this.$store.dispatch("files/getActivity");
        }
      }
    },

    async fetchFiles(item) {
      await this.$store.dispatch("files/getFiles", item.id).then((result) => {
        if (item.children.length == 0) {
          result.data.forEach((element) => {
            if (element.isFolder == 1) {
              element.children = [];
              item.children.push(element);
            }
          });

          this.sortByName(item.children, "desc");
        }
      });
    },
  },
};
</script>

<style>
.v-treeview-node__root > button::after {
  color: rgba(0, 0, 0, 0);
}
</style>