<template>
  <v-list-item :style="message.is_read ? '' : 'background-color: #ECEFF1;'">
    <v-list-item-avatar>
      <v-img :src="`/api/users/photo/${user.photo}`"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-text="user.name + ' ' + user.lastname"></v-list-item-title>
      <v-list-item-subtitle>{{message.message}}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text>{{ getDateAndTime(message.created) }}</v-list-item-action-text>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="getAppearances.btn_color"
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="grey lighten-1">
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="dialogView = true">
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>
          <v-list-item @click="readNotification(message)">
            <v-list-item-title>Mark as read</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteNotification(message)">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
    <v-dialog
      v-model="dialogView"
      persistent
      max-width="650"
    >
      <v-card>
        <v-card-title class="text-h5">
          Notification
        </v-card-title>
        <v-card-text> {{ message.message }} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogView = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";

export default {
  props: { message: Object, user: Object },

  data: function () {
    return {
      dialogView: false,
    };
  },

  mixins: [dateUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    readNotification(message) {
      this.$emit("read", message);
    },

    deleteNotification(message) {
      this.$emit("delete", message);
    },
  },
};
</script>
