import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    user: {},
    isAuthenticated: false,
    name: '',
    users: [],
    timezone: {},
    preferences: {
      DisplaySettings: [],
      Notifications: [],
    },
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getUser(state) {
      return state.user;
    },
    getUsers(state) {
      return state.users;
    },
    getUserById: (state) => (id) => {
      return state.users.find(user => user.id === id)
    },
    // getUserByID(state, id) {
    //   for (let i = 0; i < state.users.length; i++) {
    //     if (state.users[i].id == id) {
    //       return state.users[i];
    //     }
    //   }
    // },
    getTimezone(state) {
      //Atjaunot timezone, ja nesakrīt?
      if (state.user.timezone.useAutomaticTimezone == "true" && state.user.timezone.automaticTimezone != "") {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      if (state.user.timezone.manualTimezone != "") {
        return state.user.timezone.manualTimezone;
      }

      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    getAutomaticTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    getClockDisplay(state) {
      for (let i = 0; i < state.preferences.DisplaySettings.length; i++) {
        if (state.preferences.DisplaySettings[i].name == "clock_display") {
          return state.preferences.DisplaySettings[i].value;
        }
      }

      return "24h";
    },
    getPreferences(state) {
      return state.preferences
    }
  },

  mutations: {
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUsers(state, users) {
      state.users = users
    },
    clearAuth(state) {
      state.isAuthenticated = false;
      state.user = {};
    },
    setTimezone(state, timezone) {
      state.timezone = timezone;
    },
    setPreferences(state, preferences) {
      state.preferences = preferences;
    },
    setClock(state, clock) {
      for (let i = 0; i < state.preferences.DisplaySettings.length; i++) {
        if (state.preferences.DisplaySettings[i].name == "clock_display") {
          state.preferences.DisplaySettings[i].value = clock;
        }
      }
    },
  },

  actions: {
    checkSession(context) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/auth/session',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit('setUser', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },

    logIn(context, credentials) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/auth/login',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            email: credentials.email,
            password: credentials.password,
            token: credentials.token,
          }
        };
        axios(options)
          .then(response => {
            context.commit('setIsAuthenticated', true);
            context.commit('setUser', response.data);
            context.dispatch('alert/showAlert', {
              color: 'green',
              text: 'Welcome, ' + response.data.name + ' ' + response.data.lastname
            }, { root: true })
            resolve();
          }).catch((error) => {
            reject(error);
          });
      })
    },

    logOut(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/auth/logout',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {}
        };
        axios(options)
          .then(() => {
            context.dispatch('clearUserData');
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to log out!'
            }, { root: true })
          });
      })
    },

    clearUserData(context) {
      context.commit('clearAuth');
      context.commit('files/setFiles', [], { root: true })
      context.commit('toolbar/setBreadcrumbs', [], { root: true })
    },

    getUsers(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/get-users',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit('setUsers', response.data);
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to load Users'
            }, { root: true })
          });
      })
    },

    getMySessions(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/sessions',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.text
            }, { root: true })
          });
      })
    },

    getMyPreferences(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/me/preferences',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit("setPreferences", response.data);
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    updateMyPreferences(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/me/preferences',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            preferences: data
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    logOutSession(context, key) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/sessions/' + key + '/log-out',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {}
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update Name'
            }, { root: true })
          });
      })
    },

    updateName(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/update-name',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id,
            name: data.name,
            lastname: data.lastname,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update Name'
            }, { root: true })
          });
      })
    },

    updateEmail(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/update-email',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id,
            email: data.email,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update Email'
            }, { root: true })
          });
      })
    },

    updatePassword(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/update-password',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id,
            password: data.password,
            oldPassword: data.oldPassword,
            email: data.email,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    resetPassword(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/reset-password',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            id: data.id
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.text
            }, { root: true })
          });
      })
    },

    updatePhoto(context, formData) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/upload-photo',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
        };
        axios(options)
          .then(response => {
            resolve(response)
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to upload'
            }, { root: true })
          });
      })
    },

    updateTimezone(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/users/timezone',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            automaticTimezone: data.automaticTimezone,
            manualTimezone: data.manualTimezone,
            useAutomaticTimezone: data.useAutomaticTimezone,
            clockDisplay: data.clockDisplay,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update timezone'
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
