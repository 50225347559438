<template>
  <v-dialog
    v-model="showDeleteDialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title class="text-h5 px-4">
        Delete custom field?
      </v-card-title>
      <v-card-text class="px-4">All tasks will lose this field entry</v-card-text>
      <v-card-text
        v-if="ifGlobalField()"
        class="px-4"
      >
        Deleting global metadata may require deliting header manualy
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="showDeleteDialog = false"
          class="mb-2 mr-1"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red"
          text
          @click="remove()"
          class="mb-2"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    deleteConfirmation: Boolean,
    deleteFieldID: String,
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", ["getHeaders"]),

    showDeleteDialog: {
      get() {
        return this.deleteConfirmation;
      },
      set(value) {
        this.$emit("update:deleteConfirmation", value);
      },
    },
  },

  methods: {
    ...mapMutations("projects", ["setFieldUpdate"]),

    ifGlobalField() {
      if (this.customFields(this.deleteFieldID).project_id == "Global") {
        return true;
      }
      return false;
    },

    remove() {
      this.$store.dispatch("meta/delete", this.deleteFieldID).then(() => {
        let index = this.getHeaders
          .map((item) => item.value)
          .indexOf(this.deleteFieldID);
        this.getHeaders.splice(index, 1);
        this.setFieldUpdate(true);
      });
      this.showDeleteDialog = false;
    },
  },
};
</script>
