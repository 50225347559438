<template>
  <v-container fluid>
    <v-col
      v-if="projectsLoaded"
      class="pa-0"
      cols="12"
    >
      <v-row class="d-flex bg-surface-variant pa-0 ma-0">
        <h3 class="font-weight-medium">Detailed task report</h3>
        <v-spacer />
        <v-btn
          v-if="selectedTasks.length != 0"
          :color="getAppearances.btn_color"
          dark
          small
          @click="exportCsv()"
        >
          <v-icon start>
            mdi-export
          </v-icon>
          CSV
        </v-btn>

      </v-row>
      <v-select
        v-model="filteredProjects"
        @change="loadProjects()"
        :items="allProjects"
        item-text="title"
        item-value="id"
        label="Projects"
        multiple
        style="z-index: 11;"
      >
        <template v-slot:prepend-item>
          <v-list-item
            ripple
            @mousedown.prevent
            @click="toggle"
          >
            <v-list-item-action>
              <v-icon :color="filteredProjects.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
    <v-row
      class="ma-0"
      v-if="filteredProjects.length != 0 && loaded"
      style="font-size: 13px;"
    >
      <TaskFilter
        :position="'reporting'"
        :globalHeaders="headers"
        :members.sync="members"
        style="z-index: 12;"
      />
      <div class="mt-1">
        Task Count: <b>{{ filtredTaskCount() }}</b>
      </div>
    </v-row>
    <v-row class="fill-width mb-6 mt-0 mr-0">
      <div
        v-if="loaded"
        class="pb-12"
      >
        <v-col>
          <div class="stickyHeaders">
            <th
              v-for="header in headers"
              :key="header.value"
              class="stickyTitle"
            >
              <div
                :style="header.name == 'Title' ? 'width: '+titleWidth()+';' : 'width: 136px;'"
                class="my-3"
              >
                <div class="mx-3 text-left thFont">
                  {{ header.name }}
                </div>
              </div>
            </th>
          </div>
          <div
            v-for="item in selectedTasks"
            :key="item.id"
          >
            <div
              v-show="taskFilter(item) && item.trash == false"
              class="taskBorder"
            >
              <v-card
                tile
                color="white"
                class="d-flex align-center my-1"
                elevation="0"
              >
                <v-row class="mx-0 flex-nowrap">
                  <div
                    v-for="header in headers"
                    :key="header.value"
                    class="stickyTitleItems"
                    style="margin-top: 2px;"
                  >
                    <div
                      v-if="header.value == 'title'"
                      :style="'width: '+titleWidth()+'; background-color: white;'"
                    >
                      <div
                        class="ma-3"
                        style="font-size: 14px;"
                      >
                        {{ item[header.value] }}
                      </div>
                    </div>
                    <div
                      v-else-if="header.value == 'project_id'"
                      style="width: 136px;"
                    >
                      <div
                        class="ma-3"
                        style="font-size: 14px;"
                      >
                        {{ projectName(item[header.value]) }}
                      </div>
                    </div>
                    <div
                      v-else-if="header.value == 'start_date' || header.value == 'end_date' || header.value == 'updated' || header.value == 'created'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value] != ''"
                        class="ma-3 text-capitalize font-weight-regular dateFont"
                      >
                        {{ dateFormat(item[header.value]) }}
                      </div>
                      <v-icon
                        v-else
                        class="mx-2 my-3"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="header.value == 'assigned_to'"
                      style="width: 136px;"
                    >
                      <v-avatar
                        size="24"
                        :class="item[header.value] == '' ? 'mx-2 my-3' : 'ma-3'"
                        :tile="item[header.value] == '' ? true : false"
                      >
                        <v-icon
                          size="24"
                          v-if="item[header.value] == ''"
                        >
                          mdi-minus
                        </v-icon>
                        <v-img
                          v-else
                          size="22"
                          :src="`/api/users/photo/${assignedTo(item)}`"
                        >
                        </v-img>
                      </v-avatar>
                    </div>
                    <div
                      v-else-if="header.value == 'status'"
                      style="width: 136px;"
                    >
                      <div
                        v-for="status in taskStatusList"
                        :key="status.value"
                      >
                        <v-btn
                          v-if="item[header.value] == status.value"
                          x-small
                          :color="status.color"
                          class="ma-3"
                          dark
                          elevation="0"
                        >
                          {{ status.name }}
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Checkbox'"
                      style="width: 136px;"
                    >
                      <v-checkbox
                        v-model="item[header.value]"
                        false-value="false"
                        true-value="true"
                        hide-details
                        dense
                        class="mx-3 mt-2 mb-3"
                        :ripple="false"
                        readonly
                      ></v-checkbox>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Labels'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value].length > 0"
                        class="mt-2"
                      >
                        <div
                          v-for="val, index in item[header.value]"
                          :key="val"
                        >
                          <v-btn
                            x-small
                            :class="index < item[header.value].length-1 ? 'mt-1 mx-3' : 'mx-3 mt-1 mb-4'"
                            dark
                            :color="customLables(val, header.value)"
                            :max-width="122"
                            elevation="0"
                          >
                            <div
                              class="text-truncate"
                              style="max-width:105px"
                            >
                              {{ val }}
                            </div>
                          </v-btn>
                        </div>
                      </div>
                      <v-icon
                        v-else
                        size="24"
                        class="my-3 ma-2"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Dropdown'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value] != ''"
                        style="font-size: 13px;"
                        class="ma-3"
                      >
                        {{ item[header.value] }}
                      </div>
                      <v-icon
                        v-else
                        size="24"
                        class="my-3 ma-2"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Text'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value] != ''"
                        style="font-size: 13px;"
                        class="ma-3"
                      >
                        {{ item[header.value] }}
                      </div>
                      <v-icon
                        v-else
                        size="24"
                        class="my-3 ma-2"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Currency'"
                      style="width: 136px;"
                    >
                      <v-row
                        v-if="item[header.value] != ''"
                        class="d-flex align-center my-3 mx-1"
                      >
                        <v-icon
                          size="14"
                          class="mr-1"
                          right
                        >
                          mdi-currency-eur
                        </v-icon>
                        <div style="font-size: 13px;">
                          {{ item[header.value] }}
                        </div>
                      </v-row>
                      <v-icon
                        v-else
                        size="24"
                        class="my-3 ma-2"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Date'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value] != ''"
                        class="ma-3 text-capitalize font-weight-regular dateFont"
                      >
                        {{ dateFormat(item[header.value]) }}
                      </div>
                      <v-icon
                        v-else
                        class="mx-2 my-3"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Priority'"
                      style="width: 136px;"
                    >
                      <v-btn
                        v-if="item[header.value] != ''"
                        x-small
                        :color="priorityColor(item[header.value])"
                        class="ma-3"
                        dark
                      >
                        {{ item[header.value] }}
                      </v-btn>
                      <v-icon
                        v-else
                        class="mx-2 my-3"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                    <div
                      v-else-if="customFields(header.value).type == 'Number'"
                      style="width: 136px;"
                    >
                      <div
                        v-if="item[header.value] != ''"
                        style="font-size: 13px;"
                        class="ma-3"
                      >
                        {{ item[header.value] }}
                      </div>
                      <v-icon
                        v-else
                        size="24"
                        class="my-3 ma-2"
                      >
                        mdi-minus
                      </v-icon>
                    </div>
                  </div>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { projectUtils } from "../../../../mixins/project";
import { dateUtils } from "../../../../mixins/date";
import TaskFilter from "../../../../components/Projects/Menu/TaskFilter.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TaskFilter,
  },

  data() {
    return {
      allProjects: [],
      selectedTasks: [],
      members: [],
      loaded: false,
      headers: [
        { value: "title", name: "Title" },
        { value: "project_id", name: "Project" },
        { value: "start_date", name: "Start Date" },
        { value: "end_date", name: "Due Date" },
        { value: "assigned_to", name: "Assignee" },
        { value: "status", name: "Status" },
        { value: "updated", name: "Updated" },
        { value: "created", name: "Created" },
      ],
      filteredProjects: [],
      selectedMeta: [],
      projectsLoaded: false,
      isMobile: false,
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUsers"]),
    ...mapGetters("projects", ["getFilterData"]),

    allProjectsSelected() {
      return this.filteredProjects.length === this.allProjects.length;
    },
    icon() {
      if (this.allProjectsSelected) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  mixins: [projectUtils, dateUtils],

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.projectList();
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    buildTableData() {
      const tasks = [];
      const headers = [];

      for (const header of this.headers) {
        headers.push(header.name);
      }

      for (const task of this.selectedTasks) {
        const taskData = [];
        for (const header of this.headers) {
          if (header.value === "assigned_to") {
            const user = this.getUsers.find(
              (user) => user.id === task.assigned_to
            );
            taskData.push(user ? user.name + " " + user.lastname : "");
          } else if (header.value === "project_id") {
            const project = this.allProjects.find(
              (project) => project.id === task.project_id
            );
            taskData.push(project ? project.title : "");
          } else if (
            this.customFields(header.value).type == "Date" ||
            header.value == "start_date" ||
            header.value == "end_date" ||
            header.value == "updated" ||
            header.value == "created"
          ) {
            this.getDateAndTime(task[header.value]);
            taskData.push(this.getDateAndTime(task[header.value]));
          } else if (this.customFields(header.value).type == "Labels") {
            const labels = task[header.value] || [];
            taskData.push(labels.join(" / "));
          } else {
            taskData.push(task[header.value]);
          }
        }
        tasks.push(taskData);
      }

      return { tasks: tasks, headers: headers };
    },

    exportCsv() {
      const headers = this.buildTableData().headers;
      const rows = [
        headers,
        ...this.buildTableData().tasks.map((obj) => Object.values(obj)),
      ];
      const csv = rows
        .map((row) =>
          row.map((elem) => (elem.includes(",") ? `"${elem}"` : elem)).join(",")
        )
        .join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Tasks.csv");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    titleWidth() {
      if (this.isMobile) {
        return "170px";
      } else {
        return "350px";
      }
    },

    async projectList() {
      this.$store.dispatch("projects/getAllProjects").then((response) => {
        this.allProjects = response.data;
        this.projectsLoaded = true;
      });
    },

    projectName(item) {
      for (let i = 0; i < this.allProjects.length; i++) {
        if (this.allProjects[i].id == item) {
          return this.allProjects[i].title;
        }
      }
    },

    filtredTaskCount() {
      var taskCount = [];
      for (let i = 0; i < this.selectedTasks.length; i++) {
        if (
          this.taskFilter(this.selectedTasks[i]) &&
          this.selectedTasks[i].trash == false
        ) {
          taskCount.push(this.selectedTasks[i]);
        }
      }
      return taskCount.length;
    },

    toggle() {
      this.$nextTick(() => {
        var projects = [];
        if (this.allProjectsSelected) {
          this.filteredProjects = [];
        } else {
          for (let i = 0; i < this.allProjects.length; i++) {
            projects.push(this.allProjects[i].id);
          }
          this.filteredProjects = projects.slice();
        }
        this.loadProjects();
      });
    },

    loadProjects() {
      this.loaded = false;
      this.$store
        .dispatch("projects/getTasksInProjects", {
          searchType: "project",
          ids: this.filteredProjects,
        })
        .then((response) => {
          this.selectedTasks = response.data;
          this.globalCustomFields().then(() => {
            var members = [];
            var list = [];
            for (let i = 0; i < this.filteredProjects.length; i++) {
              this.$store
                .dispatch(
                  "projects/getProjectMembers",
                  this.filteredProjects[i]
                )
                .then((response) => {
                  list.push(response.data);
                  members = [].concat.apply([], list);
                });
              this.$store
                .dispatch("projects/getSelectedMeta", this.filteredProjects[i])
                .then((response) => {
                  for (let i = 0; i < this.selectedTasks.length; i++) {
                    for (let j = 0; j < response.data.length; j++) {
                      if (
                        this.selectedTasks[i].id == response.data[j].item_id
                      ) {
                        var key = response.data[j].metadata_id.toString();
                        if (
                          this.customFields(key).type == "Labels" &&
                          Array.isArray(JSON.parse(response.data[j].value)) ==
                            false
                        ) {
                          this.selectedTasks[i][key] = [
                            JSON.parse(response.data[j].value),
                          ];
                        } else {
                          this.selectedTasks[i][key] = JSON.parse(
                            response.data[j].value
                          );
                        }
                      }
                    }
                  }
                  if (i == this.filteredProjects.length - 1) {
                    const uniqueMembers = Array.from(
                      new Set(members.map((a) => a.id))
                    ).map((id) => {
                      return members.find((a) => a.id === id);
                    });
                    this.members = uniqueMembers;
                    this.loaded = true;
                  }
                });
            }
          });
        });
    },

    assignedTo(item) {
      var photo = "";
      for (let i = 0; i < this.getUsers.length; i++) {
        if (this.getUsers[i].id == item.assigned_to) {
          photo = this.getUsers[i].photo;
        }
      }
      return photo;
    },

    async globalCustomFields() {
      var fields = [];
      this.$store.dispatch("projects/getMetaList", "Global").then(() => {
        for (let i = 0; i < this.getMetaList.length; i++) {
          var header = {
            value: this.getMetaList[i].id,
            name: this.getMetaList[i].title,
            show: false,
          };
          fields.push(header);
        }
        const difference = fields.filter(
          ({ value: id1 }) =>
            !this.headers.some(({ value: id2 }) => id2 === id1)
        );
        for (let j = 0; j < difference.length; j++) {
          this.headers.push(difference[j]);
        }

        for (let i = 0; i < this.selectedTasks.length; i++) {
          for (let j = 0; j < this.getMetaList.length; j++) {
            this.selectedTasks[i][this.getMetaList[j].id.toString()] = "";
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.fill-width {
  overflow-x: auto;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 75vh;
}

.taskBorder {
  border: 1px solid rgb(245, 245, 245);
}

.taskBorder:hover {
  border: 1px solid #d8d8d8;
}

.dateFont {
  font-size: 13px;
  cursor: pointer;
}

.thFont {
  font-weight: normal;
  color: rgb(88, 88, 88);
  font-size: 14px;
}

.stickyHeaders {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 10;
}

.stickyTitle:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 8;
}

.stickyTitleItems:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
}
</style>