<template>
  <v-menu
    v-if="loaded"
    :close-on-content-click="false"
    offset-y
    absolute
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row
        v-if="placement == 'list'"
        class="ml-3 mr-0 mt-n1"
      >
        <v-btn
          v-if="selectedTask[header.value].length == 0"
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          @click="editing = selectedTask.id"
          class="mx-n1 marginIcon"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <div
          v-for="val, index in selectedTask[header.value]"
          :key="val"
          @click="editing = selectedTask.id"
        >
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            :class="index < selectedTask[header.value].length-1 ? 'mt-1 mr-1' : 'mt-1 mb-4'"
            dark
            :color="customLables(val, header.value)"
            :max-width="122"
            elevation="0"
            @click="editing = selectedTask.id"
          >
            <div
              class="text-truncate"
              style="max-width:105px"
            >
              {{ val }}
            </div>
          </v-btn>
        </div>
      </v-row>
      <v-row
        v-else-if="placement == 'fullTask'"
        class="ml-3 mr-0 mt-n1 mb-0 overflow-y-visible py-3"
      >
        <v-btn
          v-if="selectedTask[header.value].length == 0"
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          class="mx-n1"
          @click="editing = selectedTask.id"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <div
          v-for="val, index in selectedTask[header.value]"
          :key="val"
          @click="editing = selectedTask.id"
        >
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            :class="index < selectedTask[header.value].length-1 ? 'mt-1 mr-1' : 'mt-1'"
            dark
            :color="customLables(val, header.value)"
            elevation="0"
            @click="editing = selectedTask.id"
          >
            {{val}}
          </v-btn>
        </div>
      </v-row>
    </template>
    <v-list>
      <v-list-item
        v-for="label in customFields(header.value).fields"
        :key="label.name"
      >
        <v-list-item-title>
          <v-row class="ma-0 pa-0">
            <div class="mr-1">
              {{ label.name }}
            </div>
            <v-btn
              v-if="inLabelList(label.name, selectedTask[header.value])"
              icon
              x-small
              :color="customLables(label.name, header.value)"
              class="mt-0"
              @click="deleteLabel(label.name, selectedTask, header.value)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              x-small
              :color="customLables(label.name, header.value)"
              @click="addLabel(label.name, header.value, selectedTask[header.value])"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { projectUtils } from "../../../mixins/project";
import { mapGetters } from "vuex";

export default {
  name: "Labels",
  props: {
    header: Object,
    selectedTask: Object,
    placement: String,
  },

  data: () => ({
    loaded: false,
  }),

  mixins: [projectUtils],

  watch: {
    "$store.state.projects.headers": function () {
      this.bindValue();
    },
  },

  computed: {
    ...mapGetters("projects", ["getColumns"]),
  },

  mounted() {
    this.bindValue();
  },

  methods: {
    bindValue() {
      if (this.customFields(this.header.value).type == "Labels") {
        if (this.selectedTask[this.header.value] != "") {
          var inArray = Array.isArray(this.selectedTask[this.header.value]);
          if (inArray == false) {
            this.selectedTask[this.header.value] = [
              this.selectedTask[this.header.value],
            ];
          } else {
            this.selectedTask[this.header.value] =
              this.selectedTask[this.header.value];
          }
        }
      }
      this.loaded = true;
    },

    addLabel(item, value) {
      if (this.selectedTask[value].length == 0) {
        this.selectedTask[value] = [];
      }

      this.selectedTask[value].push(item);
      this.selectedTask[value] = [...this.selectedTask[value]];

      for (let i = 0; i < this.getColumns.length; i++) {
        for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
          if (value != undefined) {
            if (this.getColumns[i].tasks[j].id == this.selectedTask.id) {
              this.getColumns[i].tasks[j][value] = this.selectedTask[value];
            }
          }
        }
      }
      this.saveFields(this.selectedTask, value);
    },

    deleteLabel(item, task, value) {
      task[value].splice(task[value].indexOf(item), 1);
      task[value] = [...task[value]];
      this.saveFields(task, value);
    },

    inLabelList(item, value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] == item) {
          return true;
        }
      }
    },
  },
};
</script>

<style scoped>
.labelOverflow {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.marginIcon {
  margin-top: 2px;
}

</style>