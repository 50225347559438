var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"disable-resize-watcher":"","app":"","right":"","bottom":"","width":"350","height":"100%"},model:{value:(_vm.toggleDetails),callback:function ($$v) {_vm.toggleDetails=$$v},expression:"toggleDetails"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in","hide-on-leave":""}},[(!_vm.isSelectedFile)?_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-eye-off")]),_c('p',[_vm._v("Select item in browser to view info")])],1)],1)],1):_vm._e()],1),(_vm.isSelectedFile)?_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.selectedFile.name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDetails}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-scroll-y-transition',{attrs:{"mode":"out-in","hide-on-leave":""}},[(_vm.isSelectedFile)?_c('div',[_c('v-tabs',{attrs:{"background-color":_vm.getAppearances.btn_color,"dark":"","grow":""},model:{value:(_vm.getTab),callback:function ($$v) {_vm.getTab=$$v},expression:"getTab"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-tab',[_vm._v(" Details ")]),_c('v-tab',[_vm._v(" Activities ")])],1),_c('v-tab-item',[_c('v-card',{staticClass:"pb-2 pt-2",attrs:{"flat":""}},[(
                _vm.fileTypes[_vm.selectedFile.type] !== undefined &&
                _vm.fileTypes[_vm.selectedFile.type].fileType == 'image'
              )?_c('v-img',{attrs:{"contain":"","centered":"","width":"500","height":"200","src":`/api/files/thumbnails/${_vm.selectedFile.id}`}}):(
                _vm.fileTypes[_vm.selectedFile.type] !== undefined &&
                _vm.fileTypes[_vm.selectedFile.type].fileType !== 'image'
              )?_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"128","color":_vm.fileTypes[_vm.selectedFile.type].color}},[_vm._v(" "+_vm._s(_vm.fileTypes[_vm.selectedFile.type].icon)+" ")])],1):_c('v-row',{attrs:{"justify":"center","centered":""}},[_c('v-icon',{attrs:{"centered":"","size":"128","color":_vm.fileTypes['unknown'].color}},[_vm._v(" "+_vm._s(_vm.fileTypes["unknown"].icon)+" ")])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-list',{staticClass:"transparent mb-3",attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Type")]),(_vm.fileTypes[_vm.selectedFile.type] !== undefined)?_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.fileTypes[_vm.selectedFile.type].fileType)+" ")]):_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" Unknown filetype ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Size")]),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.convert(_vm.selectedFile.size))+" ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Owner")]),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getOwner())+" ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Modified")]),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getDateAndTime(_vm.selectedFile.modified))+" ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Created")]),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getDateAndTime(_vm.selectedFile.created))+" ")])],1),_c('Metadata'),_vm._l((_vm.selectedFile.metadata),function(item){return _c('div',{key:item.id},[(item.type == 'selection')?_c('div',{staticClass:"mx-4"},[_c('v-select',{attrs:{"disabled":_vm.getUser.id == _vm.selectedFile.owner
                        ? false
                        : !_vm.selectedFile.allowEditMeta,"items":item.fields,"color":_vm.getAppearances.btn_color,"label":item.title,"prepend-icon":"mdi-arrow-down","return-object":""},on:{"change":function($event){return _vm.updateMeta(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(item.type == 'text')?_c('div',{staticClass:"mx-4"},[_c('v-textarea',{attrs:{"disabled":_vm.getUser.id == _vm.selectedFile.owner
                        ? false
                        : !_vm.selectedFile.allowEditMeta,"color":_vm.getAppearances.btn_color,"label":item.title,"prepend-icon":"mdi-message-text-outline","auto-grow":"","rows":"1","row-height":"15"},on:{"change":function($event){return _vm.updateMeta(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(item.type == 'color')?_c('div',{staticClass:"mx-4"},[_c('v-select',{attrs:{"disabled":_vm.getUser.id == _vm.selectedFile.owner
                        ? false
                        : !_vm.selectedFile.allowEditMeta,"items":item.fields,"color":_vm.getAppearances.btn_color,"item-text":"name","label":item.title,"prepend-icon":"mdi-arrow-down","return-object":""},on:{"change":function($event){return _vm.updateMeta(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(item.type == 'date')?_c('div',{staticClass:"mx-4"},[_c('v-menu',{attrs:{"disabled":_vm.getUser.id == _vm.selectedFile.owner
                        ? false
                        : !_vm.selectedFile.allowEditMeta,"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":item.title,"color":_vm.getAppearances.btn_color,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.updateMeta(item)},"input":function($event){_vm.menu = false}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)],1):_vm._e()])})],2),_c('v-row'),(
                _vm.getDetails.sharedUsers != undefined &&
                _vm.getDetails.sharedUsers.length > 0
              )?_c('div',{staticClass:"pt-2"},[_c('v-subheader',[_vm._v("File permissions")]),_vm._l((_vm.getDetails.sharedUsers),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":`/api/users/photo/${item.photo}`}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.email)}})],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selectedFile.owner == item.id,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openPermissions(item)}}},[_c('v-list-item-title',[_vm._v("Permissions")])],1)],1),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteShare(item)}}},[_c('v-list-item-title',[_vm._v("Unshare")])],1)],1)],1)],1)],1)})],2):_vm._e(),(
                _vm.getDetails.sharedLinks != undefined &&
                _vm.getDetails.sharedLinks.length > 0
              )?_c('div',{staticClass:"pt-2"},[_c('v-subheader',[_vm._v("External links")]),_vm._l((_vm.getDetails.sharedLinks),function(item){return _c('v-list-item',{key:item.id,staticClass:"py-0"},[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-text-field',{attrs:{"value":_vm.buildLink(item.id),"hint":item.name + ', ' + _vm.getDateAndTime(item.shareTime),"persistent-hint":"","color":_vm.getAppearances.btn_color,"single-line":"","dense":"","readonly":"","label":"Link"}})],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.copyToClipBoard(item.id)}}},[_c('v-list-item-title',[_vm._v("Copy")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openLinkPermissions(item)}}},[_c('v-list-item-title',[_vm._v("Permissions")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteLink(item)}}},[_c('v-list-item-title',[_vm._v("Unshare")])],1)],1)],1)],1)],1)})],2):_vm._e()],1)],1),_c('v-tab-item',[_c('ActivityList')],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }