<template>
  <v-timeline
    class="mr-1"
    v-if="getActivity.length > 0"
    align-top
    dense
  >
    <v-timeline-item
      v-for="(item, i) in getActivity"
      :key="i"
      :color="activityType[item.Type].color"
      small
      right
    >
      <v-col class="px-0 pb-0">
        <div v-if="item.Type == 'file_create'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> created new file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_upload'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> uploaded new file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'link_share'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> shared <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            > {{ item.Parameters.name }}</strong> <br> as link - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_delete'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> deleted file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_download'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> downloaded file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_public_download'">
          <div class="pb-0 mb-0 body-2">File <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> was downloaded from link - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_restore'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> restored file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            > {{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_rename'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> renamed file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.from }}</strong> <br> to <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.to }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_update'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> updated file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong> <br> - {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_meta'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> edited file metadata - <br> {{ getDateAndTime(item.Created) }}</div>
        </div>
        <div v-if="item.Type == 'file_share'">
          <div class="pb-0 mb-0 body-2"><strong>{{ item.UserName }}</strong> Shared file <br> <strong
              style="max-width: 250px;"
              class="d-inline-block text-truncate"
            >{{ item.Parameters.name }}</strong></div>
          <div
            class="pl-0"
            v-if="item.Type == 'file_share'"
          >
            <v-list-item
              class="pl-0"
              v-for="item in item.Parameters.users"
              :key="item.title"
            >
              <v-list-item-avatar size="30">
                <img
                  :src="`/api/users/photo/${item.photo}`"
                  alt="Missing"
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.name + ' ' + item.lastname"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-col>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";

export default {
  data: () => ({
    activityType: {
      file_create: { color: "green", icon: "mdi-file-plus" },
      file_meta: { color: "blue darken-4", icon: "mdi-tag" },
      file_upload: { color: "orange", icon: "mdi-file-plus" },
      file_delete: { color: "red", icon: "mdi-delete" },
      file_restore: { color: "teal", icon: "mdi-restore" },
      file_download: { color: "blue-grey", icon: "mdi-download" },
      file_public_download: { color: "blue-grey", icon: "mdi-download" },
      file_rename: { color: "yellow", icon: "mdi-pencil-plus" },
      file_share: { color: "primary", icon: "mdi-pencil-plus" },
      link_share: { color: "primary", icon: "mdi-pencil-plus" },
      file_update: { color: "cyan", icon: "mdi-pencil-plus" },
    },
  }),

  mixins: [dateUtils],

  computed: {
    ...mapGetters("files", ["getActivity"]),
  },

  methods: {},
};
</script>
