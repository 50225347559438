<template>
  <div>
    <div class="title">
      <h5 class="text-left font-weight-medium black--text">
        <v-row class="pa-0 ma-0">
          10 Recent activities
          <div class="ml-1"> {{selectedUser()}} </div>
        </v-row>
      </h5>
    </div>
    <v-card elevation="1">
      <v-simple-table
        fixed-header
        height="400px"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <h3>Activity Type</h3>
              </th>
              <th class="text-left">
                <h3>Filename</h3>
              </th>
              <th class="text-left">
                <h3>Time</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in fileActivities()"
              :key="item.id"
            >
              <td>{{ item.Type.replace('_', ' ') }}</td>
              <td>
                <div v-if="item.Type == 'file_rename'">
                  <div v-if="item.Parameters.from.length +  item.Parameters.to.length <= 40">
                    {{ item.Parameters.from }} to {{ item.Parameters.to }}
                  </div>
                  <div v-else-if="item.Parameters.from.length <= 20 && item.Parameters.to.length >= 20">
                    {{ item.Parameters.from }} to {{ item.Parameters.to.slice(0,18) + '...' }}
                  </div>
                  <div v-else-if="item.Parameters.from.length >= 20 && item.Parameters.to.length <= 20">
                    {{ item.Parameters.from.slice(0,18) + '...' }} to {{ item.Parameters.to }}
                  </div>
                  <div v-else>
                    {{ item.Parameters.from.slice(0,18) + '...' }} to {{ item.Parameters.to.slice(0,18) + '...' }}
                  </div>
                </div>
                <div v-else>
                  <div v-if="item.Parameters.name.length <= 40">
                    {{ item.Parameters.name }}
                  </div>
                  <div v-else>
                    {{ item.Parameters.name.slice(0,40) + '...' }}
                  </div>
                </div>
              </td>
              <td>{{ getDateAndTime(item.Created) }}</td>
            </tr>
            <v-col v-if="activities.length <= 0">
              <p>No User Activities to show</p>
            </v-col>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

  </div>
</template>

<script>
import { dateUtils } from "../../../mixins/date";

export default {
  props: {
    activities: Array,
    userID: String,
    users: Array,
  },

  data() {
    return {};
  },

  mixins: [dateUtils],

  mounted() {},

  methods: {
    fileActivities() {
      let fileActivities = [];
      for (let i = 0; i < this.activities.length; i++) {
        if (this.activities[i].App == "files") {
          fileActivities.push(this.activities[i]);
        }
      }

      return fileActivities;
    },

    selectedUser() {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id == this.userID) {
          return "(" + this.users[i].name + " " + this.users[i].lastname + ")";
        }
      }
    },
  },
};
</script>