<template>
  <v-dialog
    v-model="renameDialog"
    max-width="400"
    persistent
    transition
  >
    <v-card>
      <v-card-title class="headline">
        Rename
      </v-card-title>
      <v-card-text>
        <v-scroll-y-transition
          mode="out-in"
          hide-on-leave
        >
          <v-list>
            <v-list-item>
              <v-text-field
                v-model="changeName"
                label="Enter name"
                :color="getAppearances.btn_color"
                :rules="[ fileExists ]"
                @keyup.enter="rename"
              >
              </v-text-field>
            </v-list-item>
          </v-list>
        </v-scroll-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="close"
        >
          Cancel
        </v-btn>

        <v-btn
          :disabled="disableRename"
          :color="getAppearances.btn_color"
          class="white--text"
          @click="rename"
        >
          Rename
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { utils } from '../../mixins/utils';
export default {
  props: ['editItem', 'renameDialog'],

  data: function() {
    return {
      newName: '', 
      disableRename: true,
    }
  },

  mixins: [utils],

  computed: {
    ...mapGetters('files', [
      'getFiles',
      'getTreeview'
    ]),

    ...mapGetters('admin', [
      'getAppearances',
    ]),

    changeName: {
      get() {
        return this.editItem.name
      },

      set(val) {
        this.newName = val
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    rename() {
      var data = {
            name: this.newName,
            id: this.editItem.id,
          }
      
      this.$store
        .dispatch('files/renameFile', data)
        .then(() => {
          this.close()

          let array = this.getFiles;
          let item = this.findItem('id', this.editItem.id, array);
          if(item.isFolder) {
            let tree_item = this.findItem('id', this.editItem.id, this.getTreeview);
            if(tree_item) {
              tree_item.name = this.newName;
            }
          }
          
          item.name = this.newName;
        });
    },

    fileExists() {
      let item = this.findItem('name', this.newName, this.getFiles)

      if(item) {
        if(item.isFolder == true && this.editItem.isFolder == true) {
          if(item.id != this.editItem.id) {
            this.disableRename = true;
            return 'A folder with that name already exists'
          }
        } else if (item.isFolder == false && this.editItem.isFolder == false) {
          if(item.id != this.editItem.id) {
            this.disableRename = true;
            return 'A file with that name already exists';
          }
        }
      }

      if (this.renameName == this.editItem.name) {
        this.disableRename = true;
      } else {
        this.disableRename = false;
      }

      return true
    },

  }
}
</script>
