<template>
  <v-item-group>
    <v-row class="pa-3">
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
          @click="showDownloads"
        >
          <v-card-subtitle>DOWNLOADS</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{ downloadCount }}</h2>
            <div class="subtitle">
              Downloaded files <b>{{ dateText }}</b>
            </div>
          </v-card-text>
        </v-card>
        <DialogDownloads
          :downloads='files'
          :convertSize='convertSize'
          :dialogDownloads.sync='dialogDownloads'
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
          @click="showUploads"
        >
          <v-card-subtitle>UPLOADS</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{ uploadCount }}</h2>
            <div class="subtitle">
              Uploaded files <b>{{ dateText }}</b>
            </div>
          </v-card-text>
        </v-card>
        <DialogUploads
          :uploads='files'
          :convertSize='convertSize'
          :dialogUploads.sync='dialogUploads'
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
          @click="showShared"
        >
          <v-card-subtitle>SHARED</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{ shareCount }}</h2>
            <div class="subtitle">
              Shared files <b>{{ dateText }}</b>
            </div>
          </v-card-text>
        </v-card>
        <DialogShare
          :shared='files'
          :convertSize='convertSize'
          :dialogShare.sync='dialogShare'
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          outlined
          width="100%"
        >
          <v-card-subtitle>STORAGE</v-card-subtitle>
          <v-card-text class="py-2">
            <h2>{{ convertSize(storageSize) }}</h2>
            <div class="subtitle">
              Used storage
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
import DialogDownloads from "../Files/Dialogs/Downloads";
import DialogUploads from "../Files/Dialogs/Uploads";
import DialogShare from "../Files/Dialogs/Share";
import { file } from "../../../mixins/file";

export default {
  props: {
    downloads: Object,
    uploads: Object,
    shares: Object,
    storageSize: Number,
    dateText: String,
    periodTimestamp: Object,
    userID: String,
  },

  components: {
    DialogDownloads,
    DialogUploads,
    DialogShare,
  },

  data() {
    return {
      files: [],
      dialogDownloads: false,
      dialogUploads: false,
      dialogShare: false,
    };
  },

  mixins: [file],

  computed: {
    downloadCount() {
      let cnt = 0;
      for (const prop in this.downloads) {
        cnt += this.downloads[prop];
      }

      return cnt;
    },

    uploadCount() {
      let cnt = 0;
      for (const prop in this.uploads) {
        cnt += this.uploads[prop];
      }

      return cnt;
    },

    shareCount() {
      let cnt = 0;
      for (const prop in this.shares) {
        cnt += this.shares[prop];
      }

      return cnt;
    },
  },

  methods: {
    showShared() {
      if (this.userID == "") {
        this.userID = "all";
      }
      this.$store
        .dispatch("activity/getActivityByType", {
          type: "file_share",
          id: this.userID,
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.files = response.data;
          this.dialogShare = true;
        });
    },

    showDownloads() {
      if (this.userID == "") {
        this.userID = "all";
      }
      this.$store
        .dispatch("activity/getActivityByType", {
          type: "file_download",
          id: this.userID,
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.files = response.data;
          this.dialogDownloads = true;
        });
    },

    showUploads() {
      if (this.userID == "") {
        this.userID = "all";
      }
      this.$store
        .dispatch("activity/getActivityByType", {
          type: "file_upload",
          id: this.userID,
          periodTimestamp: this.periodTimestamp,
        })
        .then((response) => {
          this.files = response.data;
          this.dialogUploads = true;
        });
    },
  },
};
</script>

<style scoped>
.h1Size {
  font-size: 270%;
  font-weight: 200;
}

.disable-events {
  pointer-events: none;
}
</style>