<template>
  <div>
    <v-col
      cols="12"
      class="px-0"
    >
      <v-card
        tile
        elevation="0"
        v-click-outside="onClickOutside"
      >
        <v-row class="mx-0 taskBorder">
          <div style="width: 410px;">
            <div class="mx-3">
              <v-text-field
                dense
                v-model="taskTitle"
                class="mb-n2"
                placeholder="Title"
                @keydown.enter="newTask"
              ></v-text-field>
            </div>
          </div>
          <v-menu
            :close-on-click="false"
            v-model="assigneeMenu"
            :close-on-content-click="false"
            offset-y
            :key="refreshAssignee"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="assignSet == false"
                @click="assigneeMenu = true, validateAssignee = true"
                icon
                v-bind="attrs"
                v-on="on"
                small
                class="mt-1 mr-3"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
              <v-avatar
                @click="assigneeMenu = true, validateAssignee = true"
                v-else
                :color="getAppearances.btn_color"
                v-bind="attrs"
                v-on="on"
                size="20"
                class="mt-2 mr-3"
              >
                <v-img :src="`/api/users/photo/${assignedUser(getAssigned)}`">
                </v-img>
              </v-avatar>
            </template>
            <MemberSelect
              @close="assigneeMenu = false, assigneeClickOutside()"
              @save="saveAssign(), assigneeClickOutside()"
            />
          </v-menu>
          <v-menu
            :close-on-click="false"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            offset-y
            :key="refreshDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-if="dateSet == false">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="mt-1 mr-3"
                  @click="validateDate = true"
                >
                  <v-icon>mdi-calendar-plus</v-icon>
                </v-btn>
              </div>
              <div
                v-else
                align="left"
                class="mt-2 mr-3 text-capitalize font-weight-regular endDateFont"
                v-bind="attrs"
                v-on="on"
                @click="validateDate = true"
              >
                {{ endDate(date) }}
              </div>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @click="validateDate = true"
            >
              <v-spacer />
              <v-btn
                text
                @click="datePickerMenu = false, dateClickOutside()"
                class="mr-1 mb-3"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                :color="getAppearances.btn_color"
                @click="saveDate(), dateClickOutside()"
                class="mb-3 mr-3"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn
            icon
            small
            class="mt-1 mr-2"
            @click="closeCreate()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment";
import MemberSelect from "../Menu/MemberSelect";
import { projectUtils } from "../../../mixins/project";
import { mapGetters } from "vuex";

export default {
  components: {
    MemberSelect,
  },
  name: "NewTask",
  props: {
    closeCreate: Function,
    addTaskIndex: Number,
    taskPosition: String,
  },
  data: () => ({
    validateAssignee: false,
    validateDate: false,
    taskTitle: "",
    datePickerMenu: false,
    dateSet: false,
    date: new Date(Date.now()).toISOString().substr(0, 10),
    refreshDate: 0,
    dueDate: "",
    assigneeMenu: false,
    refreshAssignee: 9999,
    assignSet: false,
    selectedUser: "",
  }),

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", [
      "getAssigned",
      "getProject",
      "getColumns",
      "getProjectMembers",
    ]),

    ...mapGetters("user", ["getTimezone"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    saveDate() {
      this.datePickerMenu = false;
      this.dateSet = true;
      this.refreshDate += 1;
    },

    saveAssign() {
      this.assigneeMenu = false;
      this.assignSet = true;
      this.refreshAssignee += 1;
    },

    assigneeClickOutside() {
      setTimeout(() => {
        this.validateAssignee = false;
      }, 1000);
    },

    dateClickOutside() {
      setTimeout(() => {
        this.validateDate = false;
      }, 1000);
    },

    onClickOutside() {
      if (
        this.taskTitle != "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        this.newTask();
      } else if (
        this.taskTitle == "" &&
        this.validateAssignee == false &&
        this.validateDate == false
      ) {
        this.closeCreate();
      }
    },

    endDate(date) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);
      var formatedDate = timezoneDate.utc().format("X");
      this.dueDate = formatedDate;

      return this.dateFormat(formatedDate);
    },

    newTask() {
      if (this.taskTitle != "") {
        var task = {
          title: this.taskTitle,
          position: this.taskPosition,
          date: this.dueDate,
          index: this.addTaskIndex,
          type: "task",
        };
        this.createTask(task);
        this.taskTitle = "";
        this.dueDate = "";
        this.date = new Date(Date.now()).toISOString().substr(0, 10);
      }
      this.closeCreate();
    },
  },
};
</script>

<style scoped>
.taskBorder {
  border: 1px solid rgb(245, 245, 245);
}

.taskBorder:hover {
  border: 1px solid #e2e2e2;
}

.endDateFont {
  font-size: 13px;
  cursor: pointer;
}
</style>