<template>
  <div>
    <v-row class="ma-0 d-flex align-end">
      <v-col :cols="smallDisplay ? 12 : 3">
        <v-autocomplete
          v-model="selected"
          :color="getAppearances.btn_color"
          :items="users"
          label="Select a person"
          hide-details="auto"
          item-text="name"
          item-value="id"
          return-object
          no-data-text="No users available"
          @change="updateUser"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeUser(data.item)"
            >
              <v-avatar left>
                <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
              </v-avatar>
              {{ data.item.name }} {{data.item.lastname}}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-row class="pa-3">
                <v-list-item-avatar>
                  <img :src="`/api/users/photo/${data.item.photo}`">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name + ' ' + data.item.lastname + ` (${data.item.email})`"></v-list-item-title>
                </v-list-item-content>
              </v-row>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col :cols="smallDisplay ? 12 : 3">
        <v-select
          v-model="setPeriod"
          label="Activity period"
          hide-details="auto"
          :items="periodList"
          @change="updatePeriod"
        ></v-select>
      </v-col>

      <v-col
        v-if="setPeriod == 'year'"
        :cols="smallDisplay ? 12 : 3"
      >
        <v-select
          v-model="selectedYear"
          label="Year"
          :items="yearList"
          hide-details="auto"
          @change="periodFormat()"
        ></v-select>
      </v-col>

      <v-col
        v-if="setPeriod == 'exact date'"
        :cols="smallDisplay ? 12 : 3"
      >

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatted"
              label="Select date"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="menu = false"
              class="mb-3"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="$refs.menu.save(date), periodFormat()"
              :color="getAppearances.btn_color"
              class="mb-3 mr-3"
              dark
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="setPeriod == 'month'"
        :cols="smallDisplay ? 12 : 3"
      >

        <v-menu
          ref="menuMonthPicker"
          v-model="menuMonthPicker"
          :close-on-content-click="false"
          :return-value.sync="dateMonth"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormattedMonth"
              label="Select month"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateMonth"
            type="month"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="menuMonthPicker = false"
              class="mb-3"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="$refs.menuMonthPicker.save(dateMonth),periodFormat()"
              :color="getAppearances.btn_color"
              class="mb-3 mr-3"
              dark
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    users: Array,
    selectedPeriod: String,
    periodTimestamp: Object,
    dateText: String,
  },

  data() {
    return {
      selected: [],
      periodList: ["today", "exact date", "month", "year"],
      selectedYear: new Date().getFullYear(),
      yearList: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menuMonthPicker: false,
      dateMonth: new Date().toISOString().substr(0, 7),
      smallDisplay: false,
    };
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getTimezone"]),

    dateFormatted() {
      return this.date ? moment(this.date).format("MMMM Do YYYY") : "";
    },

    dateFormattedMonth() {
      return this.dateMonth ? moment(this.dateMonth).format("MMMM, YYYY") : "";
    },

    setPeriod: {
      get() {
        return this.selectedPeriod;
      },
      set(value) {
        this.$emit("update:selectedPeriod", value);
      },
    },
    setPeriodTimestamp: {
      get() {
        return this.periodTimestamp;
      },
      set(value) {
        this.$emit("update:periodTimestamp", value);
      },
    },
    setDateText: {
      get() {
        return this.dateText;
      },
      set(value) {
        this.$emit("update:dateText", value);
      },
    },
  },

  created() {
    this.createYearList();
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  watch: {},

  methods: {
    onResize() {
      this.smallDisplay = window.innerWidth < 960;
    },

    async periodFormat() {
      var start_timestamp = "";
      var end_timestamp = "";
      if (this.setPeriod == "year") {
        var year = moment(this.selectedYear + "-01-01").format(
          "YYYY-MM-DD" + " 00:00"
        );
        start_timestamp = moment
          .tz(year, this.getTimezone)
          .startOf("year")
          .format("X");
        end_timestamp = moment
          .tz(year, this.getTimezone)
          .endOf("year")
          .format("X");
      } else if (this.setPeriod == "exact date") {
        var exactDate = moment(this.date).format("YYYY-MM-DD" + " 00:00");
        start_timestamp = moment
          .tz(exactDate, this.getTimezone)
          .startOf("day")
          .format("X");
        end_timestamp = moment
          .tz(exactDate, this.getTimezone)
          .endOf("day")
          .format("X");
      } else if (this.setPeriod == "today") {
        var todayDate = moment().format("YYYY-MM-DD" + " 00:00");
        start_timestamp = moment
          .tz(todayDate, this.getTimezone)
          .startOf("day")
          .format("X");
        end_timestamp = moment
          .tz(todayDate, this.getTimezone)
          .endOf("day")
          .format("X");
      } else if (this.setPeriod == "month") {
        var month = moment(this.dateMonth).format("YYYY-MM-DD" + " 00:00");
        start_timestamp = moment
          .tz(month, this.getTimezone)
          .startOf("month")
          .format("X");
        end_timestamp = moment
          .tz(month, this.getTimezone)
          .endOf("month")
          .format("X");
      }

      var data = { start: start_timestamp, end: end_timestamp };
      this.setPeriodTimestamp = data;

      this.fetchData();
      this.createDateText(this.setPeriod);
    },

    createYearList() {
      var max = new Date().getFullYear();
      var min = max - 9;
      for (var i = max; i >= min; i--) {
        this.yearList.push(i);
      }
    },

    removeUser() {
      this.selected = {};
      this.$emit("fetch", "all");
    },

    async saveNewTime(time) {
      this.setPeriod = time;
    },

    fetchData() {
      let id = "all";
      if (this.selected.id != undefined) {
        id = this.selected.id;
      }
      this.$emit("fetch", id);
    },

    async setText(item) {
      this.setDateText = item;
    },

    createDateText() {
      var dateText = "";
      if (this.setPeriod == "today") {
        dateText = "today";
      } else if (this.setPeriod == "exact date") {
        dateText = this.dateFormatted;
      } else if (this.setPeriod == "month") {
        dateText = this.dateFormattedMonth;
      } else if (this.setPeriod == "year") {
        dateText = "Year " + this.selectedYear.toString();
      }
      this.setText(dateText);
    },

    updatePeriod(time) {
      this.saveNewTime(time).then(() => {
        this.periodFormat();
      });
    },

    updateUser(item) {
      this.$emit("fetch", item.id);
    },
  },
};
</script>