<template>
  <v-navigation-drawer
    v-model="toggleDetails"
    :clipped="$vuetify.breakpoint.lgAndUp"
    disable-resize-watcher
    app
    right
    bottom
    width="350"
    height="100%"
  >
    <v-scroll-y-transition mode="out-in" hide-on-leave>
      <v-container v-if="!isSelectedFile" fluid class="fill-height">
        <v-row class="fill-height" align="center" justify="center">
          <v-col align="center">
            <v-icon large>mdi-eye-off</v-icon>
            <p>Select item in browser to view info</p>
          </v-col>
        </v-row>
      </v-container>
    </v-scroll-y-transition>

    <v-toolbar v-if="isSelectedFile" dense>
      <v-toolbar-title>
        {{ selectedFile.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="closeDetails" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-scroll-y-transition mode="out-in" hide-on-leave>
      <div v-if="isSelectedFile">
        <v-tabs
          v-model="getTab"
          :background-color="getAppearances.btn_color"
          dark
          grow
        >
          <v-row justify="center">
            <v-tab> Details </v-tab>
            <v-tab> Activities </v-tab>
          </v-row>

          <v-tab-item>
            <v-card flat class="pb-2 pt-2">
              <v-img
                v-if="
                  fileTypes[selectedFile.type] !== undefined &&
                  fileTypes[selectedFile.type].fileType == 'image'
                "
                contain
                centered
                width="500"
                height="200"
                :src="`/api/files/thumbnails/${selectedFile.id}`"
              ></v-img>
              <v-row
                v-else-if="
                  fileTypes[selectedFile.type] !== undefined &&
                  fileTypes[selectedFile.type].fileType !== 'image'
                "
                justify="center"
              >
                <v-icon size="128" :color="fileTypes[selectedFile.type].color">
                  {{ fileTypes[selectedFile.type].icon }}
                </v-icon>
              </v-row>
              <v-row v-else justify="center" centered>
                <v-icon centered size="128" :color="fileTypes['unknown'].color">
                  {{ fileTypes["unknown"].icon }}
                </v-icon>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-list class="transparent mb-3" dense>
                <v-list-item>
                  <v-list-item-title>Type</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="fileTypes[selectedFile.type] !== undefined"
                    class="text-right"
                  >
                    {{ fileTypes[selectedFile.type].fileType }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else class="text-right">
                    Unknown filetype
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Size</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ convert(selectedFile.size) }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Owner</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ getOwner() }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Modified</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ getDateAndTime(selectedFile.modified) }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Created</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ getDateAndTime(selectedFile.created) }}
                  </v-list-item-subtitle>
                </v-list-item>
                <Metadata />
                <div v-for="item in selectedFile.metadata" :key="item.id">
                  <div class="mx-4" v-if="item.type == 'selection'">
                    <v-select
                      :disabled="
                        getUser.id == selectedFile.owner
                          ? false
                          : !selectedFile.allowEditMeta
                      "
                      v-model="item.value"
                      :items="item.fields"
                      :color="getAppearances.btn_color"
                      :label="item.title"
                      @change="updateMeta(item)"
                      prepend-icon="mdi-arrow-down"
                      return-object
                    ></v-select>
                  </div>

                  <div class="mx-4" v-if="item.type == 'text'">
                    <v-textarea
                      :disabled="
                        getUser.id == selectedFile.owner
                          ? false
                          : !selectedFile.allowEditMeta
                      "
                      v-model="item.value"
                      :color="getAppearances.btn_color"
                      :label="item.title"
                      prepend-icon="mdi-message-text-outline"
                      auto-grow
                      rows="1"
                      row-height="15"
                      @change="updateMeta(item)"
                    ></v-textarea>
                  </div>

                  <div class="mx-4" v-if="item.type == 'color'">
                    <v-select
                      :disabled="
                        getUser.id == selectedFile.owner
                          ? false
                          : !selectedFile.allowEditMeta
                      "
                      v-model="item.value"
                      :items="item.fields"
                      :color="getAppearances.btn_color"
                      item-text="name"
                      :label="item.title"
                      prepend-icon="mdi-arrow-down"
                      @change="updateMeta(item)"
                      return-object
                    ></v-select>
                  </div>
                  <div class="mx-4" v-if="item.type == 'date'">
                    <v-menu
                      v-model="dateMenu"
                      :disabled="
                        getUser.id == selectedFile.owner
                          ? false
                          : !selectedFile.allowEditMeta
                      "
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.value"
                          :label="item.title"
                          :color="getAppearances.btn_color"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.value"
                        @change="updateMeta(item)"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </v-list>
              <v-row> </v-row>
              <div
                v-if="
                  getDetails.sharedUsers != undefined &&
                  getDetails.sharedUsers.length > 0
                "
                class="pt-2"
              >
                <v-subheader>File permissions</v-subheader>
                <v-list-item
                  v-for="item in getDetails.sharedUsers"
                  :key="item.id"
                >
                  <v-list-item-avatar>
                    <v-img :src="`/api/users/photo/${item.photo}`"> </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.email"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="selectedFile.owner == item.id"
                          icon
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="openPermissions(item)">
                          <v-list-item-title>Permissions</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-list>
                        <v-list-item @click="deleteShare(item)">
                          <v-list-item-title>Unshare</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </div>
              <div
                v-if="
                  getDetails.sharedLinks != undefined &&
                  getDetails.sharedLinks.length > 0
                "
                class="pt-2"
              >
                <v-subheader>External links</v-subheader>
                <v-list-item
                  v-for="item in getDetails.sharedLinks"
                  class="py-0"
                  :key="item.id"
                >
                  <v-list-item-content class="py-1">
                    <v-text-field
                      :value="buildLink(item.id)"
                      :hint="item.name + ', ' + getDateAndTime(item.shareTime)"
                      persistent-hint
                      :color="getAppearances.btn_color"
                      single-line
                      dense
                      readonly
                      label="Link"
                    ></v-text-field>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="copyToClipBoard(item.id)">
                          <v-list-item-title>Copy</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openLinkPermissions(item)">
                          <v-list-item-title>Permissions</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteLink(item)">
                          <v-list-item-title>Unshare</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <ActivityList />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-scroll-y-transition>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../../mixins/date";
import { removeElement } from "../../../js/utils";
import { convertSize } from "../../../js/utils";
import Metadata from "../../Dialogs/metadataDialog.vue";
import ActivityList from "../../Activity/activityList";

export default {
  components: {
    Metadata,
    ActivityList,
  },

  data: () => ({}),

  mixins: [dateUtils],

  watch: {
    $route(to) {
      if (to.name == "reports") {
        this.$store.commit("main/openDetails", false);
      }
    },
  },

  computed: {
    ...mapGetters("files", [
      "isSelectedFile",
      "selectedFile",
      "fileTypes",
      "getActivity",
      "getDetails",
      "getShareItem",
      "getFiles",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("main", ["details", "tab"]),

    ...mapGetters("user", ["getUser", "getUsers", "getUserById"]),

    toggleDetails: {
      get() {
        return this.details;
      },
      set(value) {
        this.$store.commit("main/setDetailsTab", 0);
        this.$store.commit("main/openDetails", value);
      },
    },

    getTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this.$store.commit("main/setDetailsTab", value);
      },
    },
  },

  methods: {
    copyToClipBoard(id) {
      var link = this.buildLink(id);
      navigator.clipboard.writeText(link);
    },

    buildLink(id) {
      return window.location.origin + "/share/" + id;
    },

    closeDetails() {
      this.$store.commit("main/openDetails", false);
    },

    convert(size) {
      return convertSize(size);
    },

    getOwner() {
      for (let i = 0; i < this.getUsers.length; i++) {
        if (this.getUsers[i].id == this.selectedFile.owner) {
          let str = this.getUsers[i].name + " " + this.getUsers[i].lastname;
          if (this.getUser.id == this.selectedFile.owner) {
            str += "(you)";
          }

          return str;
        }
      }
    },

    openLinkPermissions(item) {
      this.$store.commit("files/setShareItem", this.selectedFile);

      this.$store.commit("shareDialog/openLinkPermissionsDialog", {
        status: true,
        link: item,
      });
    },

    openPermissions(item) {
      this.$store.commit("files/setShareItem", this.selectedFile);

      this.$store.commit("shareDialog/openUserPermissionsDialog", {
        status: true,
        user: item,
      });
    },

    setAsUnshared() {
      if (
        this.getDetails.sharedUsers.length <= 0 &&
        this.getDetails.sharedLinks.length <= 0
      ) {
        for (let i = 0; i < this.getFiles.length; i++) {
          if (this.getFiles[i].id == this.selectedFile.id) {
            this.getFiles[i].shared = false;
          }
        }
      }
    },

    deleteShare(item) {
      this.$store
        .dispatch("files/deleteShare", {
          file: this.selectedFile.id,
          id: item.id,
        })
        .then(() => {
          for (let i = 0; i < this.getDetails.sharedUsers.length; i++) {
            if (this.getDetails.sharedUsers[i].id == item.id) {
              removeElement(
                this.getDetails.sharedUsers,
                this.getDetails.sharedUsers[i]
              );
            }
          }

          this.setAsUnshared();
        });
    },

    deleteLink(item) {
      this.$store.dispatch("files/deleteLink", item.id).then(() => {
        removeElement(this.getDetails.sharedLinks, item);
        if (
          this.getDetails.sharedUsers.length <= 0 &&
          this.getDetails.sharedLinks.length <= 0
        ) {
          for (let i = 0; i < this.getFiles.length; i++) {
            if (this.getFiles[i].id == this.selectedFile.id) {
              this.getFiles[i].shared = false;
            }
          }
        }
      });
    },

    updateMeta(item) {
      let value = item.value;
      if (item.type == "stars") {
        value = value.toString();
      }
      this.$store.dispatch("meta/updateFileMetadataEntry", {
        id: item.id,
        value: value,
        app: "Files",
      });
    },
  },
};
</script>

<style>
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 66% !important;
  top: auto;
  bottom: 0;
  min-width: 100%;
}
</style>
