<template>
  <v-container fluid>
    <v-row
      class="px-4 pt-5 fill-height"
      :align="projects.length <= 0 ? 'center' : 'start'"
    >
      <v-row
        v-if="projects.length <= 0"
        class="fill-height mt-16 pt-16"
        align="center"
        justify="center"
      >
        <div class="text-center mb-5 child-elements">
          <v-list-item-avatar
            size="200"
            class="child-elements"
          >
            <v-icon
              size="100"
              dark
              color="white"
              class="grey lighten-2"
            >
              mdi-star
            </v-icon>
          </v-list-item-avatar>
          <h1 class="grey--text text--darken-1 font-weight-medium noselect">Favourite projects will appear here</h1>
        </div>
      </v-row>

      <v-card
        v-if="projects.length > 0 && !isMobile"
        class="elevation-0"
        width="100%"
      >
        <v-card-title>
          Favourite projects
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="projects"
          :items-per-page="5"
          class="elevation-0"
          @click:row="openProject"
        >
          <template v-slot:item.completed="{ item }">
            <!-- v-model -->
            <v-progress-linear
              :value="(item.status.completed/item.status.count)*100"
              height="15"
              rounded
            >
              <strong>{{item.status.completed}} of {{item.status.count}}</strong>
            </v-progress-linear>
          </template>
          <template v-slot:item.end_date="{ item }">
            {{ getDate(item.end_date) }}
          </template>
          <template v-slot:item.start_date="{ item }">
            {{ getDate(item.start_date) }}
          </template>
          <template v-slot:item.created="{ item }">
            {{ getDate(item.created) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click.stop="removeFromFavourite(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove from favourite</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
      <v-col
        v-if="isMobile && projects.length > 0"
        class="ma-0 pa-0"
      >
        <v-card-title>
          Favourite projects
        </v-card-title>
        <v-list>
          <v-subheader>Project name</v-subheader>
          <v-list-item-group
            :color="getAppearances.btn_color"
            link
          >
            <div
              v-for="(item, i) in projects"
              :key="i"
            >
              <v-list-item @click="openProject(item)">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row class="ma-0 pa-0">
                    <v-icon
                      small
                      @click.stop="removeFromFavourite(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";

export default {
  data() {
    return {
      projects: [],
      headers: [
        {
          text: "Project name",
          align: "start",
          sortable: true,
          value: "title",
        },
        { text: "Start Date", value: "start_date" },
        { text: "End date", value: "end_date" },
        { text: "Completed", value: "completed" },
        { text: "Created", value: "created" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      isMobile: false,
    };
  },

  mixins: [dateUtils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  created() {
    this.$store.dispatch("projects/getFavourites").then((response) => {
      this.projects = response.data;
    });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    removeFromFavourite(item) {
      this.$store
        .dispatch("projects/addToFavourite", {
          project_id: item.id,
        })
        .then(() => {
          this.projects.splice(this.projects.indexOf(item), 1);
        });
    },

    openProject(item) {
      this.$router.push({
        name: "project",
        params: { id: item.id },
      });
    },

    getDate(tstamp) {
      return this.getDateString(tstamp);
    },
  },
};
</script>
