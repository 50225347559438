<template>
  <div class="fill-height">
    <div class="full-width-div fill-height">
      <Toolbar
        v-if="
          $router.currentRoute.name != 'office' &&
          $router.currentRoute.name != 'reports'
        "
      />
      <v-divider></v-divider>
      <v-scroll-x-transition mode="out-in" hide-on-leave>
        <router-view></router-view>
      </v-scroll-x-transition>
    </div>
    <FileDetails />
  </div>
</template>

<script>
import Toolbar from "../../components/Toolbar";
import FileDetails from "../../components/Navigation/drawers/FileDetails.vue";
import { mapGetters } from "vuex";
import { utils } from "../../mixins/utils";

export default {
  components: {
    Toolbar,
    FileDetails,
  },

  data: function () {
    return {
      open: [],
      active: [],
      mandatory: false,
      favourite: false,
      favourites: [
        { text: "Reports", icon: "mdi-chart-areaspline", name: "reports" },
        {
          text: "Shared with me",
          icon: "mdi-account-multiple-outline",
          name: "share",
        },
        { text: "Recents", icon: "mdi-history", name: "recents" },
        { text: "Favourites", icon: "mdi-star", name: "favourites" },
        { text: "Trash", icon: "mdi-delete", name: "trash" },
      ],
    };
  },

  mixins: [utils],

  created() {
    this.startUp();
    this.$store.dispatch("meta/getFileMetadata");
  },

  async beforeRouteUpdate(to, from, next) {
    var path;

    path = to.params.path;

    if (to.name == "files") {
      if (path == undefined) {
        next();
        this.$router.go();
        return;
      }
      this.getFiles(path);
      this.getParent(path);
    }

    next();
  },

  computed: {
    ...mapGetters("files", [
      "getTreeview",
      "getPerPage",
      "getSelection",
      "path",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("office", ["getOpened"]),

    ...mapGetters("toolbar", ["getBreadcrumbs"]),
  },

  methods: {
    async startUp() {
      await this.$store.dispatch("files/getDrive").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].children = [];
        }
        this.$store.commit("files/setPath", response.data[0].id);
        this.$store.commit("files/setTreeview", response.data);
      });

      var name = this.$router.currentRoute.name;
      var item = {
        name: "",
      };
      switch (name) {
        case "shared":
          item.name = "Shared";
          break;
        case "recent":
          item.name = "Recents";
          break;
        case "favourites":
          item.name = "Favourites";
          break;
        case "trash":
          item.name = "Trash";
          break;
        case "files":
          var path = this.$router.currentRoute.params.path;
          // item = this.getTreeview[0];
          if (path == undefined) {
            this.$router.push({
              name: "files",
              params: { path: this.getTreeview[0].id },
            });
          } else {
            this.getFiles(path);
            this.getParent(path);
          }
          break;
        case "files-highlight":
          this.$store
            .dispatch("files/downloadFile", this.$router.currentRoute.params.id)
            .then((response) => {
              this.$router.push({
                name: "files",
                params: {
                  path: response.data.parentDir,
                  highlighted: response.data.id,
                },
              });
            });
          break;
        default:
          break;
      }
    },

    async getFiles(path) {
      this.$store.commit("files/setLoaded", false);
      this.$store.commit("files/setPath", path);
      this.$store.commit("files/setPage", 1);
      this.$store.dispatch("files/getFiles", path).then((response) => {
        if (this.$router.currentRoute.params.highlighted != undefined) {
          for (let i = 0; i < response.data.length; i++) {
            if (
              response.data[i].id ==
              this.$router.currentRoute.params.highlighted
            ) {
              let selected =
                i - (Math.ceil(i / this.getPerPage) - 1) * this.getPerPage;
              let page = Math.ceil(i / this.getPerPage);

              if (i == this.getPerPage) {
                page += 1;
                selected = 0;
              } else if (selected == this.getPerPage) {
                selected = 0;
              }

              if (page == 0) {
                page = 1;
              }

              this.$store.commit("files/setPage", page);
              this.$store.commit("files/setSelection", [selected]);
            }
          }
        }
        this.$store.commit("files/setFiles", response.data);
        this.$store.commit("files/setLoaded", true);

        if (this.$router.currentRoute.params.highlighted != undefined) {
          let highlighted = this.$router.currentRoute.params.highlighted;
          setTimeout(function () {
            document
              .getElementById(highlighted)
              .scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      });
    },

    async getParent(path) {
      this.$store.dispatch("files/getParents", path).then((response) => {
        let drive = null;

        if (response.data.length <= 0) {
          this.getTreeview.forEach((element) => {
            if (path == element.id) {
              drive = element;
            }
          });
        } else {
          this.getTreeview.forEach((element) => {
            if (response.data[0].storage == element.storage) {
              drive = element;
            }
          });
        }
        var arr = [drive];
        arr.push(...response.data.reverse());
        this.$store.commit("toolbar/setBreadcrumbs", arr);
      });
    },
  },
};
</script>

<style>
.v-treeview-node {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.child-elements {
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.full-width-div {
  width: 100%;
}
</style>
