<template>
  <v-card width="400">
    <v-col cols="12">
      <v-autocomplete
        v-model="selectedUser"
        :disabled="isUpdating"
        :items="getProjectMembers"
        filled
        chips
        color="blue-grey lighten-2"
        label="Assign this task"
        item-text="name"
        item-value="id"
        class="mx-3 mt-3"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            color="white"
            class="mt-2"
          >
            <v-avatar left>
              <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
            </v-avatar>
            {{data.item.name + ' ' + data.item.lastname}}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-badge
              color="error"
              offset-x="25"
              offset-y="22"
              bordered
              overlap
              :value="data.item.deactivated_at == 0 ? false : true"
            >
              <template v-slot:badge>
                <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
              </template>
              <v-list-item-avatar>
                <img :src="`/api/users/photo/${data.item.photo}`">
              </v-list-item-avatar>
            </v-badge>
            <v-list-item-content>
              <v-list-item-title> {{data.item.name + ' ' + data.item.lastname}}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-card-actions>
      <v-btn
        v-if="user != '' && selectedUser != ''"
        small
        color="grey lighten-3"
        class="mb-3 ml-4"
        elevation="0"
        @click="removeMember(user)"
      >
        Unassign
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mb-3 mr-1"
        text
        @click="$emit('close')"
      >
        Close
      </v-btn>
      <v-btn
        class="mr-3 mb-3"
        :color="getAppearances.btn_color"
        dark
        @click="save(), selectedUser = ''"
      >
        Assign
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    user: String,
    item: Object,
  },
  data() {
    return {
      selectedUser: "",
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getProject", "getProjectMembers"]),
  },

  mixins: [projectUtils],

  mounted() {
    for (let i = 0; i < this.getProjectMembers.length; i++) {
      if (this.getProjectMembers[i].id == this.user) {
        this.selectedUser = this.getProjectMembers[i];
      }
    }
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },

  methods: {
    ...mapMutations("projects", ["setAssigned"]),

    save() {
      if (this.selectedUser != null && this.selectedUser != "") {
        this.setAssigned(this.selectedUser);
        this.$emit("save");
      }
    },

    removeMember() {
      if (this.item.id != undefined) {
        this.removeMemberFromTask(this.item.id);
      }
      this.selectedUser = "";
      this.$emit("update:user", "");
      this.$emit("close");
    },
  },
};
</script>