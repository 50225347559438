<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item>
        <v-list-item-title>Metadata</v-list-item-title>
        <v-btn
          fab
          dark
          x-small
          elevation="0"
          :color="getAppearances.btn_color"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="text-h5"> Add or remove metadata </v-card-title>
      <v-card-text>
        <v-list two-line dense>
          <v-subheader>Available metadata</v-subheader>
          <v-list-item
            class="v-list-item--link"
            v-for="item in getMetadata"
            :key="item.id"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                v-if="active(item.id)"
                fab
                dark
                x-small
                color="red"
                @click="remove(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-else
                fab
                dark
                x-small
                :color="getAppearances.btn_color"
                @click="add(item)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="getAppearances.btn_color" dark @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters("meta", ["getMetadata"]),

    ...mapGetters("files", ["selectedFile"]),

    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    active(id) {
      if (this.selectedFile.metadata == null) {
        return false;
      }
      for (let i = 0; i < this.selectedFile.metadata.length; i++) {
        if (id == this.selectedFile.metadata[i].metadata_id) {
          return true;
        }
      }
    },

    add(item) {
      this.$store
        .dispatch("meta/addFileMetadataEntry", {
          id: this.selectedFile.id,
          metaid: item.id,
          value: "",
          app: "Files",
        })
        .then((response) => {
          if (this.selectedFile.metadata == null) {
            this.selectedFile.metadata = [response.data];
            return;
          }
          this.selectedFile.metadata.push(response.data);
        });
    },

    remove(item) {
      const index = this.selectedFile.metadata.findIndex(
        (element) => element.metadata_id == item.id
      );
      if (index >= 0) {
        this.$store
          .dispatch("meta/deleteFileMetadataEntry", {
            fileID: this.selectedFile.id,
            id: this.selectedFile.metadata[index].id,
          })
          .then(() => {
            this.selectedFile.metadata.splice(index, 1);
          });
      }
    },
  },
};
</script>
