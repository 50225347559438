
<template>
  <v-container
    fluid
    class="pa-0"
    :key="getRestoreKey"
  >
    <v-row class="fill-width">
      <div class="ma-6">
        <draggable
          v-model="getColumn"
          class="row"
          style="flex-wrap: nowrap;"
          handle=".handle"
          v-bind="{disabled: getDisableDrag}"
          @end="patchProject()"
          :filter="getMobileView == true ? '.ignore-section' : ''"
        >
          <div
            v-for="col in getColumns"
            :key="col.index"
            class="ignore-section"
          >
            <div
              v-if="col.index != getSectionIndex"
              :class="getDisableDrag == false ? 'handle nameBorder' : 'nameBorder'"
              style="width: 302px;"
            >
              <v-col>
                <v-row>
                  <v-col
                    cols="9"
                    class="pa-0 ma-0"
                  >
                    <div
                      class="mx-3 my-3 font-weight-medium text-uppercase"
                      style="width: 195px;"
                    >
                      <div> {{ col.name }} </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pa-0 ma-0"
                  >
                    <v-row>
                      <v-spacer />
                      <div class="marginTop2px d-flex align-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              icon
                              @click="addTask(col.index, 'top')"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>ADD TASK</span>
                        </v-tooltip>
                      </div>
                      <SectionMenu
                        class="mb-3 mt-3 mr-3"
                        :closeCreate.sync="closeCreate"
                        :dragStart.sync="dragStart"
                        :colIndex.sync="col.index"
                        @patch="patchProject()"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </div>
            <div
              v-else-if="getEditSectionName == true && col.index == getSectionIndex && getEditing == 'board'"
              :class="getDisableDrag == false ? 'handle nameBorder d-flex align-center' : 'nameBorder d-flex align-center'"
              style="height: 48px; width: 300px; margin-top: 2px;"
            >
              <input
                v-model.lazy="updateSectionName"
                v-click-outside="editSectionClickOutside"
                style="font-size: 16px; width: 276px; text-transform:uppercase;"
                @change="editSection()"
                class="sectionTextarea mx-3"
                type="text"
              >
            </div>
            <draggable
              :list="col.tasks"
              :group="{ name: 'col' }"
              v-bind="{disabled: getDisableDrag}"
              class="fill-height"
              handle=".handle"
              draggable=".item"
              @start="onStart()"
              @end="patchProject()"
              delay="150"
              :delayOnTouchOnly="true"
            >
              <div slot="header">
                <div v-if="col.index == addTaskIndex && taskPosition == 'top'">
                  <NewTask
                    :closeCreate.sync="closeCreate"
                    :addTaskIndex.sync="addTaskIndex"
                    :taskPosition.sync="taskPosition"
                    :date="date"
                    :getProjectMembers.sync="getProjectMembers"
                  />
                </div>
              </div>
              <div
                v-for="item in col.tasks"
                :key="item.id"
                :class="getDisableDrag == false ? 'handle item' : ''"
                @click="fullTaskInfo(item)"
                @mouseover="subID = item.id"
                @mouseleave="subID = ''"
              >
                <div
                  @mouseup="clickEnd()"
                  @mousedown="clickStart(item.id)"
                  @touchstart="clickStart(item.id)"
                  @mouseleave="clickEnd()"
                  @touchend="clickEnd()"
                  @touchcancel="clickEnd()"
                >
                  <v-col
                    v-show="taskFilter(item) && item.trash == false && statusFilter(item)"
                    cols="12"
                    class="pa-1"
                  >
                    <v-card
                      elevation="0"
                      class="taskCard cardBorder"
                      width="294"
                      :style="item.status == 'completed' ? 'opacity: 0.5' : ''"
                    >
                      <div :style="selectedTaskID == item.id ?'border: 0.5px solid'+getAppearances.btn_color : ''">
                        <div v-if="item.src != undefined">
                          <v-img
                            :src="item.src"
                            class="white--text align-start text-center taskImg"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            aspect-ratio="1.5"
                          >
                            <div class="text-right my-2 mx-2">
                              <TaskMenu
                                @open="openTaskMenu(item)"
                                :dragStart.sync="dragStart"
                                :taskActions.sync="taskActions"
                              />
                            </div>
                          </v-img>
                        </div>
                        <v-card-actions
                          v-else
                          class="pb-0 mt-1"
                        >
                          <v-spacer />
                          <TaskMenu
                            @open="openTaskMenu(item)"
                            :dragStart.sync="dragStart"
                            :taskActions.sync="taskActions"
                          />
                        </v-card-actions>
                        <div
                          v-if="editTitle == true && item.id == taskID && dragStart == false"
                          class="mx-3 mb-1 py-0 mt-0"
                          style="margin-top: 2px;"
                          @click.stop
                        >
                          <input
                            v-model.lazy="newTaskTitle"
                            @change="editTaskTitle(item)"
                            v-click-outside="editTitleClickOutside"
                            style="font-size: 14px; width: 268px;"
                            class="textarea my-n1"
                            type="text"
                          >
                        </div>
                        <div
                          v-else
                          class="mx-3 py-0 mt-2 font-weight-regular"
                        >
                          <div
                            @dblclick="openEditTitle(item)"
                            class="textCursor noselect"
                            style="font-size: 14px;"
                          >
                            <v-btn
                              icon
                              x-small
                              :class="item.status == 'completed' ? 'mr-1' : 'status mr-1'"
                              :color="item.status == 'completed' ? '#4CAF50' : ''"
                              @click.stop="markCompleted(item)"
                              :key="getStatusKey"
                            >
                              <v-icon size="20">{{item.status == 'completed' ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}</v-icon>
                            </v-btn>
                            {{item.title}}
                          </div>
                        </div>
                        <v-card-actions class="mb-1">
                          <div
                            v-if="showField('assigned_to')"
                            :class="item.assigned_to == '' ? 'mx-n2 mt-n1' : 'mx-n2 mb-1'"
                          >
                            <v-badge
                              color="error"
                              offset-x="19"
                              offset-y="15"
                              bordered
                              overlap
                              :value="userStatus(item)"
                            >
                              <template v-slot:badge>
                                <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                              </template>
                              <v-avatar
                                @click.stop="openAssignDialog(item)"
                                size="24"
                                class="mx-3 mt-1 mb-n1"
                                :tile="item.assigned_to == '' ? true : false"
                                style="cursor: pointer;"
                              >
                                <v-icon
                                  size="24"
                                  v-if="item.assigned_to == ''"
                                >
                                  mdi-account-plus</v-icon>
                                <v-img
                                  v-else
                                  size="22"
                                  :src="`/api/users/photo/${assignedUser(item.assigned_to)}`"
                                >
                                </v-img>
                              </v-avatar>
                            </v-badge>
                          </div>
                          <div
                            v-if="showField('end_date')"
                            :class="item.end_date == '' ? '' : 'mb-n1'"
                          >
                            <div
                              v-if="item.end_date != ''"
                              align="left"
                              class="mx-3 my-2 text-capitalize font-weight-regular dateFont"
                              @click.stop="openDateDialog(item, 'end')"
                            >
                              {{ dateFormat(item.end_date) }}
                            </div>
                            <div v-else>
                              <v-btn
                                @click.stop="openDateDialog(item, 'end')"
                                icon
                                small
                                class="mx-2 mt-1"
                              >
                                <v-icon>mdi-calendar-plus</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                          <div class="mr-2">
                            <v-badge
                              v-if="haveSubTask(item) > 0"
                              color="rgb(255, 0, 0, 0)"
                              right
                              transition="slide-x-transition"
                              offset-y="8"
                              offset-x="8"
                            >
                              <v-btn
                                icon
                                x-small
                                :key="getStatusKey"
                                class="ml-1 marginIcon"
                              >
                                <v-icon size="18">mdi-file-tree</v-icon>
                              </v-btn>
                              <template v-slot:badge>
                                <div
                                  v-if="item.id == subID"
                                  class="black--text"
                                  style="font-size: 13px;"
                                >
                                  {{haveSubTask(item)}}
                                </div>>
                              </template>
                            </v-badge>
                            <v-badge
                              v-if="showFileIcon(item)"
                              color="rgb(255, 0, 0, 0)"
                              right
                              transition="slide-x-transition"
                              offset-y="11"
                              offset-x="11"
                            >
                              <v-btn
                                icon
                                x-small
                                :key="getStatusKey"
                                class="ml-1 marginIcon"
                              >
                                <v-icon size="18">mdi-paperclip</v-icon>
                              </v-btn>
                              <template v-slot:badge>
                                <div
                                  v-if="item.id == subID"
                                  class="black--text"
                                  style="font-size: 13px;"
                                >
                                  {{ item.files.length }}
                                </div>>
                              </template>
                            </v-badge>
                          </div>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                </div>
              </div>
              <div slot="footer">
                <div v-if="col.index == addTaskIndex && taskPosition == 'bottom'">
                  <NewTask
                    :closeCreate.sync="closeCreate"
                    :addTaskIndex.sync="addTaskIndex"
                    :taskPosition.sync="taskPosition"
                    :date="date"
                    :getProjectMembers.sync="getProjectMembers"
                  />
                </div>
                <div align="center">
                  <v-btn
                    v-if="dragStart == false"
                    text
                    color="grey darken-3"
                    @click="addTask(col.index, 'bottom')"
                    class="mb-3"
                  >
                    + Add Task
                  </v-btn>
                </div>
              </div>
            </draggable>
          </div>
          <div
            v-if="hideAddSection == true"
            class="mt-n0"
            style="min-width: 278px"
          >
            <div
              :class="getDisableDrag == false ? 'handle nameBorder d-flex align-center' : 'nameBorder d-flex align-center'"
              style="height: 48px; width: 300px;"
            >
              <input
                v-model.lazy="updateSectionName"
                @change="newSection()"
                v-click-outside="newSectionClickOutside"
                style="font-size: 16px; width: 279px;"
                class="sectionTextarea mx-3"
                type="text"
                placeholder="New Section title"
              >
            </div>
          </div>
          <div
            v-if="hideAddSection == false"
            class="px-3 mt-0"
            style="min-width: 278px"
          >
            <v-btn
              :color="getAppearances.btn_color"
              dark
              @click="addTask('','section')"
            >
              + Add Section
            </v-btn>
          </div>
        </draggable>
      </div>
      <DateDialog
        :dateDialog.sync="dateDialog"
        :selectedTaskDate.sync="selectedTaskDate"
        :selectedTask.sync="selectedTask"
      />
      <AssignDialog
        :assignDialog.sync="assignDialog"
        :selectedUser.sync="selectedUser"
        :selectedTask.sync="selectedTask"
      />
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import NewTask from "./Board/NewTask";
import AssignDialog from "./Dialogs/AssigneeDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import { projectUtils } from "../../mixins/project";
import SectionMenu from "./Menu/SectionMenu.vue";
import TaskMenu from "./Menu/TaskMenuBoard.vue";
import DateDialog from "./Dialogs/DateDialog.vue";

export default {
  components: {
    draggable,
    NewTask,
    AssignDialog,
    SectionMenu,
    TaskMenu,
    DateDialog,
  },
  data() {
    return {
      subID: "",
      taskPosition: "",
      assigneeMenu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      editTitle: false,
      newTaskTitle: "",
      taskID: "",
      hideAddSection: false,
      dragStart: false,
      addTaskIndex: "",
      interval: false,
      count: 0,
      selectedTaskID: "",
      selectedTaskToDrop: "",
      dateDialog: false,
      selectedTask: {},
      selectedTaskDate: "",
      assignDialog: false,
      selectedUser: "",
    };
  },

  watch: {
    "$store.state.projects.editing": function () {
      this.assigneeMenu = false;
    },
    count: function () {
      if (this.getMobileView == true && this.count > 75) {
        this.selectedTaskID = this.selectedTaskToDrop;
      }
    },
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", [
      "getColumns",
      "getProject",
      "getProjectMembers",
      "getRefreshDate",
      "getSectionName",
      "getSectionIndex",
      "getDisableDrag",
      "getEditSectionName",
      "getEditing",
      "getStatusKey",
      "getHeaders",
      "getRestoreKey",
      "getMobileView",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    getColumn: {
      get() {
        return this.getColumns;
      },
      set(value) {
        this.$store.commit("projects/setColumns", value);
      },
    },

    updateSectionName: {
      get() {
        return this.getSectionName;
      },
      set(value) {
        this.$store.commit("projects/setSectionName", value);
      },
    },
  },

  methods: {
    ...mapMutations("projects", [
      "setColumns",
      "setSectionName",
      "setSectionIndex",
      "setDisableDrag",
      "setEditSectionName",
      "setStatusKey",
    ]),

    showField(name) {
      for (let i = 0; i < this.getHeaders.length; i++) {
        if (this.getHeaders[i].value == name) {
          return this.getHeaders[i].show;
        }
      }
    },

    editTitleClickOutside() {
      this.taskID = "";
      this.newTaskTitle = "";
      this.closeCreate();
    },

    clickStart(item) {
      if (!this.interval) {
        this.interval = setInterval(() => this.count++, 10);
        this.selectedTaskToDrop = item;
      }
    },

    clickEnd() {
      clearInterval(this.interval);
      this.interval = false;
      this.count = 0;
      this.selectedTaskToDrop = "";
    },

    openTaskMenu(item) {
      this.taskID = item.id;
      this.newTaskTitle = item.title;
      this.closeCreate();
    },

    closeCreate() {
      this.taskPosition = "";
      this.addTaskIndex = "";
      this.setSectionIndex("");
      this.setSectionName("");
      this.setDisableDrag(false);
      this.editTitle = false;
      this.hideAddSection = false;
      this.assigneeMenu = false;
      this.dragStart = false;
      this.setEditSectionName(false);
    },

    editTaskTitle(item) {
      item.title = this.newTaskTitle;
      this.editTask(item);
      this.taskID = "";
      this.newTaskTitle = "";
      this.closeCreate();
    },

    openEditTitle(item) {
      this.taskID = item.id;
      this.closeCreate();
      this.editTitle = true;
      this.newTaskTitle = item.title;
      this.setDisableDrag(true);
    },

    taskActions(item) {
      this.editTitle = false;
      if (item.title == "Delete task") {
        this.deleteTask(this.taskID);
        this.dragStart = false;
        this.taskID = "";
      } else if (item.title == "Edit task name") {
        this.editTitle = true;
        this.setDisableDrag(true);
      }
    },

    editSectionClickOutside() {
      this.editSection();
    },

    newSectionClickOutside() {
      this.newSection();
    },

    editSection() {
      if (this.getSectionName != "") {
        for (let i = 0; i < this.getColumns.length; i++) {
          if (this.getColumns[i].index == this.getSectionIndex) {
            this.getColumns[i].name = this.getSectionName;
          }
        }
        this.closeCreate();
        this.patchProject("title");
      } else {
        this.closeCreate();
      }
    },

    onStart() {
      this.dragStart = true;
      this.editTitle = false;
    },

    patchProject(update) {
      this.selectedTaskID = "";
      this.dragStart = false;
      this.onEnd(update);
      this.$emit("websocket");
    },

    addTask(index, position) {
      this.addTaskIndex = index;
      this.dragStart = true;
      this.setDisableDrag(true);
      this.hideAddSection = false;
      this.editTitle = false;
      this.setEditSectionName(false);
      this.setSectionIndex("");

      if (position == "top") {
        this.taskPosition = "top";
        this.taskName = "";
      } else if (position == "bottom") {
        this.taskPosition = "bottom";
        this.taskName = "";
      } else {
        this.hideAddSection = true;
        this.taskPosition = "";
        this.setSectionName("");
      }
    },

    openAssignDialog(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          this.selectedUser = this.getProjectMembers[i].id;
        } else {
          this.selectedUser = "";
        }
      }

      this.selectedTask = item;
      this.assignDialog = true;
    },

    openDateDialog(item, date) {
      this.selectedTask = item;
      this.selectedTaskDate = date;
      this.dateDialog = true;
    },

    newSection() {
      if (this.getSectionName != "") {
        var maxVal = 0;
        var items = [];
        for (let i = 0; i < this.getColumns.length; i++) {
          items.push(this.getColumns[i].index);
        }
        maxVal = Math.max.apply(Math, items);
        var data = {
          index: maxVal + 1,
          name: this.getSectionName,
          tasks: [],
        };
        this.getColumns.push(data);
        this.patchProject();
        this.setSectionName("");
      }
      this.closeCreate();
    },
  },
};
</script>

<style scoped>
.nameBorder {
  border: 1px solid rgb(245, 245, 245);
  border-radius: 6px;
}

.dateFont {
  font-size: 13px;
  cursor: pointer;
}

.nameBorder:hover {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
}

.fill-width {
  overflow-x: auto;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 85.2vh;
}

.handle {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.taskCard {
  border-radius: 9px;
  border: 1px solid rgb(236, 236, 236);
}

.taskCard:hover {
  border-radius: 9px;
  border: 1px solid rgb(209, 209, 209);
}

.taskImg {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.status:hover {
  color: #4caf50;
}
.textCursor {
  cursor: text;
}

.marginTop2px {
  margin-top: 1px;
}

.textarea {
  background-color: transparent;
  resize: none;
  outline: none;
  border-bottom: 1px solid rgb(63, 63, 63);
}

.sectionTextarea {
  background-color: transparent;
  resize: none;
  outline: none;
  border: none;
}
</style>
