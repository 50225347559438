import axios from 'axios'
import { mapGetters } from 'vuex'
export const actions = {

  data: () => ({
    editItem: {
      oldname: '',
    },
    menuItems: [
      { id: 'favourites', text: 'Add to favourites', icon: 'mdi-star', color: '', show: true, disabled: false },
      { id: 'create', text: 'Create folder', icon: 'mdi-folder', color: '', show: true, disabled: false },
      { id: 'download', text: 'Download', icon: 'mdi-download', color: '', show: true, disabled: false },
      { id: 'open', text: 'Open', icon: 'mdi-open-in-new', color: '', show: true, disabled: false },
      { id: 'openIn', text: 'Open In Folder', icon: 'mdi-folder-open', color: '', show: false, disabled: false },
      { id: 'rename', text: 'Rename', icon: 'mdi-rename-box', color: '', show: true, disabled: false },
      { id: 'move', text: 'Move', icon: 'mdi-folder-move', color: '', show: true, disabled: false },
      { id: 'share', text: 'Share', icon: 'mdi-share-variant', color: '', show: true, disabled: false },
      { id: 'delete', text: 'Delete', icon: 'mdi-delete', color: '', show: true, disabled: false },
      { id: 'details', text: 'Details', icon: 'mdi-information', color: '', show: true, disabled: false },
    ],
    menu_item: false,
    showMenu: false,
    x: 0,
    y: 0,
    activeItems: [],
    multiple: false,
    deleteConf: false,
    renameDialog: false,
    filtered: []
  }),

  computed: {
    ...mapGetters('files', [
      'getSelection',
      'getTreeview'
    ]),

    ...mapGetters('toolbar', [
      'getSortBy'
    ]),

    filtered_items() {
      var filtered = this.getFiles;

      if (this.getSortBy != undefined) {
        this.sortItems(filtered);
      }

      if (this.$route.name == 'recent') {
        var arr = []
        for (var i = 0; i < filtered.length; i++) {
          if (filtered[i].isFolder == false) {
            arr.push(filtered[i]);
          }
        }

        filtered = arr;
      }

      if (this.search !== null && this.search !== '') {
        filtered = this.filtered.filter(item => {
          return item.name.toLowerCase().includes(this.search.toLowerCase())
        });
      }

      var page = this.getPage;
      var perPage = this.getPerPage;

      this.setFilteredItems(filtered);

      return filtered.slice((page - 1) * perPage, page * perPage);
    },
  },

  methods: {
    actions(item) {
      switch (item.id) {
        case 'favourites':
          this.updateFavourite();
          break;
        case 'create':
          this.$store.commit('fileDialogs/openFolderDialog', true);
          break;
        case 'download':
          this.download(this.editItem)
          break;
        case 'open':
          this.onDblClick(this.editItem)
          break;
        case 'openIn':
          this.openInFolder(this.editItem.parentDir)
          break;
        case 'rename':
          this.openRenameDialog();
          break;
        case 'move':
          this.openMoveDialog();
          break;
        case 'share':
          this.shareDialog(this.editItem);
          break;
        case 'delete':
          this.showDeleteConfirmation();
          break;
        case 'details':
          this.openDetails()
          break;

        default:
          break;
      }
    },

    setFilteredItems(filtered) {
      this.filtered = filtered;
    },

    sortDesc(arr, type) {
      switch (type) {
        case 'date':
          arr.sort(function(a, b) { return b.created - a.created });
          break;
        case 'modified':
          arr.sort(function(a, b) { return b.modified - a.modified });
          break;
        case 'size':
          arr.sort(function(a, b) { return b.size - a.size });
          break;
        case 'name':
          arr.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'type':
          arr.sort((a, b) => a.type.localeCompare(b.type));
          break;

        default:
          break;
      }
    },

    sortAsc(arr, type) {
      switch (type) {
        case 'date':
          arr.sort(function(a, b) { return a.created - b.created });
          break;
        case 'modified':
          arr.sort(function(a, b) { return a.modified - b.modified });
          break;
        case 'size':
          arr.sort(function(a, b) { return a.size - b.size });
          break;
        case 'name':
          arr.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case 'type':
          arr.sort((a, b) => b.type.localeCompare(a.type));
          break;

        default:
          break;
      }
    },

    sortItems(arr) {
      let sort = this.getSortBy;

      if (sort != undefined) {
        switch (sort.order) {
          case 'desc':
            this.sortDesc(arr, sort.type);
            break;
          case 'asc':
            this.sortAsc(arr, sort.type);
            break;

          default:
            this.sortDesc(arr, 'date');
            break;
        }
        return;
      }

      this.sortDesc(arr, 'date');
    },

    confirmDelete() {
      this.deleteConf = false;
      this.delete();
    },

    showDeleteConfirmation() {
      if (this.$route.name == 'trash') {
        this.delete();
        return;
      }

      this.deleteConf = true;
    },

    openRenameDialog() {
      this.editItem.oldname = this.editItem.name;
      this.renameDialog = true;
    },

    openDetails() {
      this.$store.commit('files/setSelectedFile', this.editItem);
      this.$store.commit('main/openDetails', true);
      this.getFileInfo();
    },

    switchMultipleOptions(status) {
      this.menuItems[5].show = status;
    },

    show(event, item, index) {
      this.$store.commit('files/setSelectedFile', item);
      this.menu_item = false;
      this.editItem = item;
      this.editItem.oldname = item.name;
      this.menuItems[4].show = false;

      if (item.favourite === true) {
        this.menuItems[0].color = 'yellow darken-1'
        this.menuItems[0].text = 'Remove from favourites'
      } else {
        this.menuItems[0].color = ''
        this.menuItems[0].text = 'Add to favourites'
      }

      if (this.multiple) {
        this.switchMultipleOptions(false)
        const found = this.getSelection.find(element => element == index);
        if (!found) {
          this.multiple = false;
          this.switchMultipleOptions(true)
          this.$store.commit('files/setSelectedFile', item);
          this.$store.commit('files/setSelection', [index]);
        }
      } else {
        this.$store.commit('files/setSelection', [index]);
      }

      switch (this.$route.name) {
        case 'shared':
          this.menuItems[0].show = false;
          this.menuItems[1].show = false;
          this.menuItems[4].show = false;
          this.menuItems[5].show = false;
          this.menuItems[6].show = false;
          break;
        case 'recent':
          this.menuItems[1].show = false;
          this.menuItems[4].show = true;
          break;
        case 'trash':
          this.menuItems[0].show = false;
          this.menuItems[1].show = false;
          this.menuItems[4].show = false;
          this.menuItems[5].show = false;
          this.menuItems[6].show = false;
          this.menuItems[7].show = false;
          break;
        case 'favourites':
          this.menuItems[1].show = false;
          this.menuItems[4].show = true;
          break;
        case 'files':
          if (!this.multiple) {
            if (this.getUser.id !== this.editItem.owner) {
              this.menuItems[4].disabled = true;
              this.menuItems[5].disabled = true;
            } else {
              this.menuItems[4].disabled = false;
              this.menuItems[5].disabled = false;
            }
          }
          break;

        default:
          break;
      }

      event.preventDefault()
      this.showMenu = false
      this.x = event.clientX
      this.y = event.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },


    updateFavourite() {
      let favourite = this.editItem.favourite === true ? false : true;

      const options = {
        url: '/api/files/favourite',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          id: this.editItem.id,
          favourite: this.editItem.favourite === true ? false : true,
        }
      };

      axios(options)
        .then(() => {
          this.editItem.favourite = favourite;
        }).catch(error => {
          this.showAlert({
            color: 'red',
            text: error.response
          })
        });
    },

    highlightFile(item) {
      setTimeout(() => {
        let files = this.getFiles;
        for (let i = 0; i < files.length; i++) {
          if (item.id == files[i].id) {
            let selected = i - (Math.ceil(i / this.getPerPage) - 1) * this.getPerPage;
            let page = Math.ceil(i / this.getPerPage);

            if (i == this.getPerPage) {
              page += 1;
              selected = 0;
            } else if (selected == this.getPerPage) {
              selected = 0;
            }

            if (page == 0) {
              page = 1;
            }

            this.$store.commit('files/setPage', page);
            this.$store.commit('files/setSelectedFile', item);
            this.$store.commit('files/setSelection', [selected]);

            setTimeout(function() {
              document.getElementById(item.id).scrollIntoView({ behavior: "smooth" });
            }, 500);

            break;
          }
        }
      }, 500);
    },

    openInFolder(id) {
      this.$router.push({ name: 'files', params: { path: id } })
    },

    openDirectory(id) {
      this.$store.commit('files/setPath', id);
      this.$router.push({ name: 'files', params: { path: id } });
      this.$store.commit('files/setSelection', []);
    },

    onDblClick(item) {
      if (item.isFolder) {
        this.openDirectory(item.id);
      } else {
        switch (item.type) {
          //this will be moved to plugin
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          case "application/msword":
          case "application/vnd.ms-powerpoint":
          case "application/vnd.ms-excel":
          case "application/vnd.oasis.opendocument.text":
          case "application/vnd.oasis.opendocument.spreadsheet":
          case "application/vnd.oasis.opendocument.presentation":
          case "text/csv":
          case "application/vnd.ms-word.document.macroenabled.12":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
          case "application/vnd.ms-word.template.macroenabled.12":
          case "application/epub+zip":
          case "text/html":
          case "application/vnd.oasis.opendocument.text-template":
          case "application/rtf":
          case "text/plain":
          case "image/vnd.djvu":
          case "application/vnd.ms-xpsdocument":
          case "application/vnd.oasis.opendocument.spreadsheet-template":
          case "application/vnd.ms-excel.sheet.macroenabled.12":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          case "application/vnd.oasis.opendocument.presentation-template":
          case "application/vnd.ms-powerpoint.template.macroenabled.12":
          case "application/vnd.ms-powerpoint.slideshow.macroenabled.12":
          case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
          case "application/vnd.ms-powerpoint.presentation.macroenabled.12":
            this.openOffice(item)
            break;
          case "image/jpeg":
          case "image/png":
          case "image/svg+xml":
            this.openFileViewer(item, 'image')
            break;
          case "application/pdf":
            this.openFileViewer(item, 'pdf')
            break;
          case "video/webm":
          case "video/ogg":
          case "video/mp4":
          case "video/mpeg":
          case "video/quicktime":
          case "video/x-m4v":
          case "video/x-matroska":
            this.openFileViewer(item, 'video')
            break;
          case "audio/aac":
          case "audio/mpeg":
          case "audio/ogg":
          case "audio/wav":
          case "audio/webm":
          case "audio/x-wav":
            this.openFileViewer(item, 'audio')
            break;
          default:
            this.download(item)
            break;
        }
      }
    },

    downloadFile(item) {
      var file_path = '/api/files/download/' + item.id;
      var a = document.createElement('A');
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async download(item) {
      this.$store
        .dispatch('files/downloadFile', item.id)
        .then(() => {
          this.downloadFile(item);
        });
    },

    setActive(event, item, index) {
      this.activeItem = index;
      this.multiple = true;

      this.$store.commit('files/setSelectedFile', item);
      this.getFileInfo();

      var items = this.getSelection;
      if (event.ctrlKey == true) {
        items.push(index);
        this.$store.commit('files/setSelection', items);
        this.getActiveItems();
        return;
      } else if (event.shiftKey == true) {
        items.push(index)
        var min;
        var max;
        var val = items;
        val[0] > val[val.length - 1] ? (max = val[0], min = val[val.length - 1]) : (max = val[val.length - 1], min = val[0]);

        var arr = [];
        for (var i = min; i < max + 1; i++) {
          arr.push(i);
        }
        val = arr;
        this.$store.commit('files/setSelection', val);
        this.getActiveItems();
        return;
      }

      this.$store.commit('files/setSelection', [index]);
      this.multiple = false;
    },

    openFileViewer(item, type) {
      this.$store.commit('fileViewer/openViewer', { src: item });
      this.$store.commit('fileViewer/setSelectedFileType', type);

      const html = document.querySelector('html');
      html.style.overflowY = 'hidden'
    },

    getFileInfo() {
      this.$store.dispatch('files/getActivity');
      this.$store.dispatch('files/getDetails');
    },

    openMoveDialog() {
      this.$store.commit('files/setItemToMove', this.multiple == true ? this.activeItems : [this.editItem]);
      this.$store.commit('fileDialogs/openMoveDialog', true);
    },

    deleteItems() {
      if (this.multiple) {
        var items = this.activeItems
        for (var i = 0; i < items.length; i++) {
          this.$store.commit('files/deleteFile', items[i]);
          if (items[i].isFolder) {
            let array = this.findItem('array', items[i].id, this.getTreeview);
            array.splice(array.indexOf(items[i]), 1);
          }
        }
        this.$store.commit('files/setSelection', []);
        return;
      }

      this.$store.commit('files/deleteFile', this.editItem);
      this.$store.commit('files/setSelection', []);
      let array = this.findItem('array', this.editItem.id, this.getTreeview);
      array.splice(array.indexOf(this.editItem), 1)
    },

    delete() {
      if (this.$route.name == 'trash') {
        this.$store
          .dispatch('files/deletePermanently', this.multiple == false ? [this.editItem.id] : this.activeItems.map(e => e.id))
          .then(() => {
            this.deleteItems();
          });
      } else {
        this.$store
          .dispatch('files/deleteFile', this.multiple == false ? [this.editItem.id] : this.activeItems.map(e => e.id))
          .then(() => {
            this.deleteItems();
          });
      }
    },

    openOffice(item) {
      this.$store.commit('main/openDetails', false);
      this.$store.dispatch("office/isEnabled").then((response) => {
        if (response.data == true) {
          this.$router.push({ name: 'office', params: { id: item.id } })
        } else {
          this.download(item)
        }
      }).catch(() => {
        this.download(item)
      })
    },

    getActiveItems() {
      this.activeItems = [];
      var items = this.getSelection;
      for (var i = 0; i < items.length; i++) {
        this.activeItems.push(this.getFiles[items[i]])
      }
    },

    shareDialog(item) {
      this.$store.commit('files/setSelectedFile', item);
      this.$store.commit('files/setShareItem', item);
      this.$store.commit('shareDialog/openShareDialog', true);
    },

    resetPage() {
      this.$store.commit('files/setPage', 1);
    },
  }
}
