import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    csrf: ""
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    getActivityByType(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/activity/type/' + data.type + "/" + data.id + "/" + data.periodTimestamp.start + "/" + data.periodTimestamp.end,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Error'
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
