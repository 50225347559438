<template>
  <v-container
    fluid
    class="fill-height px-0"
  >
    <v-row
      class="fill-height px-0"
      justify="center"
    >
      <v-col align="center">
        <v-card
          max-width="690"
          elevation="0"
        >
          <v-col>
            <div class="text-left mt-n3 mb-3">
              <v-icon class="mb-2 mr-2 ml-3">
                mdi-pencil-outline
              </v-icon>
              <span class="headline">
                Edit Account Details
              </span>
            </div>
            <v-form
              ref="form"
              v-model="valid"
            >
              <div class="my-3">
                <v-col
                  cols="12"
                  class="d-flex justify-center mb-n2"
                >
                  <v-file-input
                    v-model="photo"
                    :rules="photoRules"
                    accept="image/png, image/jpeg, image/bmp"
                    :color="getAppearances.btn_color"
                    placeholder="Pick an photo"
                    prepend-icon="mdi-camera"
                    label="Photo"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
                <div class="d-none d-sm-block">
                  <v-col
                    cols="12"
                    class="d-flex justify-center my-n2"
                  >
                    <v-text-field
                      v-model="getFilteredUser.name"
                      :rules="nameRules"
                      :color="getAppearances.btn_color"
                      required
                      type="text"
                      label="First name"
                      prepend-icon="mdi-account"
                      outlined
                      dense
                      class="mr-1"
                    ></v-text-field>
                    <v-text-field
                      v-model="getFilteredUser.lastname"
                      :rules="lastnameRules"
                      :color="getAppearances.btn_color"
                      required
                      type="text"
                      label="Last name"
                      prepend-icon="mdi-account"
                      outlined
                      dense
                      class="ml-2"
                    ></v-text-field>
                  </v-col>
                </div>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2 d-sm-none"
                >
                  <v-text-field
                    v-model="getFilteredUser.name"
                    :rules="nameRules"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="First name"
                    prepend-icon="mdi-account"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2 d-sm-none"
                >
                  <v-text-field
                    v-model="getFilteredUser.lastname"
                    :rules="lastnameRules"
                    :color="getAppearances.btn_color"
                    required
                    type="text"
                    label="Last name"
                    prepend-icon="mdi-account"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2 d-sm-none"
                >
                  <v-text-field
                    v-model="getFilteredUser.username"
                    disabled
                    :color="getAppearances.btn_color"
                    type="text"
                    label="Username"
                    prepend-icon="mdi-account-plus"
                    class="mr-1"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-text-field
                    v-model="getFilteredUser.email"
                    :color="getAppearances.btn_color"
                    :rules="[
                      checkDuplicateEmail,
                      emailRules.required,
                      emailRules.valid,
                    ]"
                    required
                    type="text"
                    label="Email"
                    outlined
                    prepend-icon="mdi-at"
                    dense
                  ></v-text-field>
                </v-col>
                <div class="text-left mb-6">
                  <v-icon class="mb-2 mr-2 ml-3">
                    mdi-pencil-outline
                  </v-icon>
                  <span class="headline">Edit Account Settings</span>
                </div>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-select
                    v-model="getFilteredUser.role"
                    :items="roles"
                    :color="getAppearances.btn_color"
                    :rules="roleRules"
                    required
                    :menu-props="{ maxHeight: '400' }"
                    label="User role"
                    prepend-icon="mdi-account-star"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center my-n2"
                >
                  <v-select
                    v-model="getFilteredUser.quota"
                    :rules="quotaRules"
                    :value="customQuota()"
                    :color="getAppearances.btn_color"
                    required
                    :items="quotaSelect"
                    :menu-props="{ maxHeight: '400' }"
                    label="Quota"
                    outlined
                    prepend-icon="mdi-cloud-upload"
                    dense
                  ></v-select>
                </v-col>
                <v-col
                  v-if="showCustomQuota == true"
                  cols="12"
                  class="d-flex justify-center mt-n3"
                >
                  <v-text-field
                    v-model="quotaCustom"
                    :rules="[customQuotaRules.required, customQuotaRules.valid]"
                    required
                    :color="getAppearances.btn_color"
                    label="Amount"
                    prepend-icon="mdi-cloud-upload"
                    outlined
                    dense
                    value="22"
                    suffix="GB"
                  ></v-text-field>
                </v-col>
              </div>
              <div class="mt-5">
                <div class="text-right mr-2 mb-1 d-sm-none">
                  <v-btn
                    outlined
                    class="mr-1"
                    color="red"
                    @click="resetPassword()"
                  >
                    Reset password
                  </v-btn>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="mr-1"
                    @click="backToUsersAndGroups()"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    outlined
                    class="mr-1 d-none d-sm-block"
                    color="red"
                    @click="resetPassword()"
                  >
                    Reset password
                  </v-btn>
                  <v-btn
                    :color="getAppearances.btn_color"
                    :disabled="updateDisabled()"
                    @click="editUser()"
                    class="mr-1 white--text"
                  >
                    Update User
                  </v-btn>
                </v-card-actions>
              </div>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { navigation } from "../../../../mixins/navigation";
export default {
  data: () => ({
    dataLoaded: true,
    quotaCustom: "",
    showCustomQuota: false,
    photo: null,
    showPw: false,
    newFilteredUser: {},
    showPw2: false,
    valid: true,
    quotaSelect: [
      "2 GB",
      "5 GB",
      "10 GB",
      "20 GB",
      "100 GB",
      "Unlimited",
      "Custom",
    ],
    roles: ["system_user", "system_admin"],

    photoRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Photo size should be less than 2 MB!",
    ],

    nameRules: [
      (v) => !!v || "First name is required",
      (v) =>
        (v && v.length <= 40) || "First name must be less than 40 characters",
    ],

    lastnameRules: [
      (v) => !!v || "Last name is required",
      (v) =>
        (v && v.length <= 40) || "First name must be less than 40 characters",
    ],

    emailRules: {
      required: (v) => !!v || "E-mail is required",
      valid: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    },

    roleRules: [(v) => !!v || "Role is required"],

    quotaRules: [(v) => !!v || "Quota is required"],

    customQuotaRules: {
      required: (v) => !!v || "Quota is required",
      valid: (v) => /^[1-9]\d*$|^$/.test(v) || "Only numbers are allowed",
    },
  }),

  mixins: [navigation],

  computed: {
    ...mapGetters("admin", ["getFilteredUser", "getAppearances"]),

    ...mapGetters("user", ["getUsers", "getUser"]),
  },

  mounted() {
    this.refresh();
    this.bindCustomQuota();
    this.$store.dispatch("user/getUsers");
    this.copyUserData();
  },

  methods: {
    ...mapMutations("user", ["setUser"]),

    copyUserData() {
      this.newFilteredUser = JSON.parse(JSON.stringify(this.getFilteredUser));
    },

    updateDisabled() {
      if (
        JSON.stringify(this.newFilteredUser) !=
          JSON.stringify(this.getFilteredUser) ||
        this.photo != null
      ) {
        return false;
      } else {
        return true;
      }
    },

    refresh() {
      if (this.getFilteredUser.length == 0) {
        this.navigateToRoute({ name: "users-and-groups" });
        this.dataLoaded = false;
      }
    },

    bindCustomQuota() {
      if (this.dataLoaded == true) {
        var changeValue = true;

        for (let i = 0; i < this.quotaSelect.length; i++) {
          if (this.getFilteredUser.quota == this.quotaSelect[i]) {
            changeValue = false;
          }
        }

        if (changeValue == true) {
          this.quotaCustom = JSON.parse(
            JSON.stringify(this.getFilteredUser.quota)
          ).replace(" GB", "");
          this.getFilteredUser.quota = "Custom";
        }
      }
    },

    customQuota() {
      if (this.getFilteredUser.quota == "Custom") {
        this.showCustomQuota = true;
      } else {
        this.showCustomQuota = false;
      }
    },

    backToUsersAndGroups() {
      this.navigateToRoute({ name: "users-and-groups" });
    },

    checkDuplicateEmail(v) {
      var result1 = this.getUsers;
      var result2 = this.getFilteredUser;
      var filtered = [];
      filtered.push(result2);
      let result = result1.filter(
        (o1) => !filtered.some((o2) => o1.id === o2.id)
      );

      if (v == undefined) {
        return false;
      } else if (v.length > 0) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].email == v) {
            return `Email "${v}" already exist`;
          }
        }
      } else {
        return true;
      }
      return true;
    },

    editUser() {
      if (this.$refs.form.validate()) {
        if (this.getFilteredUser.quota == "Custom") {
          this.getFilteredUser.quota = this.quotaCustom + " " + "GB";
        }

        var data = {
          id: this.getFilteredUser.id,
          name: this.getFilteredUser.name,
          lastname: this.getFilteredUser.lastname,
          email: this.getFilteredUser.email,
          role: this.getFilteredUser.role,
          quota: this.getFilteredUser.quota,
        };
        this.$store.dispatch("admin/editUser", data).then((response) => {
          if (this.getUser.id == this.getFilteredUser.id) {
            this.setUser(response.data);
          }
          if (this.photo != null) {
            this.editUserPhoto(this.getFilteredUser.id);
          }
        });
        this.$store.dispatch("user/getUsers").then(() => {
          this.backToUsersAndGroups();
        });
      }
    },

    editUserPhoto(id) {
      let formData = new FormData();
      formData.append("file", this.photo);
      formData.append("id", id);
      this.$store.dispatch("admin/editUserPhoto", formData).then((response) => {
        if (this.getUser.id == this.getFilteredUser.id) {
          this.getUser.photo = response.data;
        }
      });
    },

    resetPassword() {
      this.$store.dispatch("user/resetPassword", {
        id: this.getFilteredUser.id,
      });
      this.backToUsersAndGroups();
    },
  },
};
</script>