
import { mapGetters } from 'vuex';
import moment from 'moment';

export const dateUtils = {

  computed: {
    ...mapGetters('user', [
      'getTimezone',
      'getClockDisplay'
    ]),
  },

  methods: {
    getDateAndTime(tstamp) {
      if (tstamp == "") {
        return ""
      }

      if (this.getClockDisplay == '12h') {
        return moment(tstamp * 1000).tz(this.getTimezone).format('MM/DD/YYYY LT')
      }

      return moment(tstamp * 1000).tz(this.getTimezone).format('DD.MM.YYYY HH:mm')
    },

    getDateString(tstamp) {
      if (tstamp == "") {
        return ""
      }

      if (this.getClockDisplay == '12h') {
        return moment(tstamp * 1000).tz(this.getTimezone).format('MM/DD/YYYY')
      }

      return moment(tstamp * 1000).tz(this.getTimezone).format('DD.MM.YYYY')
    },

    formatDate(item) {
      var newFormat = moment(item * 1000).tz(this.getTimezone).format('D MMM, YYYY');
      return newFormat
    },

    getDate(tstamp) {
      var curDate = new Date();
      var date = new Date(tstamp * 1000);

      var years = Math.floor((curDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 30.4167 * 12));
      var months = Math.floor((curDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 30.4167));
      var days = Math.floor((curDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
      var hours = Math.floor((curDate.getTime() - date.getTime()) / (1000 * 60 * 60));
      var minutes = Math.floor((curDate.getTime() - date.getTime()) / (1000 * 60));
      var seconds = Math.floor((curDate.getTime() - date.getTime()) / 1000);

      if (years >= 1) {
        if (years == 1) {
          return 'a year ago'
        }

        return years + ' years ago'
      } else if (months >= 1) {
        if (months == 1) {
          return 'a month ago'
        }

        return months + ' months ago'
      } else if (days >= 1) {
        if (days == 1) {
          return 'a day ago'
        }

        return days + ' days ago'
      } else if (hours >= 1) {
        if (hours == 1) {
          return 'an hour ago'
        }

        return hours + ' hours ago'
      } else if (minutes >= 1) {
        if (minutes == 1) {
          return 'a minute ago'
        }

        return minutes + ' minutes ago'
      } else if (seconds >= 1) {
        if (seconds == 1) {
          return 'a second ago'
        }

        return seconds + ' seconds ago'
      }
    },
  }
}
