import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default ({
  state: {
    editPermissions: {},
    shareDialog: false,
    permissionDialog: false,
    linkPermissionDialog: false,
  },

  getters: {
    shareDialog(state) {
      return state.shareDialog
    },
    permissionDialog(state) {
      return state.permissionDialog;
    },
    linkPermissionDialog(state) {
      return state.linkPermissionDialog;
    },
    editPermissions(state) {
        return state.editPermissions;
    }
  },

  mutations: {
    openShareDialog(state, status) {
      state.shareDialog = status;
    },
    openUserPermissionsDialog(state, data) {
      state.permissionDialog = data.status;
      if (data.status) {
        state.editPermissions = data.user;
        return;
      }
      state.editPermissions = {};
    },
    openLinkPermissionsDialog(state, data) {
      state.linkPermissionDialog = data.status;
      if (data.status) {
        state.editPermissions = data.link;
        return;
      }
      state.editPermissions = {};
    }
  },

  namespaced: true,
})
