<template>
  <v-container
    fluid
    class="fill-height px-0"
  >
    <v-row
      class="fill-height px-0"
      justify="center"
    >
      <v-col
        align="center"
        class="px-0"
        lg="6"
        md="8"
        sm="10"
        xs="12"
        style="max-width: 700px"
      >
        <h1 class="font-weight-light mx-9">
          Security
        </h1>
        <div
          :style="{ maxWidth: '700px' }"
          class="text-wrap font-weight-regular text--secondary"
        >
          Manage your account security settings
        </div>
        <v-card
          max-width="700"
          class="my-9"
          elevation="0"
        >
          <div class="text-left pl-0 px-0">
            <v-col>
              <v-list two-line>
                <v-list-item class="px-0">
                  <v-col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    class="mb-n3 pl-3"
                  >
                    <v-list-item-title>
                      Multi factor authentication
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Adding multi-factor authentication will make your account more secure by requiring a code from your mobile phone each time you sign in.
                    </v-list-item-subtitle>

                    <v-dialog
                      v-model="mfaDialog"
                      persistent
                      max-width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="!getUser.mfaActive"
                          depressed
                          dark
                          small
                          :color="getAppearances.btn_color"
                          class="mt-3 mt-3 mb-7"
                          v-bind="attrs"
                          v-on="on"
                          @click="load"
                        >
                          Add MFA to Account
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h6">
                          Multi-factor Authentication Setup
                        </v-card-title>
                        <v-card-text><strong>Step 1:</strong> On your phone, download Google Authenticator from <a
                            href="https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8%27"
                            target="_blank"
                            rel="noopener noreferrer"
                          >App Store</a> or <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                          >Google Play</a></v-card-text>
                        <v-card-text><strong>Step 2:</strong> Use Google Authenticator to scan this QR code, or manually type in the secret key</v-card-text>

                        <v-row
                          align="center"
                          justify="center"
                          class="mx-0"
                        >
                          <v-col cols="5">
                            <v-img :src="'data:image/png;base64,' + qr_code"></v-img>
                          </v-col>
                        </v-row>

                        <v-row
                          align="center"
                          justify="center"
                          class="mx-0"
                        >
                          <div class="mb-5 text-body-2 mx-5">
                            Secret: {{ secret }}
                          </div>
                        </v-row>

                        <v-card-text><strong>Step 3:</strong> Enter the code generated by Google Authenticator</v-card-text>

                        <v-text-field
                          v-model="token"
                          label="MFA code"
                          outlined
                          dense
                          class="mx-5"
                        ></v-text-field>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            :color="
                          getAppearances.btn_color"
                            text
                            @click="mfaDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            :color="getAppearances.btn_color"
                            text
                            @click="save()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="disableDialog"
                      persistent
                      max-width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="getUser.mfaActive"
                          depressed
                          small
                          :color="getAppearances.btn_color"
                          dark
                          class="mt-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Remove MFA from account
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h6">
                          Remove Multi-factor Authentication
                        </v-card-title>
                        <v-card-text>By removing Multi-factor Authentication you make your account less secure</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            :color="getAppearances.btn_color"
                            text
                            @click="disableDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="red"
                            text
                            @click="disable"
                          >
                            Disable
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-list-item>
                <v-divider :class="$vuetify.breakpoint.smAndDown ? 'my-3 mx-3' : 'my-3 mx-0'" />
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-col
                      cols="12"
                      class="mb-n3 pl-3"
                    >
                      <v-list-item-title>
                        Devices & sessions
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap my-1">
                        Web, desktop and mobile clients currently logged in to your account.
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="mb-9 px-0"
                    >
                      <div
                        v-for="(item, index) in sessions"
                        :key="item.key"
                      >
                        <v-list-item
                          class="py-3"
                          @click="open(item)"
                        >
                          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
                            <v-col
                              cols="8"
                              class="px-0 py-0 pl-1"
                            >
                              <v-list-item-title>
                                <v-icon class="pb-1 mr-2">mdi-monitor</v-icon>
                                {{ item.device_id }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="text-wrap">
                                Last activity: {{ getDateAndTime(item.last_activity) }}
                              </v-list-item-subtitle>
                            </v-col>
                            <v-col
                              cols="4"
                              class="px-0 py-0 text-right pr-1"
                            >
                              <v-btn
                                depressed
                                :color="getAppearances.btn_color"
                                small
                                dark
                                @click.stop="logOut(item)"
                              >
                                Log Out
                              </v-btn>
                            </v-col>
                          </v-list-item-content>

                          <v-list-item-content v-if="$vuetify.breakpoint.smAndDown">
                            <v-col
                              cols="8"
                              class="px-0 py-0"
                            >
                              <v-list-item-title>
                                <v-icon class="pb-1 mr-2">mdi-monitor</v-icon>
                                {{ item.device_id }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="text-wrap">
                                Last activity: {{ getDateAndTime(item.last_activity) }}
                              </v-list-item-subtitle>
                            </v-col>
                            <v-col
                              cols="4"
                              class="px-0 py-0 text-right"
                            >
                              <v-btn
                                depressed
                                :color="getAppearances.btn_color"
                                small
                                dark
                                @click.stop="logOut(item)"
                              >
                                Log Out
                              </v-btn>
                            </v-col>
                          </v-list-item-content>

                        </v-list-item>
                        <v-divider
                          v-if="$vuetify.breakpoint.mdAndUp"
                          :key="index"
                        ></v-divider>
                        <v-divider
                          v-if="$vuetify.breakpoint.smAndDown"
                          class="mx-3"
                          :key="index"
                        ></v-divider>
                      </div>
                    </v-col>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{selected.device_id}}
        </v-card-title>
        <v-card-text>
          <v-list
            subheader
            two-line
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Session ID</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle v-if="$vuetify.breakpoint.mobile && selected.key != undefined"> {{ selected.key.substring(0, 15)+"..." }} </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else
                  v-text="selected.key"
                ></v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Expires</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle>{{ getDateAndTime(selected.expires) }}</v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>IP address</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle v-text="selected.ip"></v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Browser</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle v-text="selected.browser"></v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Last Activity</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle>{{ getDateAndTime(selected.last_activity) }}</v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Created</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle>{{ getDateAndTime(selected.created) }}</v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../../mixins/date";
import axios from "axios";

export default {
  data: () => ({
    loaded: false,
    sessions: [],
    dialog: false,
    mfaDialog: false,
    disableDialog: false,
    selected: "",

    qr_code: "",
    secret: "",
    token: "",
  }),

  mixins: [dateUtils],

  created() {
    this.$store.dispatch("user/getMySessions").then((response) => {
      this.sessions = response.data;
      this.sessions.sort(function (a, b) {
        return b.last_activity - a.last_activity;
      });
    });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getUser"]),
  },

  methods: {
    open(item) {
      this.selected = item;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.selected = {};
    },

    save() {
      this.mfaDialog = false;
      const options = {
        url: "/api/users/mfa",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          activate: true,
          token: this.token,
        },
      };
      axios(options).then(() => {
        this.qr_code = "";
        this.secret = "";
        this.token = "";
        this.getUser.mfaActive = true;
      });
    },

    disable() {
      const options = {
        url: "/api/users/mfa",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          activate: false,
        },
      };
      axios(options).then(() => {
        this.disableDialog = false;
        this.getUser.mfaActive = false;
      });
    },

    load() {
      const options = {
        url: "/api/users/mfa/generate",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios(options).then((response) => {
        this.qr_code = response.data.qr_code;
        this.secret = response.data.secret;
      });
    },

    logOut(item) {
      this.$store.dispatch("user/logOutSession", item.key).then(() => {
        this.sessions.splice(this.sessions.indexOf(item), 1);
      });
    },
  },
};
</script>