<template>
  <v-snackbar
    :timeout=timeout
    v-model="showNotification"
    :multi-line="multiLine"
    right
    top
  >
    <v-list-item v-if="message.id">
      <v-list-item-avatar>
        <v-img :src="`/api/users/photo/${message.user.photo}`"></v-img>
      </v-list-item-avatar>

      <v-list-item-content
        class="d-inline-block text-truncate"
        style="max-width: 400px;"
      >
        <v-list-item-title v-text="message.user.name + ' ' + message.user.lastname"></v-list-item-title>
        <v-list-item-subtitle v-text="message.message"></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <template v-slot:action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="close"
        class="mx-3"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    message: Object,
  },

  data: () => ({
    timeout: 5000,
    multiLine: true,
  }),

  computed: {
    ...mapGetters("notifications", ["getNotification"]),

    showNotification: {
      get() {
        return this.getNotification;
      },
      set(value) {
        this.$store.commit("notifications/showNotification", value);
      },
    },
  },

  methods: {
    close() {
      this.$store.commit("notifications/showNotification", false);
    },
  },
};
</script>