<template>
  <v-main :style="background()">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
        >
          <v-card class="elevation-7 form-style login-transparent">
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-img
                max-height="96"
                max-width="96"
                :src="`/api/settings/appearances/image/logo`"
              ></v-img>
            </v-row>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div v-if="!showMfa">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :color="getAppearances.btn_color"
                    name="email"
                    prepend-icon="mdi-account"
                    :rules="emailRules"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    id="password"
                    label="Password"
                    :color="getAppearances.btn_color"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="passwordRules"
                    @keyup.enter="logIn"
                  ></v-text-field>
                </div>
                <v-row
                  v-if="showMfa"
                  align="center"
                  justify="center"
                >
                  <v-col
                    class="justify-center align-center"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="8"
                  >
                    <div class="my-5 text-center">
                      To complete the sign in process, please enter a token from your smartphone's authenticator
                    </div>

                    <v-otp-input
                      v-model="token"
                      :disabled="loading"
                      @finish="logIn"
                    ></v-otp-input>
                    <v-overlay
                      absolute
                      :value="loading"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading_main_button"
                :disabled="loading_main_button"
                :dark="!loading_main_button"
                class="mb-3"
                block
                @click="logIn"
                :color="getAppearances.btn_color"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Alert />
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import { getCsrfFromCookie } from "../js/utils";
import Alert from "../components/Alert";

export default {
  components: {
    Alert,
  },

  data: () => ({
    loading_main_button: false,
    show: true,
    showMfa: false,
    loading: false,
    email: "",
    password: "",
    token: "",
    valid: true,
    redirectPath: "",
    passwordRules: [(v) => !!v || "Password is required"],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  created() {
    this.$store.dispatch("auth/getPasswordPolicy"); //after logout to retrieve valid csrf token. temporary fix

    this.redirectPath = this.$router.currentRoute.query.redirect;
    if (this.redirectPath == undefined) {
      this.redirectPath = "/";
    }
  },

  mounted() {
    setTimeout(() => {
      const els = document.querySelectorAll("input:-webkit-autofill");
      els.forEach((el) => {
        const label = el.parentElement.querySelector("label");
        label.classList.add("v-label--active");
      });
    }, 500);
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    background() {
      var style =
        "background-image: url('/api/settings/appearances/image/background');" +
        "background-repeat: no-repeat; background-size: cover;";
      return style;
    },

    logIn() {
      if (this.$refs.form.validate()) {
        this.loading_main_button = true;
        this.$store
          .dispatch("user/logIn", {
            email: this.email,
            password: this.password,
            token: this.token,
          })
          .then(() => {
            this.$store.commit("auth/setCSRF", getCsrfFromCookie());
            this.$router.push({ path: this.redirectPath });
          })
          .catch((error) => {
            if ("provide_mfa_token\n" == error.response.data) {
              this.showMfa = true;
              this.loading_main_button = false;
              return;
            }

            this.loading_main_button = false;

            this.$store.dispatch("alert/showAlert", {
              color: "red",
              text: error.response.data,
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.login-transparent {
  opacity: 0.9;
}
</style>