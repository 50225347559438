import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    csrf: ""
  },

  getters: {
    getCSRF(state) {
      return state.csrf;
    }
  },

  mutations: {
    setCSRF(state, token) {
      state.csrf = token;
    }
  },

  actions: {
    isValidLink(context, token) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/res/' + token,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            reject();
          });
      })
    },

    getPasswordPolicy(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/settings/password-policy',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Error'
            }, { root: true })
          });
      })
    },

    setPassword(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/res/' + data.token + '/set-password',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            password: data.password,
            confirmPassword: data.confirmPassword,
          }
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to set password'
            }, { root: true })
          });
      })
    }
  },

  namespaced: true,
})
