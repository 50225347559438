<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      align="center"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-col
          lg="9"
          md="9"
          sm="10"
          xs="12"
        >
          <div class="text-left mx-n7 my-n3">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-col cols="12">
                    <v-list-item-title>
                      Email server
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap my-1">
                      Set up this server to be able to send emails, like for password reset and notifications.
                    </v-list-item-subtitle>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="10"
          xs="12"
        >
          <v-row>
            <v-col
              cols="6"
              class="d-flex justify-start pb-0 mb-0"
            >
              <v-select
                v-model="emailSettings.ConnectionSecurity"
                :rules="connectionRules"
                :color="getAppearances.btn_color"
                required
                :items="connectionSecurity"
                label="Connection Security"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col
              cols="5"
              class="d-flex justify-start py-0 my-0"
            >
              <v-checkbox
                v-model="emailSettings.EnableSMTPAuth"
                :color="getAppearances.btn_color"
                :rules="enableAuthRules"
                label="Enable SMTP Authentication"
                class="my-1"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="10"
          xs="12"
        >
          <v-row>
            <v-col
              lg="5"
              md="5"
              sm="6"
              xs="6"
              class="d-flex justify-start py-0 my-0"
            >
              <v-text-field
                v-model="emailSettings.SMTPServer"
                :rules="serverAddressRules"
                :color="getAppearances.btn_color"
                required
                label="SMTP Server"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              lg="3"
              md="3"
              sm="3"
              xs="3"
              class="d-flex justify-start py-0 my-0"
            >
              <v-text-field
                v-model="emailSettings.SMTPPort"
                :color="getAppearances.btn_color"
                :rules="portRules"
                required
                label="Port"
                prepend-icon="mdi-slash-forward mr-3"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="10"
          xs="12"
        >
          <v-row>
            <v-col
              lg="5"
              md="5"
              sm="6"
              xs="6"
              class="d-flex justify-start py-0 my-0"
            >
              <v-text-field
                v-model="emailSettings.SMTPUsername"
                :color="getAppearances.btn_color"
                :rules="usernameRules"
                required
                label="SMTP Server Username"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              lg="5"
              md="5"
              sm="6"
              xs="6"
              class="d-flex justify-start py-0 my-0"
            >
              <v-text-field
                v-model="emailSettings.SMTPPassword"
                :rules="smtpPasswordRules"
                :color="getAppearances.btn_color"
                placeholder="password"
                outlined
                dense
                label="SMTP Password"
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          lg="9"
          md="9"
          sm="10"
          xs="12"
          class="mt-3"
        >
          <v-btn
            @click="updateEmailServer()"
            :disabled="checkValidation()"
            :color="getAppearances.btn_color"
            class="mt-n3 mx-0 white--text"
          >
            Save Changes
          </v-btn>
          <v-btn
            dark
            :color="getAppearances.btn_color"
            class="mt-n3 ml-3"
            @click="testConnection()"
          >
            Test connection
          </v-btn>
          <v-alert
            v-if="success"
            class="mt-3"
            dense
            text
            type="success"
          >
            No errors were reported
          </v-alert>
          <v-alert
            v-if="error"
            class="mt-3"
            dense
            text
            type="error"
          >
            {{errorMsg}}
          </v-alert>
        </v-col>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    smtpPassword: "",
    valid: true,
    emailSettings: {},
    oldSettings: {},

    connectionSecurity: ["TLS"],
    connectionRules: [(v) => !!v || "Connection Security is required"],
    enableAuthRules: [(v) => !!v || "Authentication method is required"],
    serverAddressRules: [(v) => !!v || "Server address is required"],
    portRules: [(v) => !!v || "Port is required"],
    usernameRules: [(v) => !!v || "Credentials is required"],
    smtpPasswordRules: [(v) => !!v || "SMTP password is required"],

    success: false,
    error: false,
    errorMsg: "",
  }),

  computed: {
    ...mapGetters("admin", ["getEmailServer", "getAppearances"]),
  },

  mounted() {
    this.$store.dispatch("admin/getEmailServer").then((response) => {
      this.emailSettings = response.data;
      this.oldSettings = JSON.parse(JSON.stringify(response.data));
    });
  },

  methods: {
    checkValidation() {
      return (
        JSON.stringify(this.emailSettings) === JSON.stringify(this.oldSettings)
      );
    },

    testConnection() {
      this.success = false;
      this.error = false;

      this.$store
        .dispatch("admin/testEmailConnection")
        .then((response) => {
          if (response.data == true) {
            this.success = true;
            return;
          }

          this.error = true;
        })
        .catch((error) => {
          this.errorMsg = error.response.data;
          this.error = true;
        });
    },

    updateEmailServer() {
      if (this.$refs.form.validate()) {
        var data = {
          ConnectionSecurity: this.emailSettings.ConnectionSecurity,
          EnableSMTPAuth: this.emailSettings.EnableSMTPAuth,
          SMTPServer: this.emailSettings.SMTPServer,
          SMTPPort: this.emailSettings.SMTPPort,
          SMTPUsername: this.emailSettings.SMTPUsername,
          SMTPPassword: this.emailSettings.SMTPPassword,
        };
        this.$store.dispatch("admin/updateEmailServer", data).then(() => {
          this.oldSettings = JSON.parse(JSON.stringify(this.emailSettings));
          this.$refs.form.resetValidation();
        });
      }
    },
  },
};
</script>