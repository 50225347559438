<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      lg="5"
      md="5"
      sm="12"
      xs="12"
    >
      <v-card
        v-if="!getLicense.isLicensed"
        :elevation="$vuetify.breakpoint.mobile ? 0 : 2"
      >
        <v-card-subtitle>
          Community edition
        </v-card-subtitle>
        <v-card-title>Community</v-card-title>
        <v-card-text>
          Purchase Professional or Enterprise to unlock more features.
        </v-card-text>
        <v-card-text>
          <v-card outlined>
            <v-card-title>
              License details
            </v-card-title>
            <v-card-text>
              This software is offered under a commercial license. See LICENSE.txt in your root install directory for details.
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-2 mb-3"
            :color="getAppearances.btn_color"
            dark
            @click="dialog = true"
          >
            Add new license
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        :elevation="$vuetify.breakpoint.mobile ? 0 : 2"
      >
        <v-card-subtitle>
          {{getLicense.edition}} edition
        </v-card-subtitle>
        <v-card-title>Cospace Enterprise</v-card-title>
        <v-card-text>
          This is an {{getLicense.edition}} Edition for the Cospace Enterprise plan
        </v-card-text>
        <v-card-text>
          <v-card outlined>
            <v-card-title>
              License details
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Name:</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>{{getLicense.name}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Name:</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>{{getLicense.edition}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Starts at:</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>{{ getDateString(getLicense.starts_at) }}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Expires at:</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>{{ getDateString(getLicense.expires_at) }}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Issued at:</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>{{ getDateString(getLicense.issued_at) }}</v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="dialog = true"
            class="ml-2 mb-3"
          >
            Add new license
          </v-btn>

          <v-btn
            color="red"
            dark
            text
            @click="removeLicense"
            class="mb-3"
          >
            Remove license
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Add new license
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="key"
            label="Upload license key"
            outlined
            rows="1"
            row-height="15"
            hide-details
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            text
            @click="dialog = false, key = ''"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="addLicense"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../../mixins/date";

export default {
  data: () => ({
    dialog: false,
    key: "",
  }),

  mixins: [dateUtils],

  computed: {
    ...mapGetters("license", ["getLicense"]),
    ...mapGetters("admin", ["getAppearances"]),
  },

  methods: {
    addLicense() {
      this.$store.dispatch("license/addLicense", this.key).then((response) => {
        this.$store.commit("license/setLicense", response.data);
      });

      this.dialog = false;
      this.key = "";
    },

    removeLicense() {
      this.$store.dispatch("license/removeLicense").then(() => {
        this.$store.commit("license/setLicense", { isLicensed: false });
      });
    },
  },
};
</script>
