<template>
  <v-col
    cols="12"
    class="pt-3 pb-0 px-0"
  >
    <v-expansion-panels
      v-model="panel"
      flat
      class="customField"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-regular px-3">
          Custom Fields
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="header in getHeaders"
            :key="header.value"
            class="font-weight-regular dateFont"
          >
            <v-list
              v-if="except(header.value) == true && header.show == true"
              class="pa-0 mx-n3"
            >
              <v-list-item
                dense
                class="my-n1"
              >
                <v-row class="d-flex align-center">
                  <v-col
                    cols="3"
                    :class="customFields(header.value).type == 'Labels' ? 'pa-0 my-2' : 'pa-0'"
                  >
                    <v-list-item-subtitle>
                      {{header.name}}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    :class="customFields(header.value).type == 'Labels' ? 'pa-0 my-2' : 'pa-0'"
                    cols="9"
                  >
                    <CustomFieldItems
                      :selectedTask="getTask"
                      :header="header"
                      :placement="'fullTask'"
                    />
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";
import CustomFieldItems from "../../Projects/CustomFields/CustomFieldItems.vue";

export default {
  components: {
    CustomFieldItems,
  },
  data: () => ({
    panel: 0,
  }),

  computed: {
    ...mapGetters("projects", ["getHeaders", "getTask"]),
  },
  mixins: [projectUtils],
};
</script>

<style scoped>
.customField {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  border-right: none;
  border-left: none;
  font-size: 13px;
  cursor: pointer;
}
</style>