<template>
  <v-dialog
    v-model="deleteConf"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">
        Delete selected files
      </v-card-title>

      <v-card-text>
        Deleted files can be restored from trash within 15 days.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="cancelDelete"
        >
          Cancel
        </v-btn>

        <v-btn
          color="red"
          dark
          @click="deleteFile"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

  export default {
    props: ['deleteConf'],

    data: () => ({
    }),

    computed: {
      ...mapGetters('admin', [
        'getAppearances',
      ]),
    },

    methods: {
      deleteFile() {
        this.$emit('confirmDelete')
      },

      cancelDelete() {
        this.$emit('cancelDelete')
      }
    }
  }
</script>
