<template>
  <v-dialog
    v-model="projectDialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title
        v-if="edit"
        class="pa-4"
      >Edit Project</v-card-title>
      <v-card-title
        v-else
        class="pa-4"
      >New Project</v-card-title>
      <v-container class="pa-1">
        <v-form
          ref="form"
          v-model="valid"
          v-on:submit.prevent
        >
          <div class="my-0">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-text-field
                v-model="projectTitle"
                prepend-icon="mdi-text-box"
                label="Title"
                dense
                @keyup.enter="createProject"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-textarea
                v-model="projectDescription"
                prepend-icon="mdi-text-box-plus"
                label="Description"
                rows="1"
                dense
                @keyup.enter="createProject"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="computedStartDateFormatted"
                    prepend-icon="mdi-calendar"
                    :color="getAppearances.btn_color"
                    label="Project start date"
                    rows="1"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keyup.enter="createProject"
                  ></v-textarea>
                </template>
                <v-date-picker
                  v-model="projectStartDate"
                  :color="getAppearances.btn_color"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="computedEndDateFormatted"
                    prepend-icon="mdi-calendar"
                    :color="getAppearances.btn_color"
                    label="Project end date"
                    rows="1"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keyup.enter="createProject"
                  ></v-textarea>
                </template>
                <v-date-picker
                  v-model="projectEndDate"
                  :color="getAppearances.btn_color"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-1 mb-1"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              class="white--text mb-1 mr-1"
              :disabled="loading"
              :loading="loading"
              @click="createProject()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: { projectDialog: Boolean, edit: Boolean, project: Object },

  data: () => ({
    projectTitle: "",
    projectDescription: "",
    projectStartDate: "",
    projectEndDate: "",
    valid: true,
    dateMenu: false,
    endDateMenu: false,
    loading: false,
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("user", ["getClockDisplay"]),

    ...mapGetters("projects", ["getProjects", "getProjectFolder"]),

    computedStartDateFormatted() {
      return this.formatDate(this.projectStartDate);
    },

    computedEndDateFormatted() {
      return this.formatDate(this.projectEndDate);
    },
  },

  watch: {
    edit(val) {
      if (val) {
        this.projectTitle = this.project.title;
        this.projectDescription = this.project.description;

        if (this.project.start_date != 0) {
          this.projectStartDate = moment
            .unix(this.project.start_date)
            .format("YYYY-MM-DD");
        }
        if (this.project.end_date != 0) {
          this.projectEndDate = moment
            .unix(this.project.end_date)
            .format("YYYY-MM-DD");
        }
      }
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      let clock = this.getClockDisplay;

      if (clock == "12h") {
        return `${month}/${day}/${year}`;
      }

      return `${day}.${month}.${year}`;
    },

    getData() {
      var data = {
        title: this.projectTitle,
        description: this.projectDescription,
        startDate: this.projectStartDate,
        endDate: this.projectEndDate,
        folder: "",
        id: "",
      };

      if (data.startDate != "") {
        data.startDate = moment(this.projectStartDate).unix();
      } else if (data.startDate == "") {
        data.startDate = 0;
      }

      if (data.endDate != "") {
        data.endDate = moment(this.projectEndDate).unix();
      } else if (data.endDate == "") {
        data.endDate = 0;
      }

      return data;
    },

    createProject() {
      this.loading = true;

      if (this.edit) {
        let data = this.getData();

        data.id = this.project.id;

        this.$store.dispatch("projects/patchProject", data);

        this.$emit("update", data);
        this.close();

        return;
      }

      var order = [{ index: 1, name: "ToDo", tasks: [] }];
      var header = [
        { value: "title", name: "" },
        { value: "start_date", name: "Start Date", show: true },
        { value: "end_date", name: "Due Date", show: true },
        { value: "assigned_to", name: "Assignee", show: true },
        { value: "status", name: "Status", show: true },
        { value: "updated", name: "Updated", show: true },
        { value: "created", name: "Created", show: true },
      ];

      let data = this.getData();

      let id = "0";

      if (this.$route.name == "project-folder") {
        id = this.$route.params.id;
      }

      data.folder = id;

      var properties = [order, header];
      data.properties = JSON.stringify(properties);

      this.$store
        .dispatch("projects/createProject", data)
        .then((response) => {
          if (this.$route.name == "my-projects") {
            let projects = this.getProjects;
            projects.push(response.data);

            this.$store.commit("projects/setProjects", projects);
          } else {
            this.getProjectFolder.projects.push(response.data);
            //this.$store.commit("projects/setProjectFolder", projectFolder);
          }

          this.close();
        })
        .catch(() => {
          this.close();
        });
    },

    close() {
      this.projectTitle = "";
      this.projectDescription = "";
      this.projectStartDate = "";
      this.projectEndDate = "";
      this.loading = false;

      this.$emit("close");
    },
  },
};
</script>