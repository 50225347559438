<template>
  <div>
    <v-toolbar
      color="elevation-0"
      dense
      class="my-1"
    >
      <ProjectMenu />
      <v-row
        v-if="getMobileView == false"
        :class="$route.name == 'project' ? 'ml-0 mr-2' : 'mx-2'"
        style="min-width: 5%; max-width: 75%;"
      >
        <div class="projectFont maxTextLengthDesktop">
          {{getProject.title}}
        </div>
        <!-- <v-btn
          icon
          small
          class=""
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn> -->
        <v-btn
          icon
          small
          class="mx-2"
          :color="getProject.favourite == true ? 'yellow darken-1' : 'gray'"
          @click="setFavourite"
          style="margin-top: 3px;"
        >
          <v-icon>mdi-star</v-icon>
        </v-btn>
      </v-row>
      <div
        v-else
        class="px-0"
        style="min-width: 65%; max-width: 75%;"
      >
        <div
          class="mt-2 ml-1 projectFont maxTextLengthMobile"
          style="font-size: 19px;"
        >
          {{getProject.title}}
        </div>
      </div>

      <v-spacer />
      <v-btn
        class="my-2 ml-4 d-none d-sm-block"
        dark
        :color="getAppearances.btn_color"
        @click="addMemberToProject()"
      >
        <v-icon left>
          mdi-account-supervisor
        </v-icon>
        Share
      </v-btn>
      <v-btn
        class="my-2 ml-4 d-sm-none"
        dark
        fab
        small
        elevation="0"
        :color="getAppearances.btn_color"
        @click="addMemberToProject()"
      >
        <v-icon>
          mdi-account-supervisor
        </v-icon>
      </v-btn>
      <AddMember :addMember.sync="addMember" />
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectMenu from "../Projects/ProjectMenu.vue";
import AddMember from "../../components/Projects/Dialogs/AddMember.vue";
import { projectUtils } from "../../mixins/project";

export default {
  name: "ToolbarComponent",

  components: {
    ProjectMenu,
    AddMember,
  },

  data: () => ({
    addMember: false,
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("projects", ["getProject", "getMobileView"]),
  },

  mixins: [projectUtils],

  methods: {
    setSort(item) {
      for (let i = 0; i < this.sortOptions.length; i++) {
        if (item.type != this.sortOptions[i].type) {
          this.sortOptions[i].icon = this.sortOptions[i].defaultIcon;
          this.sortOptions[i].order = "none";
        }
      }

      switch (item.order) {
        case "none":
          item.order = "desc";
          item.icon = item.icon.replace("variant", "descending");
          break;
        case "desc":
          item.order = "asc";
          item.icon = item.icon.replace("descending", "ascending");
          break;
        case "asc":
          item.order = "none";
          item.icon = item.icon.replace("ascending", "variant");
          break;

        default:
          break;
      }

      this.$store.commit("toolbar/setSortBy", item);
    },

    setFavourite() {
      this.$store
        .dispatch("projects/addToFavourite", {
          project_id: this.getProject.id,
        })
        .then(() => {
          let project = this.getProject;
          project.favourite = !project.favourite;

          this.$store.commit("projects/setProject", project);
        });
    },
  },
};
</script>

<style>
.border {
  border: 3px solid #ffffff;
  border-radius: 50%;
}

.gradient {
  background-image: linear-gradient(rgb(245, 245, 245), rgb(255, 255, 255));
}

.projectFont {
  font-size: 23px;
}

.maxTextLengthMobile {
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.maxTextLengthDesktop {
  min-width: none;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
