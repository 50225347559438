export const utils = {

    data: () => ({
        objectToMove: {},
        array: []
    }),

    methods: {

        removeElement(array, elem) {
            var index = array.indexOf(elem);
            if (index > -1) {
                array.splice(index, 1);
            }
        },

        renameTreeviewItem(editItem, path, array) {
            return array.reduce((a, item) => {
                item.path = item.path.replace(editItem.path, path);

                if (item.children) return this.renameTreeviewItem(editItem, path, item.children);
            }, null)
        },

        findItem(tosearch, attr, array) {
            return array.reduce((a, item) => {
                if (a) return a;
                switch (tosearch) {
                    case 'id':
                        if (attr == item.id) return item;
                        break;
                    case 'path':
                        if (attr == item.path) return item;
                        break;
                    case 'array':
                        if (attr == item.id) return array;
                        break;
                    case 'name':
                        if (attr == item.name) return item;
                        break;
                    case 'title':
                        if (attr == item.title) return item;
                        break;
                    default:
                        break;
                }
                if (item.children) return this.findItem(tosearch, attr, item.children);
            }, null)
        },

        addChildren(res) {
            /* this is to set up items for frontend */
            res.forEach(element => {
                if (element.isFolder == 1) {
                    if (!element.children) {
                        this.$set(element, "children", []);
                    } else if (!element.locked) {
                        this.$set(element, "locked", false);
                    }

                    if (element.type !== "cloud#drive") {
                        element.path = element.path + '/' + element.name
                    }
                }
            });
            return res;
        },
    }
}
