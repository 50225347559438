<template>
  <v-dialog
    v-model="moveDialog"
    max-width="500"
    persistent
    transition
  >
    <v-card>
      <v-card-title class="headline pa-4 ma-0">Move selected files to</v-card-title>
      <v-card-text class="pa-4">
        <v-alert
          dense
          outlined
          type="error"
          v-model="fileExists"
        >
          File with that name already exists in directory!
        </v-alert>
        <v-scroll-y-transition
          mode="out-in"
          hide-on-leave
        >
          <v-treeview
            :active.sync="active"
            :items="tree_folders"
            :load-children="loadChildren"
            v-on:update:active="activeChanged"
            item-key="id"
            item-disabled="locked"
            return-object
            activatable
            hoverable
            color="warning"
            transition
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.children">mdi-folder</v-icon>
            </template>
          </v-treeview>
        </v-scroll-y-transition>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!active[0] || fileExists || loading"
          :color="getAppearances.btn_color"
          class="white--text"
          @click="moveItem"
        >
          {{moveButtonText}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { utils } from "../../mixins/utils";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    active: [],
    items: [],
    path: "",
    fileExists: null,
    allowToMove: false,
    moveButtonText: "Move",
    loading: false,
  }),

  mixins: [utils],

  computed: {
    ...mapGetters("files", [
      "getItemToMove",
      "getTreeview",
      "getSelection",
      "getFiles",
      "open",
    ]),

    ...mapGetters("fileDialogs", ["moveDialog"]),

    ...mapGetters("admin", ["getAppearances"]),

    tree_folders() {
      const folders = this.getTreeview;
      this.disableNode(folders);

      return folders;
    },
  },

  methods: {
    ...mapMutations("fileDialogs", ["openMoveDialog"]),

    ...mapActions("alert", ["showAlert"]),

    closeDialog() {
      this.openMoveDialog(false);
      this.active = [];
      this.loading = false;
    },

    updateView() {
      let sel = this.getSelection;
      let files = this.getFiles;
      let arr = [];

      for (let i = 0; i < sel.length; i++) {
        arr.push(files[sel[i]]);
      }

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].isFolder) {
          this.open.forEach((element) => {
            if (arr[i].parentDir == element.id) {
              const index = element.children.findIndex(
                (item) => item.id == arr[i].id
              );
              if (index >= 0) {
                element.children.splice(index, 1);
              }
            }

            if (this.active[0].id == element.id) {
              arr[i].children = [];
              element.children.push(arr[i]);
            }
          });
        }

        const index = files.findIndex((item) => item.id == arr[i].id);
        if (index >= 0) {
          files.splice(index, 1);
        }
      }

      //Easier to refetch all treeview
    },

    moveItem() {
      const options = {
        url: "/api/files/move",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          id: this.getItemToMove.map((e) => e.id),
          path: this.active[0].id,
        },
      };

      this.loading = true;

      axios(options)
        .then(() => {
          this.updateView();
          this.closeDialog();
        })
        .catch((error) => {
          console.log(error);
          this.showAlert({
            color: "red",
            text: error.response.data, //error.response.data
          });
          this.closeDialog();
        });
    },

    loadChildren(item) {
      return fetch("/api/files/load-files/" + item.id)
        .then((res) => res.json())
        .then((json) => {
          const folders = this.addChildren(json);
          /* disables node if matches item to move */
          this.disableNode(folders);
          if (!item.children.length) {
            folders.forEach((element) => {
              if (element.isFolder == true) {
                item.children.push(element);
              }
            });
          }
        })
        .catch((error) => {
          this.showAlert({
            color: "red",
            text: error.response.data,
          });
        });
    },

    activeChanged(active) {
      this.path = active[0];
      this.fileCheck(active[0], this.getItemToMove.name);

      if (this.path == "") {
        this.path = "drive/files";
      }
    },

    loadFiles(path) {
      var url = "/api/files/load-files/" + path;
      fetch(url);
    },

    disableNode(folders) {
      for (var i = 0; i < this.getItemToMove.length; i++) {
        if (this.getItemToMove[i].isFolder == 1) {
          let result = this.findItem("id", this.getItemToMove[i].id, folders);
          if (result) {
            result.locked = true;
          }
        }
      }
    },

    fileCheck(path, name) {
      return fetch("/api/files/getfiles/" + path)
        .then((res) => res.json())
        .then((json) => {
          let itemToMove = this.getItemToMove;
          let item = this.findItem("name", name, json);
          if (item) {
            if (itemToMove.isFolder && item.isFolder == true) {
              this.fileExists = true;
            } else if (itemToMove.isFolder && item.isFolder == false) {
              this.fileExists = true;
            }
          } else {
            this.fileExists = false;
          }
        })
        .catch((error) => {
          this.showAlert({
            color: "red",
            text: error.response.data,
          });
        });
    },
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>