<template>
  <div>
    <v-menu
      :close-on-click="true"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            fab
            x-small
            depressed
            color="white"
            @click="$emit('open');"
            v-bind="attrs"
            v-on="on"
            class="my-n9"
          >
            <v-icon color="grey darken-2">mdi-dots-horizontal</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-list v-if="dragStart == false">
        <div
          v-for="(item, i) in taskMenu"
          :key="i"
        >
          <v-list-item
            dense
            @click="taskActions(item)"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider 
            class="my-3 mx-4" 
            v-if="item.divider == true"
          ></v-divider>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: {
      dragStart: Boolean,
      taskActions: Function,
    },
    data () {
      return {
        taskMenu: [
          { title: 'Edit task name', icon: 'mdi-pencil' },
          { title: 'Delete task', icon: 'mdi-delete' },
        ],
      }
    }
  }
</script>