import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    docEditor: null,
    config: null,
    opened: false,
    host: '',
  },

  getters: {
    getHost(state) {
      return state.host;
    },
    getConfig(state) {
      return state.config;
    },
    getDocEditor(state) {
      return state.docEditor;
    },
    getOpened(state) {
      return state.opened;
    }
  },

  mutations: {
    newDocEditor(state) {
      state.opened = true;

      //Fix later! Needs to load component and only then call new doc editor
      state.docEditor = new window.DocsAPI.DocEditor("placeholder", state.config);
    },
    destroyEditor(state) {
      state.opened = false;
      state.docEditor.destroyEditor();
    },
    setConfig(state, config) {
      state.config = config;
    },
    setOpened(state, status) {
      state.opened = status;
    },
    setHost(state, address) {
      if (state.host == '') {
        let office = document.createElement('script');
        office.setAttribute('type', 'text/javascript');
        office.setAttribute('src', address);
        document.body.appendChild(office)
      }
      state.address = address;
    }
  },

  actions: {
    openOffice({ commit }) {
      commit('newDocEditor');
    },
    closeOffice({ commit }) {
      commit('destroyEditor');
    },

    async openFile(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/office/get-config/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then((response) => {
            context.commit('setConfig', response.data);
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: "Failed to open file"
            }, { root: true })
          });
      })
    },

    async isEnabled(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/office/enabled',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getAddress(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/office/address',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then((response) => {
            context.commit('setHost', response.data);
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: "Failed to load office"
            }, { root: true })
          });
      })
    },

    async openPublicFile(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/public/open/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then((response) => {
            context.commit('setConfig', response.data);
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: "Failed to open file"
            }, { root: true })
          });
      })
    },

  },
  namespaced: true,
})
