<template>
  <v-menu
    :close-on-click="false"
    v-model="showAssigneMenu"
    :close-on-content-click="false"
    offset-y
    :content-class="item.id == taskID ? '' : 'elevation-0'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="error"
        offset-x="19"
        offset-y="15"
        bordered
        overlap
        :value="userStatus(item)"
      >
        <template v-slot:badge>
          <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
        </template>
        <v-avatar
          @click="assignActions(item)"
          v-bind="attrs"
          v-on="on"
          size="24"
          class="mx-3 mt-1 mb-n1"
          :tile="item.assigned_to == '' ? true : false"
        >
          <v-icon
            size="24"
            v-if="item.assigned_to == ''"
          >
            mdi-account-plus</v-icon>
          <v-img
            v-else
            size="22"
            :src="`/api/users/photo/${assignedUser(item.assigned_to)}`"
          >
          </v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-card
      v-if="item.id == taskID"
      width="400"
    >
      <v-col cols="12">
        <v-autocomplete
          v-model="selectedUser"
          :items="getProjectMembers"
          item-disabled="disabled"
          filled
          chips
          color="blue-grey lighten-2"
          label="Assign this task"
          item-text="name"
          item-value="id"
          class="mx-3 mt-3"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              color="white"
              class="mt-2"
            >
              <v-avatar left>
                <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
              </v-avatar>
              {{data.item.name + ' ' + data.item.lastname}}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-badge
                color="error"
                offset-x="25"
                offset-y="22"
                bordered
                overlap
                :value="data.item.deactivated_at == 0 ? false : true"
              >
                <template v-slot:badge>
                  <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                </template>
                <v-list-item-avatar>
                  <img :src="`/api/users/photo/${data.item.photo}`">
                </v-list-item-avatar>
              </v-badge>
              <v-list-item-content>
                <v-list-item-title> {{data.item.name + ' ' + data.item.lastname}}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-card-actions>
        <v-btn
          v-if="item.assigned_to != '' && selectedUser != ''"
          small
          color="grey lighten-3"
          class="mb-3 ml-4"
          elevation="0"
          @click="removeMember(item.id)"
        >
          Unassign
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-3 mr-1"
          text
          @click="close()"
        >
          Close
        </v-btn>
        <v-btn
          class="mr-3 mb-3"
          :color="getAppearances.btn_color"
          dark
          @click="save(), selectedUser = ''"
        >
          Assign
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    item: Object,
    taskID: String,
    closeCreate: Function,
    assigneeMenu: Boolean,
    position: String,
  },

  data() {
    return {
      selectedUser: "",
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("projects", ["getProject", "getProjectMembers", "getTask"]),

    showAssigneMenu: {
      get() {
        return this.assigneeMenu;
      },
      set(value) {
        this.$emit("update:assigneeMenu", value);
      },
    },
  },

  mixins: [projectUtils],

  methods: {
    ...mapMutations("projects", ["setAssigned"]),

    userStatus(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          if (this.getProjectMembers[i].deactivated_at == 0) {
            return false;
          } else {
            return true;
          }
        } else if (item.assigned_to == "") {
          return false;
        }
      }
    },

    save() {
      if (this.selectedUser != null && this.selectedUser != "") {
        this.setAssigned(this.selectedUser);
        if (this.taskID == this.getTask.id) {
          this.getTask.assigned_to = this.selectedUser;
        }
        this.assign();
      }
    },

    close() {
      this.showAssigneMenu = false;
    },

    openAssignee(item) {
      this.$emit("update:taskID", "");
      if (this.assigneeMenu == true) {
        this.$emit("update:assigneeMenu", false);
      } else {
        this.$emit("update:taskID", item.id);
        this.$emit("update:assigneeMenu", true);
      }
      this.closeCreate();
    },

    assign() {
      this.assignUser(this.taskID);
      this.closeCreate();
      this.$emit("update:taskID", "");
    },

    removeMember(item) {
      if (item != undefined) {
        this.removeMemberFromTask(item);
      }
      this.selectedUser = "";
      this.closeCreate();
    },

    assignActions(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          this.selectedUser = this.getProjectMembers[i];
        }
      }
      if (this.position == "list" || this.position == "board") {
        this.openAssignee(item);
      } else {
        this.$emit("update:taskID", item.id);
      }
    },
  },
};
</script>